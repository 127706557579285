import React from 'react'

import * as Mui from '@mui/material'

import Pad from '../Pad'

import styles from './SolarPaymentsYearsPage.module.css'

export interface SolarPaymentsYearsPageProps {
  altPpaPayments: number[]
  altPpaTwoPayments: number[]
  altYearlyPaymentsYears: number[]
  handleAltLen: (e: any) => void
  handleAltPpaIncrease: (e: any) => void
  handleAltPpaTwoIncrease: (e: any) => void
  handlePercentIncrease: (e: any) => void
}

export default React.memo<SolarPaymentsYearsPageProps>(
  function SolarPaymentsYearsPage({
    altPpaPayments,
    altPpaTwoPayments,
    altYearlyPaymentsYears,
    handleAltLen,
    handleAltPpaIncrease,
    handleAltPpaTwoIncrease,
    handlePercentIncrease,
  }) {
    return (
      <div className={styles['container']}>
        <div className={styles['chart-input']}>
          <Pad amt={10} />
          <Mui.FormControl>
            <Mui.InputLabel id="years-label">Years</Mui.InputLabel>
            <Mui.Select
              defaultValue="30"
              id="altYears"
              label="Years"
              labelId="years-label"
              onChange={handleAltLen}
            >
              <Mui.MenuItem value="5">5</Mui.MenuItem>
              <Mui.MenuItem value="10">10</Mui.MenuItem>
              <Mui.MenuItem value="20">20</Mui.MenuItem>
              <Mui.MenuItem value="30">30</Mui.MenuItem>
            </Mui.Select>
          </Mui.FormControl>
        </div>
        <Pad amt={30} />
        <div className={styles['wrapper-table-years']}>
          <div className={styles['table-years']}>
            <Mui.List sx={sx['list']}>
              <Mui.ListItem sx={sx['listFirstItem']}>
                Electricity
                <Mui.TextField
                  onChange={handlePercentIncrease}
                  placeholder="%"
                  size="small"
                  sx={sx['inputRate']}
                  type="number"
                />
              </Mui.ListItem>

              {altYearlyPaymentsYears.reduce(
                (acc, item) => (acc = acc + item),
              ) > 0
                ? altYearlyPaymentsYears.map((value) => (
                    <Mui.ListItem sx={sx['listFirstItem']}>
                      <Mui.ListItemText sx={sx['listItemText']}>
                        $ {value.toLocaleString('en')}
                      </Mui.ListItemText>
                    </Mui.ListItem>
                  ))
                : null}

              <Mui.ListItem sx={sx['listFirstItem']}>
                <Mui.ListItemText sx={sx['listItemText']}>
                  <h2>Total:</h2>
                  <h2>
                    {' '}
                    ${' '}
                    {altYearlyPaymentsYears
                      .reduce((acc, item) => (acc = acc + item))
                      .toLocaleString('en')}
                  </h2>
                </Mui.ListItemText>
              </Mui.ListItem>
            </Mui.List>
          </div>

          <div className={styles['table-years']}>
            <Mui.List sx={sx['list']}>
              <Mui.ListItem sx={sx['listItem']}>
                PPA 1
                <Mui.TextField
                  onChange={handleAltPpaIncrease}
                  placeholder="%"
                  size="small"
                  sx={sx['inputRate']}
                  type="number"
                />
              </Mui.ListItem>

              {altPpaPayments.reduce((acc, item) => (acc = acc + item)) > 0
                ? altPpaPayments.map((value) => (
                    <Mui.ListItem sx={sx['listItem']}>
                      <Mui.ListItemText sx={sx['listItemText']}>
                        $ {value.toLocaleString('en')}
                      </Mui.ListItemText>
                    </Mui.ListItem>
                  ))
                : null}

              <Mui.ListItem sx={sx['listItem']}>
                <Mui.ListItemText sx={sx['listItemText']}>
                  <h2 className={styles['total-label']}>Total:</h2>

                  <h2>
                    {' '}
                    ${' '}
                    {altPpaPayments
                      .reduce((acc, item) => (acc = acc + item))
                      .toLocaleString('en')}
                  </h2>
                </Mui.ListItemText>
              </Mui.ListItem>
            </Mui.List>
          </div>

          <div className={styles['table-years']}>
            <Mui.List sx={sx['list']}>
              <Mui.ListItem sx={sx['listItem']}>
                PPA 2
                <Mui.TextField
                  onChange={handleAltPpaTwoIncrease}
                  placeholder="%"
                  size="small"
                  sx={sx['inputRate']}
                  type="number"
                />
              </Mui.ListItem>

              {altPpaTwoPayments.reduce((acc, item) => (acc = acc + item)) > 0
                ? altPpaTwoPayments.map((value) => (
                    <Mui.ListItem sx={sx['listItem']}>
                      <Mui.ListItemText sx={sx['listItemText']}>
                        $ {value.toLocaleString('en')}
                      </Mui.ListItemText>
                    </Mui.ListItem>
                  ))
                : null}

              <Mui.ListItem sx={sx['listItem']}>
                <Mui.ListItemText sx={sx['listItemText']}>
                  <h2 className={styles['total-label']}>Total:</h2>

                  <h2>
                    {' '}
                    ${' '}
                    {altPpaTwoPayments
                      .reduce((acc, item) => (acc = acc + item))
                      .toLocaleString('en')}
                  </h2>
                </Mui.ListItemText>
              </Mui.ListItem>
            </Mui.List>
          </div>
        </div>
      </div>
    )
  },
)

const sx = {
  inputRate: {
    marginLeft: '10px',
    width: '70px',
  },
  list: {
    width: '100%',
  },
  listItem: {
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    display: 'flex',
    paddingBotton: '27.99px',
    paddingTop: '27.99px',
    justifyContent: 'center',
    width: '100%',
    '&:first-child': {
      borderTop: '1px solid black',
    },
  },
  listFirstItem: {
    borderBottom: '1px solid black',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
    display: 'flex',
    paddingBotton: '27.99px',
    paddingTop: '27.99px',
    justifyContent: 'center',
    width: '100%',
    '&:first-child': {
      borderTop: '1px solid black',
    },
  },
  listItemText: {
    textAlign: 'center',
  },
}
