/* eslint-disable require-unicode-regexp */
import React, { ReactElement, useState } from 'react'
import * as rn from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'

import MaskInput from 'react-native-mask-input'

const inputOptions = {
  mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
}

export interface SSNInputProps {
  blurOnSubmit?: boolean
  on: c.SurfaceType
  onChangeText?: (text: string) => void
  onEndEditing?: () => void
  returnKeyLabel?: 'next'
  returnKeyType?: 'next'
  value: string
}

const SSNInputWithForwardedRef = React.forwardRef<
  rn.TextInput | null,
  SSNInputProps
>(function SSNInput(
  { blurOnSubmit, on, onChangeText, onEndEditing, value }: SSNInputProps,
  ref,
): ReactElement {
  const styles = r.useThemedStyleSheet(themedStyles, on)
  const t = r.useTheme()

  const [localVal, setLocalVal] = useState(value)

  return (
    <>
      {/* <Label showAsterisk={showAsterisk}>{c.getFieldLabel('ssn')}</Label>

      <rn.View style={gStyles.padV4} /> */}

      <MaskInput
        allowFontScaling={false}
        autoCorrect={false}
        blurOnSubmit={blurOnSubmit}
        editable
        keyboardType="number-pad"
        mask={inputOptions.mask}
        maxLength={11}
        onChangeText={onChangeText || setLocalVal}
        onEndEditing={onEndEditing}
        placeholder="XXX-XX-XXXX"
        placeholderTextColor={t.input[on].faint}
        ref={ref}
        secureTextEntry={false}
        style={styles.input}
        value={onChangeText ? value : localVal}
      />
    </>
  )
})

export default React.memo(SSNInputWithForwardedRef)

const themedStyles = r.ThemedStyleSheet.create((t, on) => ({
  input: {
    ...t.input[on],
    fontFamily: t.fontFamily,
    paddingHorizontal: 12,
    paddingVertical: 18,
  },
}))
