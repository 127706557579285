import React from 'react'

import * as c from '../../../common'
import * as r from '../../../react-utils'
import {
  Customer,
  SelectCustomerFieldParams,
  ValueOf,
  getFieldLabel,
  makeSelectCustomerField,
  useSelector,
  updateCustomer,
} from '../../../common'

import * as Mui from '@mui/material'
import MaskedInput from 'react-text-mask'

import Pad from '../Pad'

export interface PhoneInputProps {
  customerID: string
  defaultValue?: string
  field: keyof Customer
  readonly noLenCheck?: boolean
  small?: boolean
}

export default React.memo<PhoneInputProps>(function PhoneField({
  customerID,
  defaultValue,
  field,
  noLenCheck,
  small,
}) {
  const t = r.useTheme()
  const selectCustomerField = React.useMemo(
    (): ReturnType<typeof makeSelectCustomerField> => makeSelectCustomerField(),
    [],
  )
  const selectCustomerFieldArgs = React.useMemo(
    (): SelectCustomerFieldParams => ({
      customerID,
      field,
    }),
    [customerID, field],
  )

  const [err, setErr] = React.useState('')
  // Remember to subscribe to the customer elsewhere!
  const currentValue = useSelector(
    (_): ValueOf<Customer> => selectCustomerField(_, selectCustomerFieldArgs),
  )

  const [phone, setPhone] = React.useState(
    (currentValue as string) || defaultValue || 'k',
  )

  const handleChange = React.useCallback(
    (e: any) => {
      try {
        const phoneNumber = e.target.value
        const normalizedPhone = c.normalizePhoneNumber(phoneNumber)
        updateCustomer(customerID, {
          [field]: normalizedPhone,
        })

        const checkLen = !noLenCheck

        if (checkLen && normalizedPhone.length !== 10) {
          throw new Error(
            'Phone number should be 10 digits long. E.g. (786) 786 - 7867',
          )
        }

        if (noLenCheck && normalizedPhone.length === 0) {
          setErr('')
          return
        }

        if (!c.isValidUSPhoneNumber(normalizedPhone)) {
          throw new Error(
            `Invalid phone number. Double-check the area code (${normalizedPhone.slice(
              0,
              3,
            )})`,
          )
        }

        setErr('')
      } catch (e) {
        setErr(c.processErr(e))
      }
    },

    [customerID, field, noLenCheck],
  )

  const startIcon = React.useMemo(
    () => ({
      startAdornment: (
        <Mui.InputAdornment position="start">
          {'🇺🇸'} {'+1'}
        </Mui.InputAdornment>
      ),
    }),
    [],
  )
  const renderInput = React.useCallback(
    (
      ref: (inputElement: HTMLElement) => void,
      props: {
        onChange: (event: React.ChangeEvent<HTMLElement>) => void
        onBlur: (event: React.FocusEvent<HTMLElement, Element>) => void
        defaultValue: string | undefined
      },
    ) => (
      <Mui.TextField
        {...props}
        InputProps={startIcon}
        inputRef={ref}
        label={getFieldLabel(field)}
        size={small ? 'small' : 'medium'}
        sx={{
          backgroundColor: t.input.paper.backgroundColor,
          width: small
            ? '203px'
            : {
                lg: 298,
                sm: 258,
                xs: '90%',
              },
        }}
      />
    ),
    [field, small, startIcon, t.input.paper.backgroundColor],
  )

  React.useEffect(() => {
    setPhone(currentValue as string)
  }, [currentValue])

  const containerStyle = React.useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'column',
      marginBottom: small ? 'unset' : 'default',
    }),
    [small],
  )

  return (
    <Mui.Box sx={containerStyle}>
      <MaskedInput
        guide={false}
        mask={mask}
        onChange={handleChange}
        render={renderInput}
        value={phone}
      />

      {err && (
        <Mui.Alert severity="error" sx={sx['inputAlert']}>
          {err}
        </Mui.Alert>
      )}

      {!small && <Pad amt={20} />}
    </Mui.Box>
  )
})

const sx = {
  inputAlert: {
    width: {
      lg: 298,
      sm: 258,
      xs: '90%',
    },
  },
}

const mask = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
