import React, { StrictMode, Suspense, lazy } from 'react'

import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'

import * as c from '../common'
import * as r from '../react-utils'
import { setLogger } from '../common'

import CssBaseline from '@mui/material/CssBaseline'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles'
import { kebabCase } from 'change-case'
import { DotLoader } from 'react-spinners'

import Customer from './routes/customer'
import CustomerList from './comps/CustomerList'
import GoogleMapReport from './comps/GoogleMap'
import Login from './routes/login/Login'

import { RequireAuth, auth, fireStorage } from './fire'

import * as cp from './comps'

import NotFound from './comps/NotFound'
import Services from './routes/services'
import Debts from './routes/debts'

import ScrollTop from './helpers/ScrollTop'
// import LogsPanel from './comps/LogsPanel'
import './global.css'
import OfflineAlert from './comps/OfflineAlert'
import PrivacyPolicy from './routes/privacy-policy'
import Solar from './routes/solar'
import HandleTheme from './helpers/HandleTheme'
import GlowModal from './comps/GlowModal'
const Auditor = lazy(() => import('./routes/auditor'))
const SIZE_DOT = 100

// const root = document.querySelector('body')

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    laptop: true
  }
}

setLogger(console.log)
c.setLoggerDebug(console.debug)

const ReduxWrapper = r.initProvider({
  fireStorage,
  getUserID() {
    return auth.currentUser?.uid || null
  },
})

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
})

let root = document.documentElement

const injectCSSVars = (prefix: string, theme: c.Theme | c.Layer): void => {
  for (const [k, val] of c.entries(theme)) {
    const name = prefix + kebabCase(k)
    if (typeof val === 'object') injectCSSVars(name + '-', val)
    const value = typeof val === 'number' ? val.toString() + 'px' : val!
    root.style.setProperty(name, value)
  }
}

export const App = () => {
  // This is shared across Native and Web
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  // This var is used by MaterialUI
  const [mode = 'light', setMode] = React.useState<'dark' | 'light'>(
    prefersDarkMode ? 'light' : 'dark',
  )
  React.useEffect(() => {
    setMode(prefersDarkMode ? 'dark' : 'light')
  }, [prefersDarkMode, setMode])
  React.useEffect(() => {}, [mode])
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        // setMode(mode === 'light' ? 'dark' : 'light')
      },
    }),
    [],
  )

  // React.useEffect(() => {
  //   setMode(prefersDarkMode ? 'dark' : 'light')
  // }, [prefersDarkMode])

  React.useEffect(() => {
    if (mode === 'dark') {
      console.log('setting to dark')
      document.body.classList.add('ups-dark')
      injectCSSVars('--ups-', c.dark)
    }
    if (mode === 'light') {
      console.log('setting to light')
      document.body.classList.remove('ups-dark')
      injectCSSVars('--ups-', c.light)
    }
  }, [mode])

  const theme = React.useMemo(
    () => createTheme(mode === 'dark' ? darkTheme : lightTheme),
    [mode],
  )

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StrictMode>
          <ReduxWrapper>
            <BrowserRouter>
              <OfflineAlert />
              <cp.OnlineAlert />
              <ScrollTop />
              <HandleTheme />

              <AppRoutes />
            </BrowserRouter>
          </ReduxWrapper>
        </StrictMode>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

const AppRoutes = React.memo(() => {
  const location = useLocation()
  const navigate = useNavigate()
  const background = location.state && location.state.background

  const handleGlowModalClose = React.useCallback(() => {
    const pathsArray = location.pathname.split('/')
    pathsArray.pop()
    const path = pathsArray.join('/')
    navigate(path)
  }, [location.pathname, navigate])

  return (
    <>
      <Routes location={background || location}>
        <Route
          index
          element={
            <RequireAuth>
              <Navigate to="/customers" />
            </RequireAuth>
          }
        />

        <Route element={<Login />} path="/login" />

        <Route
          element={
            <RequireAuth>
              <CustomerList />
            </RequireAuth>
          }
          path="/customers"
        />

        <Route
          element={
            <RequireAuth>
              <Customer />
            </RequireAuth>
          }
          path="/customers/:customerID"
        >
          <Route
            path="/customers/:customerID/glow"
            element={
              <RequireAuth>
                <GlowModal onClose={handleGlowModalClose} />
              </RequireAuth>
            }
          />
        </Route>
        <Route
          element={
            <Suspense
              fallback={
                <div
                  style={{
                    alignItems: 'center',
                    flexDirection: 'column',
                    display: 'flex',
                    height: '100vh',
                    justifyContent: 'center',
                    width: '100vw',
                  }}
                >
                  <DotLoader
                    color={c.themeTuple.light.danger}
                    loading={true}
                    size={SIZE_DOT}
                  />
                </div>
              }
            >
              <RequireAuth>
                <Auditor />
              </RequireAuth>
            </Suspense>
          }
          path="/customers/:customerID/auditor"
        />
        {c.IS_MAIN && (
          <Route
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100vh',
                      justifyContent: 'center',
                      width: '100vw',
                    }}
                  >
                    <DotLoader
                      color={c.themeTuple.light.danger}
                      loading={true}
                      size={SIZE_DOT}
                    />
                  </div>
                }
              >
                <RequireAuth>
                  <Auditor />
                </RequireAuth>
              </Suspense>
            }
            path="/customers/:customerID/customer"
          />
        )}
        <Route
          element={
            <Suspense
              fallback={
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    justifyContent: 'center',
                    width: '100vw',
                  }}
                >
                  <DotLoader
                    color={c.themeTuple.light.danger}
                    loading={true}
                    size={SIZE_DOT}
                  />
                </div>
              }
            >
              <RequireAuth>
                <GoogleMapReport />
              </RequireAuth>
            </Suspense>
          }
          path="/customers/:lat/:lng/address"
        />

        {c.IS_MAIN && (
          <Route
            path="/customers/:customerID/services"
            element={
              <RequireAuth>
                <Services />
              </RequireAuth>
            }
          />
        )}

        <Route path="customers/:customerID/debts" element={<Debts />} />

        {/* <Route path="logs" element={<LogsPanel />} /> */}

        <Route path="privacy-policy" element={<PrivacyPolicy />} />

        <Route path="solar" element={<Solar />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {background && (
        <Routes>
          <Route
            element={<GlowModal onClose={handleGlowModalClose} />}
            path="/customers/:customerID/glow"
          />
        </Routes>
      )}
    </>
  )
})

const baseTheme: ThemeOptions = {
  typography: {
    allVariants: {
      fontFamily: c.light.fontFamily,
    },
  },
}

const lightTheme: ThemeOptions = {
  ...baseTheme,
  palette: {
    mode: 'light',
    primary: {
      dark: c.light.backdrop.highlight,
      light: c.light.backdrop.highlight,
      main: c.themeTuple.light.paper.highlight!,
    },
    background: {
      default: c.themeTuple.light.canvas.backgroundColor!,
      paper: 'white',
    },
  },
  breakpoints: {
    values: r.MUIBreakpoints,
  },
}
const darkTheme: ThemeOptions = {
  ...baseTheme,
  palette: {
    action: {
      active: c.dark.backdrop.highlight,
      hover: c.dark.backdrop.highlight,
    },
    mode: 'dark',
    primary: {
      dark: c.dark.backdrop.highlight,
      light: c.dark.backdrop.highlight,
      main: c.dark.paper.highlight!,
    },
    background: { default: c.dark.canvas.backgroundColor! },
  },
  breakpoints: {
    values: r.MUIBreakpoints,
  },
}
