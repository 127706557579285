import React from 'react'

import { ConsolidationDebtData, Debt } from '../../../common'

import { onValue, ref } from 'firebase/database'
import { v1 } from 'uuid'

import ConsolidationDebts from '../ConsolidationDebts/ConsolidationDebts'
import PageReport from '../PageReport'
import { db } from '../../fire'

export interface ConsolidationDebtsPageProps {
  company?: string
  customerID: string
}

const emptyDebt: Debt = {
  collections: '',
  consolidationDebts: '',
  created_at: 0,
  debtsPayments: '',
  update_at: 0,
}

const emptyConsolidationDebt = [
  {
    address: '',
    closer: '',
    creditScore: '',
    id: v1(),
    name: '',
    notes: '',
  },
]

export default React.memo<ConsolidationDebtsPageProps>(
  function ConsolidationDebtsPage({
    customerID,

    company,
  }) {
    const [debt, setDebt] = React.useState<Debt>(emptyDebt)

    const consolidationDebts = JSON.parse(
      debt.consolidationDebts || JSON.stringify(emptyConsolidationDebt),
    ) as ConsolidationDebtData[]

    React.useEffect(() => {
      onValue(ref(db, `/Debts/${customerID}`), (snapshot) => {
        if (snapshot.val()) {
          const _debt = snapshot.val() as Debt
          setDebt(_debt)
        }
      })
    }, [customerID])

    return (
      <React.Fragment>
        {consolidationDebts.map((cb, i) => (
          <PageReport
            company={company ? company : 'CPS'}
            companyBorder={true}
            title="Debts Consolidation"
            key={cb.id + customerID}
          >
            <ConsolidationDebts
              company={company ? company : 'CPS'}
              customerAddress={consolidationDebts[0]?.address || ''}
              customerID={customerID}
              customerName={consolidationDebts[0]?.name || ''}
              homeRep={consolidationDebts[0]?.closer || ''}
              collections={debt.collections}
              consolidationDebts={consolidationDebts}
              debtsPayment={debt.debtsPayments}
              page={i}
              cb={cb}
              key={cb.id}
            />
          </PageReport>
        ))}
      </React.Fragment>
    )
  },
)
