import React from 'react'

import * as common from '../../../common'

import { Box } from '@mui/material'
import PageReport from '../../comps/PageReport'

import FinancingPage from '../../comps/FinancingPage/FinancingPage'
import styles from './Solar.module.css'
import FaqPage from '../../comps/FaqPage'
import Pad from '../../comps/Pad'

export default React.memo(function Solar() {
  const [company, setCompany] = React.useState<string>(
    // CAST: At least one company should be present
    common.COMPANY_VALUES[0]!,
  )

  const [solarBill, setSolarBill] = React.useState(0)
  const [powerBill, setPowerBill] = React.useState(0)
  const [alarmBill, setAlarmBill] = React.useState(0)

  const handleAlarmBill = React.useCallback(
    (value: string) => {
      setAlarmBill(parseInt(value))
    },
    [setAlarmBill],
  )

  const handleCompanyLogo = React.useCallback((): void => {
    setCompany(company === 'CPS' ? 'NPS' : 'CPS')
  }, [company])

  const handleSolarBill = React.useCallback(
    (value: string) => {
      setSolarBill(parseInt(value))
    },
    [setSolarBill],
  )

  const handlePowerBill = React.useCallback(
    (value: string) => {
      setPowerBill(parseInt(value))
    },
    [setPowerBill],
  )

  return (
    <Box
      sx={{
        backgroundColor: '#525659',
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Pad amt={40} />
      <PageReport
        company={company}
        companyBorder={true}
        title="Financing Options"
      >
        <FinancingPage
          alarmBill={alarmBill}
          company={'NPS'}
          contentUser={'NORMAL'}
          handleAlarmBill={handleAlarmBill}
          handlePowerBill={handlePowerBill}
          powerBill={powerBill}
          handleSolarBill={handleSolarBill}
          solarBill={solarBill}
          solarCompany={'NPS'}
          totalFinance={0}
        />
      </PageReport>

      <PageReport companyBorder={true} title="FAQ" company={company}>
        <FaqPage />
      </PageReport>
      <div className={styles['switch-company']}>
        <input
          type="checkbox"
          id="btnSwitch"
          className={styles['btn-switch']}
        />
        <label
          htmlFor="btnSwitch"
          className={
            company === 'CPS'
              ? styles['lbl-switch-cps']
              : styles['lbl-switch-nps']
          }
          onClick={handleCompanyLogo}
        ></label>
      </div>
    </Box>
  )
})
