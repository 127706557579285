import { TextField } from '@mui/material'
import React from 'react'

import Pad from '../../../comps/Pad'

import styles from './Alert.module.css'
import AlertAlt from './AlertAlt'
//import AlertChart from './AlertChart'

export interface AlertProps {
  handleMonthlyAlarm: (monthlyAlarm: number) => void
}

export default React.memo<AlertProps>(function Alert({ handleMonthlyAlarm }) {
  const [equipmentCost, setEquipmentCost] = React.useState(0)

  const [monthlyPayment, setMonthlyPayment] = React.useState(0)

  const [yearsOfCoverage, setYearsOfCoverage] = React.useState(5)

  const handleEquipmentCost = React.useCallback(
    (e) => {
      if (e.target.value !== 'e' || e.target.value !== 'E') {
        setEquipmentCost(parseFloat(e.target.value))
      }
    },
    [setEquipmentCost],
  )

  const handleMonthlyPayment = React.useCallback(
    (e) => {
      if (e.target.value !== 'e' || e.target.value !== 'E') {
        setMonthlyPayment(parseFloat(e.target.value))
      }
    },
    [setMonthlyPayment],
  )

  const handleYearsOfCoverage = React.useCallback(
    (e) => {
      if (e.target.value !== 'e' || e.target.value !== 'E') {
        setYearsOfCoverage(parseFloat(e.target.value))
      }
    },
    [setYearsOfCoverage],
  )

  const total = React.useMemo(
    () => monthlyPayment * yearsOfCoverage * 12 + equipmentCost,
    [equipmentCost, monthlyPayment, yearsOfCoverage],
  )

  React.useEffect(() => {
    if (isNaN(total)) {
      return
    }
    handleMonthlyAlarm(
      Math.round((total + total * (2 / 100)) / (yearsOfCoverage * 12)),
    )
  }, [handleMonthlyAlarm, total, yearsOfCoverage])
  return (
    <div className={styles['container']}>
      <Pad amt={30} />

      <h1>Alarms Reps</h1>

      <div className={styles['section-wrapper']}>
        <div className={styles['row-inputs']}>
          <Pad amt={20} />

          <div className={styles['input-group']}>
            <TextField
              id="monthlyPayment"
              onInput={handleMonthlyPayment}
              type="number"
              label="Monthly Monitoring Rate"
            />
          </div>
          <Pad amt={40} />
          <div className={styles['input-group']}>
            <TextField
              id="equipmentCost"
              onInput={handleEquipmentCost}
              type="number"
              label="  Equipment Cost"
            />
          </div>

          <Pad amt={20} />

          <div className={styles['input-group']}>
            <TextField
              defaultValue={yearsOfCoverage}
              id="yearsOfCoverage"
              onInput={handleYearsOfCoverage}
              type="number"
              label="Years"
            />
          </div>
        </div>

        <div className={styles['row-chart']}>
          <AlertAlt
            equipmentCost={equipmentCost}
            monthlyPayment={monthlyPayment}
          />

          <pre className={styles['info']}>{`Alarm coverage \nfor ${
            isNaN(yearsOfCoverage) ? 0 : yearsOfCoverage.toLocaleString('en')
          } years will be:`}</pre>
          <pre className={styles['info-total']}>
            ${' '}
            {isNaN(total)
              ? 0
              : (total + total * (2 / 100)).toLocaleString('en')}
          </pre>
        </div>
      </div>
    </div>
  )
})

// const years = [
//   { value: '3', label: '3' },
//   { value: '5', label: '5' },
//   { value: '10', label: '10' },
//   { value: '15', label: '15' },
//   { value: '20', label: '20' },
//   { value: '25', label: '25' },
// ]
