/* eslint-disable require-unicode-regexp */
import React from 'react'
import * as rn from 'react-native'
import {
  Alert,
  Linking,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'

import * as c from '../../../common'
import * as r from '../../../react-utils'

import MaskInput, { MaskInputProps } from 'react-native-mask-input'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'

import Pad from '../Pad'

const inputOptions = {
  code: 'US',
  dialCode: '+1',
  flag: '🇺🇸',
  label: 'United States',
  mask: [
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
}

export interface InputMaskPhoneProps extends MaskInputProps {
  error?: boolean
  showAsterisk?: boolean
  showCallBtn?: boolean
}

const PhoneInputWithForwardedRef = React.forwardRef<
  TextInput | null,
  InputMaskPhoneProps
>(function InputWithForwardedRef(
  { error, showAsterisk, showCallBtn, ...props },
  ref,
): React.ReactElement {
  const colorScheme = rn.useColorScheme() || 'light'
  const styles = r.useThemedStyleSheet(themedStyles)

  const [phoneNumber, setPhoneNumber] = React.useState(props.value)

  React.useEffect((): void => {
    setPhoneNumber(props.value)
  }, [props.value])

  const handleTextChange = (
    masked: string,
    unmasked: string,
    obfuscated: string,
  ): void => {
    setPhoneNumber(unmasked)
    props.onChangeText?.(masked, unmasked, obfuscated)
  }

  const handleCallNumber = React.useCallback(() => {
    const url = `tel:${phoneNumber}`

    Linking.canOpenURL(url)
      .then((supported) => {
        if (!supported) {
          Alert.alert('Phone number is not available')
          return
        }
        return Linking.openURL(url)
      })
      .catch(() => Alert.alert('Phone number is not available'))
  }, [phoneNumber])

  return (
    <View style={styles.container}>
      <View style={styles.openDialogView}>
        <Text style={styles.flagStyle}>{inputOptions.flag}</Text>
        <Pad amt={8} horizontal />
        <Text allowFontScaling={false} style={styles.dialCode}>
          {inputOptions.dialCode}
        </Text>
      </View>

      <Pad amt={12} horizontal />

      <MaskInput
        {...props}
        allowFontScaling={false}
        autoCorrect={false}
        editable
        keyboardType="number-pad"
        mask={inputOptions.mask}
        onChangeText={handleTextChange}
        placeholder={'(999) 999-9999'}
        placeholderTextColor={props.placeholderTextColor}
        ref={ref}
        secureTextEntry={false}
        style={error ? styles.inputError : styles.input}
        value={phoneNumber || ''}
      />

      {Boolean(phoneNumber) && showCallBtn && (
        <TouchableOpacity
          hitSlop={HIT_SLOP}
          onPress={handleCallNumber}
          style={styles.buttonCall}
        >
          <MaterialIcon
            color={c.themeTuple[colorScheme].canvas.highlight}
            name="add-ic-call"
            size={22}
          />
        </TouchableOpacity>
      )}
    </View>
  )
})

export default React.memo(PhoneInputWithForwardedRef)

const HIT_SLOP = {
  bottom: 10,
  left: 10,
  right: 10,
  top: 10,
} as const

const themedStyles = r.ThemedStyleSheet.create((t) => {
  const input = {
    ...t.input.canvas,
    flex: 1,
    fontFamily: t.fontFamily,
    fontSize: 16, // Matches normal input size
    paddingLeft: 12,
    paddingVertical: 18,
    borderWidth: 1,
  }

  const inputError = {
    borderColor: t.danger,
  } as const

  return {
    buttonCall: {
      alignItems: 'center',
      height: 40,
      justifyContent: 'center',
      marginLeft: 15,
    },
    buttonStyle: {
      alignItems: 'center',
      borderRadius: 3,
      marginBottom: 10,
      padding: 14,
    },
    container: {
      alignItems: 'center',
      borderRadius: 10,
      flexDirection: 'row',
    },
    dialCode: {
      color: t.canvas.color,
      fontSize: 16,
    },
    flagStyle: {
      fontSize: 24,
    },
    input,
    inputError: {
      ...input,
      ...inputError,
    },
    openDialogView: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    required: {
      color: c.themeTuple.light.third,
    },
    textCall: {
      color: t.canvas.highlight,
    },
  }
})
