import React from 'react'

import { useParams } from 'react-router-dom'

import * as common from '../../../common'
import * as milieu from '../../milieu'

import * as Mui from '@mui/material'
import * as MuiIcons from '@mui/icons-material'
import axios from 'axios'

import Pad from '../Pad'

import DialogModal from './DialogModal'
import IncentiveSection from './IncentiveSection'
import PieChart from './PieChart'
import QualifyButton from './QualifyButton'
import SelectFinanceButton from './SelectFinanceButton'
import Selectors from './Selectors'
import QualifyForm from './QualifyForm'

export interface GlowModalProps {
  onClose: () => void
}
const CancelToken = axios.CancelToken
let cancelTokenSource = CancelToken.source()
export default React.memo<GlowModalProps>(function GlowModal({ onClose }) {
  const { customerID } = useParams()

  // create a cancel token instance

  const selectCustomerField = React.useMemo(
    (): ReturnType<typeof common.makeSelectCustomerField> =>
      common.makeSelectCustomerField(),
    [],
  )
  const selectGlowCostArgs = React.useMemo(
    (): common.SelectCustomerFieldParams => ({
      customerID: customerID as string,
      field: 'glow_cost',
    }),
    [customerID],
  )

  const selectCustomerAddressArgs = React.useMemo(
    (): common.SelectCustomerFieldParams => ({
      customerID: customerID as string,
      field: 'customerAddress',
    }),
    [customerID],
  )

  const selectSolarCompanyArgs = React.useMemo(
    (): common.SelectCustomerFieldParams => ({
      customerID: customerID as string,
      field: 'solarCompany',
    }),
    [customerID],
  )
  const selectIncentiveGlowCashArgs = React.useMemo(
    (): common.SelectCustomerFieldParams => ({
      customerID: customerID as string,
      field: 'incentive_glow_cash',
    }),
    [customerID],
  )

  const selectIncentiveGlowTaxFederalCreditArgs = React.useMemo(
    (): common.SelectCustomerFieldParams => ({
      customerID: customerID as string,
      field: 'incentive_glow_federal_tax_credit',
    }),
    [customerID],
  )

  const selectIncentiveGlowTaxDeductionArgs = React.useMemo(
    (): common.SelectCustomerFieldParams => ({
      customerID: customerID as string,
      field: 'incentive_glow_tax_deduction',
    }),
    [customerID],
  )

  const glowCost = common.useSelector(
    (_): common.ValueOf<common.Customer> =>
      selectCustomerField(_, selectGlowCostArgs),
  ) as string

  const customerAddress = common.useSelector(
    (_): common.ValueOf<common.Customer> =>
      selectCustomerField(_, selectCustomerAddressArgs),
  ) as string

  const solarCompany = common.useSelector(
    (_): common.ValueOf<common.Customer> =>
      selectCustomerField(_, selectSolarCompanyArgs),
  ) as string

  const incentiveGlowFederalTaxCredit = common.useSelector(
    (_): common.ValueOf<common.Customer> =>
      selectCustomerField(_, selectIncentiveGlowTaxFederalCreditArgs),
  ) as 0 | 1

  const incentiveGlowCash = common.useSelector(
    (_): common.ValueOf<common.Customer> =>
      selectCustomerField(_, selectIncentiveGlowCashArgs),
  ) as 0 | 1

  const incentiveGlowTaxDeduction = common.useSelector(
    (_): common.ValueOf<common.Customer> =>
      selectCustomerField(_, selectIncentiveGlowTaxDeductionArgs),
  ) as 0 | 1

  const [isFederalTax, setIsFederalTax] = React.useState<0 | 1>(
    incentiveGlowFederalTaxCredit,
  )

  const [isGlowCash, setIsGlowCash] = React.useState<0 | 1>(incentiveGlowCash)

  const [isGlowTaxDeduction, setIsGlowTaxDeduction] = React.useState<0 | 1>(
    incentiveGlowTaxDeduction,
  )

  const glowCash = glowCost
    ? parseFloat((parseFloat(glowCost) - 800).toFixed(2)) / 192
    : 0

  const glowCashTotal = parseFloat((glowCash * 12 * 10).toFixed(2))

  // const remainCost = parseFloat((48475 - glowCashTotal - 6786.5).toFixed(2))

  const [selectFinanceOpen, setSelectFinanceOpen] = React.useState(false)
  const [qualifyFormOpen, setQualifyFormOpen] = React.useState(false)

  const [averageCarbonCredit, setAverageCarbonCredit] =
    React.useState<number>(0)

  const [glowType, setGlowType] = React.useState<common.GlowType>('glow_silver')

  const carbonCredit = parseFloat((averageCarbonCredit * 120).toFixed(2))

  const federalTaxCredit = isFederalTax === 1 ? 14542 : 0

  const glowCashTotalAmount = isGlowCash === 1 ? glowCashTotal : 0

  const taxDeduction = isGlowTaxDeduction === 1 ? 6786.5 : 0

  const isDataPieLoad = !!glowCost

  const remainingCost =
    48475 - federalTaxCredit - glowCashTotalAmount - taxDeduction

  const glowDataChart = React.useMemo(() => {
    if (glowType === 'glow_gold') {
      return [
        federalTaxCredit,
        glowCashTotalAmount,
        taxDeduction,
        remainingCost,
      ] as ApexAxisChartSeries | ApexNonAxisChartSeries
    }
    if (glowType === 'glow_silver') {
      return [
        federalTaxCredit,
        glowCashTotalAmount,
        taxDeduction,
        remainingCost,
      ] as ApexAxisChartSeries | ApexNonAxisChartSeries
    }
    return []
  }, [
    federalTaxCredit,
    glowCashTotalAmount,
    glowType,
    remainingCost,
    taxDeduction,
  ])

  const handleGlowType = React.useCallback(
    (glowType: common.GlowType) => {
      setGlowType(glowType)
    },
    [setGlowType],
  )

  const openSelectFinance = React.useCallback(() => {
    setSelectFinanceOpen(true)
  }, [setSelectFinanceOpen])

  const closeSelectFinance = React.useCallback(() => {
    setSelectFinanceOpen(false)
  }, [setSelectFinanceOpen])

  const openQualifyForm = React.useCallback(() => {
    setQualifyFormOpen(true)
  }, [setQualifyFormOpen])

  const closeQualifyForm = React.useCallback(() => {
    setQualifyFormOpen(false)
  }, [setQualifyFormOpen])

  // Selector options mockup
  const financeSelectors = React.useMemo((): common.Selector[] => {
    return [
      {
        label: 'Finance Company',
        onChange: common.EMPTY_FN,
        opts: [
          { label: 'LightReach', value: 'LightReach' },
          { label: 'GoodLeap', value: 'GoodLeap' },
          { label: 'Cash', value: 'Cash' },
        ],
        value: 'LightReach',
      },
      {
        label: 'Finance',
        onChange: common.EMPTY_FN,
        opts: [
          { label: 'LightReach Predictable PPA 2.99%', value: '2.99' },
          { label: 'LightReach Predictable PPA 0.99%', value: '0.99' },
          { label: 'LightReach Predictable PPA 3.50%', value: '3.50' },
        ],
        value: '2.99',
      },
    ]
  }, [])

  React.useEffect(() => {
    setIsFederalTax(incentiveGlowFederalTaxCredit)
  }, [incentiveGlowFederalTaxCredit])

  React.useEffect(() => {
    setIsGlowCash(incentiveGlowCash)
  }, [incentiveGlowCash, incentiveGlowFederalTaxCredit])

  React.useEffect(() => {
    setIsGlowTaxDeduction(incentiveGlowTaxDeduction)
  }, [incentiveGlowFederalTaxCredit, incentiveGlowTaxDeduction])

  React.useEffect(() => {
    axios
      .post(
        `${milieu.FB_FUNCTIONS_API_URL}/getCostCalcGlow`,
        {
          solarCompany,
          customerAddress,
          powerOutputMw: 0,
          pricePerWatt: 0,
        },
        { cancelToken: cancelTokenSource.token },
      )
      .then(({ data }) => {
        console.log('FN DATA: ', data)

        const { carbonCredit }: { carbonCredit: number } = data

        if (typeof carbonCredit === 'number') {
          setAverageCarbonCredit(carbonCredit)
        }
      })
      .catch((e) => {
        console.log('FN ERROR: ', e)
      })

    return () => {
      cancelTokenSource.cancel()
      cancelTokenSource = CancelToken.source()
    }
  }, [customerAddress, solarCompany])

  if (!customerID) {
    return null
  }
  return (
    <>
      <Mui.Modal open={true} onClose={onClose} sx={sx['modal']}>
        <Mui.Box sx={sx['containerCloseIconWrapper']}>
          <MuiIcons.HighlightOff onClick={onClose} sx={sx['closeIcon']} />
          <Mui.Box component={Mui.Paper} elevation={3} sx={sx['container']}>
            <Mui.Button onClick={onClose} size="small" sx={sx['doneButton']}>
              Done
            </Mui.Button>
            <Pad amt={30} />
            {/* GRID WRAPPER */}
            <Mui.Box sx={sx['gridWrapper']}>
              <Mui.Box sx={sx['buttonsGrid']}>
                <SelectFinanceButton onClick={openSelectFinance} />
                <QualifyButton onClick={openQualifyForm} />
              </Mui.Box>

              <IncentiveSection
                carbonCredit={carbonCredit}
                customerID={customerID}
                glowCash={glowCash}
              />
            </Mui.Box>
            {/* GRID WRAPPER */}
            <Mui.Box sx={sx['gridWrapper']}>
              <Mui.Box sx={sx['financeInfoGrid']}>
                <Mui.Box sx={sx['financeInfoRow']}>
                  <Mui.Typography
                    component="span"
                    sx={sx['financeInfoMonthText']}
                    variant="h6"
                  >
                    MONTHS:{' '}
                    <Mui.Typography
                      component="span"
                      sx={sx['financeInfoMonthLabel']}
                      variant="h6"
                    >
                      3-18
                    </Mui.Typography>
                  </Mui.Typography>
                  <Mui.Typography
                    component="span"
                    sx={sx['financeInfoMonthText']}
                    variant="h6"
                  >
                    MONTHS:{' '}
                    <Mui.Typography
                      component="span"
                      sx={sx['financeInfoMonthLabel']}
                      variant="h6"
                    >
                      19-120
                    </Mui.Typography>
                  </Mui.Typography>
                  <Mui.Typography
                    component="span"
                    sx={sx['financeInfoMonthText']}
                    variant="h6"
                  >
                    MONTHS:{' '}
                    <Mui.Typography
                      component="span"
                      sx={sx['financeInfoMonthLabel']}
                      variant="h6"
                    >
                      121-300
                    </Mui.Typography>
                  </Mui.Typography>
                </Mui.Box>
                <Mui.Box sx={sx['financeInfoRow']}>
                  <Mui.Typography
                    component="span"
                    sx={sx['financeInfoPriceLabel']}
                    variant="h6"
                  >
                    $274 - $180.59 = $93.41
                  </Mui.Typography>
                  <Mui.Typography
                    component="span"
                    sx={sx['financeInfoPriceLabel']}
                    variant="h6"
                  >
                    $274 - $180.59 = $93.41
                  </Mui.Typography>
                  <Mui.Typography
                    component="span"
                    sx={sx['financeInfoPriceLabel']}
                    variant="h6"
                  >
                    $274
                  </Mui.Typography>
                </Mui.Box>
              </Mui.Box>
              <Mui.Box></Mui.Box>
            </Mui.Box>
            {/* GRID WRAPPER */}
            <Mui.Box sx={sx['gridWrapper']}>
              <Mui.Box sx={sx['glowSelectorBox']}>
                <Pad amt={30} />
                <Mui.TextField
                  disabled
                  label="System Price"
                  size="small"
                  type="text"
                  value="48,475"
                />
                <Pad amt={30} />
                <GlowSelector
                  glowType={glowType}
                  handleGlowType={handleGlowType}
                />
              </Mui.Box>
              {isDataPieLoad ? (
                <PieChart data={glowDataChart} />
              ) : (
                <Mui.CircularProgress sx={sx['spinnerChart']} />
              )}
            </Mui.Box>
          </Mui.Box>
        </Mui.Box>
      </Mui.Modal>
      <DialogModal
        actionButton={common.EMPTY_FN}
        actionButtonText="Send"
        closeButtonText="Cancel"
        dialogTitle="Select Finance Option"
        onClose={closeSelectFinance}
        open={selectFinanceOpen}
      >
        <Selectors selectors={financeSelectors} />
      </DialogModal>

      <Mui.Modal onClose={closeQualifyForm} open={qualifyFormOpen}>
        <QualifyForm customerID={customerID} onClose={closeQualifyForm} />
      </Mui.Modal>
    </>
  )
})

interface GlowSelectorProps {
  glowType: common.GlowType | null
  handleGlowType: (glowType: common.GlowType) => void
}

const GlowSelector = React.memo<GlowSelectorProps>(
  ({ glowType, handleGlowType }) => {
    const handleChange = React.useCallback(
      (e) => {
        const option = e.target.value as common.GlowType

        handleGlowType(option)
      },
      [handleGlowType],
    )
    return (
      <Mui.FormControl sx={sx['glowTypeFormControl']}>
        <Mui.InputLabel id="demo-simple-select-label">
          Select Glow
        </Mui.InputLabel>
        <Mui.Select
          id="demo-simple-select"
          label="Select Glow"
          labelId="demo-simple-select-label"
          onChange={handleChange}
          size="small"
          sx={sx['select']}
          value={glowType}
        >
          {glowTypeOptions.map((opt) => (
            <Mui.MenuItem key={opt} value={opt}>
              {common.glowTypeToLabel[opt]}
            </Mui.MenuItem>
          ))}
        </Mui.Select>
      </Mui.FormControl>
    )
  },
)

const glowTypeOptions: common.GlowType[] = ['glow_gold', 'glow_silver']

const sx = {
  buttonsGrid: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    marginTop: '1vw',
  },
  closeIcon: {
    color: 'black',
    cursor: 'pointer',
    position: 'absolute',
    right: '10px',
    top: '10px',
    display: {
      laptop: 'unset',
      xs: 'none',
    },
    fontSize: '30px',
    zIndex: 1000,
  },
  container: {
    border: 'none',
    borderBox: 'content-box',
    borderRadius: '10px',
    fontSize: '1vw',
    maxHeight: '100vh',
    maxWidth: '998px',
    overflowY: 'scroll',
    padding: '20px',
    position: 'relative',
  },
  containerCloseIconWrapper: {
    position: 'relative',
  },
  doneButton: {
    cursor: 'pointer',
    display: {
      laptop: 'none',
      xs: 'unset',
    },
    fontSize: '2em',
    position: 'absolute',
    right: '0.1%',
    top: '1%',
  },
  financeGrid: {
    alignItems: 'start',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyContent: 'start',
    // padding: '1% 10%',
  },
  financeInfoMonthLabel: {
    fontWeight: '700',
    fontSize: 'inherit',
  },
  financeInfoPriceLabel: {
    fontSize: '1.4em',
    fontWeight: '700',
    marginRight: '1.4vw',
  },
  financeInfoMonthText: {
    fontSize: '1.4em',
  },
  financeInfoRow: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: {
      xs: '1vw',
      lg: 'calc(1200px*0.01)',
    },
    '&:last-child': {
      alignItems: 'flex-end',
      marginLeft: '1.4vw',
    },
  },
  financeInfoWrapper: {
    alignItems: 'start',
    display: 'grid',
    gridColumnGap: '5.6vw',
    gridTemplateColumns: '1fr 1fr',
    justifyContent: 'start',
  },
  financeInfoGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    marginTop: '1vw',
  },
  glowSelectorBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  glowTypeFormControl: {
    width: '100%',
  },
  gridWrapper: {
    alignItems: 'start',
    display: 'grid',
    gridColumnGap: '5.6vw',
    gridTemplateColumns: {
      lg: '454px calc(1024px-454px)',
      xs: '1fr 1fr',
    },
    justifyContent: 'start',
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  select: {
    '& .MuiSelect-select': {
      height: '12px',
    },
  },

  spinnerChart: {
    margin: '0 auto',
  },
}
