//#region colors
const white = '#FFF'
const black = '#000'
const nearWhite = '#DDD'
const middleGrey = '#BBB'
const middleDarkGrey = '#777'

// Used for making the header and/or other elements look just like native iOS
export const iphoneHeaderBG = '#F5F5F0'
export const iphoneHeaderBGDark = '#1C1C1C'
export const iphoneHeaderBorder = '#E6E6EA' // TODO: double-check
export const iphoneHeaderBorderDark = '#1C1C1C'
export const appleGrey = '#F3F2F8' // TODO: find exact value
export const appleSeparator = '#CBCBCB'
export const appleSeparatorDark = '#2D2D2D' // TODO: find exact value
export const appleBlue = '#0071EB'
export const appleRed = '#EB0C00'
export const appleYellowFill = '#FFEC7F'
export const appleYellowGlyph = '#F7CE46'

// UPS Theme
const upsGrey = '#EEEEF1'
const upsBorder = '#E5E4EC'
// const upsBGDark = '#2B2B2B'
// const upsBGSecDark = '#2E2A27'
export const upsLeaf = '#ACF163'
// const upsBrandDark = '#2D5007'
const upsBorderRad = 16
const upsPurple = '#7C3BEC'
const upsDarkGrey = '#2E2A27'
const upsFontFamily = 'DMSans'

// Glow colors from website

const glowSand = '#FAF7EB'
const glowLeaf = '#63D370'
const glowSun = '#EFA068'
const glowTreeBark = '#210102'
// Secondary button border and font
export const glowLightHover = '#EAE6DB'
// const glowPlatinum = '#EBE9E8'
export const glowStoneGrey = '#CBCAC9'
// const glowGravelBrown = '#534F4B'
const glowWetSand = '#CFC6BC'
export const glowSeparatorDark = 'turquoise'
const glowBorderRad = 32

// const glowText = glowDark
// const glowTextDark = glowLight

export const glowThird = '#EBE9E8'

// const glowSubContentLight = '#CBCAC9'
// const glowThird = '#EBE9E8'

//#endregion colors
//#region types
export const SurfaceTypes = ['backdrop', 'canvas', 'paper'] as const
export type SurfaceType = typeof SurfaceTypes[number]

export interface Layer {
  readonly backgroundColor?: string
  readonly borderColor?: string
  readonly borderRadius?: number
  readonly borderTopColor?: string
  readonly borderTopWidth?: number
  readonly borderWidth?: number
  readonly color?: string
  readonly disabled?: string
  readonly faint?: string
  readonly fontFamily?: string
  readonly fontWeight?:
    | 'normal'
    | 'bold'
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900'
  readonly highlight?: string
  readonly highlight__color?: string
  readonly hoverBG?: string
  readonly opaque?: string
  readonly opaque__color?: string
  readonly paddingHorizontal?: number
  readonly paddingVertical?: number
  readonly placeHolderColor?: string
  readonly separator?: string
}

export type BGAwareLayer = Readonly<Record<SurfaceType, Layer>>

export interface Theme {
  /** To be used sparingly. */
  readonly accent: string
  readonly backdrop: Layer
  readonly borderRadius?: number
  readonly button: BGAwareLayer
  readonly input: BGAwareLayer
  /** The color that most represents the company. */
  readonly brand: string
  /** Danger. */
  readonly danger: string
  /** Main content holder. */
  readonly canvas: Layer
  /** Font family for everything. */
  readonly fontFamily?: string
  /** Sub content container. */
  readonly paper: Layer
  /** Links that open an external target or new window. */
  readonly href: string
  /** Complementary color. */
  readonly third: string
  /** Warning. */
  readonly warning: string
}

interface ThemeTuple {
  readonly dark: Theme
  readonly light: Theme
}
//#endregion types
//#region default
const defaultLayerDark: Layer = {
  backgroundColor: upsDarkGrey,
  borderColor: upsDarkGrey,
  borderRadius: 0,
  borderWidth: 0,
  color: white,
  disabled: middleDarkGrey,
  fontWeight: 'normal',
  highlight: appleBlue,
  highlight__color: white,
  opaque: middleGrey,
  opaque__color: black,
  placeHolderColor: nearWhite,
  separator: middleGrey,
}

const defaultThemeDark: Theme = {
  accent: appleBlue,
  backdrop: {
    backgroundColor: iphoneHeaderBGDark,
  },
  brand: iphoneHeaderBG,
  button: {
    backdrop: { ...defaultLayerDark, borderColor: iphoneHeaderBGDark },
    canvas: { ...defaultLayerDark, borderColor: iphoneHeaderBGDark },
    paper: { ...defaultLayerDark, borderColor: iphoneHeaderBGDark },
  },
  canvas: defaultLayerDark,
  danger: appleRed,
  href: appleBlue,
  input: {
    backdrop: {},
    canvas: {
      ...defaultLayerDark,
      backgroundColor: iphoneHeaderBGDark,
      borderColor: iphoneHeaderBGDark,
    },
    paper: {
      ...defaultLayerDark,
      backgroundColor: iphoneHeaderBGDark,
      borderColor: iphoneHeaderBGDark,
    },
  },
  paper: defaultLayerDark,
  third: appleYellowFill,
  warning: appleYellowFill,
}

const defaultInputLight: Layer = {
  backgroundColor: upsGrey,
  borderColor: upsGrey,
  borderRadius: upsBorderRad,
  borderWidth: 1,
  color: black,
}

const defaultLayerLight: Layer = {
  backgroundColor: white,
  borderColor: white,
  borderRadius: 0,
  borderWidth: 1,
  color: black,
  disabled: middleDarkGrey,
  faint: nearWhite,
  fontWeight: 'normal',
  highlight: appleBlue,
  highlight__color: white,
  opaque: middleGrey,
  opaque__color: black,
  placeHolderColor: nearWhite,
  separator: middleGrey,
}

const defaultPaperLight: Layer = {
  ...defaultLayerLight,
  backgroundColor: upsGrey,
  borderColor: upsBorder,
}

const defaultThemeLight: Theme = {
  accent: appleBlue,
  backdrop: defaultLayerLight,
  brand: iphoneHeaderBorder,
  button: {
    backdrop: {},
    canvas: {},
    paper: defaultPaperLight,
  },
  canvas: defaultLayerLight,
  danger: appleRed,
  href: appleBlue,
  input: {
    backdrop: {},
    canvas: defaultInputLight,
    paper: { ...defaultInputLight, backgroundColor: white },
  },
  paper: defaultPaperLight,
  third: appleYellowFill,
  warning: appleYellowFill,
}
export const defaultThemeTuple: ThemeTuple = {
  dark: defaultThemeDark,
  light: defaultThemeLight,
}
//#endregion default

const glowPaperLight: Layer = {
  backgroundColor: glowSand,
  borderColor: glowSand,
  borderRadius: glowBorderRad,
  borderWidth: 1,
  color: glowTreeBark,
  fontFamily: 'Manrope',
  highlight: glowTreeBark,
  highlight__color: white,
  hoverBG: glowWetSand,
  placeHolderColor: nearWhite,
  separator: glowWetSand,
  opaque: upsDarkGrey,
  opaque__color: middleGrey,
}
const glowPaperDark: Layer = {
  color: glowSand,
}
const glowDefaultInputLight: Layer = {
  ...glowPaperLight,
  backgroundColor: white,
  borderColor: white,
  color: glowTreeBark,
  paddingHorizontal: 12,
  paddingVertical: 18,
}

const glowBtnBase: Layer = {
  borderRadius: Number.MAX_SAFE_INTEGER,
  borderWidth: 1,
  fontFamily: 'Manrope',
}

const upsBase: Layer = {
  borderWidth: 1,
  borderRadius: upsBorderRad,
  fontFamily: upsFontFamily,
  highlight: appleBlue,
  highlight__color: white,
}

const upsPaperDark: Layer = {
  ...upsBase,
  backgroundColor: upsDarkGrey,
  borderColor: iphoneHeaderBGDark,
  color: white,
  faint: middleDarkGrey,
  separator: iphoneHeaderBGDark,
  opaque: middleGrey,
  opaque__color: white,
}

const upsPaperLight: Layer = {
  ...upsBase,
  backgroundColor: upsGrey,
  borderColor: upsBorder,
  borderRadius: upsBorderRad,
  borderWidth: 2,
  color: black,
  faint: nearWhite,
  fontFamily: upsFontFamily,
  separator: upsBorder,
  highlight: appleBlue,
  highlight__color: white,
  opaque: middleGrey,
  opaque__color: black,
}

export const themeTuples: Readonly<Record<string, ThemeTuple>> = {
  glow: {
    dark: {
      accent: glowLeaf,
      brand: glowSand,
      backdrop: { backgroundColor: white },
      button: {
        backdrop: {
          ...glowBtnBase,
        },
        canvas: {
          ...glowBtnBase,
        },
        paper: {
          ...glowBtnBase,
          ...glowPaperLight,
        },
      },
      canvas: glowPaperDark,
      danger: appleRed,
      fontFamily: 'Manrope',
      href: glowSand,
      input: {
        backdrop: {},
        canvas: {
          ...glowPaperLight,
          backgroundColor: white,
          color: black,
          paddingHorizontal: 18,
          paddingVertical: 12,
        },
        paper: {
          ...glowPaperLight,
          backgroundColor: white,
          color: black,
          paddingHorizontal: 18,
          paddingVertical: 12,
        },
      },
      paper: glowPaperDark,
      third: '#9CC',
      warning: appleYellowFill,
    },
    light: {
      accent: glowSun,
      backdrop: { backgroundColor: white, separator: glowWetSand },
      borderRadius: glowBorderRad,
      brand: glowSand,
      button: {
        backdrop: { ...glowBtnBase },
        canvas: { ...glowBtnBase, color: glowTreeBark },
        paper: {
          ...glowBtnBase,
          backgroundColor: glowSand,
          borderColor: glowTreeBark,
          borderRadius: Number.MAX_SAFE_INTEGER,
          borderWidth: 1,
          color: glowTreeBark,
          highlight: appleBlue,
          hoverBG: glowWetSand,
        },
      },
      canvas: {
        borderRadius: glowBorderRad,
        backgroundColor: white,
        separator: glowTreeBark,
      },
      danger: appleRed,
      fontFamily: 'Manrope',
      href: appleBlue,
      input: {
        backdrop: {},
        canvas: {
          ...glowDefaultInputLight,
          backgroundColor: glowSand,
        },
        paper: glowDefaultInputLight,
      },
      paper: glowPaperLight,
      third: '#C91',
      warning: appleYellowFill,
    },
  },
  upsmain: {
    dark: {
      ...upsBase,
      accent: glowSun,
      backdrop: {
        backgroundColor: iphoneHeaderBGDark,
        color: white,
        highlight: appleBlue,
        highlight__color: white,
        separator: iphoneHeaderBorderDark,
      },
      borderRadius: upsBorderRad,
      brand: upsLeaf,
      button: {
        backdrop: {
          color: white,
        },
        canvas: {},
        paper: upsPaperDark,
      },
      canvas: {
        backgroundColor: upsDarkGrey,
        borderColor: iphoneHeaderBGDark,
        borderRadius: upsBorderRad,
        borderWidth: 1,
        color: white,
        faint: middleDarkGrey,
        highlight: appleBlue,
        opaque: middleDarkGrey,
        separator: iphoneHeaderBorderDark,
      },
      danger: appleRed,
      href: appleBlue,
      input: {
        backdrop: {},
        canvas: {
          ...glowDefaultInputLight,
          color: white,
          backgroundColor: iphoneHeaderBGDark,
          borderRadius: upsBorderRad,
          borderColor: iphoneHeaderBGDark,
          borderWidth: 1,
          fontFamily: upsFontFamily,
          placeHolderColor: middleDarkGrey,
        },
        paper: {
          ...glowDefaultInputLight,
          backgroundColor: iphoneHeaderBGDark,
          borderColor: upsDarkGrey,
          borderRadius: upsBorderRad,
          borderWidth: 1,
          color: white,
          fontFamily: upsFontFamily,
          highlight: appleBlue,
          placeHolderColor: middleDarkGrey,
        },
      },
      paper: upsPaperDark,
      third: upsPurple,
      warning: appleYellowFill,
    },
    light: {
      ...upsBase,
      accent: glowSun,
      backdrop: {
        backgroundColor: iphoneHeaderBG,
        highlight: appleBlue,
        highlight__color: white,
        separator: iphoneHeaderBorder,
      },
      brand: upsLeaf,
      button: {
        backdrop: {
          backgroundColor: black,
          color: white,
        },
        canvas: {
          color: white,
        },
        paper: upsPaperLight,
      },
      canvas: {
        backgroundColor: white,
        borderColor: white,
        borderWidth: 1,
        color: black,
        borderRadius: upsBorderRad,
        faint: nearWhite,
        highlight: appleBlue,
        opaque: middleGrey,
        separator: nearWhite,
      },
      danger: appleRed,
      href: appleBlue,
      input: {
        backdrop: { ...upsBase, backgroundColor: black },
        canvas: {
          ...upsBase,
          ...glowDefaultInputLight,
          backgroundColor: upsGrey,
          borderColor: upsGrey,
          borderRadius: upsBorderRad,
          borderWidth: 1,
          fontFamily: upsFontFamily,
        },
        paper: {
          ...upsBase,
          ...glowDefaultInputLight,
          backgroundColor: white,
          borderColor: white,
          borderRadius: upsBorderRad,
          borderWidth: 1,
          color: black,
          fontFamily: upsFontFamily,
          highlight: appleBlue,
        },
      },
      paper: upsPaperLight,
      third: upsPurple,
      warning: appleYellowFill,
    },
  },
}

//#endregion appColors
