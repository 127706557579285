import React from 'react'

import * as common from '../../../common'

import Button from '@mui/material/Button'
import ContentEditable from 'react-contenteditable'
import stripTags from 'striptags'

import Pad from '../Pad'
import { translateText } from '../../constants'

import styles from './ReleaseLiabilityPage.module.css'

export interface ReleaseLiabilityPageProps {
  c_signature_date: number
  c_signature: string
  company: string
  customerAddress: string
  customerID: string
  customerName: string
  handleLang: () => void
  lang: common.Lang
  releaseLiabilityAmount: string
  releaseLiabilityDate: number
  reportAddress: string
  reportAppID: string
  reportCompanySignature: string
  reportCompanySignDate: number
  reportCustomerName: string
  solarCompany: string
  toggleCompanySignatureModal: () => void
  toggleSignatureModal: () => void
}

export default React.memo<ReleaseLiabilityPageProps>(
  function ReleaseLiabilityPage({
    c_signature_date,
    c_signature,
    company,
    customerAddress,
    customerID,
    customerName,
    handleLang,
    lang,
    releaseLiabilityAmount,
    releaseLiabilityDate,
    reportAddress,
    reportAppID,
    reportCompanySignature,
    reportCompanySignDate,
    reportCustomerName,
    solarCompany,
    toggleCompanySignatureModal,
    toggleSignatureModal,
  }) {
    const customerSignLabel =
      lang === 'ENG' ? 'Signature of Customer' : 'Firma del Cliente'

    const companySignLabel =
      lang === 'ENG' ? 'Signature of Company' : 'Firma de la Compañia'

    const {
      day: customerSignDay,
      hour: customerSignHour,
      minutes: customerSignMinutes,
      month: customerSignMonth,
      seconds: customerSignSeconds,
      year: customerSignYear,
    } = common.getDateInfo(c_signature_date || 0)

    const {
      day: reportSignDay,
      hour: reportSignHour,
      minutes: reportSignMinutes,
      month: reportSignMonth,
      seconds: reportSignSeconds,
      year: reportSignYear,
    } = common.getDateInfo(reportCompanySignDate || 0)

    const [dateRelease, setDateRelease] = React.useState(releaseLiabilityDate)

    const dateReleaseLiabilityInfo = common.getDateInfo(
      dateRelease || Date.now(),
    )

    React.useEffect(() => {
      setDateRelease(releaseLiabilityDate)
    }, [releaseLiabilityDate])

    const handleDateReleaseLiabilityInfo = React.useCallback(
      (e) => {
        const date = Date.parse(e.target.value)
        if (isNaN(date)) {
          return
        }
        setDateRelease(date)

        common.updateCustomer(customerID, {
          release_liability_date: date,
        })
      },
      [customerID],
    )

    const handleReleaseLiabilityAmount = React.useCallback(
      (e) => {
        common.updateCustomer(customerID, {
          release_of_liability_amount: e.target.value,
        })
      },
      [customerID],
    )

    const handleReportAddress = React.useCallback(
      (e) => {
        common.updateCustomer(customerID, {
          report_address: stripTags(e.target.value),
        })
      },
      [customerID],
    )

    const handleReportAppId = React.useCallback(
      (e) => {
        common.updateCustomer(customerID, {
          report_app_id: e.target.value,
        })
      },
      [customerID],
    )

    const handleReportCustomerName = React.useCallback(
      (e) => {
        common.updateCustomer(customerID, {
          report_customer_name: stripTags(e.target.value),
        })
      },
      [customerID],
    )

    const buttonSignatureStyle = React.useMemo(
      () => ({
        backgroundColor: common.companyColors[company],
        '&:hover': {
          backgroundColor: common.companyColors[company],
        },
      }),
      [company],
    )

    const releaseOfLiabilityContent = React.useMemo(() => {
      if (company === 'CPS' || company === 'CPS_HIL' || company === 'CPS_RC') {
        return (
          <CaliforniaReleaseOfLiability
            currentDate={dateReleaseLiabilityInfo}
            handleReleaseLiabilityAmount={handleReleaseLiabilityAmount}
            handleReportAddress={handleReportAddress}
            handleReportAppId={handleReportAppId}
            handleReportCustomerName={handleReportCustomerName}
            handleSignDate={handleDateReleaseLiabilityInfo}
            lang={lang}
            releaseLiabilityAmount={releaseLiabilityAmount}
            reportAddress={reportAddress || customerAddress}
            reportAppId={reportAppID}
            reportCustomerName={reportCustomerName || customerName}
            solarCompany={solarCompany}
          />
        )
      }
      if (company === 'NPS' || company === 'NPS_HIL' || company === 'NPS_RC') {
        return (
          <NevadaReleaseOfLiability
            currentDate={dateReleaseLiabilityInfo}
            handleReleaseLiabilityAmount={handleReleaseLiabilityAmount}
            handleReportAddress={handleReportAddress}
            handleReportAppId={handleReportAppId}
            handleReportCustomerName={handleReportCustomerName}
            handleSignDate={handleDateReleaseLiabilityInfo}
            lang={lang}
            releaseLiabilityAmount={releaseLiabilityAmount}
            reportAddress={reportAddress || customerAddress}
            reportAppId={reportAppID}
            reportCustomerName={reportCustomerName || customerName}
            solarCompany={solarCompany}
          />
        )
      }

      return (
        <GeneralReleaseOfLiability
          currentDate={dateReleaseLiabilityInfo}
          customerName={customerName}
          handleSignDate={handleDateReleaseLiabilityInfo}
          lang={lang}
          solarCompany={company}
        />
      )
    }, [
      company,
      customerAddress,
      customerName,
      dateReleaseLiabilityInfo,
      handleDateReleaseLiabilityInfo,
      handleReleaseLiabilityAmount,
      handleReportAddress,
      handleReportAppId,
      handleReportCustomerName,
      lang,
      releaseLiabilityAmount,
      reportAddress,
      reportAppID,
      reportCustomerName,
      solarCompany,
    ])

    return (
      <div className={styles['container']}>
        <Button onClick={handleLang} variant="contained">
          {lang === 'ENG' ? 'Translate to Spanish' : 'Translate to English'}
        </Button>
        <Pad amt={40} />
        {releaseOfLiabilityContent}
        <Pad amt={100} />
        <div className={styles['signatures']}>
          <div className={styles['signature-container']}>
            <p className={styles['label-signature']}>{customerSignLabel}:</p>
            {c_signature ? (
              <>
                <img
                  alt=""
                  className={styles['signature-img']}
                  src={c_signature}
                  width={200}
                />
                <p>{`Timestamp: ${customerSignYear}/${customerSignMonth}/${customerSignDay} - ${customerSignHour}:${customerSignMinutes}:${customerSignSeconds}`}</p>
              </>
            ) : (
              ''
            )}
          </div>
          <div className={styles['signature-container']}>
            <p className={styles['label-signature']}>{companySignLabel}:</p>
            {reportCompanySignature ? (
              <>
                <img
                  alt=""
                  className={styles['signature-img']}
                  src={reportCompanySignature}
                  width={200}
                />
                <p>{`Timestamp: ${reportSignYear}/${reportSignMonth}/${reportSignDay} - ${reportSignHour}:${reportSignMinutes}:${reportSignSeconds}`}</p>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        {/*CUSTOMER SIGNATURE */}

        <Pad amt={20} />
        <div className={styles['signature-buttons']}>
          <Button
            onClick={toggleSignatureModal}
            sx={buttonSignatureStyle}
            variant="contained"
          >
            Customer Signature
          </Button>
          <Button
            onClick={toggleCompanySignatureModal}
            sx={buttonSignatureStyle}
            variant="contained"
          >
            Company Signature
          </Button>
        </div>
      </div>
    )
  },
)

interface CompanyReleaseOfLiabilityProps {
  currentDate: common.DateInfo
  handleReleaseLiabilityAmount: (e: any) => void
  handleReportAddress: (e: any) => void
  handleReportAppId: (e: any) => void
  handleReportCustomerName: (e: any) => void
  handleSignDate: (e: any) => void
  lang: common.Lang
  releaseLiabilityAmount: string
  reportAddress: string
  reportAppId: string
  reportCustomerName: string
  solarCompany: string
}

const NevadaReleaseOfLiability = React.memo<CompanyReleaseOfLiabilityProps>(
  ({
    currentDate,
    handleReleaseLiabilityAmount,
    handleReportAddress,
    handleReportAppId,
    handleReportCustomerName,
    handleSignDate,
    lang,
    releaseLiabilityAmount,
    reportAddress,
    reportAppId,
    reportCustomerName,
    solarCompany,
  }) => {
    const strings = React.useMemo(
      () => translateText(solarCompany)[lang],
      [lang, solarCompany],
    )
    const { year, month, day } = currentDate

    const [address, setAddress] = React.useState(reportAddress)
    const [appID, setAppID] = React.useState(reportAppId)
    const [customerName, setCustomerName] = React.useState(reportCustomerName)
    const [amount, setAmount] = React.useState(releaseLiabilityAmount)

    const handleAmount = React.useCallback(
      (e) => {
        setAmount(e.target.value)
        handleReleaseLiabilityAmount(e)
      },
      [handleReleaseLiabilityAmount],
    )

    const handleAddress = React.useCallback(
      (e) => {
        handleReportAddress(e)
        setAddress(stripTags(e.target.value))
      },
      [handleReportAddress, setAddress],
    )
    const handleAppID = React.useCallback(
      (e) => {
        handleReportAppId(e)
        setAppID(e.target.value)
      },
      [handleReportAppId, setAppID],
    )

    const handleCustomerName = React.useCallback(
      (e) => {
        handleReportCustomerName(e)
        setCustomerName(stripTags(e.target.value))
      },
      [handleReportCustomerName, setCustomerName],
    )

    return (
      <div className={styles['release-of-liability']}>
        <div className={styles['release-of-liability-inputs']}>
          <p>
            {strings['main1']}{' '}
            <input
              className={styles['input']}
              onChange={handleSignDate}
              type="date"
              value={`${year}-${month}-${day}`}
            />{' '}
            {strings['main3']} <strong>{strings['main4']}</strong>{' '}
            {strings['main5']}{' '}
            <ContentEditable
              className={styles['input']}
              onChange={handleCustomerName}
              html={customerName}
              tagName="span"
            />{' '}
            {strings['main6']}
            <ContentEditable
              className={styles['input']}
              onChange={handleAddress}
              html={address}
              tagName="span"
            />{' '}
            {strings['main7']}
            <ContentEditable
              className={styles['input']}
              onChange={handleAppID}
              html={appID}
              tagName="span"
            />{' '}
          </p>
        </div>
        <Pad amt={20} />
        <p>
          <b>{strings['b1']}</b> {strings['p1']}
        </p>
        <Pad amt={10} />
        <p>
          <b>{strings['b2']}</b> {strings['p2a']}{' '}
          <input
            className={styles['input']}
            onChange={handleAmount}
            type="number"
            value={amount}
          />{' '}
          {strings['p2b']}
        </p>
        <Pad amt={10} />
        <p>
          <b>{strings['b3']}</b> {strings['p3']}
        </p>
        <Pad amt={10} />
        <p>
          <b>{strings['b4']}</b> {strings['p4']}
        </p>
        <Pad amt={10} />
        <p>
          {' '}
          <b>{strings['b5']}</b> {strings['p5']}
        </p>
        <Pad amt={10} />
        <p>
          <b>{strings['b6']}</b> {strings['p6']}
        </p>
      </div>
    )
  },
)

const CaliforniaReleaseOfLiability = React.memo<CompanyReleaseOfLiabilityProps>(
  ({
    currentDate,
    handleReleaseLiabilityAmount,
    handleReportAddress,
    handleReportAppId,
    handleReportCustomerName,
    handleSignDate,
    lang,
    releaseLiabilityAmount,
    reportAddress,
    reportAppId,
    reportCustomerName,
    solarCompany,
  }) => {
    const strings = React.useMemo(
      () => translateText(solarCompany)[lang],
      [lang, solarCompany],
    )
    const { day, month, year } = currentDate

    const [address, setAddress] = React.useState(reportAddress)
    const [appID, setAppID] = React.useState(reportAppId)
    const [customerName, setCustomerName] = React.useState(reportCustomerName)
    const [amount, setAmount] = React.useState(releaseLiabilityAmount)

    const handleAmount = React.useCallback(
      (e) => {
        setAmount(e.target.value)
        handleReleaseLiabilityAmount(e)
      },
      [handleReleaseLiabilityAmount],
    )

    const handleAddress = React.useCallback(
      (e) => {
        handleReportAddress(e)
        setAddress(stripTags(e.target.value))
      },
      [handleReportAddress, setAddress],
    )
    const handleAppID = React.useCallback(
      (e) => {
        handleReportAppId(e)
        setAppID(e.target.value)
      },
      [handleReportAppId, setAppID],
    )

    const handleCustomerName = React.useCallback(
      (e) => {
        handleReportCustomerName(e)
        setCustomerName(stripTags(e.target.value))
      },
      [handleReportCustomerName, setCustomerName],
    )

    return (
      <div className={styles['release-of-liability']}>
        <div className={styles['release-of-liability-inputs']}>
          <p>
            {strings['main1']}{' '}
            <input
              className={styles['input']}
              onChange={handleSignDate}
              type="date"
              value={`${year}-${month}-${day}`}
            />{' '}
            {strings['main3']} <strong>{strings['main4']}</strong>{' '}
            {strings['main5']}{' '}
            <ContentEditable
              className={styles['input']}
              onChange={handleCustomerName}
              html={customerName}
              tagName="span"
            />{' '}
            {strings['main6']}
            <ContentEditable
              className={styles['input']}
              onChange={handleAddress}
              html={address}
              tagName="span"
            />{' '}
            {strings['main7']}
            <input
              className={styles['input']}
              onChange={handleAppID}
              value={appID}
            />{' '}
          </p>
        </div>
        <Pad amt={20} />
        <p>
          <b>{strings['b1']}</b> {strings['p1']}
        </p>
        <Pad amt={10} />
        <p>
          <b>{strings['b2']}</b> {strings['p2a']}{' '}
          <input
            className={styles['input']}
            onChange={handleAmount}
            type="text"
            value={amount}
          />{' '}
          {strings['p2b']}
        </p>
        <Pad amt={10} />
        <p>
          {' '}
          <b>{strings['b3']}</b> {strings['p3']}
        </p>
        <Pad amt={10} />
        <p>
          {' '}
          <b>{strings['b4']}</b> {strings['p4']}
        </p>
        <Pad amt={10} />
        <p>
          <b>{strings['b5']}</b> {strings['p5']}
        </p>
        <Pad amt={10} />
        <p>
          <b>{strings['b6']}</b> {strings['p6']}
        </p>
      </div>
    )
  },
)

interface GeneralReleaseOfLiabilityProps {
  currentDate: common.DateInfo
  customerName: string
  handleSignDate: (e: any) => void
  lang: common.Lang
  solarCompany: string
}

const GeneralReleaseOfLiability = React.memo<GeneralReleaseOfLiabilityProps>(
  ({ currentDate, customerName, handleSignDate, lang, solarCompany }) => {
    const strings = React.useMemo(
      () => translateText(solarCompany)[lang],
      [lang, solarCompany],
    )
    const { day, month, year } = currentDate

    return (
      <div className={styles['release-of-liability']}>
        <div className={styles['release-of-liability-inputs']}>
          <p>
            {strings['main1']}{' '}
            <input
              className={styles['input']}
              onChange={handleSignDate}
              type="date"
              value={`${year}-${parseInt(month, 10) + 1}-${day}`}
            />
          </p>
          <Pad amt={10} />
          <p>
            {strings['main2']}{' '}
            <input className={styles['input']} defaultValue={customerName} />{' '}
            {strings['main3']}{' '}
            <input
              className={styles['input']}
              disabled
              value={common.companyToLabel[solarCompany]}
            />
          </p>
        </div>
        <Pad amt={20} />
        <p>
          <b>{strings['b1']}</b> {strings['p1']}
        </p>
        <Pad amt={10} />
        <p>
          <b>{strings['b2']}</b> {strings['p2']}
        </p>
        <Pad amt={10} />
        <p>
          <b>{strings['b3']}</b> {strings['p3']}
        </p>
        <Pad amt={10} />
        <p>
          <b>{strings['b4']}</b> {strings['p4']}
        </p>
      </div>
    )
  },
)
