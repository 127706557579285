import React from 'react'

import * as common from '../../../common'
import * as r from '../../../react-utils'
import { Collection, Collections } from '../../../common'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'
import { ref, update } from 'firebase/database'
import { v1 } from 'uuid'

import { db } from '../../fire'

export interface CollectionInputsProps {
  collections: Collections[]
  company: string
  customerID: string
  handleSubtractCollection: (
    checked: boolean,
    collRef: React.MutableRefObject<HTMLInputElement>,
    setSubtractCollection: React.Dispatch<React.SetStateAction<number>>,
  ) => void
  i: number
  len: number
  page: number
  setSubtractCollection: React.Dispatch<React.SetStateAction<number>>
  total: number
  value: Collection
}

export default React.memo<CollectionInputsProps>(function CollectionsInputs({
  collections,
  company,
  customerID,
  handleSubtractCollection,
  i,
  len,
  page,
  setSubtractCollection,
  total,
  value,
}) {
  const colRef = React.useRef<HTMLInputElement>(document.createElement('input'))

  const [col, setCol, handleInputUpdateCollection] = r.useInputVal('')

  const StyledInput = React.useMemo(() => {
    return styled.input`
      background-color: white;
      border: 1.3px solid transparent;
      color: black;
      height: 100%;
      width: 100%;
      padding: 7px;
      outline: none;
      &:focus {
        border: 1.3px solid ${common.companyColors[company]};
      }
    `
  }, [company])

  const handleSubtract = React.useCallback(
    (e) => {
      handleSubtractCollection(e.target.checked, colRef, setSubtractCollection)
    },
    [handleSubtractCollection, setSubtractCollection],
  )

  const updateCollection = React.useCallback(
    (e) => {
      const coll: Collections[] = [
        ...collections.map((val, index) => {
          if (page === index) {
            let temp = val

            temp[i] = {
              consolidation_debt_id: value.consolidation_debt_id,
              id: value.id,
              value: e.target.value,
            }

            return temp
          }

          return val
        }),
      ]
      update(ref(db, `/Debts/${customerID}`), {
        collections: JSON.stringify(coll),
      })
    },
    [collections, customerID, i, page, value],
  )
  const updateCollectionDebounce = React.useMemo(
    () => common.debounce(updateCollection),
    [updateCollection],
  )

  const deleteRow = React.useCallback(() => {
    if (i === 0) return
    let coll = collections.slice()
    let filter = coll[page]?.filter((_, index) => i !== index) as Collections

    coll[page] = filter

    update(ref(db, `/Debts/${customerID}`), {
      collections: JSON.stringify(coll),
    })
  }, [collections, customerID, i, page])

  const addRow = React.useCallback(() => {
    let coll = collections.slice()

    coll[page]?.push({
      consolidation_debt_id: value.consolidation_debt_id,
      id: v1(),
      value: '',
    })

    update(ref(db, `/Debts/${customerID}`), {
      collections: JSON.stringify(coll),
    })
  }, [collections, customerID, page, value])

  React.useEffect(() => {
    setCol(value.value)
  }, [setCol, value])

  return (
    <Box sx={sx['container']}>
      <Box sx={sx['wrapper']}>
        <Box sx={sx['inputGroup']}>
          <Typography sx={i === 0 ? sx['label'] : sx['hidden']}>
            Collections
          </Typography>
          <Box sx={i + 1 === len ? sx['borderBottom'] : sx['borderBottomHide']}>
            <Checkbox
              defaultChecked={true}
              onChange={handleSubtract}
              sx={sx['checkbox']}
            />
            <StyledInput
              onChange={updateCollectionDebounce}
              // @ts-expect-error TODO
              onInput={handleInputUpdateCollection}
              ref={colRef}
              type="number"
              value={col}
            />
          </Box>

          <Box sx={i + 1 === len ? sx['totalCollection'] : sx['hidden']}>
            {`Total: $${total.toLocaleString('en')}`}
          </Box>
        </Box>
        <Box sx={sx['deleteIconWrapper']}>
          <Box sx={i === 0 ? sx['deleteIconBoxPad'] : sx['hidden']} />

          <HighlightOffIcon sx={sx['deleteIcon']} onClick={deleteRow} />
          <Box sx={i + 1 === len ? sx['deleteIconBoxPad'] : sx['hidden']} />
        </Box>
      </Box>
      <Box sx={i + 1 === len ? sx['addIconWrapper'] : sx['hidden']}>
        <AddCircleOutlineIcon sx={sx['addIcon']} onClick={addRow} />

        <Box sx={sx['endBoxPad']} />
      </Box>
    </Box>
  )
})

const sx = {
  addIcon: {
    color: '#4b4b4b',
    cursor: 'pointer',
    height: '20px',
    marginBottom: '10px',
    width: '20px',
  },
  addIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  borderBottom: {
    borderBottom: '1px solid rgb(133, 133, 133)',
    borderLeft: '1px solid rgb(133, 133, 133)',
    borderRight: '1px solid rgb(133, 133, 133)',
    borderTop: '1px solid rgb(133, 133, 133)',
    height: '40px',
    position: 'relative',
    width: '160px',
  },
  borderBottomHide: {
    borderBottom: 'unset',
    borderLeft: '1px solid rgb(133, 133, 133)',
    borderRight: '1px solid rgb(133, 133, 133)',
    borderTop: '1px solid rgb(133, 133, 133)',
    height: '40px',
    position: 'relative',
    width: '160px',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  checkbox: {
    left: -50,
    position: 'absolute',
    top: 'center',
    zIndex: 5,
  },
  deleteIcon: {
    color: '#4b4b4b',
    cursor: 'pointer',
    height: '20px',
    marginLeft: '4px',
    width: '20px',
  },
  deleteIconBoxPad: {
    display: 'unset',
    height: '24px',
  },
  deleteIconWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  endBoxPad: {
    alignSelf: 'flex-end',
    height: '24px',
    width: '24px',
  },
  hidden: {
    display: 'none',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    display: 'unset',
    fontWeight: '700',
    textAlign: 'center',
  },
  totalCollection: {
    color: 'red',
    display: 'unset',
    fontWeight: '700',
    marginTop: '10px',
    overflow: 'hidden',
    textAlign: 'center',
    textOverflow: 'ellipsis',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
  },
}
