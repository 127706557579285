import React from 'react'
import AlertChart from '../../routes/auditor/Alert/AlertChart'
import Pad from '../Pad'
import { Chart } from '../../helpers/Vars'
import TextField from '@mui/material/TextField'

import styles from './AlarmSection.module.css'

export interface AlarmSectionProps {
  chart: Chart
  company: string
}

export default React.memo<AlarmSectionProps>(function AlarmSection({
  chart,
  company,
}) {
  const [equipmentCost, setEquipmentCost] = React.useState(0)

  const [monthlyPayment, setMonthlyPayment] = React.useState(0)

  const [yearsOfCoverage, setYearsOfCoverage] = React.useState(5)

  const [feeAlarm, setFeeAlarm] = React.useState(2)

  const handleYearsOfCoverage = React.useCallback(
    (e: number) => {
      setYearsOfCoverage(e)
    },
    [setYearsOfCoverage],
  )

  const handleEquipmentCost = React.useCallback(
    (e) => {
      if (e.target.value !== 'e' || e.target.value !== 'E') {
        setEquipmentCost(parseFloat(e.target.value))
      }
    },
    [setEquipmentCost],
  )

  const handleMonthlyPayment = React.useCallback(
    (e) => {
      if (e.target.value !== 'e' || e.target.value !== 'E') {
        setMonthlyPayment(parseFloat(e.target.value))
      }
    },
    [setMonthlyPayment],
  )

  const handleFeeAlarm = React.useCallback(
    (e) => {
      setFeeAlarm(parseFloat(e.target.value))
    },
    [setFeeAlarm],
  )

  const amount = React.useMemo(
    () => monthlyPayment * 12 * yearsOfCoverage,
    [monthlyPayment, yearsOfCoverage],
  )

  return (
    <div className={chart === 'ALARM' ? styles['container'] : styles['hide']}>
      <div className={styles['row-inputs']}>
        <Pad amt={40} />
        <div className={styles['input-group']}>
          <TextField
            type="number"
            id="equipmentCost"
            onInput={handleEquipmentCost}
            label="Equipment Cost"
          />
        </div>
        <Pad amt={20} />

        <div className={styles['input-group']}>
          <TextField
            type="number"
            id="monthlyPayment"
            onInput={handleMonthlyPayment}
            label="Monthly Payment"
          />
        </div>
      </div>

      <Pad amt={50} />
      <ul className={styles['financing-years']}>
        <li
          style={{
            color: company === 'NPS' ? '#ffa600' : '#08be90',
            cursor: 'pointer',
            fontSize: '20px',
            listStyle: 'none',
          }}
          onClick={() => handleYearsOfCoverage(5)}
          onTouchEnd={() => handleYearsOfCoverage(5)}
          className={
            yearsOfCoverage === 5
              ? company === 'NPS'
                ? styles['selected-nps']
                : styles['selected-cps']
              : ''
          }
        >
          5 Years
        </li>
        <li
          style={{
            color: company === 'NPS' ? '#ffa600' : '#08be90',
            cursor: 'pointer',
            fontSize: '20px',
            listStyle: 'none',
          }}
          onClick={() => handleYearsOfCoverage(10)}
          onTouchEnd={() => handleYearsOfCoverage(10)}
          className={
            yearsOfCoverage === 10
              ? company === 'NPS'
                ? styles['selected-nps']
                : styles['selected-cps']
              : ''
          }
        >
          10 Years
        </li>
        <li
          style={{
            color: company === 'NPS' ? '#ffa600' : '#08be90',
            cursor: 'pointer',
            fontSize: '20px',
            listStyle: 'none',
          }}
          onClick={() => handleYearsOfCoverage(20)}
          onTouchEnd={() => handleYearsOfCoverage(20)}
          className={
            yearsOfCoverage === 20
              ? company === 'NPS'
                ? styles['selected-nps']
                : styles['selected-cps']
              : ''
          }
        >
          20 Years
        </li>
        <li
          style={{
            color: company === 'NPS' ? '#ffa600' : '#08be90',
            cursor: 'pointer',
            fontSize: '20px',
            listStyle: 'none',
          }}
          onClick={() => handleYearsOfCoverage(30)}
          onTouchEnd={() => handleYearsOfCoverage(30)}
          className={
            yearsOfCoverage === 30
              ? company === 'NPS'
                ? styles['selected-nps']
                : styles['selected-cps']
              : ''
          }
        >
          30 Years
        </li>
      </ul>

      <Pad amt={20} />

      <div className={styles['alarm-chart']}>
        <AlertChart equipment={equipmentCost} fee={feeAlarm} amount={amount} />
      </div>
      <Pad amt={20} />
      <div className={styles['interest-rate']}>
        <input
          type="number"
          step={0.1}
          min={0}
          onChange={handleFeeAlarm}
          defaultValue={feeAlarm}
        />
        <span className={styles['input-icon']}>%</span>
      </div>
      <Pad amt={50} />
    </div>
  )
})
