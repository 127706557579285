import Highcharts, { Options } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export interface AlertAltProps {
  // handleImageChart(url: string): void
  equipmentCost: number
  monthlyPayment: number
}

export default function AlertChart({
  equipmentCost,
  monthlyPayment,
}: AlertAltProps) {
  const options: Options = {
    accessibility: {
      enabled: false,
    },
    title: {
      text: ``,
      verticalAlign: 'middle',
      align: 'center',
      floating: true,
    },

    chart: {
      type: 'pie',
      width: 400,
      height: 300,
    },

    plotOptions: {
      pie: {
        innerSize: '80%',
        borderWidth: 40,
        borderColor: 'null',
        slicedOffset: 20,
        size: 290,
        dataLabels: {
          enabled: false,
          style: {
            fontSize: '16px',
          },
        },
      },
    },

    credits: {
      enabled: false,
    },

    series: [
      {
        name: '',
        type: 'pie',
        data: [
          {
            name: 'Monthly Payment',
            y: isNaN(monthlyPayment) ? 0 : monthlyPayment,
            color: 'blue',
          },
          {
            name: 'Equipment Cost',
            y: isNaN(equipmentCost) ? 0 : equipmentCost,
            color: 'red',
          },
        ],
      },
    ],
  }
  return <HighchartsReact highcharts={Highcharts} options={options} />
}
