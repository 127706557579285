import React from 'react'

import * as c from '../../../common'
import * as r from '../../../react-utils'
import * as common from '../../../common'
import {
  acSplitUnit,
  acTons,
  airConditionerTons,
  californiaCity,
  companyOptions,
  Customer,
  getFieldLabel,
  selectSetters,
  isSubField,
  mainPanelUpgradeNeededOrRequested,
  roofLayoverOptions,
  selectClosers,
  slidingDoorsSize,
  typeAcInstallation,
  updateCustomer,
  useSelector,
  Opt,
  closersAction,
  dispatch,
  settersAction,
} from '../../../common'

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Theme, SxProps } from '@mui/material'

import DropDownInputSpecialContent from '../DropDownInputSpecialContent'

export interface DropDownInputProps {
  customerID: string
  field: common.CustomerFieldDropDown
  fillAlert?: boolean
  handleValue?: (e: any) => void
}

export default function DropDownInput({
  customerID,
  fillAlert,
  field,
  handleValue,
}: DropDownInputProps) {
  const t = r.useTheme()
  const selectCustomer = React.useMemo(
    (): ReturnType<typeof common.makeSelectCustomer> =>
      common.makeSelectCustomer(),
    [],
  )

  const windowColors = r.useWindowColors()

  const selectCustomerArgs = React.useMemo((): common.SelectCustomerParams => {
    if (typeof customerID === 'string') {
      return {
        customerID,
      }
    } else {
      return {
        customerID: '',
      }
    }
  }, [customerID])

  const customer = common.useSelector(
    (_): common.Customer => selectCustomer(_, selectCustomerArgs),
  )

  // Remember to subscribe to the customer elsewhere!
  const currentValue = customer[field]

  const [value, setValue] = React.useState<c.ValueOf<c.Customer>>(currentValue)
  const [error, setError] = React.useState(false)
  const finalVal = String(value || currentValue || '')

  const handleBlur = React.useCallback(() => {
    const noValue = !value
    setError(c.fieldShouldBeFilled(field) && noValue)
  }, [field, value])

  const emptyError = (fillAlert && !value && isSubField(field)) || error

  const closers = useSelector(selectClosers())
  const setters = useSelector(selectSetters())

  const [installationCompanies, batteryType, batterySize] =
    r.useBattery(customerID)

  const _opts = React.useMemo((): c.Opts => {
    if (field === 'homeRep') {
      return closers
    }
    if (field === 'solarRep') {
      return setters
    }
    if (
      field === 'roof_layers_how_many' &&
      customer.roof_layover_or_tear === 'Layover'
    ) {
      return c.roofLayersHowManyMinOpts
    }
    if (
      field === 'roof_layers_how_many' &&
      customer.roof_layover_or_tear === 'Layover'
    ) {
      return c.roofLayersHowManyOpts
    }
    if (field === 'new_windows_color') {
      return windowColors
    }

    if (field === 'battery_installation_company') {
      return installationCompanies
    }
    if (field === 'battery_type') {
      return batteryType
    }
    if (field === 'battery_size') {
      return batterySize
    }
    if (field === 'main_panel_upgrade_installation_company') {
      return installationCompanies
    }
    if (field === 'sub_panel_upgrade_installation_company') {
      return installationCompanies
    }

    const hardcodedOpts = fieldToOpts[field]
    if (hardcodedOpts) {
      return hardcodedOpts
    }

    return [
      {
        label: 'No opts found',
        value: '',
      },
    ]
  }, [
    batterySize,
    batteryType,
    closers,
    customer.roof_layover_or_tear,
    field,
    installationCompanies,
    setters,
    windowColors,
  ])
  const opts = React.useMemo((): c.Opts => {
    if (finalVal === '') return _opts
    if (_opts.find((o) => o.value === finalVal)) return _opts
    return [..._opts, { label: finalVal, value: finalVal }]
  }, [_opts, finalVal])
  const finalValNotInOpts = opts !== _opts

  const visible = React.useMemo((): boolean => {
    if (!customer) {
      return false
    }

    return c.shouldDisplayInput(field, customer)
  }, [customer, field])

  const handleSelect = React.useCallback(
    (e: SelectChangeEvent<string | number | boolean>) => {
      const event = e as any
      if (handleValue) {
        handleValue(event)
        return
      }
      setValue(e.target.value)
      updateCustomer(customerID, {
        [field]: e.target.value,
      })
    },
    [customerID, field, handleValue],
  )
  React.useEffect(() => {
    setValue(currentValue as string)
  }, [currentValue])

  React.useEffect(() => {
    if (field === 'homeRep') {
      dispatch(closersAction())
    }

    if (field === 'solarRep') {
      dispatch(settersAction())
    }
  }, [field])

  const containerStyle = React.useMemo((): SxProps<Theme> => {
    return {
      backgroundColor: t.input.paper.backgroundColor,
      display: visible ? 'flex' : 'none',
      flexDirection: 'column',
      marginLeft: isAcTonsUnitSelect(field) ? '20px' : 'unset',
      marginBottom: '20px',
      width: {
        lg: field === 'air_conditioner_new_tons' ? 612 : 298,
        sm: 258,
        xs: '100%',
      },
    }
  }, [field, t.input.paper.backgroundColor, visible])

  return (
    <Box sx={containerStyle}>
      <FormControl sx={sx['formControlStyle']}>
        <InputLabel sx={sx['inputLabelStyle']} id="simple-select-label">
          {getFieldLabel(field)}
        </InputLabel>

        <Select
          disabled={finalValNotInOpts || opts.length === 0}
          sx={sx['selectStyle']}
          labelId="simple-select-label"
          id="simple-select"
          value={finalVal}
          label={opts.length ? getFieldLabel(field) : ''}
          displayEmpty
          onBlur={handleBlur}
          onChange={handleSelect}
        >
          {opts.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText sx={emptyError ? sx['alertStyle'] : sx['hiddenStyle']}>
          Empty Value
        </FormHelperText>
      </FormControl>
      <DropDownInputSpecialContent customerID={customerID} field={field} />
    </Box>
  )
}

const isAcTonsUnitSelect = (field: keyof Customer) => {
  if (
    field === 'air_conditioner_current_tons' ||
    field === 'air_conditioner_new_tons' ||
    field === 'air_conditioner_unit_type'
  ) {
    return true
  }

  return false
}

const fieldToOpts: common.ReadonlyRecordPartial<
  readonly Opt[],
  common.CustomerFieldDropDown
> = {
  air_conditioner_current_tons: acTons,
  air_conditioner_new_tons: acTons,
  air_conditioner_tons_how_many: airConditionerTons,
  air_conditioner_type_installation: typeAcInstallation,
  air_conditioner_unit_type: acSplitUnit,
  main_panel_upgrade_needed_or_requested: mainPanelUpgradeNeededOrRequested,
  new_windows_california_city: californiaCity,
  new_windows_sliding_glass_size: slidingDoorsSize,
  roof_california_city: californiaCity,
  roof_layers_how_many: c.roofLayersHowManyOpts,
  roof_layover_or_tear: roofLayoverOptions,
  solarCompany: companyOptions,
}

const sx = {
  formControlStyle: {
    display: 'flex',
    flexDirection: 'column',
    width: 298,
  },
  inputLabelStyle: {
    alignSelf: 'flex-start',
  },
  selectStyle: {
    width: '100%',
  },
  hiddenStyle: {
    display: 'none',
  },
  alertStyle: {
    color: 'error.main',
    display: 'unset',
  },
}
