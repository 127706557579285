import React from 'react'
import * as rn from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'

import * as gStyles from '../gStyles'

import Text from './Text'

export type LabelProps = c.r<{
  children: number | string | (number | string)[]
  iconRight?: React.ReactElement
  onHeight?: (height: number) => void
  showAsterisk?: boolean
}>

export default React.memo<LabelProps>(function Label({
  children,
  iconRight,
  onHeight,
  showAsterisk,
}) {
  const styles = r.useThemedStyleSheet(themedStyles)
  const handleLayout = React.useCallback(
    ({
      nativeEvent: {
        layout: { height },
      },
    }: rn.LayoutChangeEvent): void => {
      onHeight?.(height)
    },
    [onHeight],
  )

  return (
    <rn.View onLayout={handleLayout} style={gStyles.row}>
      <Text bold size={18}>
        {children} {showAsterisk && <Text style={styles.required}>*</Text>}
      </Text>
      {iconRight}
    </rn.View>
  )
})

const themedStyles = r.ThemedStyleSheet.create((t) => ({
  required: { color: t.third, fontFamily: t.fontFamily },
}))
