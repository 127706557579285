import React from 'react'

import * as c from '../../../common'

import gStyles from '../../global.module.css'

import styles from './Dialog.module.css'

export type DialogProps = c.r<{
  children: React.ReactNode
  onClose(): void
  open: boolean
}>

export default React.memo<DialogProps>(function Dialog({
  children,
  onClose,
  open,
}) {
  return (
    <div className={open ? styles['modal'] : gStyles['display-none']}>
      <div className={styles['modal-content']}>
        <div className={styles['modal-header']}>
          <span className={styles['close']} onClick={onClose}>
            &times;
          </span>
        </div>

        {children}
      </div>
    </div>
  )
})
