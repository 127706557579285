import React from 'react'

import * as c from '../../../common'
import * as r from '../../../react-utils'
import {
  Customer,
  CustomerSchema,
  disableInputs,
  EMPTY_FN,
  getFieldLabel,
  isSubField,
  normalizeTimestampToMs,
  number,
  string,
  updateCustomer,
} from '../../../common'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { SxProps, Theme } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import ResizableTextField from './ResizableTextField'
import ToastAlert from '../ToastAlert'
import { auth } from '../../fire'
import { authorizedEmails } from '../../helpers/Vars'

export interface ConnectedInputProps {
  customerID: string
  field: keyof Customer
  fillAlert?: boolean
}

export default React.memo<ConnectedInputProps>(function ConnectedInput({
  customerID,
  field,
  fillAlert,
}) {
  const fieldType = CustomerSchema[field]
  const isMounted = r.useIsMounted()

  const efficiencyPrices = c.useSelector(c.selectEfficiencyPrices)

  const selectCustomerArgs = React.useMemo(
    (): c.SelectCustomerParams => ({
      customerID,
    }),
    [customerID],
  )
  const selectCustomer = React.useMemo(
    (): ReturnType<typeof c.makeSelectCustomer> => c.makeSelectCustomer(),
    [],
  )
  const customer = c.useSelector(
    (_): c.Customer => selectCustomer(_, selectCustomerArgs),
  )

  // Remember to subscribe to the customer elsewhere!
  const currentValue = customer[field]

  const lastTimeout = React.useRef(setTimeout(EMPTY_FN, 0))

  const isDark = useTheme().palette.mode === 'dark'

  const [value, setValue] = React.useState(
    currentValue ? processValue(currentValue as string | number, field) : '',
  )

  const [error, setError] = React.useState('')

  const handleError = React.useCallback(
    (e) => {
      const noValue = !value
      if (c.fieldShouldBeFilled(field) && noValue) {
        setError('This field must be filled in')
      } else if (field === 'yearHomeBuilt' && e.target.value.length < 4) {
        setError('Year must be between 1900 and current year')
      } else {
        setError('')
      }
    },
    [field, value],
  )

  /**
   * Builtin type is whack.
   */
  type BetterHTMLInputTypeAttribute = 'text' | 'number' | 'date' | 'email'

  const inputType =
    React.useMemo<BetterHTMLInputTypeAttribute>((): BetterHTMLInputTypeAttribute => {
      if (field === 'sort_key') return 'date'
      if (field === 'date') return 'date'
      if (fieldType === number) return 'number'
      if (field.endsWith('_cost')) return 'number'
      if (field === 'cash_amount') return 'number'
      if (
        field === 'cash_artificial_turf' ||
        field === 'cash_efficient_boilers' ||
        field === 'cash_efficient_water_heaters' ||
        field === 'cash_floor_insulation' ||
        field === 'cash_geothermal_heat_pumps' ||
        field === 'cash_heat_pumps' ||
        field === 'cash_irrigation_systems' ||
        field === 'cash_on_demand_or_tankless_hot' ||
        field === 'cash_pool_pumps' ||
        field === 'cash_radiant_floors' ||
        field === 'cash_solar_water_heaters' ||
        field === 'cash_wall_insulation' ||
        field === 'cash_water_saving_fixtures' ||
        field === 'cash_whole_house_fans_or_ventilation' ||
        field === 'cash_windows'
      ) {
        return 'number'
      }

      if (field === 'mini_split_ton1' || field === 'mini_split_ton2')
        return 'number'
      if (
        field.endsWith('footage') ||
        field.endsWith('how_many') ||
        field.endsWith('how_much') ||
        field.endsWith('quantity') ||
        field.endsWith('sqft') ||
        field === 'yearHomeBuilt'
      ) {
        return 'number'
      }

      if (field === 'customerEmail') return 'email'

      if (fieldType === string) return 'text'

      return 'text'
    }, [field, fieldType])

  const type = field === 'date' || field === 'sort_key' ? 'date' : 'text'

  const containerRef = React.useRef<HTMLDivElement>(
    document.createElement('input'),
  )

  const [updated, setUpdated] = React.useState(false)

  const handleClose = () => {
    setUpdated(false)
  }

  React.useEffect(() => {
    setValue(processValue(currentValue as number | string, field))
  }, [currentValue, field])

  React.useEffect(() => {
    if (
      field === 'air_conditioner_customer_cost' ||
      field === 'air_conditioner_cost' ||
      field === 'mini_split_cost' ||
      field === 'mini_split_customer_cost'
    ) {
      if (currentValue !== value) {
        setUpdated(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue])

  React.useEffect(() => {
    if (!customer) {
      return
    }
  }, [customer, customerID, field, isMounted])

  const hidden = React.useMemo((): boolean => {
    try {
      return c.shouldHideInput(field, customer)
    } catch (e) {
      console.log(e)
      return false
    }
  }, [customer, field])
  const disabled = React.useMemo(() => {
    if (authorizedEmails.includes(auth.currentUser?.email as string)) {
      return false
    }
    return c.shouldDisableInput(field, customer, efficiencyPrices)
  }, [customer, field, efficiencyPrices])

  const emptyError =
    (fillAlert && !value && isSubField(field as keyof Customer)) || error

  const onChangeNotes = React.useCallback(
    (e) => {
      setValue(e.target.value)
      clearTimeout(lastTimeout.current)
      lastTimeout.current = setTimeout(() => {
        updateCustomer(customerID, {
          [field]: e.target.value,
        })
      }, 1500)
    },
    [customerID, field],
  )

  const onChange = React.useCallback(
    (e) => {
      const numberPattern = /([0-9]$)/

      if (inputType === 'number' && !e.target.value.match(numberPattern)) {
        e.target.value = ''
      }

      if (field === 'yearHomeBuilt') {
        if (e.target.value.length > 4) {
          e.target.value = ''
          return
        }
        if (
          e.target.value.length === 4 &&
          (parseInt(e.target.value || '0') < 1900 ||
            parseInt(e.target.value || '0') > new Date().getFullYear())
        ) {
          e.target.value = ''
        }

        if (e.target.value.length < 4) {
          setError('Year must be between 1900 and current year')
        } else {
          setError('')
        }
      }

      setValue(e.target.value)
      if (field === 'yearHomeBuilt' && e.target.value.length < 4) {
        return
      }

      clearTimeout(lastTimeout.current)
      lastTimeout.current = setTimeout(() => {
        updateCustomer(customerID, {
          [field]:
            field === 'date'
              ? normalizeTimestampToMs(Date.parse(e.target.value))
              : field === 'sort_key'
              ? Number(e.target.value)
              : e.target.value,
        })
      }, 1500)
    },
    [customerID, field, inputType],
  )

  const containerStyle = React.useMemo((): SxProps<Theme> => {
    return {
      display: hidden ? 'none' : 'unset',
      marginBottom: {
        sm: field.endsWith('customer_cost') ? 'unset' : '20px',
        xs: '20px',
      },
    }
  }, [field, hidden])

  // if (field === 'air_conditioner_explain_situation') {
  //   alert(`${field}-${hidden}`)
  // }

  // React.useEffect(() => {
  //   if (inputType === 'number') {
  //     connectedInputRef.current.setAttribute('min', '0')
  //   }
  // }, [inputType])

  return (
    <Box sx={containerStyle} ref={containerRef}>
      {field.toLowerCase().endsWith('notes') ? (
        <ResizableTextField
          disabled={disableInputs[field]}
          helperText={error ? 'Empty Value' : ''}
          field={field}
          onBlur={handleError}
          onChange={onChangeNotes}
          value={value}
        />
      ) : (
        <TextField
          disabled={disabled}
          error={emptyError ? true : false}
          helperText={error ? error : ''}
          label={getFieldLabel(field)}
          onBlur={handleError}
          onChange={onChange}
          type={type}
          sx={isDark ? sx['inputDark'] : sx['inputStyle']}
          value={value}
          variant="outlined"
        />
      )}

      <ToastAlert
        duration={6000}
        handleClose={handleClose}
        message="Prices Successfully updated!"
        open={updated}
        severity="success"
        type="alert"
      />
    </Box>
  )
})

const sx = {
  boxStyle: {
    display: {
      sm: 'flex',
      xs: 'none',
    },
    width: '20px',
  },
  inputStyle: {
    backgroundColor: c.light.input.paper.backgroundColor,
    height: '56px',
    width: {
      lg: 298,
      sm: 258,
      xs: '90%',
    },
  },
  inputDark: {
    backgroundColor: c.dark.input.paper.backgroundColor,
    height: '56px',
    width: {
      lg: 298,
      sm: 258,
      xs: '90%',
    },
  },
  inputStyleLight: {
    animation: 'loading-light 1s linear infinite alternate',
    height: '56px',
    width: {
      lg: 298,
      sm: 258,
      xs: '90%',
    },
  },
  textAreaStyle: {
    width: '320px',
  },
}

export interface LatLngLiteral {
  /**
   * Latitude in degrees. Values will be clamped to the range [-90, 90]. This
   * means that if the value specified is less than -90, it will be set to
   * -90. And if the value is greater than 90, it will be set to 90.
   */
  lat: number
  /**
   * Longitude in degrees. Values outside the range [-180, 180] will be
   * wrapped so that they fall within the range. For example, a value of -190
   * will be converted to 170. A value of 190 will be converted to -170. This
   * reflects the fact that longitudes wrap around the globe.
   */
  lng: number
}

const processValue = (data: string | number, field: keyof Customer) => {
  if (field === 'sort_key' || field === 'date') {
    // Cast: The date field is a number under the hood (UNIX time).
    const time =
      field === 'sort_key'
        ? normalizeTimestampToMs((data as number) * -1)
        : (data as number | string)

    let year = new Date(time).getUTCFullYear().toString()

    let day = new Date(time).getUTCDate().toString()
    day = day.length === 1 ? '0' + day : day

    let month = (new Date(time).getUTCMonth() + 1).toString()
    month = month.length === 1 ? '0' + month : month

    return `${year}-${month}-${day}`
  } else {
    return typeof data === 'number' ? data.toString() : data
  }
}
