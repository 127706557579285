import React from 'react'

import * as common from '../../../common'

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'

import Pad from '../../comps/Pad'
// import PicturePage from '../../comps/Reports/PicturePage/PicturePage'

import checkImg from '../../icons/checkIcon.png'
import dmSans from '../../fonts/DMSans-Bold.ttf'
import dmSansRegular from '../../fonts/DMSans-Regular.ttf'

import { Customer, getFieldLabel } from '../../../common'
import { CheckInput } from './Services'

export interface PDFServicesProps {
  company: string
  total: number
  checksInputs: CheckInput[]
  customerSignature: string
  customerName: string
  signatureDate: number
  solarCompany: string
  options: common.Opt[]
  cashbackTotal: number
  cashAmount: string
  customer: Customer
}

Font.register({
  family: 'DMSans',
  src: dmSans,
})

Font.register({
  family: 'DMSansRegular',
  src: dmSansRegular,
})

export default React.memo<PDFServicesProps>(function PDFServices({
  company,
  customerName,
  customerSignature,
  signatureDate,
  total,
  solarCompany,
  cashAmount,
  cashbackTotal,
  options,
  customer,
  checksInputs,
}) {
  return (
    <Document>
      <Page size="A4" style={pdfStyles['page']}>
        <View
          style={company === 'NPS' ? pdfStyles['padNps'] : pdfStyles['padCps']}
        ></View>
        {/* SERVICES TABLE */}
        <Pad amt={50} />
        <Text style={pdfStyles['customersServicesTitle']}>
          Energy Efficiencies
        </Text>
        <Pad amt={20} />
        {checksInputs.map((value, index) => (
          <View
            style={value.check ? pdfStyles['costInfo'] : pdfStyles['hidden']}
            key={index}
          >
            <View style={pdfStyles['checkSpace']}>
              <Image source={checkImg} style={pdfStyles['checkImg']} />
            </View>
            <View style={pdfStyles['costName']}>
              <Text>{value.text}</Text>
            </View>
            <Text style={pdfStyles['costPrice']}>{value.price}</Text>
          </View>
        ))}
        {parseInt(cashAmount) === cashbackTotal &&
          options.map((value) => (
            <View style={pdfStyles['costInfo']} key={value.label}>
              <View style={pdfStyles['checkSpace']}>
                <Image source={checkImg} style={pdfStyles['checkImg']} />
              </View>
              <View style={pdfStyles['costName']}>
                <Text>{getFieldLabel(value.value as keyof Customer)}</Text>
              </View>
              <Text style={pdfStyles['costPrice']}>
                {customer[value.value as keyof Customer]}
              </Text>
            </View>
          ))}
        <View
          style={[
            pdfStyles['totalEfficiency']!,
            { backgroundColor: company === 'NPS' ? '#ff6a00' : '#08be90' },
          ]}
        >
          <View style={pdfStyles['spaceCheck']}></View>
          <View style={pdfStyles['totalLabel']}>
            <Text>Total:</Text>
          </View>
          <View style={pdfStyles['totalPrice']}>
            <Text>${total}</Text>
          </View>
        </View>

        <Pad amt={20} />
        <Text>{`Roofing License of ${
          company === 'NPS' ? 'Nevada' : 'California'
        }`}</Text>
        <Pad amt={10} />
        <Text style={pdfStyles['license']}>
          {company === 'CPS'
            ? `CA
Titan Roofing Inc
Lic#1042457
(760) 755-7710`
            : `NV
Frank Tam
Lic#75175 / 78495
(702) 889-1688`}
        </Text>
      </Page>
      {/* RELEASE OF RELIABILITY  */}
      <Page size="A4" style={pdfStyles['page']}>
        <View
          style={company === 'NPS' ? pdfStyles['padNps'] : pdfStyles['padCps']}
        ></View>
        <Pad amt={20} />
        <View style={pdfStyles['releaseReliability']}>
          <Text style={pdfStyles['releaseTitle']}>RELEASE OF RELIABILITY</Text>
          <Pad amt={20} />
          <Text style={pdfStyles['releaseText']}>
            This General Release (this “Release”) is made on{' '}
            {' ' + new Date(signatureDate).toLocaleDateString('en') + ' '}
            between {' ' + customerName + ' '} and {' ' + solarCompany + ' '}
          </Text>
          <Pad amt={10} />
          <Text style={pdfStyles['releaseText']}>
            1. Releasor and anyone claiming on behalf of Releasor releases and
            forever discharges Releasee and its affiliates, successors and
            assigns, officers, employees, representatives, partners, agents and
            anyone claiming through them (collectively, the “Released Parties”),
            in their individual and/or corporate capacities from any and all
            claims outside the scope of Solar installation and liabilities,
            obligations, promises, agreements, disputes, demands, damages,
            causes of action of any nature and kind, known or unknown, which
            Releasor has or ever had or may in the future have against Releasee
            or any of the Released Parties arising out of or relating to: Adders
            to the system outside the scope of Solar Installation.(“Claims”).
          </Text>
          <Pad amt={10} />
          <Text style={pdfStyles['releaseText']}>
            2. This Release shall not be in any way construed as an admission by
            the Releasee that it has acted wrongfully with respect to Releasor
            or any other person, that it admits liability or responsibility at
            any time for any purpose, or that Releasor has any rights whatsoever
            against the Releasee.
          </Text>
          <Pad amt={10} />
          <Text style={pdfStyles['releaseText']}>
            3. This Release shall be binding upon and inure to the benefit of
            the parties and their respective heirs, administrators, personal
            representatives, executors, successors and assigns. Releasor has the
            authority to release the Claims and has not assigned or transferred
            any Claims to any other party. The provisions of this Release are
            severable. If any provision is held to be invalid or unenforceable,
            it shall not affect the validity or enforceability of any other
            provision. This Release constitutes the entire agreement between the
            parties and supersedes any and all prior oral or written agreements
            or understandings between the parties concerning the subject matter
            of this Release. This Release may not be altered, amended or
            modified, except by a written document signed by both parties. The
            terms of this Release shall be governed by and construed in
            accordance with the laws of the State/Commonwealth of{' '}
            {company === 'CPS' ? 'California' : 'Nevada'}.
          </Text>
          <Pad amt={10} />
          <Text style={pdfStyles['releaseText']}>
            4. Both parties represent they fully understand their right to
            review all aspects of this Release with attorneys of their choice,
            that they have had the opportunity to consult with attorneys of
            their choice, that they have carefully read and fully understand all
            the provisions of this Release and that they are freely, knowingly
            and voluntarily entering into this Release.
          </Text>
          {/* CUSTOMER SIGNATURE */}
          <Pad amt={30} />
          <View style={pdfStyles['customerSignature']}>
            <Text style={pdfStyles['customerSignatureLabel']}>
              Customer Signature:
            </Text>
            {customerSignature !== '' && (
              <Image
                source={customerSignature}
                style={pdfStyles['signatureImg']}
              />
            )}
          </View>
        </View>
      </Page>
    </Document>
  )
})

const pdfStyles = StyleSheet.create({
  page: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'DMSans',
  },
  customersServicesTitle: {
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: '22px',
  },
  mainImg: {
    height: '45%',
    width: '100%',
  },
  padNps: {
    backgroundColor: '#ff6a00',
    height: '15px',
    width: '100%',
  },
  padCps: {
    backgroundColor: '#08be90',
    height: '15px',
    width: '100%',
  },
  mainSection: {
    display: 'flex',
    flexDirection: 'row',
    height: '50%',
  },
  spaceLeft: {
    flexBasis: '10%',
  },
  companyBrands: {
    alignItems: 'center',
    display: 'flex',
    flexBasis: '40%',
    flexDirection: 'column',
  },
  mainTitleNps: {
    color: '#ff6a00',
    fontSize: '44px',
  },
  mainTitleCps: {
    color: '#08be90',
    fontSize: '44px',
  },
  companyLogo: {
    width: '150px',
  },
  spaceCenter: {
    flexBasis: '30%',
  },
  customerInfo: {
    display: 'flex',
    flexBasis: '20%',
    flexDirection: 'column',
    fontFamily: 'DMSansRegular',
    marginRight: '40px',
  },
  customerDataTitleCps: {
    fontSize: '14px',
    color: '#08be90',
  },
  customerDataTitleNps: {
    fontSize: '14px',
    color: '#ff6a00',
  },
  customerDataText: {
    fontSize: '10px',
  },
  efficiency: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
  },
  energyEfficiencyTitle: {
    alignSelf: 'flex-start',
    fontSize: '32px',
  },

  license: {
    fontSize: 16,
    // @ts-expect-error TODO
    fontWeight: '700',
  },

  costInfo: {
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
    borderTop: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'DMSans',
    fontSize: '12px',
    width: '60%',
  },
  checkSpace: {
    alignItems: 'center',
    borderRight: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    height: '30px',
    justifyContent: 'center',
    width: '30px',
  },
  checkImg: {
    height: '15px',
    width: '15px',
  },
  costName: {
    alignItems: 'center',
    borderRight: '1px solid black',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '50%',
  },
  costPrice: {
    alignSelf: 'center',
    textAlign: 'center',
    width: '50%',
  },
  totalEfficiency: {
    border: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
    height: '30px',
    width: '60%',
  },
  spaceCheck: {
    width: '30px',
  },
  totalLabel: {
    alignItems: 'center',
    borderRight: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '50%',
  },
  totalPrice: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '50%',
  },
  homeInfoLabel: {
    alignSelf: 'flex-start',
    fontSize: '12px',
  },
  efficiencyNote: {
    fontFamily: 'DMSansRegular',
    fontSize: '10px',
  },
  releaseReliability: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
  },

  releaseTitle: {
    alignSelf: 'flex-start',
    fontSize: '32px',
  },
  releaseText: {
    fontFamily: 'DMSansRegular',
    fontSize: '10px',
    width: '100%',
  },
  customerSignature: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: '200px',
    width: '100%',
  },
  customerSignatureLabel: {
    fontFamily: 'DMSansRegular',
    fontSize: '12px',
    marginRight: '30px',
  },
  signatureImg: {
    width: '200px',
  },
  assessment: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
  },
  assessmentTitle: {
    fontSize: '32px',
  },
  allNotes: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  note: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  noteText: {
    fontSize: '14px',
  },
  noteValue: {
    fontFamily: 'DMSansRegular',
    fontSize: '8px',
  },
  adders: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '90%',
    width: '80%',
  },
  addersTitle: {
    fontSize: '26px',
    alignSelf: 'flex-start',
  },
  addersSubtitle: {
    fontSize: '18px',
    alignSelf: 'flex-start',
  },
  addersInfo: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '50%',
  },
  addersRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  addersText: {
    alignSelf: 'center',
    fontFamily: 'DMSansRegular',
    fontSize: '12px',
    width: '50%',
  },
  addersExtraInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  addersExtraData: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'DMSansRegular',
    fontSize: '12px',
    width: '35%',
  },
  incentiveAddersText: {
    alignSelf: 'flex-start',
    fontFamily: 'DMSansRegular',
    fontSize: '12px',
  },
  initialsImg: {
    width: '150px',
  },
  frame: {
    width: '90%',
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  picture: {
    width: '100%',
  },
  pictureTitle: {
    fontSize: '26px',
    alignSelf: 'flex-start',
  },
  hidden: {
    display: 'none',
  },
})
