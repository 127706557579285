import * as c from '../common'
import * as r from '../react-utils'

export const alignItemsCenter = {
  alignItems: 'center',
} as const

export const deadCenter = {
  alignItems: 'center',
  // backgroundColor: 'teal',
  justifyContent: 'center',
} as const

export const displayNone = {
  display: 'none',
} as const

export const justifyCenter = {
  justifyContent: 'center',
} as const

export const flexGrow = { flexGrow: 1 } as const

export const shadowHalf = {
  elevation: 12,
  shadowColor: '#000000',
  shadowOffset: {
    height: 9,
    width: 0,
  },
  shadowOpacity: 0.22,
  shadowRadius: 9.22,
}

export const shadowFull = {
  elevation: 24,
  shadowColor: '#000000',
  shadowOffset: {
    height: 18,
    width: 0,
  },
  shadowOpacity: 0.25,
  shadowRadius: 20.0,
}

export const frostedBase = {
  ...shadowHalf,
  borderRadius: 16,
} as const

export const frostedLight = {
  ...frostedBase,
  backgroundColor: 'rgba(238, 238, 241, 0.8)',
} as const

export const frostedDark = {
  ...frostedBase,
  backgroundColor: 'rgba(28, 28, 28, 0.8)',
} as const

export const frostedBorderRad = 16

export const opacity0 = {
  opacity: 0,
} as const

export const padH1 = { paddingHorizontal: 1 }
export const padH2 = { paddingHorizontal: 2 }
export const padH4 = { paddingHorizontal: 4 }
export const padH6 = { paddingHorizontal: 6 }
export const padH8 = { paddingHorizontal: 8 }
export const padH12 = { paddingHorizontal: 12 }
export const padH16 = { paddingHorizontal: 16 }

export const padV1 = { paddingVertical: 1 }
export const padV2 = { paddingVertical: 2 }
export const padV4 = { paddingVertical: 4 }
export const padV6 = { paddingVertical: 6 }
export const padV8 = { paddingVertical: 8 }
export const padV12 = { paddingVertical: 12 }
export const padV16 = { paddingVertical: 16 }

export const row = {
  flexDirection: 'row',
} as const

export const rowCentered = {
  ...alignItemsCenter,
  ...row,
} as const

export const shrink = {
  flexShrink: 1,
} as const

export const spaceBetween = {
  justifyContent: 'space-between',
} as const

export const whiteBG = {
  backgroundColor: 'white',
} as const

export const width10 = { width: '10%' }
export const width36 = { width: '36%' }
export const width40 = { width: '40%' }
export const width48 = { width: '48%' }
export const width50 = { width: '50%' }
export const width56 = { width: '56%' }
export const width100 = { width: '100%' }

//#region composite
export const rowSpaceBetween = { ...row, ...spaceBetween }
//#endregion composite

export const themed = r.ThemedStyleSheet.create((t, on) => ({
  separatorH: { backgroundColor: t[on].separator, height: 1, width: '100%' },
  separatorV: { backgroundColor: t[on].separator, height: '100%', width: 1 },
}))

export const useGlobalStyles = (on?: c.SurfaceType) =>
  r.useThemedStyleSheet(themed, on)
