import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import * as rn from 'react-native'

import * as c from '../common'

import moment from 'moment'
import { Provider } from 'react-redux'

interface RoofClaimsProps {
  readonly customerID: string
}

export function useIsRoofClaims({ customerID }: RoofClaimsProps) {
  const selectCustomer = React.useMemo(
    (): ReturnType<typeof c.makeSelectCustomer> => c.makeSelectCustomer(),
    [],
  )

  const selectCustomerArgs = React.useMemo((): c.SelectCustomerParams => {
    if (typeof customerID === 'string') {
      return {
        customerID,
      }
    } else {
      return {
        customerID: '',
      }
    }
  }, [customerID])

  const currentCustomer = c.useSelector(
    (_): c.Customer => selectCustomer(_, selectCustomerArgs),
  )

  return c.roofCompanies.includes(currentCustomer.solarCompany)
}

export const useIsMounted = () => {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return useCallback(() => isMounted.current, [])
}

export const useBool = (initialState: boolean) => {
  const [currState, setState] = useState<boolean>(initialState)

  const toggle = useCallback((): void => {
    setState((curr) => !curr)
  }, [])

  return [currState, setState, toggle] as const
}

export const useInputVal = (initVal = '') => {
  const [val, setVal] = React.useState<string>(initVal)
  const handleClean = React.useCallback(() => void setVal(''), [])
  const handleInputChange = React.useCallback(
    (e: { target: { value: string } }): void => {
      setVal(e.target.value)
    },
    [],
  )

  return [val, setVal, handleInputChange, handleClean] as const
}

export function useObj<R extends object>(initialState: R = c.EMPTY_OBJ) {
  const [obj, setObj] = React.useState<R>(initialState)

  const assign = React.useCallback((data: R): void => {
    setObj((_) => ({
      ..._,
      ...data,
    }))
  }, [])

  return [obj, setObj, assign] as const
}

export const useWhatChanged = (deps: unknown[], name: string): void => {
  const refs = deps.map(
    // Hooks can be called inside map() if the array doesn't change length.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    (val): React.MutableRefObject<unknown> => useRef<unknown>(val),
  )

  useEffect((): void => {
    for (let i = 0; i < deps.length; i++) {
      const dep = deps[i]

      if (dep !== refs[i]!.current) {
        c.log(`${name} -> dep changed at index ${i}`)
        refs[i]!.current = dep
      }
    }
    // TODO: Look at why deps are specified this way
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export const useForceUpdate = () => {
  const [, setTimestamp] = useState(Date.now())

  return useCallback(() => {
    setTimestamp(Date.now())
  }, [])
}

export const useBattery = (customerID: string) => {
  const [installationCompaniesStr] = useSetting<string>('installationCompanies')
  const [batteryTypeStr] = useSetting<string>('batteryType')
  const [allBatterySizes] = useSetting<Record<string, string>>('batterySize')
  const [customerBatteryInstaller] = useCustomerField(
    customerID,
    'battery_installation_company',
  )
  const [customerBatteryType] = useCustomerField(customerID, 'battery_type')

  const batterySize = React.useMemo(() => {
    if (
      !customerBatteryType ||
      !customerBatteryType ||
      !customerBatteryInstaller ||
      !allBatterySizes
    ) {
      return c.EMPTY_ARRAY
    }

    const key = `Battery ${customerBatteryType}-${customerBatteryInstaller}`
    return allBatterySizes[key]
  }, [allBatterySizes, customerBatteryInstaller, customerBatteryType])

  return [
    c.convertToOptions(installationCompaniesStr),
    c.convertToOptions(batteryTypeStr),
    c.convertToOptions(batterySize),
  ] as const
}

export const initProvider = ({ fireStorage, getUserID }: c.InitStoreParams) => {
  const store = c.initStore({
    fireStorage,
    getUserID,
  })

  return React.memo<{
    children: React.ReactElement
    loading?: React.ReactElement
  }>(({ children }) => <Provider store={store}>{children}</Provider>)
}

interface IChangeEvent {
  readonly target: {
    readonly value: string
  }
}

export const useInput = (initialState: string) => {
  const [currState, setState] = useState<string>(initialState)

  const onChange = useCallback((e: IChangeEvent): void => {
    setState(e.target.value)
  }, [])

  return [currState, setState, onChange] as const
}

export const useTextInput = (initialState: string) => {
  const [currState, setState] = useState<string>(initialState)

  const onChangeText = useCallback((text: string): void => {
    setState(text)
  }, [])

  return [currState, setState, onChangeText] as const
}

export function useCustomer(customerID: string) {
  const selectCustomer = React.useMemo(
    (): ReturnType<typeof c.makeSelectCustomer> => c.makeSelectCustomer(),
    [],
  )

  const selectCustomerArgs = React.useMemo(
    (): c.SelectCustomerParams => ({
      customerID,
    }),
    [customerID],
  )

  const currentCustomer = c.useSelector(
    (_): c.Customer => selectCustomer(_, selectCustomerArgs),
  )

  return [currentCustomer] as const
}

/**
 * Customer must be subbed to elsewhere.
 */
export const useCustomerField = <K extends c.CustomerField>(
  customerID: string,
  field: K,
) => {
  type FieldVal = c.Customer[K]

  const isMounted = useIsMounted()

  const selectCustomerField = React.useMemo(
    () => c.makeSelectCustomerField<K>(),
    [],
  )
  const selectCustomerFieldArgs = React.useMemo(
    () => ({
      customerID,
      field,
    }),
    [customerID, field],
  )
  const currValue = c.useSelector(
    (_): c.CustomerValue => selectCustomerField(_, selectCustomerFieldArgs),
  ) as FieldVal

  const [localValue, setLocalValue] = React.useState<FieldVal>(currValue)

  React.useEffect(() => {
    if (!isMounted()) {
      console.warn(`useCustomerField() -> tried to set state unmounted`)
    }
    setLocalValue(currValue)
  }, [currValue, isMounted])

  type SetFieldAction = React.SetStateAction<FieldVal>
  type FieldDispatch = React.Dispatch<SetFieldAction>

  const writeValue = React.useCallback<FieldDispatch>(
    (newValueOrDispatch) => {
      if (typeof newValueOrDispatch === 'function') {
        setLocalValue((prev) => {
          const newVal = newValueOrDispatch(prev)
          c.updateCustomer(customerID, {
            [field]: newVal,
          })
          return newVal
        })
      } else {
        setLocalValue(newValueOrDispatch)
        c.updateCustomer(customerID, {
          [field]: newValueOrDispatch,
        })
      }
    },
    [customerID, field],
  )

  const toggleValue = React.useCallback(() => {
    // @ts-expect-error
    setLocalValue((b) => !b)
  }, [])

  return [localValue as c.Customer[K], writeValue, toggleValue] as const
}

export const useSetting = <Cast extends c.ValidRootValue>(key: string) => {
  const selectSetting = React.useMemo(() => c.makeSelectSetting(), [])
  const selectSettingArgs = React.useMemo(
    (): c.SelectSettingParams => ({
      key,
    }),
    [key],
  )
  const currVal = c.useSelector(
    (_): c.ValidRootValue => selectSetting(_, selectSettingArgs),
  ) as Cast

  type WriteVal = Dispatch<SetStateAction<Cast>>
  const writeVal = React.useCallback<WriteVal>(
    (valueOrSetter) => {
      c.dispatch(
        c.setSetting({
          key,
          value:
            typeof valueOrSetter === 'function'
              ? valueOrSetter(currVal)
              : valueOrSetter,
        }),
      )
    },
    [currVal, key],
  )

  return [currVal, writeVal] as const
}

export const ghostDiv: HTMLDivElement = (() => {
  if (c.IS_WEB) {
    // @ts-ignore TODO
    return document.createElement('div')
  }
  return null as unknown as HTMLDivElement
})()
export const ghostInput: HTMLInputElement = (() => {
  if (c.IS_WEB) {
    // @ts-ignore TODO
    return document.createElement('input')
  }
  return null as unknown as HTMLInputElement
})()
export const ghostP = (() => {
  if (c.IS_WEB) {
    // @ts-ignore TODO
    return document.createElement('p')
  }
  return null as unknown as HTMLParagraphElement
})()

export interface RowableUsed<Field extends c.FieldRowable> {
  addRow(): void
  changeVal(
    col: string,
    rowID: string,
    val: React.SetStateAction<number | string>,
  ): void
  cols: c.Cols
  deleteRow(rowID: string): void
  rows: readonly c.FieldToRowable<Field>[]
}

export const useRowable = <Field extends c.FieldRowable>(
  customerID: string,
  field: c.FieldRowable,
): RowableUsed<Field> => {
  const [createdAt] = useCustomerField(customerID, 'createdAt')
  const [homeRep] = useCustomerField(customerID, 'homeRep')

  const [windowColorsStr] = useSetting<string>('windowColors')
  const windowColors = JSON.parse(windowColorsStr || '[]') as readonly string[]

  const cols = React.useMemo((): c.Cols => {
    const _cols = c.rowableToCols[field]

    if (field === 'new_windows_sqft_each_window') {
      const shouldShowGlobalColor = createdAt > c.Jun4_2024

      const theCols = _cols.slice()

      if (windowColors)
        (theCols[2] as c.OptCol) = {
          ...(theCols[2] as c.OptCol),
          opts: windowColors.map(
            (c): c.Opt => ({
              label: c,
              value: c,
            }),
          ),
        }

      const isGiovanniCustomer = homeRep.toLowerCase().includes('giovanni')
      // always an old customer
      if (isGiovanniCustomer) {
        theCols.push(c.windowTypeCol)
      }

      if (shouldShowGlobalColor) {
        theCols.splice(2, 1)
        theCols[0] = {
          ...theCols[0],
          widthPer: 30,
        } as c.Col
        theCols[1] = {
          ...theCols[1],
          widthPer: 30,
        } as c.Col
        theCols[2] = {
          ...theCols[2],
          widthPer: 30,
        } as c.Col
      }

      // Remove individual color selector for newer customers
      return theCols
    }

    return _cols
  }, [createdAt, field, homeRep, windowColors])

  const [rowsStr, setRowsStr] = useCustomerField(customerID, field)

  const addRow = React.useCallback<RowableUsed<Field>['addRow']>((): void => {
    setRowsStr((currRowsStr) => {
      const newRows = JSON.parse(currRowsStr || '[]') as c.Rowable[]

      if (field === 'mini_split_tons') {
        ;(newRows as c.MiniSplit[]).push(c.newMiniSplit())
      }
      if (field === 'new_windows_sliding_glass_sqft') {
        ;(newRows as c.SlidingGlassDoorGroup[]).push(
          c.newSlidingGlassDoorGroup(),
        )
      }
      if (field === 'new_windows_sqft_each_window') {
        ;(newRows as c.WindowGroup[]).push(c.newWindowGroup())
      }

      return JSON.stringify(newRows)
    })
  }, [field, setRowsStr])

  const changeVal = React.useCallback<RowableUsed<Field>['changeVal']>(
    (col, rowID, val): void => {
      setRowsStr((currRowsStr) => {
        const newRows = JSON.parse(
          currRowsStr || '[]',
        ) as c.Writable<c.Rowable>[]

        const theRowable = newRows.find((r) => r.id === rowID)

        if (!theRowable) {
          // The row was deleted
          return currRowsStr
        }

        const colKey = col as keyof c.Rowable

        if (typeof val === 'function')
          theRowable[colKey] = val(theRowable[colKey]).toString()
        else theRowable[colKey] = val.toString()

        return JSON.stringify(newRows)
      })
    },
    [setRowsStr],
  )

  const deleteRow = React.useCallback<RowableUsed<Field>['deleteRow']>(
    (rowID) => {
      setRowsStr((currRowsStr) => {
        const newRows = JSON.parse(currRowsStr || '[]') as c.Rowable[]
        const rowIdx = newRows.findIndex((r) => r.id === rowID)
        newRows.splice(rowIdx, 1)

        return JSON.stringify(newRows)
      })
    },
    [setRowsStr],
  )

  const rows = React.useMemo(() => JSON.parse(rowsStr || '[]'), [rowsStr])

  if (!c.rowableFields.includes(field)) {
    throw new Error(`Wrong field: ${field}`)
  }

  return {
    addRow,
    changeVal,
    cols,
    deleteRow,
    rows,
  }
}

export const useWindowColors = (): c.Opts => {
  const [windowColorsStr] = useSetting<string>('windowColors')
  const windowColors = JSON.parse(windowColorsStr || '[]') as c.strings
  const windowColorOpts = windowColors.map(
    (c): c.Opt => ({
      label: c,
      value: c,
    }),
  )
  return windowColorOpts
}

export const useInsulation = (customerID: string): c.Opts => {
  const [createdAt] = useCustomerField(customerID, 'createdAt')
  const [solarCompany] = useCustomerField(customerID, 'solarCompany')
  const [currInsulationType] = useCustomerField(
    customerID,
    'attic_insulation_type',
  )

  const insulationTypes = React.useMemo((): c.Opts => {
    if (typeof createdAt !== 'number') {
      console.warn(
        `useInsulation() -> ${customerID} customer's createdAt not a number: ${typeof createdAt}`,
      )
      return c.emptyArr as c.Opts
    }
    if (!solarCompany) {
      console.warn(
        `useInsulation() -> ${customerID} customer's solarCompany blank: ${typeof solarCompany}`,
      )
      return c.emptyArr as c.Opts
    }

    // White labels won't have such old customers
    const isOldCustomer = moment(createdAt).isBefore(
      c.NEW_INSULATION_TYPES_CUTOFF,
    )
    const insulationTypesToUse = isOldCustomer
      ? c.insulationTypesOld[solarCompany]
      : c.companyToInsulationTypes[solarCompany]

    if (!insulationTypesToUse) {
      return [
        {
          label: currInsulationType,
          value: currInsulationType,
        },
      ]
    }

    return insulationTypesToUse.map((value) => ({
      label: value,
      value,
    }))
  }, [createdAt, currInsulationType, customerID, solarCompany])

  return insulationTypes
}

export const useTotalCost = (customerID: string): number => {
  c.debug('useTotalCost()')
  const customer = c.useSelector((s) => c.selectCustomer(s, customerID))
  if (!customer) {
    c.debug('useTotalCost() -> returning 0 as no customer found')
    return 0
  }
  const turnedOn = c.efficiencyKeys.filter((k) => customer[k] === 'yes')
  const costFields = turnedOn.map((k) => k + '_cost')
  const costsStr = costFields.map(
    (k) => customer[k as c.CustomerField],
  ) as c.strings
  const costs = costsStr.map((c) => Number(c)).filter(Number.isFinite)
  const total = costs.reduce((a, b) => Number(a) + Number(b), 0)
  c.debug(`useTotalCost() -> total -> ${total}`)
  return total
}

export const MUIBreakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
  laptop: 1090,
} as const

type NamedStylesMap = Record<string, unknown>

export const useStyles = <T extends NamedStylesMap>(
  lightStyles: rn.StyleSheet.NamedStyles<T>,
  darkStyles: rn.StyleSheet.NamedStyles<T> = lightStyles,
) => {
  const isDark = rn.useColorScheme() === 'dark'

  if (isDark) return darkStyles
  return lightStyles
}

interface ThemedStyledSurfaces<T> {
  readonly backdrop: {
    readonly dark: rn.StyleSheet.NamedStyles<T>
    readonly light: rn.StyleSheet.NamedStyles<T>
  }
  readonly canvas: {
    readonly dark: rn.StyleSheet.NamedStyles<T>
    readonly light: rn.StyleSheet.NamedStyles<T>
  }
  readonly paper: {
    readonly dark: rn.StyleSheet.NamedStyles<T>
    readonly light: rn.StyleSheet.NamedStyles<T>
  }
}

type ThemedStyleSheetCreator<
  T extends rn.StyleSheet.NamedStyles<T> | rn.StyleSheet.NamedStyles<any>,
> = (t: c.Theme, on: c.SurfaceType) => rn.StyleSheet.NamedStyles<T>

export const ThemedStyleSheet = {
  create: <
    T extends rn.StyleSheet.NamedStyles<T> | rn.StyleSheet.NamedStyles<any>,
  >(
    themeCreator: ThemedStyleSheetCreator<T>,
  ): ThemedStyledSurfaces<T> => {
    return {
      backdrop: {
        dark: themeCreator(c.themeTuple.dark, 'backdrop'),
        light: themeCreator(c.themeTuple.light, 'backdrop'),
      },
      canvas: {
        dark: themeCreator(c.themeTuple.dark, 'canvas'),
        light: themeCreator(c.themeTuple.light, 'canvas'),
      },
      paper: {
        dark: themeCreator(c.themeTuple.dark, 'paper'),
        light: themeCreator(c.themeTuple.light, 'paper'),
      },
    }
  },
}

export const useThemedStyleSheet = <T extends NamedStylesMap>(
  themedStyles: ThemedStyledSurfaces<T>,
  on: c.SurfaceType = 'backdrop',
): rn.StyleSheet.NamedStyles<T> => {
  const colorScheme = rn.useColorScheme() || 'light'

  return themedStyles[on][colorScheme]
}

export const useTheme = () => {
  const colorScheme = rn.useColorScheme() || 'light'
  return c.themeTuple[colorScheme]
}
