import React from 'react'

import * as c from '../../../common'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import InputBase from '@mui/material/InputBase'
import styled from '@mui/material/styles/styled'
import { SxProps, Theme, Typography } from '@mui/material'

import Pad from '../Pad/Pad'

import styles from './FinancingTable.module.css'

export interface FinancingTableProps {
  alarmElectricity: number
  alarmSolar: number
  company: string
  contentUser: 'SOLAR' | 'NORMAL' | 'ALARM'
  financingYears: number
  payPerMonth: number
  percentIncrease: number
  powerBill: number
  ppaTwoInput: number
  ppaTwoPayments: number[]
  pto: number
  solarBill: number
  totalEfficiencyAmount: number
  hideComparisonBtn?: boolean
}

export default React.memo<FinancingTableProps>(function FinancingTable({
  alarmElectricity,
  alarmSolar,
  company,
  contentUser,
  financingYears,
  payPerMonth,
  percentIncrease,
  powerBill,
  ppaTwoInput,
  ppaTwoPayments,
  solarBill,
  totalEfficiencyAmount,
  hideComparisonBtn,
}) {
  const isPPA = contentUser === 'SOLAR'
  const [years, setYears] = React.useState(2)
  const [open, setOpen] = React.useState(false)

  const handleClose = React.useCallback(() => {
    setOpen((current) => !current)
  }, [setOpen])

  const handleYears = React.useCallback(
    (event: SelectChangeEvent) => {
      setYears(parseInt(event.target.value))
    },
    [setYears],
  )

  const monthlyElectricPayment = React.useMemo(() => {
    if (!powerBill) return []
    let _monthlyElectricPayment = new Array<number>(30).fill(0)

    _monthlyElectricPayment[0] = powerBill

    for (let i = 1; i <= 30; i++) {
      const prevMonth = _monthlyElectricPayment[i - 1] as number
      _monthlyElectricPayment[i] = Math.round(
        prevMonth + prevMonth * (percentIncrease / 100),
      )
    }

    return _monthlyElectricPayment
  }, [powerBill, percentIncrease])

  const yearlyElectricPayments = React.useMemo(() => {
    const firstYear = powerBill * 12
    const _yearly = [firstYear]
    for (let i = 1; i < ppaTwoPayments.length; i++) {
      _yearly[i] = _yearly[i - 1]! * (1 + percentIncrease / 100)
    }
    return _yearly
  }, [percentIncrease, powerBill, ppaTwoPayments.length])

  console.log({
    yearlyElectricPayments,
    ppaTwoPayments,
  })

  const yearlyPowerMinusPPA = React.useMemo(
    () =>
      c.zipWith(yearlyElectricPayments, ppaTwoPayments, (a, b) =>
        Math.round(a - b),
      ),
    [ppaTwoPayments, yearlyElectricPayments],
  )

  const totalSolarAmt = React.useMemo(() => {
    if (!monthlyElectricPayment.length) return 0
    const _savings = new Array(years)
      .fill(0)
      .map((_, i) => (monthlyElectricPayment[i]! - solarBill) * 12)
    console.log('Savings TABLE: ', _savings)
    let _totalSolarAmt = 0
    _savings.forEach((value) => {
      if (value < 0) {
        _totalSolarAmt += value
      } else {
        _totalSolarAmt += value
      }
    })

    return _totalSolarAmt
  }, [monthlyElectricPayment, solarBill, years])

  React.useEffect(() => {
    const scrollY = window.scrollY
    const offScroll = (e: Event) => {
      e.preventDefault()

      window.scrollTo(0, scrollY)
    }
    if (open) {
      document.body.classList.add('no-scroll')
      document.addEventListener('scroll', offScroll)
      document.removeEventListener('scroll', offScroll)
    } else {
      document.body.classList.remove('no-scroll')
      document.removeEventListener('scroll', offScroll)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const StyledSelect = React.useMemo(() => {
    return styled(InputBase)(({ theme }) => ({
      'label + &': {
        marginTop: theme.spacing(3),
      },
      '& .MuiInputBase-input': {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        borderRadius: 4,
        fontFamily: [
          '-apple-system',
          '"Apple Color Emoji"',
          '"Helvetica Neue"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
          '"Segoe UI"',
          'Arial',
          'BlinkMacSystemFont',
          'Roboto',
          'sans-serif',
        ].join(','),
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        position: 'relative',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.

        '&:focus': {
          borderColor: c.companyColors[company],
          borderRadius: 1,
          boxShadow: `0 0 0 1px ${c.companyColors[company]}`,
        },
      },
    }))
  }, [company])

  const buttonModalStyle = React.useMemo((): SxProps<Theme> => {
    return {
      alignSelf: 'center',
      backgroundColor: c.companyColors[company],
    }
  }, [company])

  const tableContainerStyle = React.useMemo((): SxProps<Theme> => {
    return {
      maxHeight: '380px',
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: c.companyColors[company],
      },
      minWidth: '600px',
      paddingRight: years > 5 ? 'unset' : '10px',
    }
  }, [company, years])

  const ppa2MinusPowerBill = isPPA
    ? Math.round(Math.abs(powerBill - ppaTwoInput))
    : -1
  const ppa2LessThanPower = ppaTwoInput < powerBill

  const totalPowerComparison = yearlyElectricPayments
    .slice(0, years)
    .reduce((a, b) => a + b, 0)
  const totalPPA2Comparison = ppaTwoPayments
    .slice(0, years)
    .reduce((a, b) => a + b, 0)

  return (
    <React.Fragment>
      <div className={styles['container']}>
        {contentUser !== 'ALARM' && (
          <div className={styles['row']}>
            <div className={styles['title-row']}>Cash</div>
            <div className={styles['input']}>
              ${''}
              {isPPA
                ? Math.round(totalEfficiencyAmount)
                : Math.round(totalEfficiencyAmount).toLocaleString('en')}
            </div>
          </div>
        )}

        <div className={styles['row']}>
          <div className={styles['title-row']}>Financing</div>
          <div className={styles['input']}>
            ${Math.round(payPerMonth).toLocaleString('en')}
          </div>
        </div>
        {/* 
        {isPPA && (
          <div className={styles['row']}>
            <div className={styles['title-row']}>PPA1</div>
            <div className={styles['input']}>
              $ {Math.round(ppa).toLocaleString('en')}
            </div>
          </div>
        )} */}

        {isPPA && (
          <div className={styles['row']}>
            <div className={styles['title-row']}>PPA</div>
            <div
              className={styles['input']}
              style={{
                color: ppa2LessThanPower ? 'green' : undefined,
              }}
            >
              ${ppa2MinusPowerBill}
            </div>
          </div>
        )}

        {contentUser === 'ALARM' && (
          <div className={styles['row']}>
            <div className={styles['title-row']}>Alarm + Electricity</div>
            <div className={styles['input']}>
              ${Math.round(alarmElectricity).toLocaleString('en')}
            </div>
          </div>
        )}

        {contentUser === 'ALARM' && (
          <div
            className={contentUser === 'ALARM' ? styles['row'] : styles['hide']}
          >
            <div className={styles['title-row']}>Alarm + Solar</div>
            <div className={styles['input']}>
              ${Math.round(alarmSolar).toLocaleString('en')}
            </div>
          </div>
        )}

        {contentUser === 'NORMAL' && (
          <div className={styles['row']}>
            <div className={styles['title-row']}>Solar</div>
            <div className={styles['input-dark']}>
              {isNaN(Math.abs(monthlyElectricPayment[0]! - solarBill))
                ? ''
                : `$${Math.abs(
                    monthlyElectricPayment[0]! - solarBill,
                  ).toLocaleString('en')}`}
            </div>
          </div>
        )}
      </div>
      {!hideComparisonBtn && (
        <Button onClick={handleClose} sx={buttonModalStyle} variant="contained">
          SHOW COMPARISON
        </Button>
      )}
      <Modal open={open} onClose={handleClose}>
        <Box component={Paper} sx={sx['modalBox']}>
          <HighlightOffIcon sx={sx['closeModalIcon']} onClick={handleClose} />

          <InputLabel id="demo-simple-select-label" sx={sx['labelYears']}>
            Years
          </InputLabel>
          <Select
            id="demo-simple-select"
            input={<StyledSelect />}
            labelId="demo-simple-select-label"
            onChange={handleYears}
            sx={sx['formControl']}
            value={years.toString()}
          >
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
          </Select>

          <Pad amt={20} />

          <div className={styles['container-table-modal']}>
            {contentUser !== 'ALARM' && (
              <div className={styles['row-modal']}>
                <div className={styles['title-row-modal']}>Cash</div>
                <div className={styles['input-dark']}>
                  ${''}
                  {isPPA
                    ? Math.round(totalEfficiencyAmount)
                    : Math.round(totalEfficiencyAmount).toLocaleString('en')}
                </div>
              </div>
            )}

            <div className={styles['row-modal']}>
              <div className={styles['title-row-modal']}>Financing</div>
              <div className={styles['input-dark']}>
                ${Math.round(payPerMonth).toLocaleString('en')}
              </div>
            </div>

            {isPPA && (
              <div className={styles['row-modal']}>
                <div className={styles['title-row-modal']}>PPA</div>
                <div
                  className={styles['input']}
                  style={{
                    color: ppa2LessThanPower ? 'green' : undefined,
                  }}
                >
                  ${ppa2MinusPowerBill}
                </div>
              </div>
            )}

            {contentUser === 'ALARM' && (
              <div className={styles['row-modal']}>
                <div className={styles['title-row-modal']}>
                  Alarm + Electricity
                </div>
                <div className={styles['input']}>
                  ${Math.round(alarmElectricity).toLocaleString('en')}
                </div>
              </div>
            )}

            {contentUser === 'ALARM' && (
              <div
                className={
                  contentUser === 'ALARM' ? styles['row-modal'] : styles['hide']
                }
              >
                <div className={styles['title-row-modal']}>Alarm + Solar</div>
                <div className={styles['input']}>
                  ${Math.round(alarmSolar).toLocaleString('en')}
                </div>
              </div>
            )}

            {isPPA ||
              (contentUser === 'NORMAL' && (
                <div className={styles['row-modal']}>
                  <div className={styles['title-row-modal']}>Solar</div>
                  <div className={styles['input']}>
                    {isNaN(Math.abs(monthlyElectricPayment[0]! - solarBill))
                      ? ''
                      : `$${Math.abs(
                          monthlyElectricPayment[0]! - solarBill,
                        ).toLocaleString('en')}`}
                  </div>
                </div>
              ))}
          </div>

          <Pad amt={20} />

          <TableContainer
            component={Paper}
            elevation={5}
            sx={tableContainerStyle}
          >
            <Table sx={sx['table']} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={sx['tableCell']}>
                    Year
                  </TableCell>
                  <TableCell align="center" sx={sx['tableCell']}>
                    Cash
                  </TableCell>
                  <TableCell align="center" sx={sx['tableCell']}>
                    Financing
                  </TableCell>
                  <TableCell align="center" sx={sx['tableCell']}>
                    {isPPA ? 'PPA' : 'Solar'}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {new Array(years).fill(0).map((_, i) => (
                  <TableRow key={years + '>' + i}>
                    <TableCell align="center" sx={sx['tableCell']}>
                      {i + 1}
                    </TableCell>
                    <TableCell align="center" sx={sx['tableCell']}>
                      $
                      {i === 0
                        ? Math.round(totalEfficiencyAmount).toLocaleString('en')
                        : 0}
                    </TableCell>
                    <TableCell align="center" sx={sx['tableCell']}>
                      {i + 1 > financingYears
                        ? '$0'
                        : `$${Math.round(
                            Math.round(payPerMonth) * 12,
                          ).toLocaleString('en')}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={
                        isPPA
                          ? yearlyPowerMinusPPA[i]! > 0
                            ? sx['tableCellGreen']
                            : sx['tableCell']
                          : Math.round(
                              (monthlyElectricPayment[i]! - solarBill) * 12,
                            ) <= 0
                          ? sx['tableCell']
                          : sx['tableCellGreen']
                      }
                    >
                      $
                      {isPPA
                        ? Math.abs(yearlyPowerMinusPPA[i]!)
                        : solarBill && monthlyElectricPayment[i]
                        ? `$${Math.abs(
                            (monthlyElectricPayment[i]! - solarBill) * 12,
                          )}`
                        : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer>
            <Table sx={sx['tableTotal']}>
              <TableHead>
                <TableRow sx={sx['tableRowTotal']}>
                  <TableCell
                    align="center"
                    sx={sx['tableCellHiddenTotal']}
                  ></TableCell>

                  <TableCell align="center" sx={sx['tableCellTotal']}>
                    <Typography sx={sx['totalText']}>Total:</Typography>
                    <span>
                      {`$${
                        totalEfficiencyAmount
                          ? Math.round(totalEfficiencyAmount).toLocaleString(
                              'en',
                            )
                          : 0
                      }`}
                    </span>
                  </TableCell>
                  <TableCell align="center" sx={sx['tableCellTotal']}>
                    <Typography sx={sx['totalText']}>Total:</Typography>
                    <span>
                      {`$${Math.round(payPerMonth * 12 * years).toLocaleString(
                        'en',
                      )}`}
                    </span>
                  </TableCell>
                  <TableCell align="center" sx={sx['tableCellTotal']}>
                    <Typography sx={sx['totalText']}>Total:</Typography>
                    {isPPA && (
                      <span
                        style={
                          totalPowerComparison > totalPPA2Comparison
                            ? { color: 'green' }
                            : undefined
                        }
                      >
                        $
                        {Math.abs(
                          Math.round(
                            totalPowerComparison - totalPPA2Comparison,
                          ),
                        )}
                      </span>
                    )}

                    {!isPPA && solarBill && (
                      <span
                        style={
                          totalSolarAmt < 0 ? undefined : { color: 'green' }
                        }
                      >
                        ${Math.abs(Math.round(totalSolarAmt))}
                      </span>
                    )}

                    {!isPPA && !solarBill && <span>0</span>}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </React.Fragment>
  )
})

const sx = {
  closeModalIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 10,
    top: 10,
  },
  formControl: {
    width: '100px',
  },
  labelYears: {
    fontSize: '18px',
    textAlign: 'center',
  },
  modalBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    left: '50%',
    padding: '20px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  table: {
    boxSizing: 'border-box',
    margin: 0,
    minWidth: '580px',
  },
  tableCell: {
    fontSize: '22px',
  },
  tableCellGreen: {
    color: 'green',
    fontSize: '22px',
  },
  tableTotal: {
    width: '580px',
  },
  tableRowTotal: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableCellHiddenTotal: {
    fontSize: '22px',
    visibility: 'hidden',
    width: '70px',
  },
  tableCellTotal: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '22px',
  },
  totalText: {
    fontSize: '22px',
    fontWeight: '700',
  },
}
