import React from 'react'

import * as Mui from '@mui/material'

import { TransitionProps } from '@mui/material/transitions'

export interface DialogModalProps {
  actionButton?: () => void
  actionButtonText?: string
  children: React.ReactNode
  closeButtonText: string
  dialogTitle: string
  onClose: () => void
  open: boolean
}

export default React.memo<DialogModalProps>(function DialogModal({
  actionButton,
  actionButtonText,
  children,
  closeButtonText,
  dialogTitle,
  onClose,
  open,
}) {
  return (
    <Mui.Dialog
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description"
      onClose={onClose}
      open={open}
    >
      <Mui.DialogTitle>{dialogTitle}</Mui.DialogTitle>
      <Mui.DialogContent sx={sx['dialogContent']}>{children}</Mui.DialogContent>

      <Mui.DialogActions>
        <Mui.Button
          color="error"
          onClick={onClose}
          size="small"
          variant="contained"
        >
          {closeButtonText}
        </Mui.Button>
        {actionButton instanceof Function && actionButtonText ? (
          <Mui.Button
            color="primary"
            onClick={actionButton}
            size="small"
            variant="contained"
          >
            {actionButtonText}
          </Mui.Button>
        ) : null}
      </Mui.DialogActions>
    </Mui.Dialog>
  )
})

const sx = {
  dialogContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Mui.Slide direction="up" ref={ref} {...props} />
})
