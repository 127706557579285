import React from 'react'
import * as rn from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'

import Label from './Label'
import Pad from './Pad'
import Text from './Text'
import TextInput, { padBetweenLabelAndInput } from './TextInput'
import View from './View'

export type CloserCalcProps = c.r<{
  customerID: string
}>

export default React.memo<CloserCalcProps>(({ customerID }) => {
  c.debug('<CloserCalc />')
  const colorScheme = rn.useColorScheme()
  const placeholderTextColor =
    colorScheme === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.4)'
  const styles = r.useThemedStyleSheet(themedStyles)
  const [solarCompany] = r.useCustomerField(customerID, 'solarCompany')

  // TODO: Handle california being the default state for companies
  const isCali = solarCompany.startsWith('CPS')
  const isNV = solarCompany.startsWith('NPS') || solarCompany === 'affordable'
  const isWrongState = !isCali && !isNV
  const redline = isCali ? 3.2 : 2.8

  const totalCost = r.useTotalCost(customerID)

  c.debug(`<CloserCalc /> -> totalCost -> ${totalCost}`)

  const [storedBatteryCost] = r.useCustomerField(customerID, 'battery_cost')

  const [sysSize, setSysSize] = r.useInputVal('')
  const [adder, setAdder] = r.useInputVal(totalCost ? totalCost.toString() : '')
  React.useEffect(() => {
    const batteryCostN = Number(storedBatteryCost || '')
    setAdder(totalCost ? (totalCost - batteryCostN).toString() : '')
  }, [setAdder, storedBatteryCost, totalCost])

  const [batteryCost, setBatteryCost] = r.useInputVal(storedBatteryCost)
  React.useEffect(() => {
    setBatteryCost(storedBatteryCost)
  }, [setBatteryCost, storedBatteryCost])
  const [sonnenBonus, setSonnenBonus] = r.useInputVal('')
  const [ppw, setPPW] = r.useInputVal('')

  const adderPPWRaw = Number(adder) / (Number(sysSize) * 1000)
  const adderPPW = Number.isFinite(adderPPWRaw) ? adderPPWRaw : 0
  const batteryPPWRaw = Number(batteryCost) / (Number(sysSize) * 1000)
  const batteryPPW = Number.isFinite(batteryPPWRaw) ? batteryPPWRaw : 0
  const sonnenPPWRaw = Number(sonnenBonus) / (Number(sysSize) * 1000)
  const sonnenPPW = Number.isFinite(sonnenPPWRaw) ? sonnenPPWRaw : 0
  const finalPPWRaw = Number(ppw) - adderPPW - batteryPPW - sonnenPPW
  const finalPPW = Number.isFinite(finalPPWRaw) ? finalPPWRaw : 0
  const finalPPWMinusRedline = finalPPW - redline
  const finalCommission = finalPPWMinusRedline * Number(sysSize) * 1000

  const inputs = (
    <>
      {rn.Platform.OS !== 'web' && <Pad amt={24} />}
      <TextInput
        label="System Size (kW)"
        displayCleanBtn
        keyboardType="numeric"
        placeholderTextColor={placeholderTextColor}
        on="canvas"
        onChangeText={setSysSize}
        value={sysSize}
      />
      {rn.Platform.OS !== 'web' && <Pad amt={gap} />}
      <TextInput
        label="Adder"
        displayCleanBtn
        keyboardType="numeric"
        on="canvas"
        placeholderTextColor={placeholderTextColor}
        onChangeText={setAdder}
        value={adder}
      />
      {rn.Platform.OS !== 'web' && <Pad amt={gap} />}
      {isCali && (
        <>
          <TextInput
            displayCleanBtn
            label="Battery Cost"
            keyboardType="numeric"
            placeholderTextColor={placeholderTextColor}
            on="canvas"
            onChangeText={setBatteryCost}
            value={batteryCost}
          />

          {rn.Platform.OS !== 'web' && <Pad amt={gap} />}
        </>
      )}
      <TextInput
        label="Sonnen Bonus"
        displayCleanBtn
        keyboardType="numeric"
        placeholderTextColor={placeholderTextColor}
        on="canvas"
        onChangeText={setSonnenBonus}
        value={sonnenBonus}
      />
      {rn.Platform.OS !== 'web' && <Pad amt={gap} />}
      <View>
        <TextInput
          label="PPW"
          displayCleanBtn
          keyboardType="numeric"
          placeholderTextColor={placeholderTextColor}
          on="canvas"
          onChangeText={setPPW}
          value={ppw}
        />
        {Number(ppw) <= redline && (
          <Text size={12}>PPW must be greater than {redline}</Text>
        )}
      </View>
    </>
  )

  return (
    <>
      {isWrongState && (
        <Text style={styles.dangerText}>Calculator only for NV & CA</Text>
      )}

      {rn.Platform.OS === 'web' ? (
        <View flexDirection="row" flexWrap="wrap">
          {inputs}
        </View>
      ) : (
        inputs
      )}

      <Pad amt={gap * 2} />
      <Pad amt={1} color="#EEEEF1" />
      <Pad amt={gap * 2} />
      <View justifyContent="space-between" flexDirection="row">
        <View>
          <Label>Adder PPW</Label>
          <Pad amt={padBetweenLabelAndInput} />
          <Text size={14}>{adderPPW.toPrecision(3)}</Text>
        </View>

        {isCali && (
          <View alignItems="flex-end">
            <Label>Battery PPW</Label>
            <Pad amt={padBetweenLabelAndInput} />
            <Text style={styles.textAlignRight}>
              {batteryPPW.toPrecision(3)}
            </Text>
          </View>
        )}
      </View>
      <Pad amt={gap} />
      {sonnenBonus !== '' && (
        <View alignSelf="center">
          <Label>Sonnen PPW</Label>
          <Pad amt={padBetweenLabelAndInput} />
          <Text size={14}>{sonnenPPW.toPrecision(3)}</Text>
        </View>
      )}
      <Pad amt={gap} />
      <View justifyContent="space-between" flexDirection="row">
        <View>
          <Label>Final PPW</Label>
          <Pad amt={padBetweenLabelAndInput} />
          <Text size={14}>{finalPPW.toPrecision(3)}</Text>
        </View>

        <View alignItems="flex-end">
          <Label>Final PPW Minus {redline}</Label>
          <Pad amt={padBetweenLabelAndInput} />
          <Text style={styles.textAlignRight}>
            {finalPPWMinusRedline.toPrecision(3)}
          </Text>
        </View>
      </View>
      <Pad amt={gap} />
      <View>
        <Label>Closer Commission</Label>
        <Pad amt={padBetweenLabelAndInput} />
        <Text size={14}>{finalCommission.toPrecision(7)}</Text>
      </View>
    </>
  )
})

const themedStyles = r.ThemedStyleSheet.create((t) => ({
  dangerText: {
    color: t.danger,
    fontFamily: t.fontFamily,
    textAlign: 'center',
  },
  textAlignRight: {
    color: t.canvas.color,
    fontFamily: t.fontFamily,
    textAlign: 'center',
  },
}))
const gap = 12
