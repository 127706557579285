import { IS_RN, IS_WEB, globalEnv, validBase64Prefixes } from '../../common/env'

const raiseErr = (errMsg: string): void => {
  if (IS_RN) {
    // @ts-ignore (envvar check script will crash otherwise)
    if (__DEV__) {
      console.error(errMsg)
    } else {
      throw new Error(errMsg)
    }
  }
  if (IS_WEB) {
    console.error(errMsg)
  }
}

export const API_KEY_GOOGLE_PLACES_IOS = globalEnv.G_API_KEY_IOS
if (typeof API_KEY_GOOGLE_PLACES_IOS !== 'string') {
  const msg = `GOOGLE_API_KEY_IOS not a string: ${API_KEY_GOOGLE_PLACES_IOS}`
  raiseErr(msg)
}

export const API_KEY_GOOGLE_PLACES_ANDROID = globalEnv.G_API_KEY_ANDROID

if (typeof API_KEY_GOOGLE_PLACES_ANDROID !== 'string') {
  const msg = `GOOGLE_API_KEY_ANDROID not a string: ${API_KEY_GOOGLE_PLACES_ANDROID}`
  raiseErr(msg)
}

export const FB_FUNCTIONS_API_URL = globalEnv.FB_FUNCTIONS_URL

if (typeof FB_FUNCTIONS_API_URL !== 'string') {
  const msg = `REACT_APP_FB_FUNCTIONS_API_URL not a string: ${FB_FUNCTIONS_API_URL}`
  raiseErr(msg)
}

export const IMAGE_QR_REVIEW_CAPS = process.env['IMAGE_QR_REVIEW_CAPS']

if (typeof IMAGE_QR_REVIEW_CAPS !== 'string') {
  const msg = `IMAGE_QR_REVIEW_CAPS not a string: ${IMAGE_QR_REVIEW_CAPS}`
  raiseErr(msg)
}

export const IMAGE_QR_REVIEW_NVPS = process.env['IMAGE_QR_REVIEW_NVPS']

if (typeof IMAGE_QR_REVIEW_NVPS !== 'string') {
  const msg = `IMAGE_QR_REVIEW_NVPS not a string: ${IMAGE_QR_REVIEW_NVPS}`
  raiseErr(msg)
}

export const GOOGLE_LOGO = process.env['REACT_APP_GOOGLE_LOGO'] || ''

const googleLogoIsValid = validBase64Prefixes.some((p) =>
  GOOGLE_LOGO.startsWith(p),
)

if (!googleLogoIsValid) {
  const msg = 'Invalid format type string of REACT_APP_GOOGLE_LOGO'
  raiseErr(msg)
}
