import React from 'react'

import * as c from '../../../common'
import * as r from '../../../react-utils'

import * as mui from '@mui/material'
import * as mIcon from '@mui/icons-material'

import Pad from '../Pad'
import gStyles from '../../global.module.css'

import styles from './ConnRowInput.module.css'

export interface RowInputProps<K extends c.FieldRowable> {
  readonly customerID: string
  readonly field: K
}

export default React.memo(function ConnRowInput<K extends c.FieldRowable>({
  customerID,
  field,
}: RowInputProps<K>) {
  const { addRow, changeVal, cols, deleteRow, rows } = r.useRowable<K>(
    customerID,
    field,
  )

  const rowMapper = React.useCallback(
    (
      row: c.Rowable,
      _: number,
      rowsRef: readonly unknown[],
    ): React.ReactElement => (
      <Row
        cols={cols}
        key={row.id}
        onChangeValue={changeVal}
        onDeleteRow={deleteRow}
        row={row}
        showDelete={rowsRef.length > 1}
      />
    ),
    [changeVal, cols, deleteRow],
  )

  return (
    <mui.Box>
      <Pad amt={12} />

      {rows.map(rowMapper)}

      <mui.Tooltip title="Add Windows">
        <mIcon.AddCircleOutline
          className={styles['add-row']}
          onClick={addRow}
        />
      </mui.Tooltip>

      <Pad amt={36} />
    </mui.Box>
  )
})

const numberInputInputProps = {
  min: 1,
}

interface RowProps {
  readonly cols: c.Cols
  readonly onChangeValue: (
    col: string,
    rowID: string,
    val: number | string,
  ) => void
  readonly onDeleteRow: (rowID: string) => void
  readonly row: c.MiniSplit | c.SlidingGlassDoorGroup | c.WindowGroup
  readonly showDelete: boolean
}

const Row = React.memo<RowProps>(function Row({
  cols,
  row,
  onChangeValue,
  onDeleteRow,
  showDelete,
}) {
  const colorScheme = mui.useTheme().palette.mode
  const isDark = colorScheme === 'dark'

  const handleChangeValue = React.useMemo(
    () =>
      cols.map(
        (col) => (e: { target: { name: string; value: number | string } }) => {
          onChangeValue(col.key, row.id, e.target.value)
        },
      ),
    [cols, onChangeValue, row.id],
  )

  // const handleInputClean = React.useMemo(
  //   () =>
  //     cols.map((col) => () => {
  //       onChangeValue(col.key, rowIdx, '')
  //     }),
  //   [cols, onChangeValue, rowIdx],
  // )

  const handleDeleteRow = React.useCallback(
    (): void => void onDeleteRow(row.id),
    [onDeleteRow, row.id],
  )

  if (cols.length === 0) {
    return null
  }

  return (
    <mui.Box className={styles['row']}>
      {cols.map((col, colIdx) => (
        <React.Fragment key={row.id + col.key}>
          {col.type === 'calc' && (
            <mui.Typography
              align="right"
              component="span"
              sx={isDark ? sx['inputDark'] : sx['input']}
              width={80}
            >
              {col.calc(row)}
            </mui.Typography>
          )}

          {col.type === 'input' && (
            <mui.TextField
              label={col.label}
              onChange={handleChangeValue[colIdx]}
              placeholder="e.g. 5"
              sx={isDark ? sx['inputDark'] : sx['input']}
              type="text"
              value={
                c.zeroes.includes(row[col.key as keyof typeof row])
                  ? ''
                  : row[col.key as keyof typeof row]
              }
              fullWidth
            />
          )}

          {col.type === 'number-input' && (
            <mui.TextField
              fullWidth
              inputProps={numberInputInputProps}
              label={col.label}
              onChange={handleChangeValue[colIdx]}
              placeholder="e.g. 5"
              // sx={sx['windowAmt']}
              type="number"
              value={row[col.key as keyof typeof row]}
            />
          )}

          {col.type === 'opts' && (
            <mui.TextField
              label={col.label}
              onChange={handleChangeValue[colIdx]}
              select
              value={row[col.key as keyof typeof row]}
              fullWidth
            >
              {col.opts.map((color) => (
                <mui.MenuItem
                  key={row.id + col.key + color.value}
                  color={c.themeTuple[colorScheme].canvas.color}
                  value={color.value}
                >
                  {color.label}
                </mui.MenuItem>
              ))}
            </mui.TextField>
          )}

          <Pad amt={8} row />
        </React.Fragment>
      ))}

      <mui.Tooltip title="Delete Windows">
        <mIcon.Delete
          className={showDelete ? styles['del-row'] : gStyles['opacity-0']}
          onClick={showDelete ? handleDeleteRow : c.emptyFn}
        />
      </mui.Tooltip>
    </mui.Box>
  )
})

const sx = {
  input: { color: c.light.input.paper.backgroundColor },
  inputDark: { color: c.dark.input.paper.backgroundColor },
}
