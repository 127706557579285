import React from 'react'
import FaqQuestions from '../../routes/auditor/FaqQuestions'
import { questions } from '../../helpers/Vars'

import styles from './FaqPage.module.css'

export interface FaqPageProps {}

export default React.memo<FaqPageProps>(function FaqPageProps() {
  return (
    <div className={styles['container']}>
      <h1>6 common questions</h1>

      {questions.map((question, i) => (
        <FaqQuestions index={i} question={question} />
      ))}
    </div>
  )
})
