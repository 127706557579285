import React from 'react'

import * as common from '../../../common'

import * as Mui from '@mui/material'
import * as MuiIcons from '@mui/icons-material'

import Pad from '../Pad'

export interface QualifyFormProps {
  customerID: string
  onClose: () => void
}

export default React.memo<QualifyFormProps>(function QualifyForm({
  customerID,
  onClose,
}) {
  return (
    <Mui.Box component={Mui.Paper} sx={sx['container']!}>
      <MuiIcons.HighlightOff onClick={onClose} sx={sx['closeIcon']} />
      <Mui.Typography component="span" sx={sx['mainTitle']} variant="h5">
        Credit Check Application
      </Mui.Typography>
      <Pad amt={20} />
      <Mui.Accordion defaultExpanded sx={sx['accordion']}>
        <Mui.AccordionSummary
          expandIcon={<MuiIcons.ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Primary Applicant
        </Mui.AccordionSummary>
        <Mui.AccordionDetails>
          {common.qualifyGlowPrimaryAplicantInputs.map((inputs) => (
            <FormGroupInput
              customerID={customerID}
              key={JSON.stringify(inputs)}
              inputs={inputs}
            />
          ))}
        </Mui.AccordionDetails>
      </Mui.Accordion>

      <Pad amt={20} />
      <Mui.Accordion defaultExpanded sx={sx['accordion']}>
        <Mui.AccordionSummary
          expandIcon={<MuiIcons.ExpandMore />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Address Information
        </Mui.AccordionSummary>
        <Mui.AccordionDetails>
          {common.qualifyAddressInfoInputs.map((inputs) => (
            <FormGroupInput
              customerID={customerID}
              key={JSON.stringify(inputs)}
              inputs={inputs}
            />
          ))}
        </Mui.AccordionDetails>
      </Mui.Accordion>
      <Pad amt={20} />
      <Mui.Accordion defaultExpanded sx={sx['accordion']}>
        <Mui.AccordionSummary
          expandIcon={<MuiIcons.ExpandMore />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Power Purchase Agreement (PPA) Disclosures
        </Mui.AccordionSummary>
        <Mui.AccordionDetails>
          <Mui.Box sx={sx['agreement']}>
            Authorization to Obtain Credit Report. You authorize Palmetto Solar,
            LLC, DBA LightReach, to obtain a credit report from one or more
            consumer credit reporting agencies. We may use the credit report for
            any lawful purpose, including but not limited to (i) for
            authentication purposes, to make sure you are who you say you are,
            (ii) to determine how much debt you currently have, in order to
            determine your debt-to-income ratio, (iii) to obtain your credit
            score, and (iv) to obtain and display certain information and
            characteristics from your credit report from one or more consumer
            credit reporting agencies, including but not limited to the number,
            age, type and status of the credit lines currently being reported,
            public records (such as bankruptcies and judgments) and mortgage
            loans appearing on your credit report, and the number of your recent
            requests for credit./n/nCo-Applicant. You verify that (i) you, any
            co-applicant, or both, are the current legal owner of the property
            or control the trust that is the legal owner of the property and
            (ii) that any applicants or co-applicants who are not current legal
            owners or who do not control the trust that is the legal owner of
            the property have a legal relationship such as a spouse or domestic
            partner to the current legal owner or person who controls the trust
            that is the legal owner of the property.
          </Mui.Box>
        </Mui.AccordionDetails>
      </Mui.Accordion>
      <Pad amt={10} />
      <Mui.FormControl component={Mui.Paper} sx={sx['agreementCheckbox']}>
        <Mui.FormControlLabel
          control={<Mui.Checkbox />}
          label="I AGREE to company Terms and Conditions"
        />
      </Mui.FormControl>

      <Mui.Box sx={sx['buttonsWrapper']}>
        <Mui.Button color="primary" size="small" variant="contained">
          Submit
        </Mui.Button>
        <Pad amt={20} row />
        <Mui.Button
          color="error"
          onClick={onClose}
          size="small"
          variant="contained"
        >
          Cancel
        </Mui.Button>
      </Mui.Box>
    </Mui.Box>
  )
})

interface InputProps {
  customerID: string
  field: keyof common.Customer
}

const Input = React.memo<InputProps>(({ customerID, field }) => {
  const selectCustomerField = React.useMemo(
    (): ReturnType<typeof common.makeSelectCustomerField> =>
      common.makeSelectCustomerField(),
    [],
  )
  const selectCustomerFieldArgs = React.useMemo(
    (): common.SelectCustomerFieldParams => ({
      customerID,
      field: field as keyof common.Customer,
    }),
    [customerID, field],
  )
  const currentValue = common.useSelector(
    (_): common.ValueOf<common.Customer> =>
      selectCustomerField(_, selectCustomerFieldArgs),
  )
  const [value, setValue] =
    React.useState<common.ValueOf<common.Customer>>(currentValue)

  const { day, month, year } = common.getDateInfo(
    field === 'qualify_glow_birth_date'
      ? (currentValue as number) || Date.now()
      : Date.now(),
  )

  const onChange = React.useCallback(
    (e) => {
      if (field === 'qualify_glow_birth_date') {
        const date = common.normalizeTimestampToMs(Date.parse(e.target.value))
        setValue(e.target.value)
        common.updateCustomer(customerID, {
          [field]: date,
        })
      } else {
        setValue(e.target.value)
        common.updateCustomer(customerID, {
          [field]: e.target.value,
        })
      }
    },
    [customerID, field],
  )

  const inputStyle = sx[field as string] as Mui.SxProps<Mui.Theme>

  if (field === 'qualify_glow_birth_date') {
    return (
      <Mui.TextField
        label={common.fieldToLabel[field]}
        onChange={onChange}
        sx={inputStyle ? inputStyle : sx['input']}
        type="date"
        value={`${year}-${month}-${day}`}
      />
    )
  }
  return (
    <Mui.TextField
      label={common.fieldToLabel[field]}
      onChange={onChange}
      sx={inputStyle ? inputStyle : sx['input']}
      type="text"
      value={value}
    />
  )
})

interface FormGroupInputProps {
  customerID: string
  inputs: common.CustomerKeyArr
}
const FormGroupInput = React.memo<FormGroupInputProps>(
  ({ customerID, inputs }) => {
    return (
      <>
        <Mui.FormGroup sx={sx['formGroup']}>
          {inputs.map((field, i) => (
            <React.Fragment key={field}>
              <Input customerID={customerID} field={field} />
              {i + 1 !== inputs.length ? <Mui.Box sx={sx['pad']} /> : null}
            </React.Fragment>
          ))}
        </Mui.FormGroup>
        <Pad amt={15} />
      </>
    )
  },
)

const sx: Record<string, Mui.SxProps<Mui.Theme>> = {
  accordion: {
    width: '100%',
  },
  agreement: {
    border: '1px solid black',
    fontSize: '15px',
    maxHeight: '300px',
    overflowY: 'scroll',
    padding: '20px',
  },
  agreementCheckbox: {
    width: '100%',
    padding: '5px',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    left: '50%',
    maxHeight: '90%',
    overflowY: 'scroll',
    padding: '20px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      lg: '960px',
      md: '720px',
      sm: '520px',
      xs: '95%',
    },
  },

  formGroup: {
    display: 'inline-flex',
    flexDirection: {
      sm: 'row',
      xs: 'column',
    },
    justifyContent: {
      sm: 'space-between',
      xs: 'unset',
    },
    width: '100%',
  },
  formGroupTitle: {
    alignSelf: 'flex-start',
    fontWeight: '700',
  },
  input: {
    width: {
      lg: '48%',
      xs: '100%',
    },
  },
  mainTitle: {
    alignSelf: 'flex-start',
    fontWeight: '700',
  },
  pad: {
    display: {
      lg: 'none',
      xs: 'unset',
    },
    height: '20px',
    width: '100%',
  },
  qualify_glow_street: {
    width: '100%',
  },
  qualify_glow_city: {
    width: {
      lg: '40%',
      xs: '100%',
    },
  },

  qualify_glow_state: {
    width: {
      xs: '100%',
      lg: '20%',
    },
  },
  qualify_glow_zip: {
    width: {
      xs: '100%',
      lg: '20%',
    },
  },
}
