import * as React from 'react'
import * as rn from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'

export type ButtonProps = c.r<{
  alignSelfStart?: boolean
  children: string
  on: c.SurfaceType
  onPress(): void
}>

export default React.memo<ButtonProps>(function Button({
  alignSelfStart,
  children,
  on,
  onPress,
}) {
  const styles = r.useThemedStyleSheet(themedStyles, on)

  const rootStyle = React.useCallback(
    (s: rn.PressableStateCallbackType): rn.StyleProp<rn.ViewStyle> => {
      if (alignSelfStart && s.hovered) return styles.rootHoveredAlignStart
      if (s.hovered) return styles.rootHovered
      if (alignSelfStart) return styles.rootAlignStart
      return styles.root
    },
    [alignSelfStart, styles],
  )

  return (
    <rn.Pressable onPress={onPress} style={rootStyle}>
      <rn.Text style={styles.label}>{children}</rn.Text>
    </rn.Pressable>
  )
})

const themedStyles = r.ThemedStyleSheet.create((t, on) => {
  const root = {
    ...t.button[on], // TODO: Parametrize
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 32,
    paddingHorizontal: 24,
    paddingVertical: 12,
  } as const

  const rootHovered = {
    ...root,
    backgroundColor: t.input[on].hoverBG,
  }

  return {
    label: {
      fontFamily: t.fontFamily,
      fontSize: 15,
      fontWeight: '500',
      lineHeight: 21,
      color: t.input.paper.color,
    },
    root,
    rootAlignStart: { ...root, alignSelf: 'flex-start' },
    rootHovered,
    rootHoveredAlignStart: { ...rootHovered, alignSelf: 'flex-start' },
  }
})
