import Highcharts, { Options } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export interface AlertChartProps {
  // handleImageChart(url: string): void
  equipment: number
  amount: number
  fee: number
}

export default function AlertChart({
  amount,
  equipment,
  fee,
}: AlertChartProps) {
  // const amount = () => {

  //     if(isNaN(fee) && !isNaN(total) ){

  //        return total
  //     }else if( isNaN((total + total * (fee /100)))){

  //        return 0
  //     }else{}
  // }
  const options: Options = {
    accessibility: {
      enabled: false,
    },
    title: {
      text: `ESTIMATED</br>TOTAL COST + FEE</br></br><h1 class='pay-per-month'>$ ${
        isNaN(amount + amount * (fee / 100))
          ? 0
          : fee
          ? Math.round(amount + amount * (fee / 100)).toLocaleString('en')
          : amount.toLocaleString('en')
      }</h1>`,
      verticalAlign: 'middle',
      align: 'center',
      floating: true,
    },

    chart: {
      type: 'pie',
      width: 700,
      height: 410,
      style: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },

    tooltip: {
      formatter() {
        return `<b>$ ${this.y}</b>`
      },
    },

    plotOptions: {
      pie: {
        innerSize: '80%',
        borderWidth: 40,
        borderColor: 'null',
        slicedOffset: 20,
        size: 290,
        dataLabels: {
          enabled: true,
          connectorWidth: 1,
          alignTo: 'toPlotEdges',

          style: {
            fontSize: '14px',
          },
        },
      },
    },

    credits: {
      enabled: false,
    },

    series: [
      {
        type: 'pie',
        data: [
          {
            name: `Equipment Cost:</br> 
            $ ${isNaN(equipment) ? 0 : equipment}`,

            y: isNaN(equipment) ? 0 : equipment,
            color: 'blue',
          },
          {
            name: `Additional Fees: 
            $ ${
              isNaN(amount - equipment) ? 0 : Math.round(amount - equipment)
            }`,
            y: isNaN(amount - equipment) ? 0 : Math.round(amount - equipment),
            color: 'red',
          },
        ],
      },
    ],
  }
  return <HighchartsReact highcharts={Highcharts} options={options} />
}
