import React from 'react'

import * as c from '../../../common'

import * as Mui from '@mui/material'
import * as MuiIcon from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'

import Pad from '../Pad'

// TODO: Parametrize
enum EnumButtonFilter {
  CALIFORNIA = 'california',
  NEVADA = 'nevada',
  UTAH = 'utah',
  TEXAS = 'texas',
}

export interface ModalPriceProps {
  customer?: c.Customer
  field: keyof c.Customer
  onRequestClose: () => void
  onRequestOpen: () => void
  open: boolean
}

type City = 'fresno' | 'victorville' | ''

export default React.memo<ModalPriceProps>(function ModalPrice({
  customer,
  field,
  onRequestClose,
  onRequestOpen,
  open,
}) {
  const isDark = useMediaQuery('(prefers-color-scheme: dark)')
  const solarCompany = customer?.solarCompany || 'CPS'

  const filterSelected = getFilterSelected(solarCompany as EnumButtonFilter)

  const [priceList, setPriceList] = React.useState<c.Price[]>([])
  const [pricesByCityList, setPricesByCityList] = React.useState<
    c.CityPrices[]
  >([])

  const [city, setCity] = React.useState<City>('fresno')

  const handleCityToFresno = React.useCallback(() => {
    setCity('fresno')
  }, [setCity])

  const handleCityToVictorVille = React.useCallback(() => {
    setCity('victorville')
  }, [setCity])

  const dataPriceSelected = c.useSelector(c.selectEfficiencyPrice(field))

  const pricesCitySelected = React.useMemo(() => {
    let cities: c.CityPrices[] = []
    if (city && pricesByCityList.length) {
      const _pricesByCity = pricesByCityList.slice()

      if (_pricesByCity.length) {
        _pricesByCity.forEach((item) => {
          if (item.list && item?.label?.toLowerCase().startsWith(city)) {
            cities.push(item)
          }
        })
      }

      return cities
    }

    return cities
  }, [city, pricesByCityList])

  const isEmpty = !priceList.length && !pricesCitySelected.length

  const onLoadDataByFilter = React.useCallback((): void => {
    if (!dataPriceSelected) {
      return
    }
    if (
      filterSelected === EnumButtonFilter.TEXAS ||
      filterSelected === EnumButtonFilter.UTAH
    ) {
      setPriceList([])
      setPricesByCityList([])
      return
    }

    if (filterSelected === EnumButtonFilter.CALIFORNIA) {
      const californiaPrice = dataPriceSelected.california.pricesByCity

      setPriceList(californiaPrice[0]?.list || [])
      setPricesByCityList(californiaPrice)
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions
    const nevadaPrice: c.Price[] = dataPriceSelected?.nevada?.list || []
    setPricesByCityList([])
    setPriceList(nevadaPrice)
  }, [dataPriceSelected, filterSelected])

  React.useEffect((): void => {
    onLoadDataByFilter()
  }, [onLoadDataByFilter])

  return (
    <>
      <MuiIcon.AttachMoney
        onClick={onRequestOpen}
        sx={
          isEmpty ? sx['hide'] : isDark ? sx['priceIconDark'] : sx['priceIcon']
        }
      />
      <Mui.Modal onClose={onRequestClose} open={open}>
        <>
          <Mui.Box component={Mui.Paper} sx={isEmpty ? sx['hide'] : sx['box']}>
            <MuiIcon.HighlightOff
              onClick={onRequestClose}
              sx={sx['closeModalIcon']}
            />
            <Mui.Typography sx={sx['title']}>
              {solarCompany || ''}
            </Mui.Typography>
            <Pad amt={10} />
            <Mui.Typography sx={sx['title']}>
              {c.getFieldLabel(field)}
            </Mui.Typography>
            <Pad amt={20} />
            <Mui.Typography sx={sx['cityLabel']}>
              {filterSelected || ''}
            </Mui.Typography>

            {pricesCitySelected.length ? (
              <>
                <Pad amt={20} />
                <Mui.Box sx={sx['boxButtons']}>
                  <Mui.Button
                    color={city === 'fresno' ? 'primary' : 'inherit'}
                    onClick={handleCityToFresno}
                    size="small"
                    sx={sx['button']}
                    variant="contained"
                  >
                    Fresno
                  </Mui.Button>
                  <Pad amt={20} row />
                  <Mui.Button
                    color={city === 'victorville' ? 'primary' : 'inherit'}
                    onClick={handleCityToVictorVille}
                    size="small"
                    sx={sx['button']}
                    variant="contained"
                  >
                    <Mui.Typography sx={sx['textButton']}>
                      Victorville / S.Bernandino
                    </Mui.Typography>
                  </Mui.Button>
                </Mui.Box>
              </>
            ) : null}
            <Mui.Box sx={sx['priceListBox']}>
              {pricesCitySelected.length ? (
                <Mui.List sx={sx['list']}>
                  {pricesCitySelected.map((price) => (
                    <Mui.ListItem
                      sx={sx['listItem']}
                      key={JSON.stringify(price.list)}
                    >
                      {price.list.map((item) => (
                        <React.Fragment key={item.label}>
                          <Mui.ListItemText>
                            <Mui.Typography sx={sx['priceLabel']}>
                              {item.label}
                            </Mui.Typography>
                          </Mui.ListItemText>
                          <Mui.ListItemText>
                            <Mui.Typography sx={sx['price']}>
                              ${item.value}
                            </Mui.Typography>
                          </Mui.ListItemText>
                        </React.Fragment>
                      ))}
                    </Mui.ListItem>
                  ))}
                </Mui.List>
              ) : (
                <Mui.List sx={sx['list']}>
                  {priceList.map((price) => (
                    <Mui.ListItem sx={sx['listItem']} key={price.label}>
                      <Mui.ListItemText>
                        <Mui.Typography sx={sx['priceLabel']}>
                          {price.label}
                        </Mui.Typography>
                      </Mui.ListItemText>
                      <Mui.ListItemText>
                        <Mui.Typography sx={sx['price']}>
                          ${price.value}
                        </Mui.Typography>
                      </Mui.ListItemText>
                    </Mui.ListItem>
                  ))}
                </Mui.List>
              )}
            </Mui.Box>
          </Mui.Box>
          <Mui.Box sx={isEmpty ? sx['box'] : sx['hide']}>
            <Mui.Alert
              onClose={onRequestClose}
              severity="info"
              sx={sx['alert']}
            >
              Price list is empty
            </Mui.Alert>
          </Mui.Box>
        </>
      </Mui.Modal>
    </>
  )
})

const sx = {
  alert: {
    fontSize: '18px',
  },
  box: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    left: '50%',
    padding: '30px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      sm: '425px',
      xs: '90%',
    },
  },
  boxButtons: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: '120px',
  },
  cityLabel: {
    fontSize: '18px',
    fontWeight: '700',
    textTransform: 'capitalize',
  },
  closeModalIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: '20px',
    top: '20px',
  },
  hide: {
    display: 'none',
  },
  list: {
    padding: 0,
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
  },
  modal: {
    display: 'unset',
  },
  price: {
    color: 'darkgreen',
    fontWeight: '700',
    fontSize: '18px',
  },
  priceIcon: {
    alignSelf: 'flex-start',
    color: c.themeTuple.light.paper.color,
    cursor: 'pointer',
    width: '40px',
  },
  priceIconDark: {
    alignSelf: 'flex-start',
    color: c.themeTuple.dark.paper.color,
    cursor: 'pointer',
    width: '40px',
  },
  priceLabel: {
    fontSize: '18px',
    textAlign: 'center',
  },
  priceListBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '425px',
    overflowY: 'auto',
    width: '95%',
  },
  textButton: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
  },
  title: {
    fontSize: '22px',
    fontWeight: '700',
  },
}

// TODO: Parametrize
const getFilterSelected = (
  solarCompany: EnumButtonFilter,
): EnumButtonFilter | null => {
  if (solarCompany) {
    return null
  }
  if (solarCompany === 'NPS') {
    return EnumButtonFilter.NEVADA
  } else if (solarCompany === 'CPS') {
    return EnumButtonFilter.CALIFORNIA
  } else if (solarCompany === 'UTPS') {
    return EnumButtonFilter.UTAH
  } else if (solarCompany === 'TPS') {
    return EnumButtonFilter.TEXAS
  }
  return null
}
