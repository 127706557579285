import React from 'react'

import * as common from '../../../common'

import AcGuideline from '../AcGuideline'
import LayoverLayerAlert from '../LayoverLayerAlert'

export interface DropDownInputSpecialContentProps {
  customerID: string
  field: keyof common.Customer
}

export default React.memo<DropDownInputSpecialContentProps>(
  function DropDownInputSpecialContent({ customerID, field }) {
    if (field === 'roof_layers_how_many') {
      return (
        <>
          <LayoverLayerAlert customerID={customerID} />
        </>
      )
    }

    if (field === 'air_conditioner_new_tons') {
      return (
        <>
          <AcGuideline customerID={customerID} />
        </>
      )
    }
    return null
  },
)
