import React from 'react'
import { Question } from '../../helpers/Vars'
//@ts-ignore
import { Fancybox } from '@fancyapps/ui'

import Rec from '../../img/faq/REC 265 INV.jpeg'

import elecGen from '../../img/faq/Electricity generation, transmission, and distribution.jpeg'

import homeSolarPanel from '../../img/faq/Home with Solar Panels.jpeg'

import styles from './FaqQuestions.module.css'

export interface FaqQuestionsProps {
  question: Question
  index: number
}

export default React.memo<FaqQuestionsProps>(function FaqQuestions({
  question,
  index,
}) {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    Fancybox.bind('[data-fancybox]', {
      Thumbs: {
        autoStart: false,
      },
    })
  }, [])
  return (
    <div className={styles['container']}>
      <h3
        className={styles['question']}
        onClick={() => {
          setShow((current) => !current)
        }}
      >
        {index + 1}). {question.question}
      </h3>

      <div
        className={
          !show
            ? styles['answer-content']
            : styles['answer-content'] + ' ' + styles['show']
        }
      >
        {index + 1 === 2 ? (
          <div className={styles['gallery']}>
            <img
              src={Rec}
              width={320}
              alt=""
              data-fancybox
              data-src={Rec}
              //@ts-expect-error
              data-caption={question?.answer[0]}
            />
          </div>
        ) : null}

        {index + 1 === 5 ? (
          <div className={styles['gallery']}>
            <img src={elecGen} alt="" data-fancybox data-src={elecGen} />
            <img
              src={homeSolarPanel}
              alt=""
              data-fancybox
              data-src={homeSolarPanel}
            />
          </div>
        ) : null}

        {question.answer
          ? question.answer.map((value) => (
              <p className={styles['answer']}>{value}</p>
            ))
          : null}
      </div>
    </div>
  )
})
