import React from 'react'

import * as c from '../../../common'

import * as Mui from '@mui/material'

export interface SelectFinanceButtonProps {
  onClick: () => void
}

export default React.memo<SelectFinanceButtonProps>(
  function SelectFinanceButton({ onClick }) {
    return (
      <Mui.Button
        onClick={onClick}
        sx={sx['selectFinanceButton']}
        variant="contained"
      >
        <Mui.Box component="span" sx={sx['selectFinanceWrapperButtonLabel']}>
          <Mui.Typography component="span" sx={sx['selectFinanceButtonText']}>
            GoodLeap 25yr 4.49 (Levelized Payment)
          </Mui.Typography>
          <Mui.Typography component="span" sx={sx['selectFinanceButtonLabel']}>
            Select Finance Option
          </Mui.Typography>
        </Mui.Box>
      </Mui.Button>
    )
  },
)

const sx = {
  selectFinanceButton: {
    backgroundColor: 'black',
    borderRadius: '0.8vw',
    color: c.themeTuple.light.paper.color!,
    cursor: 'pointer',
    display: 'grid',
    fontSize: '1vw',
    fontWeight: '700',
    justifyItems: 'center',
    padding: {
      lg: 'calc(1200px*0.02)',
      xs: '2vw',
    },
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: 'black',
    },
  },
  selectFinanceButtonLabel: {
    fontSize: {
      lg: 'calc(1200px*0.013)',
      xs: '1.3em',
    },
    marginTop: '0.5vh',
    opacity: '.7',
  },
  selectFinanceButtonText: {
    borderRadius: '0.8vw',
    color: c.themeTuple.light.paper.color!,
    cursor: 'pointer',
    display: 'grid',
    fontSize: {
      xs: '2em',
      lg: 'calc(1200px*0.02)',
    },
    fontWeight: '700',
    height: 'auto',
    justifyItems: 'center',
    padding: {
      lg: 'calc(1200px*0.02)',
      xs: '2vw',
    },
    textTransform: 'none',
    width: '100%',
  },
  selectFinanceWrapperButtonLabel: {
    alignItems: 'inherit',
    display: 'inherit',
    justifyContent: 'inherit',
    width: '100%',
  },
}
