import { Box, Checkbox, TextField } from '@mui/material'
import React from 'react'
import { CheckInput } from './Services'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'

export interface CheckInputsProps {
  checkRow: CheckInput
  i: number
  setCheckInputs: React.Dispatch<React.SetStateAction<CheckInput[]>>
  len: number
}

export default React.memo<CheckInputsProps>(function ChecksInputs({
  checkRow,
  i,
  setCheckInputs,
  len,
}) {
  const textRef = React.useRef<HTMLInputElement>(
    document.createElement('input'),
  )
  const priceRef = React.useRef<HTMLInputElement>(
    document.createElement('input'),
  )
  const checkRef = React.useRef<HTMLInputElement>(
    document.createElement('input'),
  )
  return (
    <Box sx={{ display: 'flex', width: '70%', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Checkbox
          defaultChecked={checkRow.check}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
          inputRef={checkRef}
          onChange={() => {
            const check = checkRef.current.checked
            const text = textRef.current.value
            const price = priceRef.current.value

            if (!check) {
              textRef.current.disabled = true
              textRef.current.style.backgroundColor = 'gainsboro'
              priceRef.current.disabled = true
              priceRef.current.style.backgroundColor = 'gainsboro'
            } else {
              textRef.current.disabled = false
              textRef.current.style.backgroundColor = 'unset'
              priceRef.current.disabled = false
              priceRef.current.style.backgroundColor = 'unset'
            }
            setCheckInputs((prevState) => [
              ...prevState.map((value, index) => {
                if (index === i) {
                  return (prevState[i] = {
                    check: check,
                    text: text,
                    price: price,
                  })
                }

                return value
              }),
            ])
          }}
        />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          value={checkRow.text}
          inputRef={textRef}
          onChange={(e) => {
            const check = checkRef.current.checked
            const text = e.target.value
            const price = priceRef.current.value

            setCheckInputs((prevState) => [
              ...prevState.map((value, index) => {
                if (index === i) {
                  return (prevState[i] = {
                    check: check,
                    text: text,
                    price: price,
                  })
                }

                return value
              }),
            ])
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          value={checkRow.price}
          inputRef={priceRef}
          type="number"
          onChange={(e) => {
            const check = checkRef.current.checked
            const text = textRef.current.value
            const price = e.target.value
            setCheckInputs((prevState) => [
              ...prevState.map((value, index) => {
                if (index === i) {
                  return (prevState[i] = {
                    check: check,
                    text: text,
                    price: price,
                  })
                }

                return value
              }),
            ])
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',

          height: '56px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <RemoveCircleOutlineIcon
          onClick={() => {
            if (i + 1 === len) return
            setCheckInputs((currentValue) => [
              ...currentValue.filter((_, index) => index !== i),
            ])
          }}
          sx={{ cursor: 'pointer' }}
        />
      </Box>
    </Box>
  )
})
