// Keep the wrong style import (common/env instead of common) due to
// scripts/env-check
import * as utils from '../../common/utils'
import { companiesLen, globalEnv } from '../../common/env'

const validBase64Prefixes = [
  'data:image/jpeg;base64,',
  'data:image/png;base64,',
  'data:image/svg+xml;base64,',
] as const

export const REACT_APP_GOOGLE_PLACES_API_KEY = globalEnv.G_API_KEY_WEB || ''

if (typeof REACT_APP_GOOGLE_PLACES_API_KEY !== 'string') {
  throw new Error(
    `REACT_APP_GOOGLE_PLACES_API_KEY not a string: ${REACT_APP_GOOGLE_PLACES_API_KEY}`,
  )
}

if (REACT_APP_GOOGLE_PLACES_API_KEY.length < 30) {
  throw new Error(
    `REACT_APP_GOOGLE_PLACES_API_KEY envvar too short: ${REACT_APP_GOOGLE_PLACES_API_KEY}`,
  )
}
//#region fakeHouse
export const FAKE_HOUSE = process.env['REACT_APP_FAKE_HOUSE'] || ''

const fakeCustomerHouseIsValid = validBase64Prefixes.some((p) =>
  FAKE_HOUSE.startsWith(p),
)

if (!fakeCustomerHouseIsValid) {
  throw new TypeError('Invalid format type string of REACT_APP_FAKE_HOUSE')
}
//#endregion fakeHouse
//#region companyLogos
const COMPANY_LOGOS_UNPARSED = process.env['REACT_APP_COMPANY_LOGOS'] || ''

if (typeof COMPANY_LOGOS_UNPARSED !== 'string') {
  throw new TypeError(`REACT_APP_COMPANY_LOGOS envvar not an string`)
}

const COMPANY_LOGOS = COMPANY_LOGOS_UNPARSED.split(':')

export const companyToLogo: utils.ReadonlyRecord<string> = (() => {
  const res: Record<string, string> = {}

  for (const companiesToLogo of COMPANY_LOGOS) {
    const hasBadChars =
      companiesToLogo.includes(':') ||
      companiesToLogo.includes('"') ||
      companiesToLogo.includes("'")
    const hasCorrectChars = companiesToLogo.includes('=')
    const isValid = !hasBadChars && hasCorrectChars

    if (!isValid) {
      throw new TypeError('REACT_APP_COMPANY_LOGOS envvar malformed')
    }

    const [companiesStr = '', logo = ''] = companiesToLogo.split('=')

    if (companiesStr.length < 2 || logo.length < 100) {
      console.log('LOGO LENGTH: ', logo.length, ' ', logo)
      throw new TypeError('REACT_APP_COMPANY_LOGOS envvar malformed')
    }

    const companies = companiesStr.split(',')

    for (const company of companies) {
      if (company.length < 2 || company.includes('=')) {
        throw new TypeError('REACT_APP_COMPANY_LOGOS envvar malformed')
      }
      res[company] = 'data:image/png;base64,' + logo
    }
  }

  const logoCompaniesLen = utils.keys(res).length
  if (logoCompaniesLen !== companiesLen) {
    throw new Error(
      `There are ${companiesLen} companies but got ${logoCompaniesLen} from the logos`,
    )
  }

  return res
})()
//#endregion companyLogos

export const FORTITUDE_LOGO = process.env['REACT_APP_FORTITUDE_LOGO'] || ''

const fortitudeLogoIsValid = validBase64Prefixes.some((p) =>
  FORTITUDE_LOGO.startsWith(p),
)

if (!fortitudeLogoIsValid) {
  throw new TypeError('Invalid format type string of REACT_APP_FORTITUDE_LOGO')
}

export const ATLAS_LOGO = process.env['REACT_APP_ATLAS_LOGO'] || ''

const atlasLogoIsValid = validBase64Prefixes.some((p) =>
  ATLAS_LOGO.startsWith(p),
)

if (!atlasLogoIsValid) {
  throw new TypeError('Invalid format type string of REACT_APP_ATLAS_LOGO')
}

export const ABOVE_ALL_LOGO = process.env['REACT_APP_ABOVE_ALL_LOGO'] || ''

const aboveAllLogoIsValid = validBase64Prefixes.some((p) =>
  ABOVE_ALL_LOGO.startsWith(p),
)

if (!aboveAllLogoIsValid) {
  throw new TypeError('Invalid format type string of REACT_APP_ABOVE_ALL_LOGO')
}

export const RHINO_LOGO = process.env['REACT_APP_RHINO_LOGO'] || ''

const rhinoLogoIsValid = validBase64Prefixes.some((p) =>
  RHINO_LOGO.startsWith(p),
)

if (!rhinoLogoIsValid) {
  throw new TypeError('Invalid format type string of REACT_APP_RHINO_LOGO')
}

export const FB_FUNCTIONS_API_URL = globalEnv.FB_FUNCTIONS_URL

if (typeof FB_FUNCTIONS_API_URL !== 'string') {
  const msg = `REACT_APP_FB_FUNCTIONS_API_URL not a string: ${FB_FUNCTIONS_API_URL}`
  // @ts-ignore

  throw new Error(msg)
}
