import React from 'react'

import * as common from '../../../common'

import { Chart } from '../../helpers/Vars'

import styles from './FinancingButton.module.css'
import styled from '@emotion/styled'

export interface FinancingButtonProps {
  company: string
  handleChart: (value: Chart) => void
  chart: Chart
  contentUser: 'SOLAR' | 'NORMAL' | 'ALARM'
}

export default React.memo<FinancingButtonProps>(function FinancingButtonProps({
  chart,
  company,
  handleChart,
  contentUser,
}) {
  const StyledButton = styled.button`
    align-self: center;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    padding: 7px;
    width: 30%;
    font-size: 20px;
    font-weight: bold;
  `

  return (
    <div className={styles['container']}>
      {/* CASH  BUTTON */}
      {contentUser === 'ALARM' ? null : (
        <StyledButton
          style={{
            color: chart === 'CASH' ? 'white' : common.companyColors[company],
            backgroundColor:
              chart === 'CASH' ? common.companyColors[company] : 'white',
          }}
          onClick={() => {
            handleChart('CASH')
          }}
        >
          Cash
        </StyledButton>
      )}
      {/* ALARM ALT  BUTTON */}
      {contentUser !== 'ALARM' ? null : (
        <StyledButton
          style={{
            color: chart === 'REPS' ? 'white' : common.companyColors[company],
            backgroundColor:
              chart === 'REPS' ? common.companyColors[company] : 'white',
          }}
          onClick={() => {
            handleChart('REPS')
          }}
        >
          Alarms Reps
        </StyledButton>
      )}

      {/* ALARM  BUTTON */}
      {contentUser !== 'ALARM' ? null : (
        <StyledButton
          style={{
            color: chart === 'ALARM' ? 'white' : common.companyColors[company],
            backgroundColor:
              chart === 'ALARM' ? common.companyColors[company] : 'white',
          }}
          onClick={() => {
            handleChart('ALARM')
          }}
        >
          Alarm
        </StyledButton>
      )}

      {/* FINANCING BUTTON */}
      <StyledButton
        style={{
          color:
            chart === 'FINANCING' ? 'white' : common.companyColors[company],
          backgroundColor:
            chart === 'FINANCING' ? common.companyColors[company] : 'white',
        }}
        onClick={() => {
          handleChart('FINANCING')
        }}
      >
        Financing
      </StyledButton>

      {/* SOLAR  BUTTON */}
      <StyledButton
        style={{
          color: chart === 'SOLAR' ? 'white' : common.companyColors[company],
          backgroundColor:
            chart === 'SOLAR' ? common.companyColors[company] : 'white',
        }}
        onClick={() => {
          handleChart('SOLAR')
        }}
      >
        {contentUser === 'SOLAR' ? 'PPA' : 'Solar'}
      </StyledButton>
    </div>
  )
})
