import React from 'react'

import * as c from '../../../common'

import * as r from '../../../react-utils'

import Button from '@mui/material/Button'

import Pad from '../../comps/Pad'
import { auth } from '../../fire'

import styles from './Signature.module.css'

export interface CustomerSignatureProps {
  customerID: string
  field: keyof c.Customer
  onRequestClose(): void
}

export default React.memo<CustomerSignatureProps>(function Signature({
  customerID,
  field,
  onRequestClose,
}) {
  const selectCustomerField = React.useMemo(
    (): ReturnType<typeof c.makeSelectCustomerField> =>
      c.makeSelectCustomerField(),
    [],
  )
  const selectCustomerFieldArgs = React.useMemo(
    (): c.SelectCustomerFieldParams => ({
      customerID,
      field,
    }),
    [customerID, field],
  )

  // Remember to subscribe to the customer elsewhere!
  const currentSignature = c.useSelector(
    (_): c.ValueOf<c.Customer> =>
      selectCustomerField(_, selectCustomerFieldArgs),
  )

  const signature = React.useRef<HTMLCanvasElement>(
    document.createElement('canvas'),
  )

  const isMounted = r.useIsMounted()

  const draw = React.useCallback((posX: number, posY: number) => {
    const context = signature.current.getContext('2d')
    if (!context) return

    context.beginPath()
    context.moveTo(initialX as number, initialY as number)
    context.lineWidth = 2
    context.strokeStyle = '#000'
    context.lineCap = 'round'
    context.lineJoin = 'round'
    context.lineTo(posX, posY)
    context.stroke()
    initialX = posX
    initialY = posY
  }, [])

  const getRelativePosition = (clientX: number, clientY: number) => {
    const rect = signature.current.getBoundingClientRect()
    const scaleX = signature.current.width / rect.width
    const scaleY = signature.current.height / rect.height

    return {
      x: (clientX - rect.left) * scaleX,
      y: (clientY - rect.top) * scaleY,
    }
  }

  const touchMove = React.useCallback(
    (e) => {
      e.preventDefault()
      const touches = e.changedTouches

      if (touches[0]) {
        const { x, y } = getRelativePosition(
          touches[0]?.clientX,
          touches[0]?.clientY,
        )
        draw(x, y)
      }
    },
    [draw],
  )

  const mouseMove = React.useCallback(
    (e) => {
      const { x, y } = getRelativePosition(e.clientX, e.clientY)
      draw(x, y)
    },
    [draw],
  )

  const mouseUp = React.useCallback(() => {
    signature.current.removeEventListener('mousemove', mouseMove)
    initialX = undefined
    initialY = undefined
  }, [mouseMove])

  const mouseDown = React.useCallback(
    (e) => {
      const { x, y } = getRelativePosition(e.clientX, e.clientY)
      initialX = x
      initialY = y
      draw(x, y)
      signature.current.addEventListener('mousemove', mouseMove)
    },
    [draw, mouseMove],
  )

  const touchStart = React.useCallback(
    (e) => {
      const touches = e.changedTouches

      if (touches[0]) {
        const { x, y } = getRelativePosition(
          touches[0]?.clientX,
          touches[0]?.clientY,
        )
        initialX = x
        initialY = y
        draw(x, y)
        signature.current.addEventListener('touchmove', touchMove)
      }
    },
    [draw, touchMove],
  )

  const touchUp = React.useCallback(() => {
    signature.current.removeEventListener('touchmove', touchMove)
    initialX = undefined
    initialY = undefined
  }, [touchMove])

  const isCanvasEmpty = React.useCallback(() => {
    const canvas = signature.current
    const context = signature.current.getContext('2d')

    if (!context) return

    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, canvas.width, canvas.height).data.buffer,
    )

    return !pixelBuffer.some((color) => color !== 0)
  }, [])

  const compressSignature = (): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const signatureCanvas = signature.current
      signatureCanvas.toBlob(
        (blob) => {
          if (blob === null) {
            reject(blob)
          } else {
            resolve(blob)
          }
        },
        'image/jpg',
        0.1,
      )
    })
  }

  const signatureToBase64 = React.useCallback(async () => {
    if (currentSignature) {
      alert(c.getFieldLabel(field) + ' is already draw')
      return
    }

    if (isCanvasEmpty()) {
      alert('Signature is empty')
      return
    }

    const image = await compressSignature()

    if (!isMounted()) {
      return
    }
    let reader = new FileReader()
    reader.readAsDataURL(image)
    reader.onload = () => {
      if (!isMounted()) {
        return
      }
      const base64String = reader.result

      const currentDate = Date.now()

      const signDateKey = `${field}_date`
      c.updateCustomer(customerID, {
        [field]: base64String as string,
        [signDateKey]: currentDate,
      })

      onRequestClose()
    }
  }, [
    currentSignature,
    isCanvasEmpty,
    isMounted,
    field,
    customerID,
    onRequestClose,
  ])

  const clear = React.useCallback(() => {
    const signDateKey = `${field}_date`
    if (
      authorizedEmails.includes(auth.currentUser?.email as string) === false
    ) {
      signature.current
        .getContext('2d')!
        .clearRect(0, 0, signature.current.width, signature.current.height)
      alert('The canvas was reset, but not the signature')
    } else {
      signature.current
        .getContext('2d')!
        .clearRect(0, 0, signature.current.width, signature.current.height)
      c.updateCustomer(customerID, {
        [field]: '',
        [signDateKey]: '',
      })
      alert('The signature was reset')
    }
  }, [customerID, field])

  React.useEffect((): c.VoidFn => {
    if (!customerID) {
      return c.EMPTY_FN
    }
    c.dispatch(
      c.subToSingleCustomer({
        customerID: customerID,
      }),
    )
    return () => {
      c.dispatch(
        c.unSubFromSingleCustomer({
          customerID,
        }),
      )
    }
  }, [customerID])

  return (
    <>
      <canvas
        onTouchStart={touchStart}
        onTouchEnd={touchUp}
        onMouseDown={mouseDown}
        onMouseUp={mouseUp}
        height={320}
        ref={signature}
        className={styles['canvas']}
        width={480}
      />

      <Pad amt={20} />
      <div style={sx.containerButtons}>
        <Button variant="contained" onClick={signatureToBase64}>
          Save
        </Button>

        <Button variant="contained" color="error" onClick={clear}>
          Clear
        </Button>
      </div>
    </>
  )
})

const authorizedEmails = [
  'danlugo92@gmail.com',
  'castellanorick@gmail.com',
  'ricardo@unitedpowersolutions.com',
  'erwinjv98@gmail.com',
]

let initialX: unknown
let initialY: unknown

const sx = {
  containerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
} as const
