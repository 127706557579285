import React from 'react'
import * as c from '../../../common'

import * as r from '../../../react-utils'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { SxProps, Theme } from '@mui/material'

export interface SsnInputProps {
  customerID: string
  field: 'ssn'
}

const formatSsn = (char: string) => {
  if (char.length === 3) {
    const _char = char + '-'
    return _char
  } else if (char.length === 6) {
    const _char = char + '-'
    return _char
  } else if (char.length > 10) {
    return char.substring(0, char.length - 1)
  } else {
    return char
  }
}

export default React.memo<SsnInputProps>(function SsnInput({
  customerID,
  field,
}) {
  const t = r.useTheme()
  const [snnValue] = r.useCustomerField(customerID, field)

  const [value, setValue] = React.useState<string>(snnValue)
  const [isSsn, setIsSsn] = React.useState(true)

  const validateSsn = React.useCallback(
    (e) => {
      let regExp = new RegExp(/^\d{3}-?\d{2}-?\d{4}$/)

      if (regExp.test(e.target.value)) {
        setIsSsn(true)
        return true
      } else {
        setIsSsn(false)

        return false
      }
    },
    [setIsSsn],
  )

  React.useEffect(() => {
    setValue(snnValue)
  }, [snnValue])

  const handleKeyDown = React.useCallback((e) => {
    if (e.key !== 'Backspace') {
      //@ts-ignore
      e.target.value = formatSsn(e.target.value)
    }
  }, [])

  const handleChange = React.useCallback(
    (e) => {
      const changeValue = e.target.value
      setValue(changeValue)
      if (!validateSsn(e)) {
        return
      }

      c.updateCustomer(customerID, {
        [field]: changeValue,
      })
    },
    [customerID, field, validateSsn],
  )

  const containerStyle = React.useMemo((): SxProps<Theme> => {
    return {
      display: { xs: 'column', sm: 'flex' },
      marginBottom: {
        xs: '20px',
        sm: field.endsWith('customer_cost') ? 'unset' : '20px',
      },
    }
  }, [field])

  return (
    <Box sx={containerStyle}>
      <TextField
        id={field}
        label={c.getFieldLabel(field)}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        sx={{
          backgroundColor: t.input.paper.backgroundColor,
          width: { xs: '90%', sm: 258, lg: 298 },
        }}
        type="text"
        value={value}
        variant="outlined"
      />

      {!isSsn && (
        <Alert severity="warning" sx={sx.alert}>
          Invalid SSN
        </Alert>
      )}
    </Box>
  )
})

const sx = {
  alert: {
    marginLeft: { xs: 'unset', sm: '20px' },
    marginTop: { xs: '20px', sm: 'unset' },
    textAlign: 'center',
    width: { xs: '90%', sm: 258, lg: 298 },
  },
} as const
