import React from 'react'
import Alert from '../../routes/auditor/Alert/Alert'
import { Chart } from '../../helpers/Vars'
import styles from './AlarmRepsSection.module.css'

export interface AlarmRepsSectionProps {
  chart: Chart
  handleMonthlyAlarm: (monthlyAlarm: number) => void
}

export default React.memo<AlarmRepsSectionProps>(function AlarmRepsSection({
  chart,
  handleMonthlyAlarm,
}) {
  return (
    <div className={chart === 'REPS' ? styles['container'] : styles['hide']}>
      <Alert handleMonthlyAlarm={handleMonthlyAlarm} />
    </div>
  )
})
