import React, { useState, useRef } from 'react'
import * as rn from 'react-native'
import {
  Dimensions,
  FlatList,
  TouchableOpacity,
  useColorScheme,
} from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'

import { Modal } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import * as gStyles from '../gStyles'

import Text from './Text'
import View from './View'

interface DropdownProps {
  readonly data: c.Opts
  readonly disabled?: boolean
  readonly on: c.SurfaceType
  readonly onValueChange: (value: string) => void
  /**
   * Empty means no value selected.
   */
  readonly selectedValue: string
}

const screenHeight = Dimensions.get('window').height
const maxDropdownHeight = screenHeight * 0.4
const initDropdownGeometry = { bottom: 0, left: 0, top: 0, width: 0 }
const getInitialScrollIndex = (data: c.Opts, selectedValue: string) =>
  data.findIndex((x) => x.value === selectedValue)

const CustomDropdown = React.memo<DropdownProps>(
  ({ data, disabled, on, onValueChange, selectedValue }) => {
    const colorScheme = useColorScheme()
    const isDark = colorScheme === 'dark'
    const styles = r.useThemedStyleSheet(themedStyles, on)

    const [isVisible, setIsVisible] = useState(false)
    const [dropdownGeometry, setDropdownGeometry] = useState<{
      bottom?: number
      left: number
      top?: number
      width: number
    }>(initDropdownGeometry)

    const buttonRef = useRef<TouchableOpacity | null>(null)

    const toggleDropdown = React.useCallback(() => {
      if (isVisible) {
        setIsVisible(false)
      } else {
        buttonRef.current?.measure((_, __, width, height, pageX, pageY) => {
          const spaceBelow = screenHeight - pageY - height
          const spaceAbove = pageY

          // Determines whether the dropdown menu should scroll up or down
          if (spaceBelow >= maxDropdownHeight) {
            // Drop down
            setDropdownGeometry({ left: pageX, top: pageY + height, width })
          } else if (spaceAbove >= 150) {
            // Unfold up
            setDropdownGeometry({
              bottom: screenHeight - pageY,
              left: pageX,
              width,
            })
          }

          setIsVisible(true)
        })
      }
    }, [isVisible])

    const handleSelect = React.useCallback(
      (value: string) => {
        onValueChange(value)
        setIsVisible(false)
      },
      [onValueChange],
    )

    const renderItem = React.useCallback(
      ({ item }: { item: c.Opt }): React.ReactElement => (
        <Item
          isSelected={selectedValue === item.value}
          label={item.label}
          onSelect={handleSelect}
          value={item.value}
        />
      ),
      [handleSelect, selectedValue],
    )

    const dropdownStyle = React.useMemo(
      () => [styles.dropdown, dropdownGeometry],
      [dropdownGeometry, styles.dropdown],
    )

    const renderDropdown = React.useCallback((): React.ReactElement => {
      const handlePress = () => {
        setIsVisible(false)
      }

      return (
        <Modal animationType="none" transparent visible={isVisible}>
          <TouchableOpacity onPress={handlePress} style={gStyles.flexGrow}>
            <FlatList
              ItemSeparatorComponent={CustomDropdownSeparator}
              data={data}
              initialScrollIndex={getInitialScrollIndex(data, selectedValue)}
              keyExtractor={keyExtractor}
              onScrollToIndexFailed={console.log}
              renderItem={renderItem}
              style={dropdownStyle}
            />
          </TouchableOpacity>
        </Modal>
      )
    }, [data, dropdownStyle, isVisible, renderItem, selectedValue])

    const buttonTextStyle = React.useMemo(
      () => [styles.buttonText, !selectedValue && styles.placeholder],
      [selectedValue, styles.buttonText, styles.placeholder],
    )

    if (disabled) {
      return (
        <View style={styles.button}>
          <Text centerText>{selectedValue || 'Select...'}</Text>
        </View>
      )
    }

    return (
      <>
        <rn.Pressable
          onPress={toggleDropdown}
          ref={buttonRef}
          style={styles.button}
        >
          <Text style={buttonTextStyle}>
            {data.find((o) => o.value === selectedValue)?.label || 'Select...'}
          </Text>

          <Icon
            color={isDark ? 'white' : 'black'}
            name={isVisible ? 'chevron-up' : 'chevron-down'}
            size={24}
          />
        </rn.Pressable>

        {renderDropdown()}
      </>
    )
  },
)

const keyExtractor = (item: c.Opt): string => item.value

interface ItemProps extends c.Opt {
  readonly isSelected: boolean
  readonly onSelect: (value: string) => void
}

const Item = React.memo<ItemProps>(function DropdownItem({
  isSelected,
  label,
  onSelect,
  value,
}) {
  const t = r.useTheme()
  const styles = r.useThemedStyleSheet(themedStyles)

  const handlePress = React.useCallback(() => {
    onSelect(value)
  }, [onSelect, value])

  return (
    <rn.Pressable onPress={handlePress} style={styles.item}>
      <Text style={styles.itemText}>{label}</Text>

      {isSelected && <Icon color={t.paper.highlight} name="check" size={24} />}
    </rn.Pressable>
  )
})

const CustomDropdownSeparator = React.memo(function CustomDropdownSeparator() {
  const styles = gStyles.useGlobalStyles('backdrop')
  return <rn.View style={styles.separatorH} />
})

const themedStyles = r.ThemedStyleSheet.create((t, on) => {
  return {
    button: {
      ...t.input[on],
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 12,
      paddingVertical: 18,
    },
    buttonText: {
      fontFamily: t.fontFamily,
      color: t.input[on].color,
      fontSize: 15,
    },
    placeholder: { color: t.input[on].placeHolderColor },
    icon: { marginRight: 10 },
    dropdown: {
      ...t.backdrop,
      borderRadius: t.borderRadius,
      maxHeight: maxDropdownHeight,
      position: 'absolute',
      ...gStyles.shadowHalf,
    },
    item: {
      alignItems: 'center',
      borderRadius: t.borderRadius,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 12,
      paddingVertical: 12,
    },
    itemText: {
      color: t.backdrop.color,
      fontFamily: t.fontFamily,
    },
  }
})

export default CustomDropdown
