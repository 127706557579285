import React from 'react'

import * as common from '../../../common'
import { Customer } from '../../../common'

import { Box, Checkbox, TextField } from '@mui/material'

export interface CashbackOptions {
  cashbackOption: common.Opt
  customer: Customer
}

export default React.memo<CashbackOptions>(function cashBackOptions({
  cashbackOption,
  customer,
}) {
  return (
    <Box sx={{ display: 'flex', width: '70%', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Checkbox
          checked={true}
          disabled
          sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
        />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextField disabled value={cashbackOption.label} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          disabled
          value={customer[cashbackOption.value as keyof Customer]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '24px',
          height: '56px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ></Box>
    </Box>
  )
})
