import React from 'react'

import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import { useParams } from 'react-router-dom'

import { REACT_APP_GOOGLE_PLACES_API_KEY } from '../../milieu'

export interface GoogleMapReportProps {}

export default React.memo<GoogleMapReportProps>(function GoogleMapReport() {
  const { lat, lng } = useParams()

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_PLACES_API_KEY,
  })

  const coordinates = React.useMemo(
    () => ({
      lat: parseFloat(lat as string),
      lng: parseFloat(lng as string),
    }),
    [lat, lng],
  )

  if (!isLoaded) return <div>Loading...</div>

  return (
    <div>
      <GoogleMap
        center={coordinates}
        mapContainerStyle={mapContainerStyle}
        zoom={30}
      >
        <Marker position={coordinates} />
      </GoogleMap>
    </div>
  )
})

const mapContainerStyle = {
  height: '100vh',
  width: '100vw',
}
