import React from 'react'

import * as c from '../../../common'

import * as Mui from '@mui/material'
import * as MuiIcons from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

import Pad from '../Pad'

import './MediaImage.css'

export interface MediaImageProps {
  mediaKind: c.MediaKind
  mediaItemID: string
  customerID: string
}

export default React.memo<MediaImageProps>(function MediaImage({
  customerID,
  mediaItemID,
  mediaKind,
}) {
  const [open, setOpen] = React.useState(false)
  const isDark = useTheme().palette.mode === 'dark'

  const imageRef = React.useRef<HTMLImageElement | HTMLVideoElement>(
    document.createElement('img'),
  )

  const handleOpen = React.useCallback(() => {
    setOpen((current) => !current)
  }, [setOpen])

  const selectMediaItem = React.useMemo(
    (): ReturnType<typeof c.makeSelectMediaItem> => c.makeSelectMediaItem(),
    [],
  )

  const selectorArgs = React.useMemo<c.SelectMediaItemParams>(
    (): c.SelectMediaItemParams => ({
      customerID,
      mediaItemID,
      mediaKind,
    }),
    [customerID, mediaItemID, mediaKind],
  )

  const mediaItem = c.useSelector(
    (_): c.MediaItem => selectMediaItem(_, selectorArgs),
  )

  const handleClickIcon = React.useCallback(() => {
    imageRef.current.click()
  }, [])

  const thumbnail = c.useSelector(c.selectThumbnail(customerID, mediaItemID))

  const handleDeleteImage = React.useCallback(() => {
    handleDelete(customerID, mediaItemID, mediaKind)
  }, [customerID, mediaItemID, mediaKind])

  React.useEffect(() => {
    // console.log(Fancybox)
    if (!imageRef) {
      return
    }
    imageRef.current?.setAttribute('data-fancybox', '')

    if (mediaItem.video_of) {
      imageRef.current?.setAttribute('data-type', 'html5video')
    }

    imageRef.current?.setAttribute(
      'data-src',
      `${
        mediaItem.video_of
          ? c.getMediaItemSrc(mediaItem)
          : c.getMediaItemSrc(mediaItem)
          ? c.getMediaItemSrc(mediaItem)
          : thumbnail
      }`,
    )
  }, [mediaItem, thumbnail])

  return (
    <Mui.Card
      sx={isDark ? sx['containerCard'] : sx['containerDark']}
      elevation={5}
    >
      <Mui.CardActionArea>
        <MuiIcons.PlayCircleOutline
          onClick={handleClickIcon}
          sx={mediaItem.video_of ? sx['playIcon'] : sx['hide']}
        />
        <Mui.CardMedia
          //@ts-ignore
          ref={imageRef}
          component={mediaItem.image_of ? 'img' : 'video'}
          sx={sx['cardMedia']}
          image={
            mediaItem.video_of
              ? c.getMediaItemSrc(mediaItem)
              : thumbnail
              ? thumbnail
              : c.getMediaItemSrc(mediaItem)
          }
          alt={mediaItem.description}
        />
        <Mui.CardContent
          sx={isDark ? sx['cardContentDark'] : sx['cardContent']}
        >
          <Mui.TextField
            defaultValue={mediaItem.description}
            multiline
            onChange={(e) => {
              c.updateMediaItem(customerID, mediaItemID, {
                description: e.target.value,
              })
            }}
            rows={3}
            sx={sx['imageNotes']}
          />
        </Mui.CardContent>
      </Mui.CardActionArea>
      <Mui.Modal open={open} onClose={handleOpen}>
        <Mui.Box component={Mui.Paper} sx={sx['boxModal']}>
          <Mui.Alert severity="warning">Are you sure ?</Mui.Alert>
          <Pad amt={20} />
          <Mui.Button
            color="error"
            endIcon={<MuiIcons.Delete />}
            onClick={handleDeleteImage}
            variant="contained"
          >
            Delete
          </Mui.Button>
        </Mui.Box>
      </Mui.Modal>
      <Mui.Box
        sx={isDark ? sx['deleteIconWrapperDark'] : sx['deleteIconWrapper']}
      >
        <MuiIcons.HighlightOffTwoTone
          sx={sx['deleteIcon']}
          onClick={handleOpen}
        />
      </Mui.Box>
    </Mui.Card>
  )
})

const handleDelete = (
  customerID: string,
  mediaItemID: string,
  mediaKind: c.MediaKind,
) => {
  c.dispatch(
    c.requestedMediaDeletion({
      customerID,
      mediaItemID,
      mediaKind: mediaKind,
    }),
  )
}

const deleteIconWrapperBase = {
  display: 'flex',
  justifyContent: 'flex-end',
  left: '50%',
  position: 'absolute',
  top: '4px',
  transform: 'translate(-50%, 0%)',
  width: 'calc( 100% - 8px )',
}

const sx = {
  boxModal: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    left: '50%',
    padding: '20px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '300px',
  },
  cardMedia: {
    backgroundColor: c.dark.paper.backgroundColor!,
    height: {
      sm: '240px',
      xs: '240px',
    },
    padding: '4px',
  },
  cardContent: {
    backgroundColor: c.light.paper.backgroundColor!,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContentDark: {
    backgroundColor: c.dark.paper.backgroundColor!,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  containerCard: {
    backgroundColor: c.light.paper.backgroundColor!,
    position: 'relative',
    width: '260px',
  },
  containerDark: {
    backgroundColor: c.dark.paper.backgroundColor!,
    position: 'relative',
    width: '260px',
  },
  deleteIconWrapper: {
    ...deleteIconWrapperBase,
    backgroundColor: c.light.paper.backgroundColor!,
  },
  deleteIconWrapperDark: {
    ...deleteIconWrapperBase,
    backgroundColor: c.dark.paper.backgroundColor!,
  },
  deleteIcon: {
    color: '#fff',
    cursor: 'pointer',
    fontSize: '32px',
  },
  hide: {
    display: 'none',
  },
  imageNotes: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  playIcon: {
    backgroundColor: 'transparent',
    borderRadius: '50%',
    boxShadow: '4px 0px 7px rgba(0,0,0,1)',
    color: '#000000',
    cursor: 'pointer',
    fontSize: '100px',
    left: 'calc(50% - 50px)',
    position: 'absolute',
    top: '20%',
    width: 'inline',
    zIndex: 100,
  },
}
