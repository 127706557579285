import React from 'react'
import * as rn from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'

import * as gStyles from '../gStyles'

interface ValidationGuidelineProps {
  customerID?: string
  field: keyof c.Customer
}

export default React.memo<ValidationGuidelineProps>(
  function ValidationGuideline({ customerID, field }) {
    const styles = r.useThemedStyleSheet(themedStyles)

    // TODO: Check if selecting whole customer here is slow
    const selectCustomer = React.useMemo(
      (): ReturnType<typeof c.makeSelectCustomer> => c.makeSelectCustomer(),
      [],
    )
    const selectCustomerArgs = React.useMemo(
      (): c.SelectCustomerParams => ({
        customerID: customerID || '',
      }),
      [customerID],
    )

    const currentCustomer = c.useSelector(
      (_): c.Customer => selectCustomer(_, selectCustomerArgs),
    )

    const description = React.useMemo(
      // TODO: Check if useMemo really necessary in this case
      () => c.getDescriptionValidationGuideline(field, currentCustomer),
      [field, currentCustomer],
    )

    const isWarning = React.useMemo(
      () =>
        field === 'air_conditioner_new_tons' &&
        (isNaN(Number(currentCustomer?.attic_square_footage)) ||
          !currentCustomer?.attic_square_footage),
      [currentCustomer?.attic_square_footage, field],
    )

    if (!description) return null

    return (
      <rn.View style={styles.container}>
        <rn.View style={isWarning ? styles.circleWarning : styles.circleInfo}>
          <rn.Text style={styles.i}>i</rn.Text>
        </rn.View>

        <rn.View style={gStyles.padH4} />

        <rn.Text style={isWarning ? styles.textWarning : styles.textInfo}>
          {description}
        </rn.Text>
      </rn.View>
    )
  },
)

const themedStyles = r.ThemedStyleSheet.create((t) => {
  const circle = {
    alignItems: 'center',
    borderRadius: Number.MAX_SAFE_INTEGER,
    height: 20,
    justifyContent: 'center',
    width: 20,
  } as const
  const text: rn.TextStyle = { flexWrap: 'wrap', fontFamily: t.fontFamily }

  return {
    container: { alignItems: 'center', flexDirection: 'row' },
    i: { color: 'white' },
    circleInfo: { ...circle, backgroundColor: t.paper.highlight },
    circleWarning: { ...circle, backgroundColor: t.warning },
    textInfo: { ...text, color: t.paper.highlight },
    textWarning: { ...text, color: t.warning },
  }
})
