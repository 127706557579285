import React from 'react'

import * as common from '../../../common'

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import styled from '@emotion/styled'

import Pad from '../Pad'
import SolarChart from '../../routes/auditor/SolarChart'
import ToastAlert from '../ToastAlert'
import { Chart } from '../../helpers/Vars'
import { FormatPrice } from '../../helpers/FormatPrice'

import styles from './SolarSection.module.css'

export interface SolarSectionProps {
  altFeeAlarm: number
  chart: Chart
  company: string
  contentUser: 'SOLAR' | 'ALARM' | 'NORMAL'
  handleAlarmBill: (e: string) => void
  handleAltFeeAlarm: (e: any) => void
  handlePercentIncrease: (e: any) => void
  handlePowerBill: (e: string) => void
  handlePpa: (e: string) => void
  handlePpaIncrease: (e: any) => void
  handlePpaTwo: (e: string) => void
  handlePpaTwoIncrease: (e: any) => void
  handleSolarBill: (e: string) => void
  handleSolarLen: (e: any) => void
  handleSolarMonthly: (e: any) => void
  monthlyAlertPayments: number[]
  percentIncrease: number
  ppaIncrease: number
  ppaPayments: number[]
  ppaTwoIncrease: number
  ppaTwoPayments: number[]
  savingsPpa: number[]
  savingsPpaTwo: number[]
  savingsSolar: number[]
  solarLen: number
  solarPayments: number[]
  totalEnergy: number
  totalSolar: number
  yearlyPayments: number[]
}

export default React.memo<SolarSectionProps>(function SolarSection({
  altFeeAlarm,
  chart,
  company,
  contentUser,
  handleAlarmBill,
  handleAltFeeAlarm,
  handlePercentIncrease,
  handlePowerBill,
  handlePpa,
  handlePpaIncrease,
  handlePpaTwo,
  handlePpaTwoIncrease,
  handleSolarBill,
  handleSolarLen,
  handleSolarMonthly,
  monthlyAlertPayments,
  percentIncrease,
  ppaIncrease,
  ppaPayments,
  ppaTwoIncrease,
  ppaTwoPayments,
  savingsPpa,
  savingsPpaTwo,
  savingsSolar,
  solarLen,
  solarPayments,
  totalEnergy,
  totalSolar,
  yearlyPayments,
}) {
  const electricityBillInput = React.useRef<HTMLInputElement>(
    document.createElement('input'),
  )

  const percentIncreaseInput = React.useRef<HTMLInputElement>(
    document.createElement('input'),
  )

  const ppaInput = React.useRef<HTMLInputElement>(
    document.createElement('input'),
  )

  const ppaTwoInput = React.useRef<HTMLInputElement>(
    document.createElement('input'),
  )

  const solarPaymentInput = React.useRef<HTMLInputElement>(
    document.createElement('input'),
  )

  const alarmRef = React.useRef<HTMLInputElement>(
    document.createElement('input'),
  )

  const [alert, setAlert] = React.useState(false)

  const totalSavingsPpa = ppaPayments.reduce((a, b) => a + b, 0)

  const totalSavingPpaTwo = ppaTwoPayments.reduce((a, b) => a + b, 0)

  const handleCloseAlert = React.useCallback(() => {
    setAlert((current) => !current)
  }, [setAlert])

  const handleAlertSolarEmpty = React.useCallback(() => {
    if (!solarPaymentInput.current.value || !solarLen) {
      setAlert(true)
    }
  }, [solarLen, setAlert])

  const handleAlertAlarmEmpty = React.useCallback(() => {
    if (!alarmRef.current.value || !solarLen) {
      setAlert(true)
    }
  }, [solarLen, setAlert])

  const handleAlertElectricityEmpty = React.useCallback(() => {
    if (!electricityBillInput.current.value || percentIncrease < 0) {
      setAlert(true)
    }
  }, [percentIncrease, setAlert])

  const handlePpaEmpty = React.useCallback(() => {
    if (!ppaInput.current.value || ppaIncrease < 0) {
      setAlert(true)
    }
  }, [ppaIncrease])

  const handlePpaTwoEmpty = React.useCallback(() => {
    if (!ppaTwoInput.current.value || ppaTwoIncrease < 0) {
      setAlert(true)
    }
  }, [ppaTwoIncrease])

  const handleSolarPpaChart = React.useCallback(() => {
    if (contentUser !== 'SOLAR') {
      handleAlertSolarEmpty()
      handleSolarBill(solarPaymentInput.current.value)
    }
    if (contentUser === 'SOLAR') {
      handlePpaEmpty()
      handlePpa(ppaInput.current.value)
    }
  }, [
    contentUser,
    handleAlertSolarEmpty,
    handlePpa,
    handlePpaEmpty,
    handleSolarBill,
  ])

  const handleSolarPpa2Chart = React.useCallback(() => {
    handlePpaTwoEmpty()
    handlePpaTwo(ppaTwoInput.current.value)
  }, [handlePpaTwo, handlePpaTwoEmpty])

  const handleAlarmChart = React.useCallback(() => {
    handleAlertAlarmEmpty()
    handleAlarmBill(alarmRef.current.value)
  }, [handleAlarmBill, handleAlertAlarmEmpty])

  const handleAlertElectricityChart = React.useCallback(() => {
    handleAlertElectricityEmpty()
    handlePowerBill(electricityBillInput.current.value)
  }, [handleAlertElectricityEmpty, handlePowerBill])

  const StyledButton = React.useMemo(() => {
    return styled.button`
      align-self: center;
      background-color: ${common.companyColors[company]};
      border-radius: 10px;
      border: none;
      color: white;
      cursor: pointer;
      font-size: 20px;
      font-weight: bold;
      padding: 7px;
      width: 30%;
    `
  }, [company])

  return (
    <div className={chart === 'SOLAR' ? styles['container'] : styles['hide']}>
      <Pad amt={15} />
      {/*INPUTS */}
      {contentUser === 'SOLAR' ? (
        <FormControl>
          <InputLabel
            sx={{ alignSelf: 'flex-start' }}
            id="demo-simple-select-label"
          >
            Years
          </InputLabel>

          <Select
            className={styles['input']}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={25}
            label="Years"
            onChange={handleSolarLen}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
        </FormControl>
      ) : null}

      <Pad amt={20} />
      <div
        className={
          contentUser === 'SOLAR'
            ? styles['chart-inputs-group-between']
            : styles['chart-inputs-group-around']
        }
      >
        <div className={styles['wrapper-chart-input']}>
          {/*ELECTRICITY BILL INPUT */}
          <div>
            <TextField
              id="electricityBill"
              inputRef={electricityBillInput}
              label="Electricity Bill"
              sx={{ width: 228.84 }}
              type="number"
            />
          </div>

          <Pad amt={20} />
          {/*PERCENT INCREASE */}

          <div>
            <TextField
              className={styles['input']}
              id="percentIncrease"
              inputRef={percentIncreaseInput}
              label="Percent Increase"
              onChange={handlePercentIncrease}
              type="number"
            />
          </div>
        </div>

        {contentUser === 'ALARM' ? (
          <div className={styles['wrapper-chart-input']}>
            {/*POWER TO OWN */}
            <div>
              <TextField
                className={styles['input']}
                id="alarm"
                inputRef={alarmRef}
                label="Alarm"
                onChange={handlePercentIncrease}
                type="number"
              />
            </div>
            <Pad amt={20} />

            <div>
              <TextField
                className={styles['input']}
                defaultValue={altFeeAlarm}
                id="altFeeAlarm"
                label="Percent increase"
                onChange={handleAltFeeAlarm}
                type="number"
              />
            </div>
          </div>
        ) : null}
        <div className={styles['wrapper-chart-input']}>
          {/*POWER TO OWN / SOLAR BILL  */}
          <div>
            {contentUser === 'SOLAR' ? (
              <TextField
                className={styles['input']}
                inputRef={ppaInput}
                label="PPA 1"
                type="number"
              />
            ) : (
              <TextField
                className={styles['input']}
                id="solarPayment"
                inputRef={solarPaymentInput}
                label="Solar Payment"
                onChange={handleSolarMonthly}
                type="number"
              />
            )}
          </div>
          <Pad amt={20} />

          {/*SOLAR PAYMENT  YEARS */}
          {contentUser === 'SOLAR' ? (
            <div>
              <TextField
                className={styles['input']}
                defaultValue={ppaIncrease}
                label="PPA %"
                onChange={handlePpaIncrease}
                type="number"
              />
            </div>
          ) : (
            <FormControl>
              <InputLabel
                sx={{ alignSelf: 'flex-start' }}
                id="demo-simple-select-label"
              >
                Years
              </InputLabel>

              <Select
                className={styles['input']}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={25}
                label="Years"
                onChange={handleSolarLen}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </FormControl>
          )}
        </div>

        {contentUser === 'SOLAR' ? (
          <div className={styles['wrapper-chart-input']}>
            {/*PPA INPUT */}
            <div>
              <TextField
                className={styles['input']}
                inputRef={ppaTwoInput}
                label="PPA 2"
                type="number"
              />
            </div>

            <Pad amt={20} />
            {/* PPA PERCENT INCREASE */}

            <div>
              <TextField
                className={styles['input']}
                defaultValue={ppaTwoIncrease}
                id="ppaIncrease"
                label="PPA %"
                onChange={handlePpaTwoIncrease}
                type="number"
              />
            </div>
          </div>
        ) : null}
      </div>

      {/*SOLAR BUTTONS */}
      <div
        className={
          contentUser === 'SOLAR'
            ? styles['solar-buttons-between']
            : styles['solar-buttons-around']
        }
      >
        <StyledButton onClick={handleAlertElectricityChart}>
          Show Energy Bill
        </StyledButton>

        {contentUser === 'ALARM' ? (
          <StyledButton onClick={handleAlarmChart}>Show Alarm</StyledButton>
        ) : null}
        <StyledButton onClick={handleSolarPpaChart}>
          {contentUser === 'SOLAR' ? 'Show PPA 1' : ' Show Solar'}
        </StyledButton>
        {contentUser === 'SOLAR' ? (
          <StyledButton onClick={handleSolarPpa2Chart}>Show PPA 2</StyledButton>
        ) : null}
      </div>

      <Box className={styles['solar-chart-box']}>
        <SolarChart
          contentUser={contentUser}
          monthlyAlertPayment={monthlyAlertPayments}
          ppaPayments={ppaPayments}
          ppaTwoPayments={ppaTwoPayments}
          solarLen={solarLen}
          solarPayments={solarPayments}
          yearlyPayments={yearlyPayments}
        />
      </Box>

      <div className={styles['cost-info']}>
        <div className={styles['cost-vs-savings']}>
          <div className={styles['electricity-cost']}>
            {contentUser === 'ALARM' ? (
              <pre
                className={styles['title-cost']}
              >{`Alarm + electricity cost total i \n(30 years)`}</pre>
            ) : (
              <pre
                className={styles['title-cost']}
              >{`Electricity cost total in \n30 years`}</pre>
            )}

            {contentUser !== 'ALARM' ? (
              <h1 className={styles['total-energy']}>
                $ {isNaN(totalEnergy) ? 0 : totalEnergy.toLocaleString('en')}
              </h1>
            ) : null}

            {contentUser === 'ALARM' ? (
              <h1 className={styles['total-energy']}>
                ${' '}
                {isNaN(
                  monthlyAlertPayments.reduce(
                    (acc, item) => (acc = acc + item * 12),
                  ),
                )
                  ? isNaN(totalEnergy)
                    ? 0
                    : totalEnergy.toLocaleString('en')
                  : (
                      monthlyAlertPayments.reduce(
                        (acc, item) => (acc = acc + item * 12),
                      ) +
                      yearlyPayments.reduce((acc, item) => (acc = acc + item))
                    ).toLocaleString('en')}
              </h1>
            ) : null}

            {contentUser === 'NORMAL' ? (
              <div className={styles['total-saving-solar']}>
                {/* TOTAL SOLAR */}
                <span className={styles['title-cost-solar']}>
                  Total saving (30 years)
                </span>
                <h1>
                  {solarPayments.reduce((acc, item) => {
                    return (acc = acc + item)
                  }) > 0 &&
                    `$ ${
                      isNaN(totalEnergy - totalSolar)
                        ? 0
                        : Math.round(totalEnergy - totalSolar).toLocaleString(
                            'en',
                          )
                    }`}
                </h1>
              </div>
            ) : null}
          </div>
        </div>

        {contentUser === 'NORMAL' ? (
          <div className={styles['savings-per-month']}>
            <span className={styles['title-cost']}>Monthly solar savings</span>
            <ul className={styles['savings']}>
              {yearlyPayments.reduce((acc, item) => {
                return acc + item
              }) > 0 &&
                savingsSolar.map((saving, index) => (
                  <li
                    className={
                      saving < 0
                        ? styles['saving-item-red']
                        : styles['saving-item-green'] +
                          ' ' +
                          (index > 5
                            ? styles['saving-item-hidden']
                            : styles['saving-item-flex'])
                    }
                    // TODO
                    key={index.toString()}
                  >{`${index + 1}) ${FormatPrice(saving, '$')}`}</li>
                ))}
            </ul>
          </div>
        ) : null}

        {contentUser === 'SOLAR' ? (
          <div className={styles['savings-per-month']}>
            <span className={styles['title-cost']}>Monthly savings PPA 1</span>
            <ul className={styles['savings']}>
              {yearlyPayments.reduce((acc, item) => {
                return acc + item
              }) > 0 &&
                savingsPpa.map((saving, index) => (
                  <li
                    className={
                      saving < 0
                        ? styles['saving-item-red']
                        : styles['saving-item-green'] +
                          ' ' +
                          (index > 5
                            ? styles['saving-item-hidden']
                            : styles['saving-item-flex'])
                    }
                    // TODO
                    key={index.toString()}
                  >{`${index + 1}) ${FormatPrice(saving, '$')}`}</li>
                ))}
            </ul>
            {contentUser === 'SOLAR' ? (
              <div className={styles['total-saving']}>
                {/* PPA 1 TOTAL SAVING */}
                <pre className={styles['title-cost']}>
                  {`Total saving PPA 1\n(30 years)`}
                </pre>
                <h1>
                  {ppaPayments.reduce((acc, item) => {
                    return (acc = acc + item)
                  }) > 0 &&
                    `$ ${
                      isNaN(totalEnergy - totalSavingsPpa)
                        ? 0
                        : Math.round(
                            totalEnergy - totalSavingsPpa,
                          ).toLocaleString('en')
                    }`}
                </h1>
              </div>
            ) : null}
          </div>
        ) : null}

        {contentUser === 'SOLAR' ? (
          <div className={styles['savings-per-month']}>
            <span className={styles['title-cost']}>Monthly savings PPA 2</span>
            <ul className={styles['savings']}>
              {yearlyPayments.reduce((acc, item) => {
                return acc + item
              }) > 0 &&
                savingsPpaTwo.map((saving, index) => (
                  <li
                    className={
                      saving < 0
                        ? styles['saving-item-red']
                        : styles['saving-item-green'] +
                          ' ' +
                          (index > 5
                            ? styles['saving-item-hidden']
                            : styles['saving-item-flex'])
                    }
                    // TODO
                    key={index.toString()}
                  >{`${index + 1}) ${FormatPrice(saving, '$')}`}</li>
                ))}
            </ul>

            <div className={styles['total-saving']}>
              {/* PPA 2 TOTAL SAVING */}
              <pre className={styles['title-cost']}>
                {`Total saving PPA 2\n(30 years)`}
              </pre>
              <h1>
                {ppaTwoPayments.reduce((acc, item) => {
                  return (acc = acc + item)
                }) > 0 &&
                  `$ ${
                    isNaN(totalEnergy - totalSavingPpaTwo)
                      ? 0
                      : Math.round(
                          totalEnergy - totalSavingPpaTwo,
                        ).toLocaleString('en')
                  }`}
              </h1>
            </div>
          </div>
        ) : null}
      </div>
      <ToastAlert
        duration={6000}
        handleClose={handleCloseAlert}
        message="One or two inputs are empty"
        open={alert}
        severity="warning"
        type="alert"
      />
    </div>
  )
})
