import React from 'react'

import * as c from '../../../common'
import * as r from '../../../react-utils'

import * as Mui from '@mui/material'
import * as MuiIcons from '@mui/icons-material'
import DeviceDetector from 'device-detector-js'
import { Link } from 'react-router-dom'
import { InstantSearch, SearchBox, connectHits } from 'react-instantsearch-dom'

import * as fire from '../../fire'
import AddCustomerModal from '../AddCustomerModal'
import CustomerCard from '../CustomerCard'
import EditCustomerButton from '../EditCustomerButton'
import NavBar from '../NavBar'
import ToastAlert from '../ToastAlert'

import Actions from './Actions'
import styles from './CustomerList.module.css'

export interface CustomerListProps {}

const deviceDetector = new DeviceDetector()
const deviceInfo = deviceDetector.parse(navigator.userAgent)

export default React.memo<CustomerListProps>(function CustomerList() {
  const isMounted = r.useIsMounted()
  const [addCustomerOpen, setAddCustomerOpen] = React.useState(false)
  const [customerIDToBeDeleted, setCustomerIDToBeDeleted] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const container = React.useRef<HTMLDivElement>(document.createElement('div'))

  const dispatch = c.useDispatch()

  const clearCustomerIDToBeDeleted = React.useCallback(() => {
    setCustomerIDToBeDeleted('')
  }, [])

  const handleCustomerDeletion = React.useCallback((customerID: string) => {
    setCustomerIDToBeDeleted(customerID)
  }, [])

  const undoDeleteCustomer = React.useCallback(() => {
    if (!customerIDToBeDeleted) return
    c.updateCustomer(customerIDToBeDeleted, {
      deleted: false,
    })
    handleCustomerDeletion('')
  }, [customerIDToBeDeleted, handleCustomerDeletion])

  const handleOpen = React.useCallback(
    (e) => {
      setOpen(!!e.target.value)
    },
    [setOpen],
  )

  const handleClickAwayOpen = React.useCallback(() => {
    if (!isMounted()) {
      return
    }
    setOpen(false)
  }, [isMounted])

  const toggleAddCustomer = React.useCallback(() => {
    setAddCustomerOpen((_) => !_)
  }, [])

  const complexCustomers = c.useSelector(c.selectAllCustomers)

  const customers = React.useMemo(
    (): c.SimpleCustomer[] => complexCustomers.map(c.simplifyCustomer),
    [complexCustomers],
  )

  const authCheckTimeoutRef = React.useRef<NodeJS.Timeout>(
    setTimeout(c.EMPTY_FN, 0),
  )
  React.useEffect(() => {
    if (customers.length > 0) {
      return c.EMPTY_FN
    }
    authCheckTimeoutRef.current = setTimeout(() => {
      if (customers.length === 0 && Boolean(fire.auth.currentUser)) {
        // If no customers have loaded by 10 seconds, probably needs to re-auth
        fire.auth.signOut()
      }
    }, 10000)

    return () => {
      clearTimeout(authCheckTimeoutRef.current)
    }
  }, [customers])

  const onEndReached = React.useCallback((): void => {
    if (customers.length !== c.MAX_CUSTOMERS_AT_ONCE) {
      dispatch(c.requestedMoreCustomers())
    }
  }, [customers.length, dispatch])

  React.useEffect((): c.VoidFn => {
    dispatch(c.subToCustomers())

    return (): void => {
      dispatch(c.unSubFromCustomers())
    }
  }, [dispatch])

  React.useEffect(() => {
    const addMoreCustomers = () => {
      const percent90height = document.body.offsetHeight * 0.95

      if (window.scrollY + window.innerHeight >= percent90height) {
        onEndReached()
      }
    }
    document.addEventListener('scroll', addMoreCustomers)

    return () => {
      document.removeEventListener('scroll', addMoreCustomers)
    }
  }, [onEndReached])

  const handleDragStart = React.useCallback((e) => {
    e.preventDefault()
  }, [])

  const searchInputRef = React.useRef<HTMLInputElement>(
    document.createElement('input'),
  )

  const handleClickActiveInput = React.useCallback(() => {
    searchInputRef.current.focus()
  }, [])

  return (
    <InstantSearch
      indexName="prod_ecohome"
      refresh={true}
      searchClient={c.searchClient}
    >
      <Mui.Box sx={sx['boxNavbar']}>
        <Mui.Stack sx={sx['stackNavbar']}>
          <NavBar>
            <Search onDragStart={handleDragStart}>
              <MuiIcons.Search
                onClick={handleClickActiveInput}
                sx={sx['searchIcon']}
                width={20}
              />

              <SearchBox
                className={styles['search-box']}
                inputRef={searchInputRef}
                onChange={handleOpen}
              />
            </Search>
          </NavBar>
        </Mui.Stack>
        <Mui.Box sx={sx['boxPadSearch']} />
        <Mui.Box sx={sx['boxSearch']}>
          <Mui.ClickAwayListener onClickAway={handleClickAwayOpen}>
            <Mui.Box sx={open ? sx['boxSearchResults'] : sx['hidden']}>
              <SearchResults />
            </Mui.Box>
          </Mui.ClickAwayListener>

          <EditCustomerButton
            bottom={15}
            handleAddCustomerModal={toggleAddCustomer}
            right={15}
          />

          <Mui.Box ref={container} sx={sx['boxTable']}>
            <Mui.TableContainer component={Mui.Paper} sx={sx['tableContainer']}>
              <Mui.Table
                aria-label="sticky table"
                stickyHeader
                sx={sx['table']}
              >
                <Mui.TableHead>
                  <Mui.TableRow>
                    <Mui.TableCell>Date</Mui.TableCell>
                    <Mui.TableCell align="center">Name</Mui.TableCell>
                    <Mui.TableCell align="center">Address</Mui.TableCell>
                    <Mui.TableCell align="center">Rep Name</Mui.TableCell>
                    <Mui.TableCell align="center">Solar Company</Mui.TableCell>
                    <Mui.TableCell align="center">Actions</Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableHead>
                <Mui.TableBody>
                  {customers.map((customer) => (
                    <React.Fragment key={customer.objectID}>
                      <Mui.TableRow
                        sx={!customer.deleted ? sx['tableRow'] : sx['hidden']}
                      >
                        <Mui.TableCell component="th" scope="row">
                          {new Date(
                            customer.sort_key * -1,
                          ).toLocaleDateString()}
                        </Mui.TableCell>
                        <Mui.TableCell align="center">
                          {' '}
                          {customer.customerName}
                        </Mui.TableCell>
                        <Mui.TableCell align="center">
                          {' '}
                          {customer.customerAddress}
                        </Mui.TableCell>
                        <Mui.TableCell align="center">
                          {' '}
                          {customer.solarRep}
                        </Mui.TableCell>
                        <Mui.TableCell align="center">
                          {' '}
                          {c.companyToLabel[customer.solarCompany]}
                        </Mui.TableCell>
                        <Actions
                          customerID={customer.objectID}
                          onCustomerDeletion={handleCustomerDeletion}
                        />
                      </Mui.TableRow>
                    </React.Fragment>
                  ))}
                </Mui.TableBody>
              </Mui.Table>
            </Mui.TableContainer>
            {customers.map((customer) => (
              <CustomerCard
                customer={customer}
                key={customer.key}
                onCustomerDeletion={handleCustomerDeletion}
              />
            ))}
          </Mui.Box>

          {/* ADD REPORT MODAL */}
          <Mui.Modal open={addCustomerOpen} onClose={toggleAddCustomer}>
            <AddCustomerModal onClose={toggleAddCustomer} />
          </Mui.Modal>
        </Mui.Box>

        <ToastAlert
          duration={4000}
          handleClose={clearCustomerIDToBeDeleted}
          message="Customer Deleted"
          onClick={undoDeleteCustomer}
          open={customerIDToBeDeleted ? true : false}
          severity="info"
          stopTime={customerIDToBeDeleted ? false : true}
          type="undoButton"
        />
      </Mui.Box>
    </InstantSearch>
  )
})

const SearchResults = connectHits(({ hits }) => {
  // Algolia re-renders unnecessarily thus necessitating this useMemo() here
  const sanitized = React.useMemo(() => {
    const validated = hits.map(c.validateCustomer).filter((c) => c.ok)

    return (
      validated
        .map((r) => r.sanitized)
        // A typical ID is 20 characters long
        .filter((c) => c.key.length >= 18)
        .filter((c) => !c.deleted)
    )
  }, [hits])

  React.useEffect(() => {
    c.dispatch(
      c.receivedSimpleCustomers({
        customers: sanitized.map(c.simplifyCustomer),
      }),
    )
  }, [sanitized])

  return (
    <Mui.Box
      component={Mui.Paper}
      elevation={9}
      sx={sanitized.length >= 9 ? sx['boxResults'] : sx['boxResultsAutoHeight']}
    >
      {sanitized.map((customer) => (
        <Link
          key={customer.key}
          style={!customer.deleted ? linkStyle : hiddenStyle}
          to={`/customers/${customer.objectID}`}
        >
          <Mui.Box
            component={Mui.Paper}
            elevation={6}
            sx={sx['boxResultsItem']}
          >
            <Mui.Box sx={sx['boxResultsItemText']}>
              <Mui.Typography sx={sx['typographyResultsItemText']}>
                {customer.customerName}
              </Mui.Typography>
            </Mui.Box>

            <Mui.Box sx={sx['textRow']}>
              <Mui.Typography sx={sx['typographyResultsItemText']}>
                {customer.customerAddress}
              </Mui.Typography>
            </Mui.Box>
            <Mui.Box sx={sx['boxSolarRepText']}>
              {' '}
              <Mui.Typography sx={sx['typographyResultsItemText']}>
                {customer.solarRep}
              </Mui.Typography>
            </Mui.Box>
          </Mui.Box>
        </Link>
      ))}
    </Mui.Box>
  )
})

const sx = {
  boxIcon: {
    backgroundColor: '#3b8ad9',
    borderRadius: '5px 0 0px 5px',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '30px',
  },
  boxIconDark: {
    backgroundColor: '#474747',
    borderRadius: '5px 0 0px 5px',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '30px',
  },
  boxInputSearch: {
    alignSelf: 'center',
    display: 'flex',
    height: '100%',
    width: '80%',
  },
  boxNavbar: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: {
      lg: deviceInfo.device?.model === 'iPad' ? 'auto' : '100vh',
      xs: 'auto',
    },
    width: '100vw',
  },

  boxSearch: {
    height: {
      lg: deviceInfo.device?.model === 'iPad' ? 'auto' : 'calc( 100% - 70px )',
      xs: 'auto',
    },
    margin: {
      lg: '0 auto',
    },
    overflowY: 'auto',
    position: 'relative',
    width: {
      lg: '90vw',
      xs: '100%',
    },
  },

  boxSearchResults: {
    display: 'flex',
  },

  boxResultsAutoHeight: {
    backgroundColor: 'white',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    left: '50%',
    overflowY: 'auto',
    padding: '5px',
    position: 'fixed',
    top: '96px',
    transform: 'translate(-50%, 0%)',
    zIndex: 7,
  },

  boxResults: {
    backgroundColor: 'white',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    height: '480px',
    left: '50%',
    overflowY: 'auto',
    padding: '5px',
    position: 'fixed',
    top: '96px',
    transform: 'translate(-50%, 0%)',
    zIndex: 7,
  },

  boxResultsItem: {
    alignItems: 'center',
    display: 'flex',
    height: '50px',
    justifyContent: 'space-around',
    marginBottom: '5px',
    width: '100%',
  },

  boxResultsItemText: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  boxPadSearch: {
    display: {
      lg: deviceInfo.device?.model === 'iPad' ? 'flex' : 'none',
      xs: 'flex',
    },
    height: '70px',
    width: '100%',
  },

  boxSolarRepText: {
    alignItems: 'center',
    display: { xs: 'none', md: 'flex' },
    height: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  boxTable: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  hidden: {
    display: 'none',
  },

  searchIcon: {
    ':hover': {
      cursor: 'text',
    },
  },

  stackNavbar: {
    height: '70px',
    position: {
      lg: deviceInfo.device?.model === 'iPad' ? 'fixed' : 'relative',
      xs: 'fixed',
    },
    width: '100%',
    zIndex: {
      xs: 7,
      lg: deviceInfo.device?.model === 'iPad' ? 7 : 'none',
    },
  },

  table: {
    minWidth: 600,
  },

  textRow: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },

  typographyResultsItemText: {
    overflow: 'hidden',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: {
      sm: '180px',
      xs: '150px',
    },
  },

  tableContainer: {
    display: {
      md: 'block',
      xs: 'none',
    },
  },

  tableRow: {
    display: 'table-row',
  },
}

const linkStyle = {
  textDecoration: 'none',
}

const hiddenStyle = {
  display: 'none',
}

const Search = Mui.styled('div')(({ theme }) => ({
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Mui.alpha(theme.palette.common.white, 0.15),
  display: 'flex',
  flexDirection: 'row',
  marginRight: theme.spacing(2),
  marginLeft: 0,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}))
