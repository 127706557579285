import React from 'react'

import * as common from '../../../common'

import * as Mui from '@mui/material'

import Pad from '../Pad'

export interface SelectorProps {
  selectors: common.Selector[]
}

export default React.memo<SelectorProps>(function Selectors({ selectors }) {
  return (
    <Mui.FormGroup sx={sx['formGroup']}>
      {selectors.map((selector, i) => (
        <React.Fragment key={selector.label}>
          <FormControlSelector selector={selector} />
          {i + 1 !== selectors.length ? <Pad amt={10} /> : <></>}
        </React.Fragment>
      ))}
    </Mui.FormGroup>
  )
})

interface FormControlSelectorProps {
  selector: common.Selector
}
const FormControlSelector = React.memo<FormControlSelectorProps>(
  ({ selector }) => {
    return (
      <Mui.FormControl sx={sx['formControl']}>
        <Mui.InputLabel id="demo-simple-select-label">
          {selector.label}
        </Mui.InputLabel>
        <Mui.Select
          id="demo-simple-select"
          label={selector.label}
          labelId="demo-simple-select-label"
          onChange={selector.onChange}
          value={selector.value}
          sx={{ width: '100%' }}
        >
          {selector.opts.map((opt) => (
            <Mui.MenuItem
              key={`${opt.value}-${opt.label}-${selector.label}`}
              value={opt.value}
            >
              {opt.label}
            </Mui.MenuItem>
          ))}
        </Mui.Select>
      </Mui.FormControl>
    )
  },
)

const sx = {
  formControl: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  formGroup: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    width: {
      lg: '512px',
      sm: '420px',
      xs: '80%',
    },
  },
}
