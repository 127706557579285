/* eslint-disable require-yield */
import {
  PayloadAction,
  createAction,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { Task as SagaTask, eventChannel } from 'redux-saga'
import {
  all,
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  spawn,
  take,
  takeEvery,
} from 'redux-saga/effects'

import * as api from '../api'
import * as utils from '../utils'
import {
  FireEvent,
  FireSnapshot,
  Storage,
  getDatabase,
  getStorage,
} from '../api'
import {
  extractMediaKind,
  MediaCaptureWithBackup,
  MediaCapture,
  MediaItem,
  MediaKind,
  processMediaItem,
  SectionMediaCapture,
} from '../schema'
import {
  EMPTY_ARRAY,
  EMPTY_OBJ,
  DeepReadonly,
  createEventBatcher,
  keys,
  log,
  processErr,
  values,
} from '../utils'

import { IS_WEB } from '../env'

import { YieldReturn } from './common'
import { selectPlainThumbnail } from './thumbnails'

const PREFIX = 'media'

export interface MediaItemToBeUploaded {
  customerID: string
  description?: string
  height?: number
  name: string
  ofWhat: MediaKind
  path: string
  thumbnail: string
  type: 'image' | 'video'
  width?: number
}

export type Media = Record<string, MediaItem>

export type MediaKindToMedia = Record<MediaKind, Media>

type CustomerToKinds = Record<string, MediaKindToMedia>

interface WritableMediaState {
  customerToKinds: CustomerToKinds
}

type MediaState = DeepReadonly<WritableMediaState>

const initCustomerMediaIfNeeded = (
  customerToKinds: CustomerToKinds,
  customerID: string,
) => {
  customerToKinds[customerID] =
    customerToKinds[customerID] || emptyMediaKindToMedia()

  if (
    customerToKinds[customerID] &&
    (!customerToKinds[customerID]?.solar_proposal ||
      !customerToKinds[customerID]?.all_exterior_walls_site_survey ||
      !customerToKinds[customerID]?.rafters_site_survey ||
      !customerToKinds[customerID]?.electrical_panel_site_survey ||
      !customerToKinds[customerID]?.all_planes_roof_site_survey ||
      !customerToKinds[customerID]?.other_site_survey ||
      !customerToKinds[customerID]?.deal_certificate_mh ||
      !customerToKinds[customerID]?.foundation_pic_mh ||
      !customerToKinds[customerID]?.hud_plate_mh ||
      !customerToKinds[customerID]?.install_certificate_mh ||
      !customerToKinds[customerID]?.property_title_mh ||
      !customerToKinds[customerID]?.attic_site_survey ||
      !customerToKinds[customerID]?.attic_site_survey ||
      !customerToKinds[customerID]?.electrical_site_survey ||
      !customerToKinds[customerID]?.house360_site_survey ||
      !customerToKinds[customerID]?.roof_site_survey ||
      !customerToKinds[customerID]?.hvac_system ||
      !customerToKinds[customerID]?.windows ||
      !customerToKinds[customerID]?.rc_front_house ||
      !customerToKinds[customerID]?.rc_address_verification ||
      !customerToKinds[customerID]?.rc_3d_view ||
      !customerToKinds[customerID]?.rc_wires_home ||
      !customerToKinds[customerID]?.rc_siding ||
      !customerToKinds[customerID]?.rc_other_property_wind_damage ||
      !customerToKinds[customerID]?.rc_wind_damages ||
      !customerToKinds[customerID]?.rc_layers ||
      !customerToKinds[customerID]?.rc_roof_inclination ||
      !customerToKinds[customerID]?.rc_roof_overview ||
      !customerToKinds[customerID]?.rc_gutters ||
      !customerToKinds[customerID]?.rc_flashings ||
      !customerToKinds[customerID]?.rc_penetrations ||
      !customerToKinds[customerID]?.rc_hail ||
      !customerToKinds[customerID]?.rc_length_shingle ||
      !customerToKinds[customerID]?.rc_drip_edge ||
      !customerToKinds[customerID]?.rc_shingles_color ||
      !customerToKinds[customerID]?.rc_garage ||
      !customerToKinds[customerID]?.rc_ice_shield)
  ) {
    customerToKinds[customerID] = {
      attic_site_survey: customerToKinds[customerID]?.attic_site_survey || {},
      hvac_system: customerToKinds[customerID]?.hvac_system || {},
      electrical_site_survey:
        customerToKinds[customerID]?.electrical_site_survey || {},
      house360_site_survey:
        customerToKinds[customerID]?.house360_site_survey || {},
      roof_site_survey: customerToKinds[customerID]?.roof_site_survey || {},
      adders: customerToKinds[customerID]?.adders || {},
      electrical: customerToKinds[customerID]?.electrical || {},
      electricity: customerToKinds[customerID]?.electricity || {},
      id: customerToKinds[customerID]?.id || {},
      insulation: customerToKinds[customerID]?.insulation || {},
      owner_house: customerToKinds[customerID]?.owner_house || {},
      problem: customerToKinds[customerID]?.problem || {},
      roof: customerToKinds[customerID]?.roof || {},
      temperature: customerToKinds[customerID]?.temperature || {},
      thermal: customerToKinds[customerID]?.thermal || {},
      other_documents: customerToKinds[customerID]?.other_documents || {},
      solar_proposal: customerToKinds[customerID]?.solar_proposal || {},
      all_exterior_walls_site_survey:
        customerToKinds[customerID]?.all_exterior_walls_site_survey || {},
      rafters_site_survey:
        customerToKinds[customerID]?.rafters_site_survey || {},
      electrical_panel_site_survey:
        customerToKinds[customerID]?.electrical_panel_site_survey || {},
      all_planes_roof_site_survey:
        customerToKinds[customerID]?.all_planes_roof_site_survey || {},
      other_site_survey: customerToKinds[customerID]?.other_site_survey || {},
      deal_certificate_mh:
        customerToKinds[customerID]?.deal_certificate_mh || {},
      foundation_pic_mh: customerToKinds[customerID]?.foundation_pic_mh || {},
      hud_plate_mh: customerToKinds[customerID]?.hud_plate_mh || {},
      install_certificate_mh:
        customerToKinds[customerID]?.install_certificate_mh || {},
      property_title_mh: customerToKinds[customerID]?.property_title_mh || {},
      windows: customerToKinds[customerID]?.windows || {},
      rc_front_house: customerToKinds[customerID]?.rc_front_house || {},
      rc_address_verification:
        customerToKinds[customerID]?.rc_address_verification || {},
      rc_3d_view: customerToKinds[customerID]?.rc_3d_view || {},
      rc_wires_home: customerToKinds[customerID]?.rc_wires_home || {},
      rc_siding: customerToKinds[customerID]?.rc_siding || {},
      rc_other_property_wind_damage:
        customerToKinds[customerID]?.rc_other_property_wind_damage || {},
      rc_wind_damages: customerToKinds[customerID]?.rc_wind_damages || {},
      rc_layers: customerToKinds[customerID]?.rc_layers || {},
      rc_roof_inclination:
        customerToKinds[customerID]?.rc_roof_inclination || {},
      rc_roof_overview: customerToKinds[customerID]?.rc_roof_overview || {},
      rc_gutters: customerToKinds[customerID]?.rc_gutters || {},
      rc_flashings: customerToKinds[customerID]?.rc_flashings || {},
      rc_penetrations: customerToKinds[customerID]?.rc_penetrations || {},
      rc_hail: customerToKinds[customerID]?.rc_hail || {},
      rc_length_shingle: customerToKinds[customerID]?.rc_length_shingle || {},
      rc_drip_edge: customerToKinds[customerID]?.rc_drip_edge || {},
      rc_shingles_color: customerToKinds[customerID]?.rc_shingles_color || {},
      rc_garage: customerToKinds[customerID]?.rc_garage || {},
      rc_ice_shield: customerToKinds[customerID]?.rc_ice_shield || {},
    }
  }
}

const mediaSlice = createSlice({
  name: PREFIX,
  initialState: {
    customerToKinds: {},
  } as MediaState,
  reducers: {
    beganUpload(
      { customerToKinds }: WritableMediaState,
      {
        payload: { items },
      }: PayloadAction<{
        items: MediaItem[]
      }>,
    ) {
      for (const mediaItem of items) {
        const { order_id: customerID, name: mediaItemID } = mediaItem
        const mediaKind = extractMediaKind(mediaItem)

        initCustomerMediaIfNeeded(customerToKinds, customerID)

        const item = customerToKinds[customerID]?.[mediaKind]?.[mediaItemID]

        if (!item) {
          throw new ReferenceError(
            `Could not find item for coordinates: ${customerID}-${mediaKind}-${mediaItemID}`,
          )
        }
        item.status = 'uploading'
      }
    },
    draftForMediaUpload(
      { customerToKinds },
      {
        payload: { item },
      }: PayloadAction<{
        item: MediaItemToBeUploaded
      }>,
    ) {
      const { customerID, name, ofWhat, path, type } = item

      if (!name) {
        throw new TypeError(`File name not provided for item.`)
      }

      initCustomerMediaIfNeeded(customerToKinds, customerID)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      customerToKinds[customerID]![ofWhat][name] = {
        description: item.description || '',
        height:
          item.height || customerToKinds[customerID]![ofWhat][name]!.height,
        err: '',
        image_of:
          type === 'image'
            ? ofWhat === 'owner_house'
              ? ofWhat
              : ofWhat + '_pictures'
            : '',
        name,
        order_id: customerID,
        path,
        status: 'draft',
        thumbnail: item.thumbnail,
        updated_at: Date.now(),
        url: '',
        video_of: type === 'video' ? ofWhat + '_videos' : '',
        width: item.width || customerToKinds[customerID]![ofWhat][name]!.width,
      }
    },
    removedDraftMediaItem(
      { customerToKinds },
      {
        payload,
      }: PayloadAction<{ customerID: string; ofWhat: MediaKind; name: string }>,
    ) {
      delete customerToKinds[payload.customerID]![payload.ofWhat][payload.name]
    },
    removeAllDraftByCustomer(
      { customerToKinds },
      { payload }: PayloadAction<{ customerID: string }>,
    ) {
      // TODO: Check non-null assertions
      for (const ofWhat of utils.keys(customerToKinds[payload.customerID]!)) {
        const _ofWhat = ofWhat as MediaKind
        const mediaListToDelete = utils
          .keys(customerToKinds[payload.customerID]![_ofWhat])
          .filter(
            (name) =>
              customerToKinds[payload.customerID]![_ofWhat]![name]!.status ===
              'draft',
          )
        for (const name of mediaListToDelete) {
          delete customerToKinds[payload.customerID]![_ofWhat][name]
        }
      }
    },
    receivedMediaFBEvents(
      { customerToKinds },
      {
        payload: { events },
      }: PayloadAction<{ events: FireEvent<MediaItem>[] }>,
    ) {
      // Cast: At least one event should be in there
      const {
        item: { order_id: customerID },
      } = events[0]!

      initCustomerMediaIfNeeded(customerToKinds, customerID)

      for (const { key, item: mediaItem, type } of events) {
        if (type === 'child_added' || type === 'child_changed') {
          const mediaKind = extractMediaKind(mediaItem)

          const customerMediaOfThisKind =
            customerToKinds[customerID]![mediaKind]

          customerMediaOfThisKind[key] = mediaItem
        }
        if (type === 'child_removed') {
          const kindToMedia = customerToKinds[customerID]!

          for (const media of values(kindToMedia)) {
            delete media[key]
          }
        }
      }
    },
    requestedMediaDeletion(
      { customerToKinds },
      {
        payload: { customerID, mediaItemID, mediaKind },
      }: PayloadAction<{
        customerID: string
        mediaItemID: string
        mediaKind: MediaKind
      }>,
    ) {
      initCustomerMediaIfNeeded(customerToKinds, customerID)

      delete customerToKinds[customerID]?.[mediaKind]?.[mediaItemID]
    },
    requestedMediaUpload(
      { customerToKinds },
      {
        payload: { items },
      }: PayloadAction<{
        items: MediaItemToBeUploaded[]
      }>,
    ) {
      for (const item of items) {
        const { customerID, name, ofWhat, path, type } = item

        if (!name) {
          throw new TypeError(`File name not provided for item.`)
        }

        initCustomerMediaIfNeeded(customerToKinds, customerID)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const currItem = customerToKinds[customerID]![ofWhat][name]

        if (currItem) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          currItem.status = 'pending'
        } else {
          customerToKinds[customerID]![ofWhat][name] = {
            description: item.description || '',
            err: '',
            image_of:
              type === 'image'
                ? ofWhat === 'owner_house'
                  ? ofWhat
                  : ofWhat + '_pictures'
                : '',
            name,
            order_id: customerID,
            path: IS_WEB ? '' : path,
            status: 'pending',
            thumbnail: '',
            updated_at: Date.now(),
            url: '',
            video_of: type === 'video' ? ofWhat + '_videos' : '',
          }
        }
      }
    },
  },
  extraReducers(builder) {
    return builder.addCase('persist/REHYDRATE', ({ customerToKinds }) => {
      for (const mediaByKind of values(customerToKinds)) {
        for (const customerMedia of values(mediaByKind)) {
          for (const mediaItem of values(customerMedia)) {
            if (mediaItem.status === 'uploading') {
              mediaItem.status = 'pending'
            }
          }
        }
      }
    })
  },
})

export const {
  actions: {
    beganUpload,
    draftForMediaUpload,
    receivedMediaFBEvents,
    requestedMediaUpload,
    removedDraftMediaItem,
    removeAllDraftByCustomer,
    requestedMediaDeletion,
  },
  reducer: media,
} = mediaSlice

export const subToCustomerMedia = createAction<{ customerID: string }>(
  `${PREFIX}/subToCustomerMedia`,
)

export const unSubFromCustomerMedia = createAction<{ customerID: string }>(
  `${PREFIX}/unSubFromCustomerMedia`,
)

//#region selectors

interface GlobalState {
  [PREFIX]: MediaState
}

export const selectAllMediaCustomers = ({
  media: { customerToKinds },
}: GlobalState): CustomerToKinds => {
  if (Object.keys(customerToKinds).length === 0) {
    // Reduce re-renders
    return EMPTY_OBJ
  }
  return customerToKinds
}

export interface SelectCustomerHasMediaParams {
  customerID: string
}

export type SelectCustomerHasMedia = (
  _: GlobalState,
  args: SelectCustomerHasMediaParams,
) => boolean

export const makeSelectCustomerHasMedia = (): SelectCustomerHasMedia =>
  createSelector(
    selectAllMediaCustomers,
    (_: GlobalState, args: SelectCustomerHasMediaParams) => args,
    (customerToKinds, { customerID }): boolean => {
      const mediaKinds = customerToKinds[customerID]
      if (!mediaKinds) {
        return false
      }
      for (const mediaOfThatKind of values(mediaKinds)) {
        if (keys(mediaOfThatKind).length > 0) {
          return true
        }
      }
      return false
    },
  )

export interface SelectMediaKeysParams {
  customerID: string
  mediaKind: MediaKind
}

export const makeSelectMediaKeys = () =>
  createSelector(
    selectAllMediaCustomers,
    (_: GlobalState, args: SelectMediaKeysParams) => args,
    (customerToKinds, { customerID, mediaKind }): string[] => {
      if (Object.keys(customerToKinds).length === 0) {
        // Reduce re-renders
        return EMPTY_ARRAY as string[]
      }
      const items = (customerToKinds[customerID]?.[mediaKind] ||
        EMPTY_OBJ) as Media

      if (Object.keys(items).length === 0) {
        // Reduce re-renders
        return EMPTY_ARRAY as string[]
      }
      return Object.keys(items)
        .sort()
        .filter((x) => items[x]?.status !== 'draft')
    },
  )

export const makeSelectDraftMediaKeys =
  (customerID: string) =>
  ({
    media: { customerToKinds },
  }: GlobalState): {
    sectionMediaCapture: SectionMediaCapture[]
    allMedia: MediaCapture[]
  } => {
    if (Object.keys(customerToKinds).length === 0) {
      // Reduce re-renders
      return {
        sectionMediaCapture: EMPTY_ARRAY as SectionMediaCapture[],
        allMedia: EMPTY_ARRAY as MediaCapture[],
      }
    }
    const mediaKinds = (customerToKinds[customerID] ||
      EMPTY_ARRAY) as MediaKindToMedia

    const items: SectionMediaCapture[] = []
    let allMedia: MediaCapture[] = []

    for (const mediaOfThatKind of keys(mediaKinds)) {
      const data: MediaCaptureWithBackup[] = []

      for (const mediaItem of values(mediaKinds[mediaOfThatKind])) {
        if (mediaItem.status !== 'draft') {
          continue
        }
        const mediaCapture = {
          height: mediaItem.height,
          path: mediaItem.thumbnail,
          width: mediaItem.width,
        } as any
        data.push({
          id: mediaItem.name,
          description: mediaItem.description || '',
          mediaCapture: {
            media: mediaCapture,
            type: 'photo',
          },
        })
        const metadata = {
          type: 'photo',
          media: {
            height: mediaItem.height || 0,
            path: mediaItem.thumbnail,
            width: mediaItem.width || 0,
          },
        } as any
        allMedia.push(metadata)
      }

      if (!data.length) {
        continue
      }
      items.push({
        title: mediaOfThatKind,
        data,
      })
    }
    return { sectionMediaCapture: items, allMedia }
  }

export interface SelectMediaParams {
  customerID: string
  mediaKind: MediaKind
}

export const makeSelectMedia = () =>
  createSelector(
    selectAllMediaCustomers,
    (_: GlobalState, args: SelectMediaParams) => args,
    (customerToKinds, { customerID, mediaKind }): Media => {
      if (Object.keys(customerToKinds).length === 0) {
        // Reduce re-renders
        return EMPTY_OBJ
      }
      const items = customerToKinds[customerID]?.[mediaKind] || EMPTY_OBJ

      if (Object.keys(items).length === 0) {
        // Reduce re-renders
        return EMPTY_OBJ
      }
      return items
    },
  )

export interface SelectMediaItemParams {
  customerID: string
  mediaItemID: string
  mediaKind: MediaKind
}

export const makeSelectMediaItem = () => {
  const selectCustomerMedia = makeSelectMedia()

  return createSelector(
    selectCustomerMedia,
    (_: GlobalState, args: SelectMediaItemParams) => args,
    (mediaOfThatKind, { customerID, mediaItemID, mediaKind }): MediaItem => {
      const res = mediaOfThatKind[mediaItemID]

      if (res) {
        return res
      }

      return {
        description: '',
        err: '',
        image_of: mediaKind + 'pictures',
        name: mediaItemID,
        order_id: customerID,
        path: '',
        status: 'pending',
        thumbnail: '',
        updated_at: 0,
        url: '',
        video_of: '',
      }
    },
  )
}

export interface SelectAllCustomerMediaParams {
  readonly customerID: string
}

export const makeSelectAllCustomerMedia = () =>
  createSelector(
    selectAllMediaCustomers,
    (_: GlobalState, args: SelectAllCustomerMediaParams) => args,
    (allMediaCustomers, { customerID }): MediaKindToMedia => {
      return allMediaCustomers[customerID] || emptyMediaKindToMedia()
    },
  )

//#endregion selectors

//#region mediaChannel

type MediaChannelPayload = FireEvent<MediaItem>[]

const mediaSubscription =
  (
    mediaType: 'picture' | 'video',
    eventType: 'child_added' | 'child_changed' | 'child_removed',
    listener: (e: FireEvent<MediaItem> | Error) => void,
  ) =>
  (data: FireSnapshot, prevKey?: string | null) => {
    try {
      const { key } = data

      if (!key) {
        throw new ReferenceError(`${mediaType} ${eventType} without key`)
      }

      const val = data.val()

      const item = processMediaItem(val)

      listener({
        type: eventType,
        item,
        key,
        prevKey,
      })
    } catch (e) {
      log(`Error inside ${mediaType} subscription (${eventType}):`)
      log(e)
      if (e instanceof Error) {
        listener(e)
      }
    }
  }

const createMediaChannel = (customerID: string) =>
  eventChannel<MediaChannelPayload | Error>((emit) => {
    const database = getDatabase()
    const picsRef = database.ref(`NewPics/${customerID}`)
    const vidsRef = database.ref(`Videos/${customerID}`)

    const eventBatcher = createEventBatcher<FireEvent<MediaItem>>({
      debounceTime: 1000,
    })

    eventBatcher.onEvents(emit)

    picsRef.on(
      'child_added',
      mediaSubscription('picture', 'child_added', (e) => {
        if (e instanceof Error) {
          eventBatcher.flush()
          emit(e)
        } else {
          eventBatcher.eventReceived(e)
        }
      }),
    )

    picsRef.on(
      'child_changed',
      mediaSubscription('picture', 'child_changed', (e) => {
        if (e instanceof Error) {
          eventBatcher.flush()
          emit(e)
        } else {
          eventBatcher.eventReceived(e)
        }
      }),
    )

    picsRef.on(
      'child_removed',
      mediaSubscription('picture', 'child_removed', (e) => {
        if (e instanceof Error) {
          eventBatcher.flush()
          emit(e)
        } else {
          eventBatcher.eventReceived(e)
        }
      }),
    )

    vidsRef.on(
      'child_added',
      mediaSubscription('video', 'child_added', (e) => {
        if (e instanceof Error) {
          eventBatcher.flush()
          emit(e)
        } else {
          eventBatcher.eventReceived(e)
        }
      }),
    )

    vidsRef.on(
      'child_changed',
      mediaSubscription('video', 'child_changed', (e) => {
        if (e instanceof Error) {
          eventBatcher.flush()
          emit(e)
        } else {
          eventBatcher.eventReceived(e)
        }
      }),
    )

    vidsRef.on(
      'child_removed',
      mediaSubscription('video', 'child_removed', (e) => {
        if (e instanceof Error) {
          eventBatcher.flush()
          emit(e)
        } else {
          eventBatcher.eventReceived(e)
        }
      }),
    )

    return () => {
      eventBatcher.flush()
      eventBatcher.off()
      picsRef.off()
      vidsRef.off()
    }
  })

function* subToMedia(customerID: string) {
  const mediaChannel: YieldReturn<typeof createMediaChannel> = yield call(
    createMediaChannel,
    customerID,
  )

  try {
    while (true) {
      const payload: MediaChannelPayload = yield take(mediaChannel)

      yield put(
        receivedMediaFBEvents({
          events: payload,
        }),
      )
    }
  } catch (e) {
    log(e)
  } finally {
    if ((yield cancelled()) as boolean) {
      mediaChannel.close()
    }
  }
}

function* mediaSubWatcher() {
  try {
    while (true) {
      const action: ReturnType<typeof subToCustomerMedia> = yield take(
        subToCustomerMedia,
      )

      const {
        payload: { customerID },
      } = action

      const mediaSubTask: SagaTask = yield fork(subToMedia, customerID)

      yield take(unSubFromCustomerMedia)

      yield cancel(mediaSubTask)
    }
  } catch (e) {
    log(`Error inside subscription (${e}):`)
  }
}

//#endregion mediaChannel

type UploadChannelPayload = { type: 'success' } | Error

const createUploadChannel = (
  blobOrPath: Blob | string,
  ref: ReturnType<Storage['ref']>,
) =>
  eventChannel<UploadChannelPayload | Error>((emit) => {
    const task = ref.putFile(blobOrPath)

    task.on('state_changed', (snapshot) => {
      if (snapshot.error) {
        emit(snapshot.error)
      } else {
        if (snapshot.state === 'success') {
          emit({ type: 'success' })
        }
      }
    })

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {}
  })

const base64ToBlob = async (base64: string): Promise<Blob> => {
  const blob = await (await fetch(base64)).blob()

  return blob
}

function* uploadMediaItem(item: MediaItem) {
  if (!item.path) {
    throw new ReferenceError(`MediaItem ${item.name} without path`)
  }

  const blobOrPath: YieldReturn<typeof base64ToBlob> | string = IS_WEB
    ? yield call(base64ToBlob, item.path)
    : item.path

  const fileDBRef = getStorage().ref(item.order_id + '/' + item.name)
  const uploadChannel = createUploadChannel(blobOrPath, fileDBRef)

  while (true) {
    const payload: UploadChannelPayload = yield take(uploadChannel)

    if (payload instanceof Error) {
      const database = getDatabase()
      const isPic = !!item.image_of
      const newItem = {
        ...item,
        err: processErr(payload.message),
      }

      const itemRef = database.ref(
        isPic
          ? `NewPics/${item.order_id}/${item.name}`
          : `Videos/${item.order_id}/${item.name}`,
      )
      log(`Error inside uploadMediaItem: ${processErr(payload.message)}`)
      itemRef.update(newItem)
    } else if (payload.type === 'success') {
      const isPic = !!item.image_of

      const database = getDatabase()

      const itemRef = database.ref(
        isPic
          ? `NewPics/${item.order_id}/${item.name}`
          : `Videos/${item.order_id}/${item.name}`,
      )

      const newItem: MediaItem = {
        ...item,
        path: '',
        status: 'uploaded',
      }

      // let the round trip handle the state update
      itemRef.update(newItem as unknown as Record<string, unknown>)

      if (item.image_of === 'owner_house') {
        api._updateCustomer(item.order_id, {
          owner_house: item.thumbnail,
        })
      }
    } else {
      log(`Error inside uploadMediaItem: Unexpected payload type`)
      throw new Error(`Unexpected payload type`)
    }
  }
  //  listen to then put to thing?

  // const task: YieldReturn<typeof ref.putFile> =
  // yield call(ref.putFile, item.path)

  // let us see if mediaItemReceived

  // task.on('state_changed', (snapshot) => {
  //   snapshot.state === 'success'
  // })
}

const itemsBeingProcessed = new Set<string>()

function* mediaUploadWatcher({
  payload: { items },
}: ReturnType<typeof requestedMediaUpload>) {
  const currentBatchToBeProcessed = [] as MediaItem[]
  try {
    for (const item of items) {
      if (itemsBeingProcessed.has(item.name)) {
        continue
      }
      itemsBeingProcessed.add(item.name)

      const thumbnail = selectPlainThumbnail(item.name)(yield select())
      currentBatchToBeProcessed.push({
        description: item.description || '',
        err: '',
        image_of:
          item.type === 'image'
            ? item.ofWhat === 'owner_house'
              ? item.ofWhat
              : item.ofWhat + '_pictures'
            : '',
        name: item.name,
        order_id: item.customerID,
        path: item.path,
        status: 'pending',
        thumbnail,
        updated_at: Date.now(),
        url: '',
        video_of: item.type === 'video' ? item.ofWhat + '_videos' : '',
      })
    }

    yield put(
      beganUpload({
        items: currentBatchToBeProcessed.map((it) => ({
          ...it,
          path: IS_WEB ? '' : it.path,
        })),
      }),
    )

    yield all(
      currentBatchToBeProcessed.map((mediaItem) =>
        spawn(uploadMediaItem, mediaItem),
      ),
    )
  } catch (e) {
    log(`Error inside mediaUploadWatcher (${e}):`)
  }
}

function* mediaDeletionWatcher({
  payload: { customerID, mediaKind, mediaItemID },
}: ReturnType<typeof requestedMediaDeletion>) {
  if (mediaKind === 'owner_house') {
    api._updateCustomer(customerID, {
      owner_house: '',
    })
  }

  api.deleteMediaItem(customerID, mediaItemID)
}

export function* mediaSaga() {
  yield all([
    takeEvery(requestedMediaUpload, mediaUploadWatcher),
    takeEvery(requestedMediaDeletion, mediaDeletionWatcher),
    call(mediaSubWatcher),
  ])
}

const emptyMediaKindToMedia = () => ({
  adders: {},
  hvac_system: {},
  electrical: {},
  electricity: {},
  id: {},
  insulation: {},
  owner_house: {},
  problem: {},
  roof: {},
  temperature: {},
  thermal: {},
  other_documents: {},
  solar_proposal: {},
  all_exterior_walls_site_survey: {},
  rafters_site_survey: {},
  electrical_panel_site_survey: {},
  all_planes_roof_site_survey: {},
  other_site_survey: {},
  deal_certificate_mh: {},
  foundation_pic_mh: {},
  hud_plate_mh: {},
  install_certificate_mh: {},
  property_title_mh: {},
  attic_site_survey: {},
  electrical_site_survey: {},
  house360_site_survey: {},
  roof_site_survey: {},
  windows: {},
  rc_front_house: {},
  rc_address_verification: {},
  rc_3d_view: {},
  rc_wires_home: {},
  rc_siding: {},
  rc_other_property_wind_damage: {},
  rc_wind_damages: {},
  rc_layers: {},
  rc_roof_inclination: {},
  rc_roof_overview: {},
  rc_gutters: {},
  rc_flashings: {},
  rc_penetrations: {},
  rc_hail: {},
  rc_length_shingle: {},
  rc_drip_edge: {},
  rc_shingles_color: {},
  rc_garage: {},
  rc_ice_shield: {},
})
