import React from 'react'
import * as rn from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'

import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import * as gStyles from '../gStyles'

import CustomDropdown from './CustomDropdown'
import NumberInput from './NumberInput'
import Pad from './Pad'
import Text from './Text'
import TextInput from './TextInput'

import View from './View'

export interface RowInputProps<K extends c.FieldRowable> {
  readonly customerID: string
  readonly field: K
}

export default React.memo(function ConnRowInput<K extends c.FieldRowable>({
  customerID,
  field,
}: RowInputProps<K>) {
  const colorScheme = rn.useColorScheme() || 'light'
  const isDark = colorScheme === 'dark'

  const { addRow, changeVal, cols, deleteRow, rows } = r.useRowable<K>(
    customerID,
    field,
  )

  const fieldLabel = React.useMemo(() => c.getFieldLabel(field), [field])

  const cellStyles = React.useMemo(() => {
    let sum = 0

    const styles = cols.map((c) => {
      sum += c.widthPer
      return {
        // ...gStyles.deadCenter,
        // backgroundColor: rndColor(),
        marginRight: '4%',
        width: `${c.widthPer - 4}%`,
      }
    })

    if (sum !== 90) {
      console.warn(
        `Column widths must add up to 90 (percent), got: ${cols
          .map((c) => c.widthPer)
          .toString()}`,
      )
    }

    return styles
  }, [cols])

  const rowMapper = React.useCallback(
    (
      row: c.Rowable,
      _: number,
      rowsRef: readonly unknown[],
    ): React.ReactElement => (
      <Row
        cellStyles={cellStyles}
        cols={cols}
        key={row.id}
        onChangeValue={changeVal}
        onDeleteRow={deleteRow}
        row={row}
        showDelete={rowsRef.length > 1}
      />
    ),
    [changeVal, cellStyles, cols, deleteRow],
  )

  const headerStyles = React.useMemo(
    () =>
      cols.map(
        (c): rn.ViewStyle => ({
          // backgroundColor: rndColor(),
          width: `${c.widthPer}%`,
        }),
      ),
    [cols],
  )

  return (
    <View marginBottom={16}>
      {fieldLabel !== field && (
        <Text style={colorScheme === 'dark' ? styles.labelDark : styles.label}>
          {fieldLabel}
        </Text>
      )}

      {fieldLabel !== field && <Pad amt={16} />}

      <View marginLeft="-2%" style={styles.connRow}>
        {cols.map((col, colIdx) => (
          <rn.View key={customerID + col.key} style={headerStyles[colIdx]}>
            <Text centerText size={14}>
              {col.label}
            </Text>
          </rn.View>
        ))}

        {/* Delete column */}
        <rn.View style={gStyles.width10} />
      </View>

      <Pad amt={12} />

      {rows.map(rowMapper)}

      <Pad amt={12} />

      <rn.View style={styles.center}>
        <rn.Pressable
          onPress={addRow}
          style={isDark ? styles.buttonAdd : styles.buttonAddDark}
        >
          <Icon
            color={c.themeTuple[colorScheme].paper.color}
            name="plus"
            size={20}
          />
        </rn.Pressable>
      </rn.View>
    </View>
  )
})

interface RowProps {
  readonly cellStyles: readonly rn.ViewStyle[]
  readonly cols: c.Cols
  readonly onChangeValue: (
    col: string,
    rowID: string,
    val: React.SetStateAction<number | string>,
  ) => void
  readonly onDeleteRow: (rowID: string) => void
  readonly row: c.MiniSplit | c.SlidingGlassDoorGroup | c.WindowGroup
  readonly showDelete: boolean
}

const Row = React.memo<RowProps>(function Row({
  cellStyles,
  cols,
  row,
  onChangeValue,
  onDeleteRow,
  showDelete,
}) {
  const t = r.useTheme()

  const handleChangeValue = React.useMemo(
    () =>
      cols.map((col) => (value: React.SetStateAction<number | string>) => {
        onChangeValue(col.key, row.id, value)
      }),
    [cols, onChangeValue, row.id],
  )

  // const handleInputClean = React.useMemo(
  //   () =>
  //     cols.map((col) => () => {
  //       onChangeValue(col.key, rowIdx, '')
  //     }),
  //   [cols, onChangeValue, rowIdx],
  // )

  const handleDeleteRow = React.useCallback(
    (): void => void onDeleteRow(row.id),
    [onDeleteRow, row.id],
  )

  if (cols.length === 0) {
    return null
  }

  return (
    <rn.View style={styles.connRow}>
      {cols.map((col, colIdx) => (
        <rn.View key={row.id + '>' + col.key} style={cellStyles[colIdx]}>
          {col.type === 'calc' && (
            <Text centerText grow>
              {col.calc(row)}
            </Text>
          )}
          {col.type === 'input' && (
            <TextInput
              keyboardType="decimal-pad"
              maxLength={2}
              on="paper"
              onChangeText={handleChangeValue[colIdx]}
              value={
                c.zeroes.includes(row[col.key as keyof typeof row])
                  ? ''
                  : String(row[col.key as keyof typeof row])
              }
            />
          )}
          {col.type === 'number-input' && (
            <NumberInput
              onChange={handleChangeValue[colIdx]}
              value={row[col.key as keyof typeof row]}
            />
          )}
          {col.type === 'opts' && (
            <CustomDropdown
              data={col.opts}
              on="paper"
              onValueChange={handleChangeValue[colIdx]!}
              selectedValue={row[col.key as keyof typeof row]}
            />
          )}
        </rn.View>
      ))}

      <rn.View style={showDelete ? styles.rowRemove : styles.rowRemoveHidden}>
        <rn.TouchableOpacity onPress={showDelete ? handleDeleteRow : c.emptyFn}>
          <Icon color={t.danger} name="close" size={16} />
        </rn.TouchableOpacity>
      </rn.View>
    </rn.View>
  )
})

const buttonAddDefault = {
  alignItems: 'center',
  borderRadius: 100,
  justifyContent: 'center',
  padding: 12,
} as const

const styles = rn.StyleSheet.create({
  buttonAdd: {
    ...buttonAddDefault,
    backgroundColor: c.light.paper.color,
  },
  buttonAddDark: {
    ...buttonAddDefault,
    backgroundColor: c.dark.paper.color,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  connRow: {
    flexDirection: 'row',
    marginVertical: 12,
  },
  label: {
    color: c.light.paper.color,
    fontFamily: c.light.fontFamily,
    fontSize: 18,
  },
  labelDark: {
    color: c.dark.paper.color,
    fontFamily: c.dark.fontFamily,
    fontSize: 18,
  },
  rowRemove: {
    ...gStyles.deadCenter,
    width: '6%',
  },
  rowRemoveHidden: {
    ...gStyles.deadCenter,
    ...gStyles.opacity0,
    width: '6%',
  },
  textGray: {
    color: 'rgba(0,0,0,0.4)',
  },
  textGrayDark: {
    color: 'rgba(255,255,255,0.4)',
  },
})

// const colors = new Array(255).fill(null).map(() => {
//   const r = c.randomIntFromInterval(0, 255)
//   const g = c.randomIntFromInterval(0, 255)
//   const b = c.randomIntFromInterval(0, 255)

//   return `rgb(${r},${g},${b})`
// })

// const rndColor = () => colors[c.randomIntFromInterval(0, 254)]
