import React from 'react'
import * as rn from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'

import Icon from 'react-native-vector-icons/MaterialIcons'

import * as appUtils from '../utils'

import Label from './Label'
import Pad from './Pad'

export type TextInputProps = c.r<{
  displayCleanBtn?: boolean
  editable?: boolean
  keyboardType?: rn.TextInputProps['keyboardType']
  label?: string
  labelIconRight?: React.ReactElement
  maxLength?: number
  multiline?: boolean
  on: c.SurfaceType
  onBlur?(): void
  onChangeText?(text: string): void
  onClean?(): void
  onEndEditing?: rn.TextInputProps['onEndEditing']
  onInputClean?(): void
  placeholder?: string
  placeholderTextColor?: string
  value?: string
}>

const each = 56
const cleanBtnHitSlop = { left: each, top: each, bottom: each, right: each }
const cleanIconSize = 16
const btnCleanBase = {
  borderRadius: 8 /* TODO */,
  position: 'absolute',
  right: 16,
} as const

export const padBetweenLabelAndInput = 12

const screenHeight = rn.Dimensions.get('window').height

export default React.memo<TextInputProps>(function TextInput({
  displayCleanBtn,
  editable,
  keyboardType,
  label = '',
  labelIconRight,
  maxLength,
  multiline,
  on,
  onBlur,
  onChangeText,
  onClean,
  onEndEditing,
  placeholder,
  value,
}) {
  const viewWrapped =
    label || labelIconRight || ((onClean || displayCleanBtn) && editable)
  const t = r.useTheme()
  const styles = r.useThemedStyleSheet(themedStyles, on)
  const inputRef = React.useRef<rn.TextInput>(appUtils.emptyTextInput)
  const [{ height: txtNodeHeight }, setTxtNodeLayout] = appUtils.useLayout()
  const [labelHeight, setLabelHeight] = React.useState(0)

  const handleFocus = () => {
    if (!multiline || !appUtils.flatListSalesRef.current) return

    const scrollOffset = appUtils.flatListOffsetSales

    inputRef.current.measureInWindow((_x, y, _width, height) => {
      const offset = y + scrollOffset - screenHeight / 2 + height / 2
      appUtils.flatListSalesRef.current?.scrollToOffset({
        offset,
        animated: true,
      })
    })
  }

  const cleanStyle = React.useMemo((): rn.ViewStyle => {
    if (multiline) {
      return {
        ...btnCleanBase,
        top: labelHeight + padBetweenLabelAndInput + 12,
      }
    }
    return {
      ...btnCleanBase,
      bottom: txtNodeHeight / 2 - cleanIconSize / 2,
    }
  }, [labelHeight, multiline, txtNodeHeight])
  const handleClean = React.useCallback(
    () => void (onClean || onChangeText)?.(''),
    [onChangeText, onClean],
  )

  // TODO: Handle label + !shrink
  // if (label && !shrink) {
  //   throw new Error('Must use shrink when using label')
  // }

  const txtNode = (
    <rn.TextInput
      editable={editable}
      keyboardType={keyboardType}
      maxLength={maxLength}
      multiline={multiline}
      onBlur={onBlur}
      onFocus={handleFocus}
      onChangeText={onChangeText}
      onEndEditing={onEndEditing}
      onLayout={setTxtNodeLayout}
      placeholder={placeholder}
      placeholderTextColor={t.input[on].placeHolderColor}
      ref={inputRef}
      scrollEnabled
      style={multiline ? styles.inputMultiline : styles.input}
      value={value}
    />
  )

  if (viewWrapped) {
    return (
      <rn.View>
        <Label iconRight={labelIconRight} onHeight={setLabelHeight}>
          {label}
        </Label>

        <Pad amt={padBetweenLabelAndInput} />

        {txtNode}

        {(onClean || displayCleanBtn) && editable && !!value && (
          <rn.TouchableOpacity
            hitSlop={cleanBtnHitSlop}
            onPress={handleClean}
            style={cleanStyle}
          >
            <Icon color={t.canvas.faint} name="close" size={cleanIconSize} />
          </rn.TouchableOpacity>
        )}
      </rn.View>
    )
  }

  return txtNode
})

const themedStyles = r.ThemedStyleSheet.create((t, on) => {
  const inputBase = { ...t.input[on], paddingLeft: 12, paddingVertical: 18 }

  return {
    input: inputBase,
    inputMultiline: { ...inputBase, height: 160 },
    wrapper: {
      justifyContent: 'space-between',
      borderRadius: t.input[on].borderRadius,
    },
  }
})
