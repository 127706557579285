import React from 'react'
import * as rn from 'react-native'
import {
  ActivityIndicator,
  Alert,
  Linking,
  Platform,
  StyleProp,
  Text,
  TextInputProps,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'
import {
  EMPTY_FN,
  Customer,
  acSplitUnit,
  acTons,
  californiaCity,
  companyOptions,
  selectClosers,
  selectSetters,
  mainPanelUpgradeNeededOrRequested,
  roofLayoverOptions,
  slidingDoorsSize,
  updateCustomer,
  useSelector,
  validateInputMaxLength,
} from '../../common'

import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import axios from 'axios'

import * as gStyles from '../gStyles'
import { VerifyFillEfficienciesListener } from '../helpers/VerifyFillEfficienciesListener'
import {
  API_KEY_GOOGLE_PLACES_ANDROID,
  API_KEY_GOOGLE_PLACES_IOS,
} from '../habitat'

import Label from './Label'
import OptionGroup from './OptionGroup'
import Pad from './Pad'
import PhoneInput from './PhoneInput'
import SsnInput from './SsnInput'
import UPSTextInput from './TextInput'
import ValidationGuideline from './ValidationGuideline'
import UPSView from './View'
import CustomDropdown from './CustomDropdown'

export interface InputProps extends TextInputProps {
  customer?: Customer
  customerId?: string
  disableErr?: boolean
  field?: keyof Customer
  handleInputClean?: (text: string) => void
  handleSaveAddress?: (text: string) => void
  inputStyle?: TextStyle
  label?: string
  labelIconRight?: React.ReactElement
  loading?: boolean
  mapRoute?: boolean
  marginVertical?: number
  on: c.SurfaceType
  showAsterisk?: boolean
  showCallBtn?: boolean
  showIconMap?: boolean
  style?: never
  /**
   * Adds an additional 24 points of padding to the bottom of the input.
   */
  withPadding?: boolean
}

const InputWithForwardedRef = React.forwardRef<rn.TextInput | null, InputProps>(
  function InputWithForwardedRef(
    {
      customer,
      customerId,
      disableErr,
      editable,
      field,
      handleInputClean: onClean,
      handleSaveAddress,
      inputStyle,
      label,
      labelIconRight,
      loading,
      mapRoute,
      marginVertical,
      maxLength,
      multiline,
      on,
      onChangeText,
      onEndEditing,
      showAsterisk,
      showCallBtn,
      showIconMap = true,
      value: propsValue = '',
      withPadding,
      ...props
    },
    ref,
  ): React.ReactElement | null {
    const styles = r.useThemedStyleSheet(themedStyles, on)
    const t = r.useTheme()
    const closers = useSelector(selectClosers())
    const setters = useSelector(selectSetters())

    const windowColors = r.useWindowColors()

    const [installationCompanies, batteryType, batterySize] = r.useBattery(
      customerId || '',
    )

    const _opts = React.useMemo((): c.Opts => {
      c.debug('<Input /> | _opts()')
      const options = c.fieldToRadioOpts(field as keyof Customer, customer)
      if (options.length) {
        return options
      }
      if (field === 'air_conditioner_current_tons') {
        return acTons
      }
      if (field === 'air_conditioner_new_tons') {
        return acTons
      }
      if (field === 'roof_california_city') {
        return californiaCity
      }
      if (field === 'air_conditioner_unit_type') {
        return acSplitUnit
      }
      if (field === 'solarRep') {
        return setters
      }
      if (field === 'homeRep') {
        return closers
      }
      if (field === 'solarCompany') {
        return companyOptions
      }
      if (field === 'roof_layover_or_tear') {
        return roofLayoverOptions
      }
      if (field === 'main_panel_upgrade_needed_or_requested') {
        return mainPanelUpgradeNeededOrRequested
      }
      if (field === 'main_panel_upgrade_installation_company') {
        return installationCompanies
      }
      if (field === 'new_windows_california_city') {
        return c.californiaCity
      }
      if (field === 'new_windows_sliding_glass_size') {
        return slidingDoorsSize as unknown as c.Opt[]
      }
      if (field === 'new_windows_color') {
        return windowColors
      }

      if (field === 'battery_installation_company') {
        return installationCompanies
      }
      if (field === 'battery_type') {
        return batteryType
      }
      if (field === 'battery_size') {
        return batterySize
      }
      if (field === 'sub_panel_upgrade_installation_company') {
        return installationCompanies
      }
      return c.EMPTY_ARRAY as c.Opts
    }, [
      batterySize,
      batteryType,
      closers,
      customer,
      field,
      installationCompanies,
      setters,
      windowColors,
    ])

    const internalRef = React.useRef<rn.TextInput | null>({} as rn.TextInput)

    React.useImperativeHandle<rn.TextInput | null, rn.TextInput | null>(
      ref,
      (): rn.TextInput | null => internalRef.current,
    )

    const [value, setValue] = React.useState(propsValue)
    const opts = React.useMemo((): c.Opts => {
      if (value === '') return _opts
      if (_opts.find((o) => o.value === value)) return _opts
      return [..._opts, { label: value, value }]
    }, [_opts, value])
    const currValueNotInOpts = opts !== _opts
    const [predictions, setPredictions] = React.useState<PredictionType[]>([])
    // const [isFocused, setIsFocused] = React.useState(false)
    const [coordinates, setCoordinates] =
      React.useState<Location>(defaultCoordinates)

    const [error, setError] = React.useState<string>('')

    const styleDropDown = React.useMemo(
      (): StyleProp<ViewStyle> => ({
        height: 'auto',
        marginVertical,
      }),
      [marginVertical],
    )

    // const handleFocus = React.useCallback((): void => {
    //   setIsFocused(true)
    // }, [])

    const handleBlur = React.useCallback((): void => {
      // setIsFocused(false)
      const errEnabled = !disableErr
      const noValue = !value
      if (field && errEnabled && c.fieldShouldBeFilled(field) && noValue) {
        setError('This field must be filled in')
      }
    }, [disableErr, field, value])

    const handleTextChange = React.useCallback(
      (text: string): void => {
        setError('')
        onChangeText?.(text)
      },
      [onChangeText],
    )

    const handleMaskedTextChange = React.useCallback(
      (mask): void => {
        handleTextChange(mask)
      },
      [handleTextChange],
    )

    const onChangeDropdownValue = React.useCallback(
      (e: any) => {
        if (!customerId || (field && e === customer?.[field])) {
          return
        }
        setValue(e)
        updateCustomer(customerId, {
          [field as string]: e,
        })

        error && setError('')
      },
      [customer, customerId, error, field],
    )

    const handleSelectCoordinate = (placeId: string): void => {
      const apiGetCoordinates = `${GOOGLE_PLACES_API_BASE_URL}/details/json?key=${API_KEY_GOOGLE_PLACES}&place_id=${placeId}`

      axios
        .request({
          method: 'get',
          url: apiGetCoordinates,
        })
        .then((res): void => {
          if (res.status === 200) {
            const data = res.data as PlaceInfo
            setCoordinates(data.result.geometry.location)
          }
        })
        .catch((e): void => {
          c.log(e)
          Alert.alert('Error', c.processErr(e))
        })
    }
    const handleSelectPlace = (prediction: string, placeId: string) => () => {
      internalRef.current?.setNativeProps({ text: prediction })

      internalRef.current?.focus?.()
      onChangeText?.(prediction)
      setValue(prediction)
      handleSaveAddress?.(prediction)
      handleSelectCoordinate(placeId)
      setPredictions([])
    }

    const handleAddressTextChange = React.useCallback<
      Required<InputProps>['onChangeText']
    >(
      (newText): void => {
        handleTextChange(newText)

        if (!newText) {
          setPredictions(c.EMPTY_ARRAY as PredictionType[])
          return
        }

        axios
          .request({
            method: 'get',
            url: `${API_PLACES_URL}${newText}`,
          })
          .then((res): void => {
            if (res.status === 200) {
              const data = res.data as Prediction
              setPredictions(data.predictions)
              coordinates.lat && setCoordinates(defaultCoordinates)
            }
          })
          .catch((e): void => {
            c.log(e)
            Alert.alert('Error', c.processErr(e))
          })
      },
      [coordinates, handleTextChange],
    )

    const onPressGoMap = (): void => {
      const latitude = coordinates.lat
      const longitude = coordinates.lng

      if (!coordinates.lat || !coordinates.lng) {
        return
      }

      const url: any = Platform.select({
        ios: `comgooglemaps://?center=${latitude},${longitude}&q=${latitude},${longitude}&zoom=14&views=traffic"`,
        android: `geo://?q=${latitude},${longitude}`,
      })

      Linking.canOpenURL(url)
        .then((supported) => {
          if (supported) {
            return Linking.openURL(url)
          } else {
            const browser_url = `https://www.google.de/maps/@${latitude},${longitude}`
            return Linking.openURL(browser_url)
          }
        })
        .catch(() => {
          if (Platform.OS === 'ios') {
            Linking.openURL(`maps://?q=${latitude},${longitude}`)
          }
        })
    }

    React.useEffect((): void => {
      if (field === 'customerAddress' && propsValue) {
        axios
          .request({
            method: 'get',
            url: `${API_PLACES_URL}${propsValue}`,
          })
          .then((res): void => {
            if (res.status === 200) {
              const data = res.data as Prediction
              const { predictions } = data
              if (predictions.length === 0) {
                return
              }
              const [firstPrediction] = predictions
              if (!firstPrediction) {
                return
              }
              const { place_id: first_predicted_place_id } = firstPrediction
              handleSelectCoordinate(first_predicted_place_id)
            }
          })
          .catch((e): void => {
            c.log(e)
            Alert.alert('Error', c.processErr(e))
          })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect((): void => {
      setValue(propsValue)
    }, [propsValue])

    const hideInput = (isFilled: boolean): void => {
      if (!internalRef.current) {
        return
      }
      if (isFilled) {
        internalRef.current.setNativeProps?.({ editable: true })

        // TODO: Show input
      } else {
        internalRef.current.setNativeProps?.({ editable: false })
      }
    }

    React.useEffect((): void => {
      if (!customer || !field) {
        return
      }
      VerifyFillEfficienciesListener(customer, field, hideInput)
    }, [customer, field])

    const handleInputClean = React.useCallback(() => {
      if (onClean) {
        onClean('')
      }
    }, [onClean])

    const isNewInputField =
      field !== 'solarRep' &&
      field !== 'solarCompany' &&
      field !== 'homeRep' &&
      field !== 'customerAddress' &&
      field !== 'customerPhone' &&
      field !== 'customerPhoneAlt' &&
      field !== 'ssn' &&
      !c.isDropdownField(field)

    const txtNode = (
      <>
        {field === 'customerAddress' && (
          <rn.View>
            <rn.View style={gStyles.row}>
              <rn.TextInput
                {...props}
                onBlur={handleBlur}
                onChangeText={handleAddressTextChange}
                onEndEditing={EMPTY_FN}
                placeholderTextColor={t.input[on].placeHolderColor}
                ref={internalRef}
                style={error ? styles.inputError : styles.input}
                value={value}
              />
              {showIconMap && coordinates.lat ? (
                <TouchableOpacity onPress={onPressGoMap} style={touchMap}>
                  <Icon
                    name="map-marker-radius"
                    size={24}
                    color={t.canvas.highlight}
                  />
                </TouchableOpacity>
              ) : null}
            </rn.View>
            {predictions.map(
              (prediction): JSX.Element => (
                <View
                  key={prediction.description}
                  onTouchEnd={handleSelectPlace(
                    prediction.description,
                    prediction.place_id,
                  )}
                  style={styles.input}
                >
                  <Text style={styles.predictionText}>
                    {prediction.description}
                  </Text>
                </View>
              ),
            )}
          </rn.View>
        )}
        {isNewInputField &&
          (!loading ? (
            <UPSTextInput
              editable={editable}
              label={label}
              maxLength={
                maxLength || validateInputMaxLength(field as keyof Customer)
              }
              multiline={multiline}
              on={on}
              onChangeText={handleTextChange}
              onClean={onClean ? handleInputClean : undefined}
              onBlur={handleBlur}
              onEndEditing={onEndEditing}
              value={
                typeof propsValue === 'string' ? propsValue : String(propsValue)
              }
            />
          ) : (
            <ActivityIndicator
              color={c.themeTuple.light.accent}
              size="small"
              style={styles.activityIndicator}
            />
          ))}
        {(field === 'customerPhone' || field === 'customerPhoneAlt') && (
          <PhoneInput
            {...props}
            error={!!error}
            onBlur={handleBlur}
            onChangeText={handleMaskedTextChange}
            onEndEditing={onEndEditing}
            // onFocus={handleFocus}
            placeholderTextColor={t.input[on].placeHolderColor}
            ref={internalRef}
            showCallBtn={showCallBtn}
            value={propsValue || ''}
          />
        )}
        {field === 'ssn' && (
          <SsnInput
            on={on}
            onChangeText={handleMaskedTextChange}
            ref={internalRef}
            value={propsValue || ''}
          />
        )}

        {(field === 'solarRep' ||
          field === 'solarCompany' ||
          field === 'homeRep' ||
          c.isDropdownField(field)) && (
          <View style={styleDropDown}>
            {opts.length > 3 || field === 'roof_layers_how_many' ? (
              <CustomDropdown
                data={opts}
                disabled={currValueNotInOpts}
                on={on}
                onValueChange={onChangeDropdownValue}
                selectedValue={value}
              />
            ) : (
              <OptionGroup
                buttons={opts}
                disabled={currValueNotInOpts}
                field={field as keyof Customer}
                onPress={onChangeDropdownValue}
                selectedValue={value}
              />
            )}
          </View>
        )}
        {(field === 'air_conditioner_new_tons' ||
          field === 'roof_layers_how_many' ||
          field === 'battery_size') && (
          <ValidationGuideline customerID={customerId} field={field} />
        )}
        {!!error && field !== 'air_conditioner_new_tons' && (
          <Text style={styles.textError}>{error}</Text>
        )}
      </>
    )

    if (isNewInputField || !label) {
      return (
        <>
          {txtNode}

          {withPadding && <Pad amt={24} />}
        </>
      )
    }

    return (
      <>
        <UPSView justifyContent="center">
          {label && (
            <>
              <Label iconRight={labelIconRight} showAsterisk={showAsterisk}>
                {label}
              </Label>

              <Pad amt={8} />
            </>
          )}

          {txtNode}
        </UPSView>

        {withPadding && <Pad amt={24} />}
      </>
    )
  },
)

export default React.memo(InputWithForwardedRef)

const themedStyles = r.ThemedStyleSheet.create((t, on) => {
  const input: rn.TextStyle = {
    ...t.input[on],
    fontFamily: t.fontFamily,
  }

  return {
    activityIndicator: { height: 32 },
    borderRadius: { borderRadius: 12 },
    input,
    inputError: { ...input, borderColor: t.danger },
    predictionText: { color: t.input[on].color, fontFamily: t.fontFamily },
    textError: { color: 'red', marginTop: 5, zIndex: -1 },
  }
})

const defaultCoordinates: Location = {
  lat: '',
  lng: '',
}

const touchMap: StyleProp<ViewStyle> = {
  alignSelf: 'center',
  marginLeft: 10,
}

export interface Prediction {
  predictions: PredictionType[]
}

export interface PredictionType {
  description: string
  place_id: string
  reference: string
  matched_substrings: unknown[]
  structured_formatting: object
  terms: object[]
  types: string[]
}

export interface PlaceInfo {
  html_attributions: unknown[]
  result: ResultsDataPlace
  status: string
}

export interface ResultsDataPlace {
  address_components: object[]
  adr_address: string
  formatted_address: string
  geometry: Geometry
  icon: string
  icon_background_color: string
  icon_mask_base_uri: string
  name: string
  photos: object[]
  place_id: string
  reference: string
  types: string[]
  url: string
  utc_offset: number
  vicinity: string
}

export interface Geometry {
  location: Location
  viewport: Viewport
}

export interface Location {
  lat: string
  lng: string
}

export interface Viewport {
  northeast: Location
  southwest: Location
}

const GOOGLE_PLACES_API_BASE_URL = 'https://maps.googleapis.com/maps/api/place'
const API_KEY_GOOGLE_PLACES =
  Platform.OS === 'android'
    ? API_KEY_GOOGLE_PLACES_ANDROID
    : API_KEY_GOOGLE_PLACES_IOS

const API_PLACES_URL = `${
  GOOGLE_PLACES_API_BASE_URL as string
}/autocomplete/json?key=${API_KEY_GOOGLE_PLACES}&language=en&components=country:us&input=`
