import React from 'react'
import Highcharts, { Options } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export interface FinancingChartProps {
  // handleImageChart(url: string): void
  interest: number
  totalRepayment: number
  payPerMonth: number
}

export default function FinancingChart({
  interest,
  payPerMonth,
  totalRepayment,
}: FinancingChartProps) {
  const options: Options = {
    accessibility: {
      enabled: false,
    },
    title: {
      text: `ESTIMATED<br/>MONTHLY<br/>PAYMENT<br/><br/><h1 class='pay-per-month'>$ ${
        isNaN(payPerMonth) ? 0 : payPerMonth.toLocaleString('en')
      }</h1>`,
      verticalAlign: 'middle',
      align: 'center',
      floating: true,
    },

    chart: {
      type: 'pie',
      width: 560,
    },

    plotOptions: {
      pie: {
        innerSize: '80%',
        borderWidth: 40,
        borderColor: 'null',
        slicedOffset: 20,
        size: 300,
        dataLabels: {
          connectorWidth: 1,
          alignTo: 'connectors',

          style: {
            fontSize: '22px',
          },
        },
      },
    },

    credits: {
      enabled: false,
    },

    series: [
      {
        name: '',
        type: 'pie',
        data: [
          { name: 'Interest', y: isNaN(interest) ? 0 : interest, color: 'red' },
          {
            name: 'Cost',
            y:
              isNaN(totalRepayment) || isNaN(interest)
                ? 0
                : parseFloat((totalRepayment - interest).toFixed(2)),
            color: 'blue',
          },
        ],
      },
    ],
  }
  // Unnecessary cast just to avoid React var not used error
  return (
    <HighchartsReact highcharts={Highcharts} options={options} />
  ) as React.ReactElement
}
