import React from 'react'

import * as c from '../../../common'

import AlarmRepsSection from '../AlarmRepsSection'
import AlarmSection from '../AlarmSection'
import CashDescription from '../CashDescription'
import FinancingButtons from '../FinancingButton'
import FinancingSection from '../FinancingSection'
import FinancingTable from '../FinancingTable'
import Pad from '../Pad'
import PageReport from '../PageReport'
import SolarPaymentYearsPage from '../SolarPaymentYearsPage'
import SolarSection from '../SolarSection'
import { Chart } from '../../helpers/Vars'

// import styles from './FinancingPage.module.css'

export interface FinancingPageProps {
  alarmBill: number
  company: string
  contentUser: 'SOLAR' | 'NORMAL' | 'ALARM'
  handleAlarmBill: (value: string) => void
  handlePowerBill: (value: string) => void
  handleSolarBill: (value: string) => void
  powerBill: number
  solarBill: number
  solarCompany: string
  totalFinance: number
}

export default React.memo<FinancingPageProps>(function FinancingPage({
  alarmBill,
  company,
  contentUser,
  handleAlarmBill,
  handlePowerBill,
  handleSolarBill,
  powerBill,
  solarBill,
  solarCompany,
  totalFinance,
}) {
  const [solarLen, setSolarLen] = React.useState(25)
  const [altLen, setAltLen] = React.useState(30)
  const [financingYears, setFinancingYears] = React.useState(10)
  const [interestFinancing, setInterestFinancing] = React.useState(0.11)

  const [ppa, setPpa] = React.useState(0)
  const [ppaTwo, setPpaTwo] = React.useState(0)

  const [altFeeAlarm, setAltFeeAlarm] = React.useState(2)
  const [monthlyAlarmRep, setMonthlyAlarmRep] = React.useState(0)

  const [solarMonthly, setSolarMonthly] = React.useState(0)
  const [percentIncrease, setPercentIncrease] = React.useState(0.0)

  const [ppaIncrease, setPpaIncrease] = React.useState(0.0)
  const [ppaTwoIncrease, setPpaTwoIncrease] = React.useState(3.9)

  const [altPpaIncrease, setAltPpaIncrease] = React.useState(0.0)
  const [altPpaTwoIncrease, setAltPpaTwoIncrease] = React.useState(3.9)

  //  const [altPpaIncrease, setAltPpaIncrease] = React.useState(2.9)

  const [chart, setChart] = React.useState<Chart>(
    contentUser === 'SOLAR' ? 'CASH' : 'FINANCING',
  )
  const [cash, setCash] = React.useState(totalFinance.toString())

  const handleMonthlyAlarmRep = React.useCallback(
    (monthlyAlarm: number) => {
      setMonthlyAlarmRep(monthlyAlarm)
    },
    [setMonthlyAlarmRep],
  )

  const handleAltFeeAlarm = React.useCallback(
    (e) => {
      if (e.target.value) {
        setAltFeeAlarm(parseFloat(e.target.value))
      }
    },
    [setAltFeeAlarm],
  )

  const monthlyAlertPayments = React.useMemo(() => {
    let _payments: number[] = []
    let prev = alarmBill
    new Array(30).fill(0).forEach((_, i) => {
      if (i === 0) {
        _payments.push(Math.round(alarmBill))
      }
      _payments.push(Math.round(prev + prev * (altFeeAlarm / 100)))

      prev = Math.round(prev + prev * (altFeeAlarm / 100))
    })

    return _payments
  }, [alarmBill, altFeeAlarm])

  const solarYears = React.useMemo(
    (): number[] =>
      new Array<number>(solarLen).fill(0).map((_, i) => {
        return i
      }),
    [solarLen],
  )

  const altSolarYears = React.useMemo(
    (): number[] =>
      new Array<number>(altLen).fill(0).map((_, i) => {
        return i
      }),
    [altLen],
  )

  const solarPayments = React.useMemo((): number[] => {
    if (!solarBill || !solarYears.length) {
      return [0]
    }
    let array: number[] = []
    solarYears.forEach(() => {
      array.push(solarBill * 12)
    })

    paymentYears.forEach((_, i) => {
      if (i > solarYears.length - 1) {
        array.push(0)
      }
    })

    return array
  }, [solarBill, solarYears])

  const resSolarPayments = React.useMemo((): number[] => {
    let array: number[] = []

    altSolarYears.forEach((_, i) => {
      if (i + 1 > 25) {
        array.push(0)
        return
      }
      array.push(solarBill * 12)
    })

    return array
  }, [altSolarYears, solarBill])

  const yearlyPayments = React.useMemo((): number[] => {
    if (!powerBill || percentIncrease <= 0.0) {
      return [0]
    }
    const _yearlyPayments = new Array<number>(31).fill(0)

    _yearlyPayments[0] = powerBill * 12

    for (let i = 1; i <= 30; i++) {
      const prevYear = _yearlyPayments[i - 1] as number

      _yearlyPayments[i] = Math.round(
        prevYear + prevYear * (percentIncrease / 100),
      )
    }

    return _yearlyPayments
  }, [powerBill, percentIncrease])

  const yearlyPaymentsAlarm = React.useMemo(() => {
    if (!powerBill || percentIncrease <= 0.0) {
      return [0]
    }
    const _yearlyPayments = new Array<number>(solarLen + 1).fill(0)

    _yearlyPayments[0] = powerBill * 12

    for (let i = 1; i <= solarLen; i++) {
      const prevYear = _yearlyPayments[i - 1] as number

      _yearlyPayments[i] = Math.round(
        prevYear + prevYear * (percentIncrease / 100),
      )
    }
    return _yearlyPayments
  }, [percentIncrease, powerBill, solarLen])

  const altYearlyPaymentsYears = React.useMemo((): number[] => {
    const _yearlyPayments = new Array<number>(altLen).fill(0)

    _yearlyPayments[0] = powerBill * 12

    for (let i = 1; i < altLen; i++) {
      const prevYear = _yearlyPayments[i - 1] as number

      _yearlyPayments[i] = Math.round(
        prevYear + prevYear * (percentIncrease / 100),
      )
    }

    return _yearlyPayments
  }, [altLen, percentIncrease, powerBill])

  const ppaPayments = React.useMemo((): number[] => {
    if (!ppa && !ppaIncrease) {
      return [0]
    }
    let array: number[] = []
    let prevYear = 0
    new Array(solarLen + 1).fill(0).forEach((_, i) => {
      if (i === 0) {
        array[i] = ppa * 12
      } else {
        prevYear = array[i - 1]!
        array.push(prevYear + prevYear * (ppaIncrease / 100))
      }
    })

    paymentYears.forEach((_, i) => {
      if (i > solarLen - 1) {
        array.push(0)
      }
    })

    return array
  }, [ppa, ppaIncrease, solarLen])

  const ppaTwoPayments = React.useMemo((): number[] => {
    if (!ppaTwo && !ppaTwoIncrease) {
      return [0]
    }
    let array: number[] = []
    let prevYear = 0
    new Array(solarLen + 1).fill(0).forEach((_, i) => {
      if (i === 0) {
        array[i] = ppaTwo * 12
      } else {
        prevYear = array[i - 1]!
        array.push(prevYear + prevYear * (ppaTwoIncrease / 100))
      }
    })

    paymentYears.forEach((_, i) => {
      if (i > solarLen - 1) {
        array.push(0)
      }
    })

    return array
  }, [ppaTwo, ppaTwoIncrease, solarLen])

  const altPpaPayments = React.useMemo((): number[] => {
    const _ppaPayments = new Array<number>(altLen).fill(0)

    _ppaPayments[0] = ppa * 12

    for (let i = 1; i < altLen; i++) {
      if (i > 24) {
        _ppaPayments[i] = 0
      } else {
        const prevYear = _ppaPayments[i - 1] as number

        _ppaPayments[i] = Math.round(
          prevYear + prevYear * (altPpaIncrease / 100),
        )
      }
    }

    return _ppaPayments
  }, [altLen, altPpaIncrease, ppa])

  const altPpaTwoPayments = React.useMemo((): number[] => {
    const _ppaPayments = new Array<number>(altLen).fill(0)

    _ppaPayments[0] = ppaTwo * 12

    for (let i = 1; i < altLen; i++) {
      if (i > 24) {
        _ppaPayments[i] = 0
      } else {
        const prevYear = _ppaPayments[i - 1] as number

        _ppaPayments[i] = Math.round(
          prevYear + prevYear * (altPpaTwoIncrease / 100),
        )
      }
    }

    return _ppaPayments
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [altLen, ppaTwo, altPpaTwoIncrease])

  const totalSolar = React.useMemo((): number => {
    const res = solarPayments.reduce((acc, item) => {
      return (acc = acc + item)
    })
    return res
  }, [solarPayments])

  const totalEnergy = React.useMemo((): number => {
    const res = yearlyPayments.reduce((acc, item) => {
      return (acc = acc + item)
    })

    return res
  }, [yearlyPayments])

  const ptoTable = React.useMemo(
    () => (resSolarPayments[0]! / 12 ? resSolarPayments[0]! / 12 : 0),
    [resSolarPayments],
  )

  // const ppaTable = React.useMemo(
  //   () => Math.round(ppaPayments.reduce((acc, item) => (acc = acc + item))),
  //   [ppaPayments],
  // )

  const alarmSolar = React.useMemo(
    () => Math.round(monthlyAlarmRep + solarMonthly),

    [monthlyAlarmRep, solarMonthly],
  )

  // const solar = React.useMemo(() => {
  //   return Math.abs(Math.round((yearlyPayments[0]! - solarPayments[0]!) / 12))
  // }, [solarPayments, yearlyPayments])

  const alarmElectricity = React.useMemo(() => {
    return (
      Math.round(
        yearlyPaymentsAlarm.reduce((acc, item) => (acc = acc + item)) /
          (solarLen * 12),
      ) +
      monthlyAlertPayments.reduce((acc, item) => (acc = acc + item)) / solarLen
    )
  }, [monthlyAlertPayments, solarLen, yearlyPaymentsAlarm])

  const handleFinancingYears = (years: number) => {
    setFinancingYears(years)
  }

  const handleInterestFinancing = React.useCallback(
    (e) => {
      setInterestFinancing(parseInt(e.target.value) / 100)
    },
    [setInterestFinancing],
  )

  const payPerMonth = React.useMemo((): number => {
    const exp = Math.pow(1 + interestFinancing / 12, -(financingYears * 12))
    const div = 1 - exp
    const ipv = (cash ? parseInt(cash) : 0) * (interestFinancing / 12)
    const res = (ipv / div).toFixed(2)
    return parseFloat(res)
  }, [interestFinancing, financingYears, cash])

  const totalRepayment = React.useMemo(() => {
    return parseFloat((payPerMonth * (financingYears * 12)).toFixed(2))
  }, [financingYears, payPerMonth])

  const interest = React.useMemo((): number => {
    let countInterest = 0
    let balance = cash ? parseInt(cash) : 0
    for (let i = 0; i < financingYears * 12; i++) {
      const _interest = balance * (interestFinancing / 12)
      const principal = payPerMonth - _interest
      balance = balance - principal

      countInterest += _interest
    }

    return parseFloat(countInterest.toFixed(2))
  }, [cash, financingYears, interestFinancing, payPerMonth])

  const handleSolarMonthly = React.useCallback(
    (e) => {
      if (e.target.value) {
        setSolarMonthly(parseInt(e.target.value))
      }
    },
    [setSolarMonthly],
  )

  const handlePpa = React.useCallback(
    (value: string) => {
      setPpa(parseInt(value))
    },
    [setPpa],
  )

  const handlePpaTwo = React.useCallback(
    (value: string) => {
      setPpaTwo(parseInt(value))
    },
    [setPpaTwo],
  )

  const handleSolarLen = React.useCallback(
    (e) => {
      setSolarLen(parseInt(e.target.value))
    },
    [setSolarLen],
  )

  const handleAltLen = React.useCallback(
    (e) => {
      setAltLen(parseInt(e.target.value))
    },
    [setAltLen],
  )
  const handlePercentIncrease = React.useCallback(
    (e) => {
      if (e) {
        setPercentIncrease(parseFloat(e.target.value))
      }
    },
    [setPercentIncrease],
  )

  const handlePpaIncrease = React.useCallback(
    (e) => {
      if (e.target.value) {
        setPpaIncrease(parseFloat(e.target.value))
      } else {
        setPpaIncrease(0)
      }
    },
    [setPpaIncrease],
  )

  const handlePpaTwoIncrease = React.useCallback(
    (e) => {
      if (e.target.value) {
        setPpaTwoIncrease(parseFloat(e.target.value))
      } else {
        setPpaTwoIncrease(0)
      }
    },
    [setPpaTwoIncrease],
  )

  const handleAltPpaIncrease = React.useCallback(
    (e) => {
      if (e.target.value) {
        setAltPpaIncrease(parseFloat(e.target.value))
      } else {
        setAltPpaIncrease(0)
      }
    },
    [setAltPpaIncrease],
  )

  const handleAltPpaTwoIncrease = React.useCallback(
    (e) => {
      if (e.target.value) {
        setAltPpaTwoIncrease(parseFloat(e.target.value))
      } else {
        setAltPpaTwoIncrease(0)
      }
    },
    [setAltPpaTwoIncrease],
  )

  // const handleAltPpaIncrease = React.useCallback(
  //   (e) => {
  //     if (e.target.value) {
  //       setAltPpaIncrease(parseInt(e.target.value))
  //     }
  //   },
  //   [setAltPpaIncrease],
  // )

  const handleCash = React.useCallback(
    (e) => {
      setCash(e.target.value)
    },
    [setCash],
  )

  const resetCash = React.useCallback(() => {
    setCash(totalFinance.toString())
  }, [totalFinance])

  const handleChart = React.useCallback(
    (value: Chart) => {
      setChart(value)
    },
    [setChart],
  )

  React.useEffect(() => {
    setCash(totalFinance.toString())
  }, [totalFinance])

  const savingsSolar = React.useMemo(() => {
    const _yearlyPayments = yearlyPayments.slice(0, 5)

    let _savings: number[] = []
    const substraction = solarBill

    if (_yearlyPayments && substraction) {
      new Array(5).fill(0).forEach((_, i) => {
        const yearly = yearlyPayments[i]
        if (yearly) {
          _savings.push(Math.round(yearly / 12 - substraction))
        }
      })

      return _savings
    }

    return []
  }, [solarBill, yearlyPayments])

  const savingsPpa = React.useMemo(() => {
    const _yearlyPayments = yearlyPayments.slice(0, 5)

    let _savings: number[] = []

    if (_yearlyPayments) {
      new Array(5).fill(0).forEach((_, i) => {
        const substraction = ppaPayments[i]
        const yearly = yearlyPayments[i]
        if (yearly && substraction) {
          _savings.push(Math.round(yearly / 12 - substraction / 12))
        }
      })

      return _savings
    }

    return []
  }, [ppaPayments, yearlyPayments])

  const savingsPpaTwo = React.useMemo(() => {
    const _yearlyPayments = yearlyPayments.slice(0, 5)

    let _savings: number[] = []

    if (_yearlyPayments) {
      new Array(5).fill(0).forEach((_, i) => {
        const substraction = ppaTwoPayments[i]
        const yearly = yearlyPayments[i]
        if (yearly && substraction) {
          _savings.push(Math.round(yearly / 12 - substraction / 12))
        }
      })

      return _savings
    }

    return []
  }, [ppaTwoPayments, yearlyPayments])

  return (
    <>
      <PageReport
        manualHeight={contentUser === 'SOLAR' ? 1300 : undefined}
        company={solarCompany}
        companyBorder
        title={
          c.CODENAME === 'glow'
            ? 'Glow Silver'
            : contentUser === 'SOLAR'
            ? 'PPA Options'
            : 'Financing Options'
        }
      >
        <>
          {/* FINANCING OPTIONS BUTTONS */}
          <FinancingButtons
            chart={chart}
            company={company}
            handleChart={handleChart}
            contentUser={contentUser}
          />
          <Pad amt={20} />
          {/*  CASH DESCRIPTION */}

          {contentUser === 'ALARM' ? null : (
            <CashDescription
              handleCash={handleCash}
              cash={cash}
              chart={chart}
              resetCash={resetCash}
            />
          )}

          {/* ALARM REPS CHART  */}

          {contentUser === 'ALARM' ? (
            <AlarmRepsSection
              chart={chart}
              handleMonthlyAlarm={handleMonthlyAlarmRep}
            />
          ) : null}

          {/* ALARM CHART  */}

          {contentUser === 'ALARM' ? (
            <AlarmSection chart={chart} company={company} />
          ) : null}

          {/*FINANCING CHART */}

          {contentUser === 'ALARM' ? null : (
            <FinancingSection
              cash={cash}
              chart={chart}
              company={company}
              financingYears={financingYears}
              handleCash={handleCash}
              handleFinancingYears={handleFinancingYears}
              handleInterestFinancing={handleInterestFinancing}
              interest={interest}
              interestFinancing={interestFinancing}
              payPerMonth={payPerMonth}
              resetCash={resetCash}
              totalRepayment={totalRepayment}
            />
          )}

          {/*SOLAR CHART */}
          <SolarSection
            altFeeAlarm={altFeeAlarm}
            chart={chart}
            company={company}
            contentUser={contentUser}
            handleAlarmBill={handleAlarmBill}
            handleAltFeeAlarm={handleAltFeeAlarm}
            handlePercentIncrease={handlePercentIncrease}
            handlePowerBill={handlePowerBill}
            handlePpa={handlePpa}
            handlePpaIncrease={handlePpaIncrease}
            handlePpaTwo={handlePpaTwo}
            handlePpaTwoIncrease={handlePpaTwoIncrease}
            handleSolarBill={handleSolarBill}
            handleSolarLen={handleSolarLen}
            handleSolarMonthly={handleSolarMonthly}
            monthlyAlertPayments={monthlyAlertPayments}
            percentIncrease={percentIncrease}
            ppaIncrease={ppaIncrease}
            ppaPayments={ppaPayments}
            ppaTwoIncrease={ppaTwoIncrease}
            ppaTwoPayments={ppaTwoPayments}
            savingsPpa={savingsPpa}
            savingsPpaTwo={savingsPpaTwo}
            savingsSolar={savingsSolar}
            solarLen={solarLen}
            solarPayments={solarPayments}
            totalEnergy={totalEnergy}
            totalSolar={totalSolar}
            yearlyPayments={yearlyPayments}
          />
          {/* CASH VS PTO VS PPA */}

          <FinancingTable
            alarmElectricity={alarmElectricity}
            alarmSolar={alarmSolar}
            company={company}
            contentUser={contentUser}
            ppaTwoInput={ppaTwo}
            ppaTwoPayments={ppaTwoPayments}
            pto={ptoTable}
            totalEfficiencyAmount={cash ? parseInt(cash) : 0}
            powerBill={powerBill}
            solarBill={solarBill}
            percentIncrease={percentIncrease}
            payPerMonth={payPerMonth}
            // interestRate={interestRate}
            financingYears={financingYears}
          />
        </>
      </PageReport>
      <Pad amt={30} />

      {contentUser === 'SOLAR' ? (
        <PageReport company={solarCompany} companyBorder title="">
          <SolarPaymentYearsPage
            altPpaPayments={altPpaPayments}
            altPpaTwoPayments={altPpaTwoPayments}
            altYearlyPaymentsYears={altYearlyPaymentsYears}
            handleAltLen={handleAltLen}
            handleAltPpaIncrease={handleAltPpaIncrease}
            handleAltPpaTwoIncrease={handleAltPpaTwoIncrease}
            handlePercentIncrease={handlePercentIncrease}
          />
        </PageReport>
      ) : null}
    </>
  )
})

const paymentYears = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30,
]
