import React from 'react'

import * as c from '../../../common'
import * as common from '../../../common'
import * as r from '../../../react-utils'
import {
  InitialCustomer,
  companyOptions,
  createdCustomer,
  createEmptyCustomer,
  dispatch,
  processErr,
} from '../../../common'

import * as Mui from '@mui/material'
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone'

import MaskedInput from 'react-text-mask'
import SendIcon from '@mui/icons-material/Send'

import { onValue, push, set } from 'firebase/database'
import { useNavigate } from 'react-router-dom'

import AddressInput from '../AddressInput'
import Pad from '../Pad'
import gStyles from '../../global.module.css'
import loading from '../../icons/load.gif'
import { closersDB, customersDB, settersDB } from '../../fire'
import { useMediaQuery } from '../../hooks/useMediaQuery'

export interface AddCustomerModalProps {
  onClose: () => void
}

export default React.memo<AddCustomerModalProps>(function AddCustomerModal({
  onClose,
}) {
  // const [submitting, setSubmitting] = useState(false)
  const [err, setErr] = React.useState('')
  const [solarRep, setSolarRep] = React.useState('n/a')
  const [solarCompany, setSolarCompany] = React.useState('')
  const [homeRep, setHomeRep] = React.useState('n/a')

  const [closers, setClosers] = React.useState<SelectOption>(c.optsWithOnlyNA)
  const [setters, setSetters] = React.useState<SelectOption>(c.optsWithOnlyNA)

  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [phoneNumberAlt, setPhoneNumberAlt] = React.useState('')
  const [name, setName] = React.useState('')
  const [queryLocation, , handleQueryLocation] = r.useTextInput('')

  const [isLoading, setIsLoading] = React.useState(false)

  const screen = useMediaQuery()

  const navigate = useNavigate()

  const isMounted = r.useIsMounted()

  const startIcon = React.useMemo(
    () => ({
      startAdornment: (
        <Mui.InputAdornment position="start">
          {'🇺🇸'} {'+1'}
        </Mui.InputAdornment>
      ),
    }),
    [],
  )
  const renderPhoneInput = React.useCallback(
    (
      ref: (inputElement: HTMLElement) => void,
      props: {
        onChange: (event: React.ChangeEvent<HTMLElement>) => void
        onBlur: (event: React.FocusEvent<HTMLElement, Element>) => void
        defaultValue: string | undefined
      },
    ) => (
      <Mui.TextField
        {...props}
        InputProps={startIcon}
        inputRef={ref}
        label="Customer Phone Number"
        sx={sx['input']}
      />
    ),
    [startIcon],
  )

  const renderPhoneAltInput = React.useCallback(
    (
      ref: (inputElement: HTMLElement) => void,
      props: {
        onChange: (event: React.ChangeEvent<HTMLElement>) => void
        onBlur: (event: React.FocusEvent<HTMLElement, Element>) => void
        defaultValue: string | undefined
      },
    ) => (
      <Mui.TextField
        {...props}
        InputProps={startIcon}
        inputRef={ref}
        label="Alt Phone Number (Optional)"
        sx={sx['input']}
      />
    ),
    [startIcon],
  )

  const containerStyle = React.useMemo(() => {
    return {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      left: '50%',
      paddingBottom: {
        xs: screen.height >= 426 ? '50px' : '10px  ',
      },
      paddingLeft: {
        md: '24px',
        xs: '18px',
      },
      paddingTop: {
        xs: screen.height >= 426 ? '50px' : '10px',
      },
      paddingRight: {
        md: '24px',
        xs: '18px',
      },
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: {
        lg: 700,
        sm: 600,
        xs: screen.height <= 320 ? 500 : 290,
      },
    }
  }, [screen.height])

  const handleSolarRep = React.useCallback(
    (e) => {
      setSolarRep(e.target.value)
    },
    [setSolarRep],
  )

  const handleSolarCompany = React.useCallback(
    (e) => {
      setSolarCompany(e.target.value)
    },
    [setSolarCompany],
  )

  const handleHomeRep = React.useCallback(
    (e) => {
      setHomeRep(e.target.value)
    },
    [setHomeRep],
  )

  // const form = React.useRef(null)

  const handleSubmit = React.useCallback(async () => {
    try {
      if (!isMounted()) {
        return
      }

      const newCustomer = await push(customersDB, {})

      const allFieldsFilled =
        homeRep.length > 0 &&
        name.length > 0 &&
        queryLocation.length > 0 &&
        phoneNumber.length > 0 &&
        solarCompany.length > 0 &&
        solarRep.length > 0

      if (!allFieldsFilled) {
        throw new Error('Fill all fields')
      }

      const normalizedPhone = c.normalizePhoneNumber(phoneNumber)
      const normalizedPhoneAlt = c.normalizePhoneNumber(phoneNumberAlt)

      if (normalizedPhone.length !== 10) {
        throw new Error(
          'Main phone number should be 10 digits long. E.g. (786) 786 - 7867',
        )
      }

      if (phoneNumberAlt && normalizedPhoneAlt.length !== 10) {
        throw new Error(
          'Alternative phone number should be 10 digits long. E.g. (786) 786 - 7867',
        )
      }

      if (normalizedPhone && !c.isValidUSPhoneNumber(normalizedPhone)) {
        throw new Error(
          `Invalid main phone number. Double-check the area code (${normalizedPhone.slice(
            0,
            3,
          )})`,
        )
      }

      if (normalizedPhoneAlt && !c.isValidUSPhoneNumber(normalizedPhoneAlt)) {
        throw new Error(
          `Invalid alternative phone number. Double-check the area code (${normalizedPhoneAlt.slice(
            0,
            3,
          )})`,
        )
      }

      const newCustomerData: InitialCustomer = {
        customerAddress: queryLocation,
        customerName: name,
        customerPhone: phoneNumber!,
        customerPhoneAlt: phoneNumberAlt!,
        ecohomeRep: homeRep,
        firebaseKey: newCustomer.key!,
        homeRep: homeRep,
        solarCompany: solarCompany,
        solarRep: solarRep,
      }

      setIsLoading(true)
      dispatch(createdCustomer({ customer: newCustomerData }))

      await set(newCustomer, createEmptyCustomer(newCustomerData))
      // TODO: Why DOM manipulation?
      const body = document.querySelector('body')
      if (body) {
        body.style.overflow = 'unset'
      }
      setIsLoading(false)
      navigate(newCustomer.key!)
    } catch (e) {
      setErr(processErr(e))
    }
  }, [
    homeRep,
    isMounted,
    name,
    navigate,
    phoneNumber,
    phoneNumberAlt,
    queryLocation,
    solarCompany,
    solarRep,
  ])

  React.useEffect(() => {
    const getClosers = async () => {
      try {
        onValue(closersDB, (snapshot) => {
          if (!isMounted()) {
            return
          }
          setClosers(snapshot.val() as SelectOption)
        })
      } catch (error) {
        common.log('Fire Error closers: ', error)
      }
    }

    getClosers()
  }, [isMounted])

  React.useEffect(() => {
    const getSetters = async () => {
      try {
        onValue(settersDB, (snapshot) => {
          if (!isMounted()) {
            return
          }
          setSetters(snapshot.val() as SelectOption)
        })
      } catch (error) {
        common.log('Fire Error setters: ', error)
      }
    }

    getSetters()
  }, [isMounted])

  const handlePhoneNumber = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setPhoneNumber(e.target.value)
    },
    [],
  )

  const handlePhoneNumberAlt = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setPhoneNumberAlt(e.target.value)
    },
    [],
  )

  const handleName = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setName(e.target.value)
    },
    [],
  )

  return (
    <Mui.Box component={Mui.Paper} sx={containerStyle}>
      <Mui.Typography component="h4" sx={sx['mainTitle']} variant="h4">
        Add Customer
      </Mui.Typography>
      <Pad amt={20} />

      <Mui.Box
        sx={
          screen.height <= 320 ? sx['inputGroupsRow'] : sx['inputGroupsColumn']
        }
      >
        <Mui.Box sx={sx['inputGroup']}>
          {/* ECOHOME REP */}
          <Mui.FormControl sx={sx['formControl']}>
            <Mui.InputLabel id="custom-select-label" sx={sx['inputLabel']}>
              {common.HOLDING_NAME} Rep
            </Mui.InputLabel>

            <Mui.Select
              defaultValue="n/a"
              displayEmpty
              id="custom-select"
              label={closers.length ? 'EcohomeRep' : ''}
              labelId="custom-select-label"
              onChange={handleHomeRep}
              sx={sx['input']}
              value={homeRep}
            >
              {closers.map((value) => (
                <Mui.MenuItem key={value.value} value={value.value}>
                  {value.label}
                </Mui.MenuItem>
              ))}
            </Mui.Select>
          </Mui.FormControl>
          <Pad amt={20} />
          {/* CUSTOMER NAME */}
          <Mui.FormControl sx={sx['formControl']}>
            <Mui.TextField
              label="Customer Name"
              name="customerName"
              onChange={handleName}
              sx={sx['input']}
            />
          </Mui.FormControl>
          <Pad amt={20} />
          {/* CUSTOMER ADDRESS */}
          <Mui.FormControl sx={sx['formControl']}>
            <AddressInput
              inputClassName={gStyles['width-100']}
              onChangeText={handleQueryLocation}
              renderMUITextField
              value={queryLocation}
            />
          </Mui.FormControl>
        </Mui.Box>
        <Mui.Box sx={sx['boxPad']} />
        <Mui.Box sx={sx['inputGroup']}>
          <Mui.FormControl sx={sx['formControl']}>
            <Mui.InputLabel id="custom-solar-rep" sx={sx['inputLabel']}>
              Solar Rep
            </Mui.InputLabel>

            <Mui.Select
              defaultValue="n/a"
              displayEmpty
              label={setters.length ? 'Solar Rep' : ''}
              labelId="custom-solar-rep"
              onChange={handleSolarRep}
              sx={sx['input']}
              value={solarRep}
            >
              {setters.map((value) => (
                <Mui.MenuItem key={value.value} value={value.value}>
                  {value.label}
                </Mui.MenuItem>
              ))}
            </Mui.Select>
          </Mui.FormControl>
          <Pad amt={20} />
          {/*PHONE NUMBER */}
          <Mui.FormControl sx={sx['formControl']}>
            <MaskedInput
              guide={false}
              mask={mask}
              onChange={handlePhoneNumber}
              render={renderPhoneInput}
              value={phoneNumber}
            />
          </Mui.FormControl>
          <Pad amt={20} />
          {/*PHONE NUMBER Alt */}
          <Mui.FormControl sx={sx['formControl']}>
            <MaskedInput
              guide={false}
              mask={mask}
              onChange={handlePhoneNumberAlt}
              render={renderPhoneAltInput}
              value={phoneNumberAlt}
            />
          </Mui.FormControl>
          <Pad amt={20} />
        </Mui.Box>
      </Mui.Box>
      <Mui.Box
        sx={
          screen.height <= 320 ? sx['inputGroupsRow'] : sx['inputGroupsColumn']
        }
      >
        <Mui.FormControl sx={sx['formControl']}>
          <Mui.InputLabel sx={sx['inputLabel']} id="solar-company-select">
            Solar Company
          </Mui.InputLabel>

          <Mui.Select
            defaultValue=""
            displayEmpty
            id="solar-company"
            label={companyOptions.length ? 'Solar Company' : ''}
            labelId="solar-company-select"
            onChange={handleSolarCompany}
            sx={sx['input']}
          >
            {companyOptions.map((value) => (
              <Mui.MenuItem key={value.value} value={value.value}>
                {value.label}
              </Mui.MenuItem>
            ))}
          </Mui.Select>
        </Mui.FormControl>
      </Mui.Box>
      <HighlightOffTwoToneIcon onClick={onClose} sx={sx['closeIcon']} />

      {err && (
        <Mui.Alert severity="warning" sx={sx['alert']}>
          {err}
        </Mui.Alert>
      )}

      <Mui.Box sx={sx['boxPad']} />

      <img
        alt="loading"
        src={loading}
        style={isLoading ? sx['spinner'] : sx['hidden']}
        width={56}
      />
      <Mui.Button
        endIcon={<SendIcon />}
        onClick={handleSubmit}
        sx={!isLoading ? sx['sendButton'] : sx['hidden']}
        variant="contained"
      >
        Create Customer
      </Mui.Button>
    </Mui.Box>
  )
})

const sx = {
  alert: {
    marginTop: '20px',
  },
  boxPad: {
    display: {
      sm: 'none',
      xs: 'flex',
    },
    height: '20px',
  },
  closeIcon: {
    cursor: 'pointer',
    fontSize: '32px',
    position: 'absolute',
    right: '10px',
    top: '10px',
    zIndex: 7,
  },
  formControl: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: {
      sm: '100%',
      xs: '90%',
    },
  },
  hidden: {
    display: 'none',
  },
  input: {
    width: '100%',
  },
  inputGroup: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: {
      sm: '45%',
      xs: '100%',
    },
  },
  inputGroupsRow: {
    display: 'flex',
    flexDirection: {
      sm: 'row',
      xs: 'row',
    },
    justifyContent: 'space-between',
    width: '90%',
  },
  inputGroupsColumn: {
    display: 'flex',
    flexDirection: {
      sm: 'row',
      xs: 'column',
    },
    justifyContent: 'space-between',
    width: '90%',
  },
  inputLabel: {
    alignSelf: 'flex-start',
  },
  mainTitle: {
    alignSelf: 'center',
    fontSize: {
      lg: 42,
      md: 36,
      sm: 30,
      xs: 24,
    },
  },
  sendButton: {
    marginTop: {
      sm: '20px',
      xs: 'unset',
    },
    width: {
      md: '223.55px',
      sm: '238.94px',
      xs: '80%',
    },
  },
  spinner: {
    marginTop: '20px',
  },
}

export interface PhoneValidator {
  carrier?: string
  country: PhoneCountry
  format: PhoneFormat
  location: string
  phone: string
  type: string
  valid: boolean
}

export interface PhoneCountry {
  code: string
  name: string
  prefix: string
}
export interface PhoneFormat {
  international: string
  local: string
  name: string
}

type SelectOption = typeof companyOptions

const mask = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
