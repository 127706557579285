import React from 'react'

import * as Mui from '@mui/material'

import * as common from '../../../common'

export interface LayoverLayerAlertProps {
  customerID: string
}

export default React.memo<LayoverLayerAlertProps>(function LayoverLayerAlert({
  customerID,
}) {
  const selectCustomerField = React.useMemo(
    (): ReturnType<typeof common.makeSelectCustomerField> =>
      common.makeSelectCustomerField(),
    [],
  )

  const selectRoofroofLayooverOrTearArgs = React.useMemo(
    (): common.SelectCustomerFieldParams => ({
      customerID,
      field: 'roof_layover_or_tear',
    }),
    [customerID],
  )

  // Remember to subscribe to the customer elsewhere!
  const roofLayoverOrTear = common.useSelector(
    (_): common.ValueOf<common.Customer> =>
      selectCustomerField(_, selectRoofroofLayooverOrTearArgs),
  )

  const selectRoofLayersHowMany = React.useMemo(
    (): common.SelectCustomerFieldParams => ({
      customerID,
      field: 'roof_layers_how_many',
    }),
    [customerID],
  )

  const roofLayersHowMany = common.useSelector(
    (_): common.ValueOf<common.Customer> =>
      selectCustomerField(_, selectRoofLayersHowMany),
  )

  const open = roofLayersHowMany === '3' && roofLayoverOrTear === 'Layover'

  return (
    <Mui.Alert severity="info" sx={open ? sx['alert'] : sx['hide']}>
      For more than 2 layers you need to do a tear off
    </Mui.Alert>
  )
})

const sx = {
  alert: {
    display: 'flex',
    width: '298px',
  },
  hide: {
    display: 'none',
  },
}
