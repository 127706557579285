import React from 'react'

import * as common from '../../../common'
import * as r from '../../../react-utils'
import {
  ConsolidationDebtData,
  DebtPayments,
  Collections,
} from '../../../common'

import { ref, update } from 'firebase/database'
import { useLocation } from 'react-router-dom'
import { v1 } from 'uuid'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Divider from '@mui/material/Divider'
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import PostAddIcon from '@mui/icons-material/PostAdd'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { styled, alpha } from '@mui/material/styles'

import CollectionInputs from './CollectionInputs'
import DebtsPaymentsInputs from './DebtsPaymentsInputs'
import DebtsPlace from './DebtsPlace'

import Pad from '../Pad'
import { db } from '../../fire'
import { FormatPrice } from '../../helpers/FormatPrice'

export interface ConsolidationDebtsProps {
  consolidationDebts: ConsolidationDebtData[]
  cb: ConsolidationDebtData
  customerAddress: string
  customerID: string
  customerName: string
  homeRep: string
  page: number
  collections: string
  debtsPayment: string
  company: string
}

export default React.memo<ConsolidationDebtsProps>(function ConsolidationDebts({
  customerID,
  page,
  customerAddress,
  customerName,
  consolidationDebts,
  collections,
  debtsPayment,
  cb,
  company,
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const location = useLocation()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [openSavings, setOpenSavings] = React.useState(false)

  const [name, setName, handleInputUpdateName] = r.useInputVal('')

  const [notes, setNotes, handleInputUpdateNote] = r.useInputVal()

  const [creditScore, setCreditScore, handleInputUpdateCreditScore] =
    r.useInputVal('')

  const [closer, setCloser, handleInputUpdateCloser] = r.useInputVal('')

  const [subtractDebt, setSubtractDebt] = React.useState(0)
  const [subtractPayment, setSubtractPayment] = React.useState(0)
  const [subtractCollection, setSubtractCollection] = React.useState(0)

  React.useEffect(() => {
    setName(cb.name)
  }, [cb, setName])

  React.useEffect(() => {
    setCloser(cb.closer)
  }, [cb.closer, setCloser])

  React.useEffect(() => {
    setCreditScore(cb.creditScore)
  }, [cb, setCreditScore])

  React.useEffect(() => {
    setNotes(cb.notes)
  }, [cb, setNotes])

  const dp = React.useMemo((): DebtPayments[] => {
    if (debtsPayment) {
      const _dp = JSON.parse(debtsPayment) as DebtPayments[]
      return _dp
    }

    return [
      [
        {
          debt: '',
          payment: '',
          consolidation_debt_id: consolidationDebts[0]?.id as string,
          id: v1(),
        },
      ],
    ]
  }, [consolidationDebts, debtsPayment])

  const coll = React.useMemo((): Collections[] => {
    if (collections) {
      const _coll = JSON.parse(collections) as Collections[]
      return _coll
    }

    return [
      [
        {
          consolidation_debt_id: consolidationDebts[0]?.id as string,
          id: v1(),
          value: '',
        },
      ],
    ]
  }, [collections, consolidationDebts])

  const amountDebt = React.useMemo((): number => {
    let _totalDebt = 0
    if (dp[page] && dp[page]?.length) {
      dp[page]?.forEach((value) => {
        _totalDebt += value.debt ? parseInt(value.debt) : 0
      })

      return _totalDebt
    }
    return _totalDebt
  }, [dp, page])

  const amountPayment = React.useMemo((): number => {
    let _totalPayment = 0
    if (dp[page] && dp[page]?.length) {
      dp[page]?.forEach((value) => {
        _totalPayment += value.payment ? parseInt(value.payment) : 0
      })

      return _totalPayment
    }
    return _totalPayment
  }, [dp, page])

  const handleSubtractDebtPayment = (
    checked: boolean,
    debtRef: React.MutableRefObject<HTMLInputElement>,
    paymentRef: React.MutableRefObject<HTMLInputElement>,
    setSubtractPayment: React.Dispatch<React.SetStateAction<number>>,
    setSubtractDebt: React.Dispatch<React.SetStateAction<number>>,
  ) => {
    if (!checked) {
      debtRef.current.disabled = true
      paymentRef.current.disabled = true

      debtRef.current.style.backgroundColor = 'gainsboro'
      paymentRef.current.style.backgroundColor = 'gainsboro'
      setSubtractDebt(
        (current) =>
          current +
          (debtRef.current.value ? parseInt(debtRef.current.value) : 0),
      )
      setSubtractPayment(
        (current) =>
          current +
          (paymentRef.current.value ? parseInt(paymentRef.current.value) : 0),
      )
    } else {
      debtRef.current.disabled = false
      paymentRef.current.disabled = false
      debtRef.current.style.backgroundColor = 'unset'
      paymentRef.current.style.backgroundColor = 'unset'
      setSubtractDebt(
        (current) =>
          current -
          (debtRef.current.value ? parseInt(debtRef.current.value) : 0),
      )
      setSubtractPayment(
        (current) =>
          current -
          (paymentRef.current.value ? parseInt(paymentRef.current.value) : 0),
      )
    }
  }

  const handleSubtractCollection = (
    checked: boolean,
    collRef: React.MutableRefObject<HTMLInputElement>,
    setSubtractCollection: React.Dispatch<React.SetStateAction<number>>,
  ) => {
    if (!checked) {
      collRef.current.disabled = true
      collRef.current.style.backgroundColor = 'gainsboro'

      setSubtractCollection(
        (current) =>
          current +
          (collRef.current.value ? parseInt(collRef.current.value) : 0),
      )
    } else {
      collRef.current.disabled = false
      collRef.current.style.backgroundColor = 'unset'
      setSubtractCollection(
        (current) =>
          current -
          (collRef.current.value ? parseInt(collRef.current.value) : 0),
      )
    }
  }

  const amountCollections = React.useMemo((): number => {
    let _totalCollection = 0

    if (coll[page] && coll[page]?.length) {
      coll[page]?.forEach((value) => {
        _totalCollection += value.value ? parseInt(value.value) : 0
      })

      return _totalCollection
    }
    return _totalCollection
  }, [coll, page])

  const updateName = React.useCallback(
    (e) => {
      const cb = consolidationDebts.map((value, i) => {
        if (i === page) {
          return {
            ...value,
            name: e.target.value,
          }
        }

        return value
      })
      update(ref(db, `/Debts/${customerID}`), {
        consolidationDebts: JSON.stringify(cb),
      })
    },
    [consolidationDebts, customerID, page],
  )
  const updateNameDebounce = React.useMemo(
    () => common.debounce(updateName),
    [updateName],
  )

  const updateCloser = React.useCallback(
    (e) => {
      const cb = [
        ...consolidationDebts.map((value, i) => {
          if (i === page) {
            return {
              ...value,
              closer: e.target.value,
            }
          }

          return value
        }),
      ]
      update(ref(db, `/Debts/${customerID}`), {
        consolidationDebts: JSON.stringify(cb),
      })
    },
    [consolidationDebts, customerID, page],
  )
  const updateCloserDebounce = React.useMemo(
    () => common.debounce(updateCloser),
    [updateCloser],
  )

  const updateCreditScore = React.useCallback(
    (e) => {
      const creditScore = e.target.value
      const cb = [
        ...consolidationDebts.map((value, i) => {
          if (i === page) {
            return {
              ...value,
              creditScore: creditScore,
            }
          }

          return value
        }),
      ]
      update(ref(db, `/Debts/${customerID}`), {
        consolidationDebts: JSON.stringify(cb),
      })
    },
    [consolidationDebts, customerID, page],
  )
  const updateCreditScoreDebounce = React.useMemo(
    () => common.debounce(updateCreditScore),
    [updateCreditScore],
  )

  const updateNote = React.useCallback(
    (e) => {
      const notes = e.target.value
      const cb = [
        ...consolidationDebts.map((value, i) => {
          if (i === page) {
            return {
              ...value,
              notes: notes,
            }
          }

          return value
        }),
      ]
      update(ref(db, `/Debts/${customerID}`), {
        consolidationDebts: JSON.stringify(cb),
      })
    },
    [consolidationDebts, customerID, page],
  )
  const updateNoteDebounce = React.useMemo(
    () => common.debounce(updateNote),
    [updateNote],
  )

  const addDCPage = React.useCallback(() => {
    const parentId = v1()
    let cb = consolidationDebts.slice()

    let debts = dp.slice()
    let collect = coll.slice()

    cb.push({
      address: customerAddress,
      closer: '',
      creditScore: '',
      name: customerName,
      notes: '',
      id: parentId,
    })

    debts.push([
      {
        consolidation_debt_id: parentId,
        debt: '',
        id: v1(),
        payment: '',
      },
    ])

    collect.push([{ consolidation_debt_id: parentId, id: v1(), value: '' }])

    update(ref(db, `/Debts/${customerID}`), {
      consolidationDebts: JSON.stringify(cb),
      collections: JSON.stringify(collect),
      debtsPayments: JSON.stringify(debts),
    })
  }, [coll, consolidationDebts, customerAddress, customerID, customerName, dp])

  const deleteDCPage = React.useCallback(() => {
    if (page === 0) return
    let cb = consolidationDebts.filter((_, i) => i !== page)
    let debts = dp.filter((_, i) => i !== page)
    let collect = coll.filter((_, i) => i !== page)

    update(ref(db, `/Debts/${customerID}`), {
      collections: JSON.stringify(collect),
      consolidationDebts: JSON.stringify(cb),
      debtsPayments: JSON.stringify(debts),
    })
  }, [coll, consolidationDebts, customerID, dp, page])

  const payMonthly = Math.round((amountDebt / 1000) * 5)

  const savingsPerMonth = amountPayment - payMonthly

  const savingsPerYear = savingsPerMonth * 12

  const savingsPerFiveYears = savingsPerYear * 5

  const totalDebt = amountDebt - subtractDebt
  const totalPayment = amountPayment - subtractPayment
  const totalCollection = amountCollections - subtractCollection

  const handleOpenSavings = React.useCallback(() => {
    if (location.pathname.endsWith('debts')) {
      return
    }
    setOpenSavings((current) => !current)
  }, [location])

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '75%',
      }}
    >
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
          <TextField
            disabled={page === 0}
            label="Name"
            onChange={updateNameDebounce}
            // @ts-expect-error TODO
            onInput={handleInputUpdateName}
            value={name}
          />
          <Pad amt={20} />
          <TextField
            label="Closer"
            onChange={updateCloserDebounce}
            // @ts-expect-error TODO
            onInput={handleInputUpdateCloser}
            type={location.pathname.endsWith('auditor') ? 'password' : 'text'}
            value={closer}
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
          <DebtsPlace
            address={cb.address}
            disabled={page === 0}
            consolidationDebtsData={consolidationDebts}
            page={page}
            customerID={customerID}
          />

          <TextField
            label="Credit Score"
            onChange={updateCreditScoreDebounce}
            // @ts-expect-error TODO
            onInput={handleInputUpdateCreditScore}
            type={location.pathname.endsWith('auditor') ? 'password' : 'number'}
            value={creditScore}
          />
        </Box>
      </Box>
      <Pad amt={30} />
      <TextField
        multiline
        onChange={updateNoteDebounce}
        // @ts-expect-error TODO
        onInput={handleInputUpdateNote}
        rows={6}
        sx={{ width: '100%' }}
        value={notes}
      />
      <Pad amt={40} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '607.5px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {dp[page]?.map((value, i) => (
            <DebtsPaymentsInputs
              company={company}
              customerID={customerID}
              debtPayment={value}
              debtPayments={dp}
              handleSubtractDebtPayment={handleSubtractDebtPayment}
              index={i}
              key={value.consolidation_debt_id + value.id}
              len={dp[page]?.length as number}
              page={page}
              totalDebt={totalDebt}
              totalPayment={totalPayment}
              setSubtractDebt={setSubtractDebt}
              setSubtractPayment={setSubtractPayment}
            />
          ))}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {coll[page]?.map((value, i) => (
            <CollectionInputs
              collections={coll}
              company={company}
              customerID={customerID}
              i={i}
              key={value.consolidation_debt_id + value.id}
              len={coll[page]?.length as number}
              page={page}
              total={totalCollection}
              value={value}
              handleSubtractCollection={handleSubtractCollection}
              setSubtractCollection={setSubtractCollection}
            />
          ))}
        </Box>
      </Box>

      <Pad amt={40} />

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box>
          <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            //@ts-ignore
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            sx={{
              backgroundColor: common.companyColors[company] || 'blue',
              '&:hover': {
                backgroundColor: common.companyColors[company] || 'blue',
              },
            }}
          >
            Options
          </Button>

          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {!location.pathname.endsWith('debts') && (
              <MenuItem
                onClick={() => {
                  handleOpenSavings()
                  handleClose()
                }}
                disableRipple
              >
                <VisibilityIcon />
                Show Savings
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                addDCPage()
                handleClose()
              }}
              disableRipple
            >
              <PostAddIcon />
              Add New Debt
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem
              onClick={() => {
                deleteDCPage()
                handleClose()
              }}
              disableRipple
            >
              <DeleteForeverIcon />
              Delete Debt
            </MenuItem>
          </StyledMenu>
        </Box>
      </Box>

      <Modal open={openSavings} onClose={handleOpenSavings}>
        <Box
          component={Paper}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '480px',
            height: '480px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            alignItems: 'center',
          }}
        >
          <Pad amt={50} />
          <Typography component="h4" variant="h4" sx={{ fontWeight: '700' }}>
            Debts Consolidation
          </Typography>
          <Pad amt={20} />
          <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>
            <span style={{ color: 'red' }}>{`${FormatPrice(
              totalPayment,
              '$',
            )} per month `}</span>
            vs{' '}
            <span style={{ color: 'green' }}>
              {FormatPrice(totalDebt, '$')}
            </span>
          </Typography>

          <Pad amt={20} />
          <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>
            {`Monthly Savings = `}
            <span style={{ color: 'green', fontSize: '24px' }}>
              {FormatPrice(savingsPerMonth, '$')}
            </span>
          </Typography>

          <Pad amt={20} />
          <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>
            {`Yearly Savings = `}
            <span style={{ color: 'green', fontSize: '24px' }}>
              {FormatPrice(savingsPerYear, '$')}
            </span>
          </Typography>

          <Pad amt={20} />
          <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>
            {`5 years Savings = `}{' '}
            <span style={{ color: 'green', fontSize: '24px' }}>
              {FormatPrice(savingsPerFiveYears, '$')}
            </span>
          </Typography>
          <HighlightOffTwoToneIcon
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              fontSize: '32px',
              cursor: 'pointer',
              zIndex: 7,
            }}
            onClick={handleOpenSavings}
          />
        </Box>
      </Modal>
    </Box>
  )
})

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}))
