import React from 'react'

import * as common from '../../../common'

import * as Mui from '@mui/material'

import Pad from '../Pad'

export interface IncentiveSectionProps {
  carbonCredit: number
  customerID: string
  glowCash: number
}

export default React.memo<IncentiveSectionProps>(function IncentiveSection({
  carbonCredit,
  customerID,
  glowCash,
}) {
  return (
    <Mui.Box sx={sx['incentiveSection']}>
      <Pad amt={30} />
      <Mui.Typography
        component="span"
        sx={sx['incentiveSectionTitle']}
        variant="h6"
      >
        How will you see your incentive?
      </Mui.Typography>

      <Mui.FormGroup sx={sx['incentiveCheckGroup']}>
        <Pad amt={10} />
        <Mui.Box sx={sx['incentiveCheckGroupItem']}>
          {' '}
          <CheckboxLabel
            customerID={customerID}
            field="incentive_glow_carbon_credits"
            hideCheckbox
          />
          <Mui.Typography component="span" sx={sx['calculationText']}>
            {carbonCredit ? (
              carbonCredit
            ) : (
              <Mui.CircularProgress size="1.4vw" />
            )}
          </Mui.Typography>
        </Mui.Box>

        <Pad amt={50} />
        <Mui.Box sx={sx['incentiveCheckGroupItem']}>
          <CheckboxLabel
            customerID={customerID}
            field="incentive_glow_federal_tax_credit"
          />
          <Mui.Typography component="span" sx={sx['calculationText']}>
            $14.542
          </Mui.Typography>
        </Mui.Box>
        <Mui.Box sx={sx['incentiveCheckGroupItem']}>
          <CheckboxLabel customerID={customerID} field="incentive_glow_cash" />
          <Mui.Typography component="span" sx={sx['calculationText']}>
            ${parseFloat((glowCash * 120).toFixed(2)).toLocaleString('es')}
          </Mui.Typography>
        </Mui.Box>
        <Mui.Box sx={sx['incentiveCheckGroupItem']}>
          <CheckboxLabel
            customerID={customerID}
            field="incentive_glow_tax_deduction"
          />
          <Mui.Typography component="span" sx={sx['calculationText']}>
            $6786.5
          </Mui.Typography>
        </Mui.Box>
      </Mui.FormGroup>

      {/* <Mui.Box sx={sx['calculationSection']}>
          <Mui.Typography component="span" sx={sx['calculationText']}>
            {carbonCredit}
          </Mui.Typography>
          <Pad amt={50} />
        

         
       
        </Mui.Box> */}
    </Mui.Box>
  )
})

interface CheckboxLabelProps {
  customerID: string
  field: keyof common.Customer
  hideCheckbox?: boolean
}

const CheckboxLabel = React.memo<CheckboxLabelProps>(
  ({ customerID, field, hideCheckbox }) => {
    const selectCustomerField = React.useMemo(
      (): ReturnType<typeof common.makeSelectCustomerField> =>
        common.makeSelectCustomerField(),
      [],
    )
    const selectCustomerFieldArgs = React.useMemo(
      (): common.SelectCustomerFieldParams => ({
        customerID,
        field,
      }),
      [customerID, field],
    )

    const currentValue = common.useSelector(
      (_): common.ValueOf<common.Customer> =>
        selectCustomerField(_, selectCustomerFieldArgs),
    )

    const [check, setCheck] = React.useState<0 | 1>(currentValue as 0 | 1)

    const handleChange = React.useCallback(
      (e) => {
        if (e.target.checked) {
          setCheck(1)
          common.updateCustomer(customerID, {
            [field]: 1,
          })
        } else {
          setCheck(0)
          common.updateCustomer(customerID, {
            [field]: 0,
          })
        }
      },
      [customerID, field],
    )
    React.useEffect(() => {
      setCheck(currentValue as 0 | 1)
    }, [currentValue])
    const isCheck = check === 1
    console.log('Is checked: ', isCheck, ' ', currentValue)
    const styleLabel = incentiveToStyleLabel[field] as keyof SxStyle
    return (
      <Mui.FormControlLabel
        checked={isCheck}
        control={
          <Mui.Checkbox
            color="default"
            sx={hideCheckbox ? sx['hide'] : sx['incentiveCheckbox']}
          />
        }
        label={common.fieldToLabel[field]}
        onChange={handleChange}
        sx={
          hideCheckbox
            ? sx['hideLabel']
            : (sx[styleLabel] as Mui.SxProps<Mui.Theme>)
        }
        value={check}
      />
    )
  },
)

const sx = {
  calculationSection: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  calculationText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: {
      lg: 'calc(1200px*0.014)',
      xs: '1.4vw',
    },
    fontWeight: '700',
    height: '100%',
  },

  checkgroupSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  hide: {
    display: 'none',
  },
  hideLabel: {
    '& .MuiFormControlLabel-label': {
      marginLeft: '32px',
      fontSize: {
        lg: 'calc(1200px*0.014)',
        xs: '1.4vw',
      },
      fontWeight: '700',
    },
  },
  incentiveCheckGroup: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  incentiveCheckGroupItem: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  incentiveCheckGroupLabelFederalTax: {
    '& .MuiFormControlLabel-label': {
      color: '#ffd500',
      fontSize: {
        lg: 'calc(1200px*0.014)',
        xs: '1.4vw',
      },
      fontWeight: '700',
    },
  },
  incentiveCheckGroupLabelGlowCash: {
    '& .MuiFormControlLabel-label': {
      color: '#00e676',
      fontSize: {
        lg: 'calc(1200px*0.014)',
        xs: '1.4vw',
      },
      fontWeight: '700',
    },
  },
  incentiveCheckGroupLabelTaxDeduction: {
    '& .MuiFormControlLabel-label': {
      color: '#ff4081',
      fontSize: {
        lg: 'calc(1200px*0.014)',
        xs: '1.4vw',
      },
      fontWeight: '700',
    },
  },
  incentiveCheckbox: {
    '& .MuiSvgIcon-root': {
      width: {
        lg: 'calc(1200px*0.014)',
        xs: '1.4vw',
      },
      height: {
        lg: 'calc(1200px*0.014)',
        xs: '1.4vw',
      },
    },
  },
  incentiveSection: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  incentiveSectionItems: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  incentiveSectionTitle: {
    alignSelf: 'flex-start',
    fontSize: {
      xs: '1.6vw',
      lg: 'calc(1200px*0.016)',
    },
    fontWeight: '700',
    textTransform: 'uppercase',
  },
}

type SxStyle = typeof sx

const incentiveToStyleLabel: common.DeepReadonly<
  Partial<Record<keyof common.Customer, keyof SxStyle>>
> = {
  incentive_glow_federal_tax_credit: 'incentiveCheckGroupLabelFederalTax',
  incentive_glow_cash: 'incentiveCheckGroupLabelGlowCash',
  incentive_glow_tax_deduction: 'incentiveCheckGroupLabelTaxDeduction',
}
