import React from 'react'

import { useParams } from 'react-router-dom'

import styles from './Debts.module.css'

import ConsolidationDebtsPage from '../../comps/ConsolidationDebtsPage'

export interface DebtsProps {}

export default React.memo<DebtsProps>(function Debts() {
  const { customerID } = useParams()

  return (
    <div className={styles['container']}>
      <ConsolidationDebtsPage customerID={customerID as string} />
    </div>
  )
})
