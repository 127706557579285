import React from 'react'

import * as common from '../../../common'
import * as r from '../../../react-utils'
import { DebtPayment, DebtPayments } from '../../../common'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'
import { ref, update } from 'firebase/database'
import { v1 } from 'uuid'

import { db } from '../../fire'

export interface DebtsPaymentsInputsProps {
  company: string
  customerID: string
  debtPayment: DebtPayment
  debtPayments: DebtPayments[]
  handleSubtractDebtPayment: (
    checked: boolean,
    debtRef: React.MutableRefObject<HTMLInputElement>,
    paymentRef: React.MutableRefObject<HTMLInputElement>,
    setSubtractDebt: React.Dispatch<React.SetStateAction<number>>,
    setSubtractPayment: React.Dispatch<React.SetStateAction<number>>,
  ) => void
  index: number
  len: number
  page: number
  setSubtractDebt: React.Dispatch<React.SetStateAction<number>>
  setSubtractPayment: React.Dispatch<React.SetStateAction<number>>
  totalDebt: number
  totalPayment: number
}

export default React.memo<DebtsPaymentsInputsProps>(
  function DebtsPaymentsInputs({
    company,
    customerID,
    debtPayment,
    debtPayments,
    handleSubtractDebtPayment,
    index,
    len,
    page,
    setSubtractDebt,
    setSubtractPayment,
    totalDebt,
    totalPayment,
  }) {
    const debtRef = React.useRef<HTMLInputElement>(
      document.createElement('input'),
    )

    const paymentRef = React.useRef<HTMLInputElement>(
      document.createElement('input'),
    )

    const [debt, setDebt, handleInputUpdateDebt] = r.useInputVal('')
    const [payment, setPayment, handleInputUpdatePayment] = r.useInputVal('')

    const handleSubtract = React.useCallback(
      (e) => {
        handleSubtractDebtPayment(
          e.target.checked,
          debtRef,
          paymentRef,
          setSubtractPayment,
          setSubtractDebt,
        )
      },
      [handleSubtractDebtPayment, setSubtractDebt, setSubtractPayment],
    )

    const updateDebt = React.useCallback(
      (e) => {
        const dp: DebtPayments[] = [
          ...debtPayments.map((value, i) => {
            if (i === page) {
              let temp = value.slice()
              temp[index] = {
                debt: e.target.value,
                payment: paymentRef.current.value,
                consolidation_debt_id: debtPayment.consolidation_debt_id,
                id: debtPayment.id,
              }
              return temp
            }
            return value
          }),
        ]
        update(ref(db, `/Debts/${customerID}`), {
          debtsPayments: JSON.stringify(dp),
        })
      },
      [customerID, debtPayment, debtPayments, index, page],
    )
    const updateDebtDebounce = React.useMemo(
      () => common.debounce(updateDebt),
      [updateDebt],
    )

    const updatePayment = React.useCallback(
      (e) => {
        const dp: DebtPayments[] = [
          ...debtPayments.map((value, i) => {
            if (i === page) {
              let temp = value.slice() as DebtPayments
              temp[index] = {
                debt: debtRef.current.value,
                payment: e.target.value,
                consolidation_debt_id: debtPayment.consolidation_debt_id,
                id: debtPayment.id,
              }
              return temp
            }

            return value
          }),
        ]
        update(ref(db, `/Debts/${customerID}`), {
          debtsPayments: JSON.stringify(dp),
        })
      },
      [
        customerID,
        debtPayment.consolidation_debt_id,
        debtPayment.id,
        debtPayments,
        index,
        page,
      ],
    )
    const updatePaymentDebounce = React.useMemo(
      () => common.debounce(updatePayment),
      [updatePayment],
    )

    const deleteRow = React.useCallback(() => {
      if (index === 0) return
      let dp = debtPayments
      const filter = dp[page]?.filter(
        (_, i) => i !== index,
      ) as unknown as DebtPayments
      dp[page] = filter
      update(ref(db, `/Debts/${customerID}`), {
        debtsPayments: JSON.stringify(dp),
      })
    }, [customerID, debtPayments, index, page])

    const addRow = React.useCallback(() => {
      let dp = debtPayments.slice()
      dp[page]?.push({
        debt: '',
        payment: '',
        consolidation_debt_id: debtPayment.consolidation_debt_id,
        id: v1(),
      })

      update(ref(db, `/Debts/${customerID}`), {
        debtsPayments: JSON.stringify(dp),
      })
    }, [customerID, debtPayment, debtPayments, page])

    React.useEffect(() => {
      setDebt(debtPayment.debt)
    }, [debtPayment, setDebt])

    React.useEffect(() => {
      setPayment(debtPayment.payment)
    }, [debtPayment, setPayment])

    const StyledInput = React.useMemo(() => {
      return styled.input`
        background-color: white;
        border: 1.3px solid transparent;
        color: black;
        height: 100%;
        width: 100%;
        outline: none;
        &:focus {
          border: 1.3px solid ${common.companyColors[company]};
        }
      `
    }, [company])

    return (
      <Box sx={sx['container']}>
        {/* ROW */}
        <Box sx={sx['row']}>
          {/* INPUT CONTAINER */}
          <Box sx={sx['inputContainer']}>
            {/* LABEL */}
            <Typography sx={index === 0 ? sx['label'] : sx['hidden']}>
              Debts
            </Typography>
            {/* INPUT */}
            <Box
              sx={
                index + 1 === len ? sx['borderBottom'] : sx['borderBottomHide']
              }
            >
              <Checkbox
                defaultChecked={true}
                onChange={handleSubtract}
                sx={sx['checkbox']}
              />
              <StyledInput
                onChange={updateDebtDebounce}
                // @ts-expect-error TODO
                onInput={handleInputUpdateDebt}
                ref={debtRef}
                type="number"
                value={debt}
              />
            </Box>

            <Box sx={index + 1 === len ? sx['totalDebtAmount'] : sx['hidden']}>
              {`Total: $${totalDebt.toLocaleString('en')}`}
            </Box>
          </Box>
          {/* INPUT CONTAINER */}
          <Box sx={sx['inputContainer']}>
            {/* LABEL */}
            <Typography sx={index === 0 ? sx['label'] : sx['hidden']}>
              Monthly Payments
            </Typography>
            {/* INPUT */}
            <Box
              sx={
                index + 1 === len
                  ? sx['borderBottomPayment']
                  : sx['borderBottomPaymentHide']
              }
            >
              <StyledInput
                onChange={updatePaymentDebounce}
                // @ts-expect-error TODO
                onInput={handleInputUpdatePayment}
                ref={paymentRef}
                type="number"
                value={payment}
              />
            </Box>

            <Box sx={index + 1 === len ? sx['totalDebtAmount'] : sx['hidden']}>
              {`Total: $${totalPayment.toLocaleString('en')}`}
            </Box>
          </Box>
          <Box sx={sx['deleteWrapper']}>
            <Box sx={index === 0 ? sx['deleteBoxPad'] : sx['hidden']} />

            <HighlightOffIcon sx={sx['deleteIcon']} onClick={deleteRow} />
            <Box
              sx={index + 1 === len ? sx['deleteIconBoxPad'] : sx['hidden']}
            />
          </Box>
        </Box>
        <Box sx={index + 1 === len ? sx['addIconWrapper'] : sx['hidden']}>
          <AddCircleOutlineIcon sx={sx['addIcon']} onClick={addRow} />
          <Box sx={sx['addIconEndBoxPad']} />
        </Box>
      </Box>
    )
  },
)

const sx = {
  addIcon: {
    color: '#4b4b4b',
    cursor: 'pointer',
    height: '20px',
    marginBottom: '10px',
    width: '20px',
  },

  addIconEndBoxPad: {
    alignSelf: 'flex-end',
    heigth: '24px',
    width: '24px',
  },

  addIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },

  borderBottom: {
    borderBottom: '1px solid rgb(133, 133, 133)',
    borderLeft: '1px solid rgb(133, 133, 133)',
    borderTop: '1px solid rgb(133, 133, 133)',
    height: '40px',
    position: 'relative',
    width: '160px',
  },

  borderBottomHide: {
    borderBottom: 'unset',
    borderLeft: '1px solid rgb(133, 133, 133)',
    borderTop: '1px solid rgb(133, 133, 133)',
    height: '40px',
    position: 'relative',
    width: '160px',
  },

  borderBottomPayment: {
    borderBottom: '1px solid rgb(133, 133, 133)',
    borderLeft: '1px solid rgb(133, 133, 133)',
    borderRight: '1px solid rgb(133, 133, 133)',
    borderTop: '1px solid rgb(133, 133, 133)',
    height: '40px',
    width: '160px',
  },

  borderBottomPaymentHide: {
    borderBottom: 'none',
    borderLeft: '1px solid rgb(133, 133, 133)',
    borderRight: '1px solid rgb(133, 133, 133)',
    borderTop: '1px solid rgb(133, 133, 133)',
    height: '40px',
    width: '160px',
  },

  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },

  checkbox: {
    left: -50,
    position: 'absolute',
    top: 'center',
    zIndex: 5,
  },

  deleteBoxPad: {
    display: 'unset',
    height: '24px',
  },

  deleteIcon: {
    color: '#4b4b4b',
    cursor: 'pointer',
    height: '20px',
    marginLeft: '4px',
    width: '20px',
  },

  deleteIconBoxPad: {
    height: '24px',
    display: 'unset',
  },

  deleteWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  row: {
    alignItems: 'center',
    display: 'flex',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  totalDebtAmount: {
    color: 'red',
    display: 'unset',
    fontWeight: '700',
    marginTop: '10px',
    overflow: 'hidden',
    textAlign: 'center',
    textOverflow: 'ellipsis',
  },

  label: {
    display: 'unset',
    fontWeight: '700',
    textAlign: 'center',
  },

  hidden: {
    display: 'none',
  },
}
