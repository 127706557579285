import React from 'react'

import * as common from '../../../common'

import * as Mui from '@mui/material'
import * as MuiIcons from '@mui/icons-material'

export interface AcGuidelineProps {
  customerID: string
}

export default React.memo<AcGuidelineProps>(function AcGuideline({
  customerID,
}) {
  const selectCustomerField = React.useMemo(
    (): ReturnType<typeof common.makeSelectCustomerField> =>
      common.makeSelectCustomerField(),
    [],
  )
  const selectCustomerFieldArgs = React.useMemo(
    (): common.SelectCustomerFieldParams => ({
      customerID,
      field: 'attic_square_footage',
    }),
    [customerID],
  )

  // Remember to subscribe to the customer elsewhere!
  const atticSquareFootage = common.useSelector(
    (_): common.ValueOf<common.Customer> =>
      selectCustomerField(_, selectCustomerFieldArgs),
  )

  const atticSquareFootageNumber = parseInt(
    (atticSquareFootage as string) || '0',
  )

  const recomendedToonage = atticSquareFootageNumber / 400

  return (
    <Mui.Box sx={sx['container']}>
      <Mui.Alert
        icon={<MuiIcons.InfoOutlined />}
        severity="warning"
        sx={atticSquareFootageNumber ? sx['hide'] : sx['alertTons']}
      >
        Input square meters of house to calculate tonnage
      </Mui.Alert>
      <Mui.Alert
        icon={<MuiIcons.InfoOutlined />}
        severity="info"
        sx={atticSquareFootageNumber ? sx['alertTons'] : sx['hide']}
      >
        {`Recommended tonnage for sq. ft. of house: ${recomendedToonage.toFixed(
          2,
        )} tons`}
      </Mui.Alert>
    </Mui.Box>
  )
})

const sx = {
  alertTons: {
    overflowY: 'hidden !important',
    textAlign: 'center',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: 298,
  },
  hide: {
    display: 'none',
  },
}
