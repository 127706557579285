import { Customer } from '../../../common'

export const IsAutoCalcPrice = (key: keyof Customer) => {
  if (
    key === 'attic_insulation' ||
    key === 'duct_seal' ||
    key === 'new_windows' ||
    key === 'smart_thermostat' ||
    key === 'main_panel_upgrade' ||
    key === 'air_conditioner' ||
    key === 'mini_split' ||
    'new_windows'
  ) {
    return true
  }

  return false
}
