import * as React from 'react'

import * as env from '../env'
import * as schema from '../schema'
import * as utils from '../utils'
import { Customer, CustomerSchema, MediaItem, MediaKind } from '../schema'
import { Writable, isBetween, keys, range, stringIsNumber } from '../utils'

export const fieldToLabel: Partial<Record<keyof Customer, string>> = {
  air_conditioner_cost: 'Cost',
  air_conditioner_customer_cost: 'Customer Cost',
  air_conditioner_replace_ducts: 'Are you replacing ducts?',
  air_conditioner_size: 'Size',
  air_conditioner: 'Air Conditioner',
  air_conditioner_working: 'Do you currently have an AC?',
  air_conditioner_new_tons: 'How many tons will the new AC be? ',
  air_conditioner_current_tons: 'How many tons is your current AC?',
  air_conditioner_unit_type: 'What type AC unit are you going to  install?',
  air_conditioner_explain_situation: 'Explain the situation of the home',
  air_conditioner_type_installation: 'Type of Installation',
  air_conditioner_any_swamp_cooler: 'Do you have any swamp cooler(s)?',
  air_conditioner_swamp_cooler_use: 'Are you using a Swamp Cooler?',
  air_conditioner_tons_how_many: 'How many tons will the new AC be?',
  air_conditioner_swamp_cooler_have_remove:
    'Would you be removing any swamp coolers?',
  air_conditioner_swamp_cooler_use_remove:
    'Would you be removing any swamp coolers?',
  air_conditioner_replace_curve: 'Are you changing the AC size?',
  air_conditioner_extra_notes: 'Custom Notes',
  attic_fan_cost: 'Cost',
  attic_fan_customer_cost: 'Customer Cost',
  attic_fan_quantity: 'How Many?',
  attic_fan: 'Attic Fan',
  attic_insulation_type: 'What type of insulation?',
  attic_insulation: 'Attic Insulation Needed',
  attic_insulation_how_much: 'How many sqft?',
  attic_insulation_cost: 'Cost',
  attic_insulation_customer_cost: 'Customer Cost',
  attic_square_footage: 'How many sqft?',
  attic_insulation_extra_notes: 'Custom Notes',
  battery_cost: 'Cost',
  battery_customer_cost: 'Customer Cost',
  battery_notes: 'Notes',
  battery_type: 'Select Battery Type',
  battery_size: 'Select Battery Size',
  battery_installation_company: 'Installer',
  battery: 'Battery',
  cash_cost: 'Cost',
  cash_amount: 'Cashback Amount',
  cash_customer_cost: 'Customer Cost',
  cash_pool_pumps: 'Pool Pumps',
  cash_heat_pumps: 'Heat Pumps',
  cash_whole_house_fans_or_ventilation: 'Whole House Fans / Ventilation',
  cash_efficient_boilers: 'Efficient Boilers',
  cash_geothermal_heat_pumps: 'Geothermal Heat Pumps',
  cash_radiant_floors: 'Radiant Floors',
  cash_water_saving_fixtures: 'Water Saving Fixtures',
  cash_solar_water_heaters: 'Solar Water Heaters',
  cash_on_demand_or_tankless_hot: 'On Demand / Tank-less Hot Water Heater',
  cash_efficient_water_heaters: 'Efficient Water Heaters',
  cash_artificial_turf: 'Artificial Turf',
  cash_irrigation_systems: 'Irrigation Systems',
  cash_windows: 'Windows',
  cash_air_conditioner: 'Air Conditioner',
  cash_wall_insulation: 'Wall Insulation',
  cash_floor_insulation: 'Floor Insulation',

  cash: 'Cash',
  cost: 'Cost',
  createdAt: 'Created at',
  custom_eight_fields_cost: 'Cost',
  custom_eight_fields_customer_cost: 'Customer Cost',
  custom_eight_fields_description: 'Description',
  custom_eight_fields_name: 'Name',
  custom_eight_fields: 'Custom 8',
  custom_fields_cost: 'Cost',
  custom_fields_customer_cost: 'Customer Cost',
  custom_fields_description: 'Description',
  custom_fields_name: 'Name',
  custom_fields: 'Custom 1',
  custom_five_fields_cost: 'Cost',
  custom_five_fields_customer_cost: 'Customer Cost',
  custom_five_fields_description: 'Description',
  custom_five_fields_name: 'Name',
  custom_five_fields: 'Custom 5',
  custom_four_fields_cost: 'Cost',
  custom_four_fields_customer_cost: 'Customer Cost',
  custom_four_fields_description: 'Description',
  custom_four_fields_name: 'Name',
  custom_four_fields: 'Custom 4',
  custom_nine_fields_cost: 'Cost',
  custom_nine_fields_customer_cost: 'Customer Cost',
  custom_nine_fields_description: 'Description',
  custom_nine_fields_name: 'Name',
  custom_nine_fields: 'Custom 9',
  custom_seven_fields_cost: 'Cost',
  custom_seven_fields_customer_cost: 'Customer Cost',
  custom_seven_fields_description: 'Description',
  custom_seven_fields_name: 'Name',
  custom_seven_fields: 'Custom 7',
  custom_six_fields_cost: 'Cost',
  custom_six_fields_customer_cost: 'Customer Cost',
  custom_six_fields_description: 'Description',
  custom_six_fields_name: 'Name',
  custom_six_fields: 'Custom 6',
  custom_ten_fields_cost: 'Cost',
  customer_id_number: 'ID Number',
  customer_initials: 'Customer Initials',
  custom_ten_fields_customer_cost: 'Customer Cost',
  custom_ten_fields_description: 'Description',
  custom_ten_fields_name: 'Name',
  custom_ten_fields: 'Custom 10',
  custom_three_fields_cost: 'Cost',
  custom_three_fields_customer_cost: 'Customer Cost',
  custom_three_fields_description: 'Description',
  custom_three_fields_name: 'Name',
  custom_three_fields: 'Custom 3',
  custom_two_fields_cost: 'Cost',
  custom_two_fields_customer_cost: 'Customer Cost',
  custom_two_fields_description: 'Description',
  custom_two_fields_name: 'Name',
  custom_two_fields: 'Custom 2',
  customer_cost: 'Customer Cost',
  customerAddress: 'Customer Address',
  customerEmail: 'Customer Email',
  customerName: 'Customer Name',
  customerPhone: 'Customer Phone',
  customerPhoneAlt: 'Alt Phone Number (Optional)',
  date: 'Created At',
  derate: 'Derate',
  derate_cost: 'Cost',
  derate_customer_cost: 'Customer Cost',
  derate_notes: 'Notes',
  duct_insulation_cost: 'Cost',
  duct_insulation_customer_cost: 'Customer Cost',
  duct_insulation: 'Duct Insulation',
  duct_repair_cost: 'Cost',
  duct_repair_customer_cost: 'Customer Cost',
  duct_repair_quantity: 'How Many?',
  duct_repair: 'Duct Repair',
  duct_seal_cost: 'Cost',
  duct_seal_extra_notes: 'Custom Notes',
  duct_seal_customer_cost: 'Customer Cost',
  duct_seal: 'Duct Seal',
  flat_roof_panels: 'Flat Roof Panels',
  flat_roof_panels_cost: 'Cost',
  flat_roof_panels_customer_cost: 'Customer Cost',
  flat_roof_panels_how_many: 'How Many?',
  globalNotes: 'Global Customer Notes',
  glow: 'Protocol Fee (Glow Gold)',
  glow_cost: 'Cost',
  glow_customer_cost: 'Customer Cost',
  glow_price_per_watt: 'Price Per Kw/h from the utility bill',
  glow_power_output_mw: 'System Size in Kw',
  incentive_adders_description: 'Incentive/Adders Description',
  main_panel_upgrade_cost: 'Cost',
  main_panel_upgrade_customer_cost: 'Customer Cost',
  main_panel_upgrade: 'Main Panel Upgrade (MPU)',
  main_panel_upgrade_needed_or_requested: 'Is needed or requested by customer?',
  main_panel_upgrade_installation_company: 'Installer',
  mini_split: 'Mini Split',
  mini_split_ton1: 'Mini Split System 1Ton',
  mini_split_ton2: 'Mini Split System 2Ton',
  mini_split_cost: 'Cost',
  mini_split_customer_cost: 'Customer Cost',
  manufactured_home_notes: 'Manufactured Home Notes',
  new_ducts_cost: 'Cost',
  new_ducts_customer_cost: 'Customer Cost',
  new_ducts_description: 'Description',
  new_ducts_quantity: 'How Many?',
  new_ducts_replace: 'Replacing Ducts?',
  new_ducts: 'New Ducts',
  new_ducts_extra_notes: 'Custom Notes',
  // #region new_windows
  new_windows_areas: 'What Areas?',
  new_windows_color: 'Window Colors',
  new_windows_cost: 'Cost',
  new_windows_customer_cost: 'Customer Cost',
  new_windows_grid: 'Grid Windows?',
  new_windows_quantity: 'Total Number of Windows',
  new_windows_sqft_each_window: 'Sqft of each window?',
  new_windows_sliding_glass_sqft: 'Sqft of each sliding glass door?',
  new_windows_sliding_glass: 'Sliding Glass Doors included?',
  new_windows_sliding_glass_how_many:
    'Total Number of Glass Sliding Doors included:',
  new_windows_sqft: 'How Much Square Footage?',
  new_windows_replace: 'Are you replacing the windows on the whole house?',
  new_windows_replace_custom:
    'What areas of home windows will be installed in detail?',
  new_windows_notes: 'Notes',
  new_windows_extra_notes: 'Custom Notes',
  new_windows: 'Windows & Sliding Glass Doors',
  new_windows_california_city: 'Choose a city',
  new_windows_sliding_glass_size: 'Sliding glass door Size',
  newWindows: 'Windows & Sliding Glass Doors',
  // #endregion new_windows
  payment_given_customer: 'Payment Given to Customer',
  pool_pump_cost: 'Cost',
  pool_pump_customer_cost: 'Customer Cost',
  pool_pump: 'Pool Pump',
  roof_cost: 'Cost',
  roof_california_city: 'Choose a city',
  roof_customer_cost: 'Customer Cost',
  roof_square_footage: 'Roof Squares',
  roof_layers_how_many: 'How many shingle layers does the roof currently have?',
  roof_fascia_included: 'Are we including fascia?',
  roof_fascia_included_square_footage:
    'How many linear feet are we going to included?',
  roof_extra_notes: 'Custom Notes',
  roof_layover_or_tear: 'Choose type of roof work',
  roof_patio_included: 'Are we including a patio?',
  roof_patio_how_many: 'How many sqft of torch down?',
  roof_plywood_replaced: 'Will you be replacing all the plywood?',
  roof_plywood_replaced_square: 'How many squares?',
  roof: 'Roof',
  // Closer
  homeRep: 'Eco Home Rep (Closer)',
  smart_thermostat_cost: 'Cost',
  smart_thermostat_customer_cost: 'Customer Cost',
  smart_thermostat_how_many: 'How Many?',
  smart_thermostat: 'Smart Thermostat',
  smart_thermostat_extra_notes: 'Custom Notes',
  solar_tax_cost: 'Cost',
  solar_tax_customer_cost: 'Customer Cost',
  solar_tax: 'Solar Tax Consultant',
  solarCompany: 'Solar Company',
  solarEmail: 'Solar Rep Email',
  // Setter
  solarRep: 'Solar Rep (Setter)',
  sort_key: 'Last Update',
  sub_panel_upgrade: 'Sub Panel Upgrade',
  sub_panel_upgrade_installation_company: 'Installer',
  sub_panel_upgrade_cost: 'Cost',
  sub_panel_upgrade_customer_cost: 'Customer Cost',
  sub_panel_upgrade_notes: 'Sub Panel Upgrade Notes',
  site_survey_notes: 'Site Survey Notes',
  ssn: 'SSN',
  system_size: 'System Size',
  small_system: 'Small System',
  small_system_cost: 'Cost',
  small_system_customer_cost: 'Customer Cost',
  wall_insulation_cost: 'Cost',
  wall_insulation_customer_cost: 'Customer Cost',
  wall_insulation_feet: 'Linear Feet',
  wall_insulation: 'Wall Insulation',
  yearHomeBuilt: 'Year Home Was Built',
  panel_removal: 'Panel Removal',
  panel_removal_how_many: 'How many panels do you need to remove?',
  panel_removal_cost: 'Cost',
  panel_removal_customer_cost: 'Customer Cost',
  panel_removal_notes: 'Notes',

  // Checklist

  attach_thermal_camera: 'Attach thermal camera to the phone',

  take_picture_of_home: 'Take picture of home',
  get_debts_report: 'Get debts report',

  perform_energy_assessment: 'Perform energy assessment',
  /**/ use_thermal_camera: 'Use thermal camera',
  /**/ take_quiz: 'Take survey',
  /**/ use_room_that_gets_hot: 'Room that gets hot',
  /**/ use_smoke_pen: 'Use smoke pen',

  take_pictures: 'Take pictures',

  fill_out_prices: 'Fill out prices',

  verify_pricing_or_ppw: 'Verify pricing/PPW (info)',

  show_ecohome_report: `Show ${env.HOLDING_NAME} report`,

  show_solo_report: 'Show Solo report',

  /**/ /**/

  run_credit: 'Run credit',

  change_to_correct_email: 'Change to correct email',

  sign_financing_agreement: 'Sign financing agreement (info)',
  /**/ sign_cosigner: 'Sign co-signer',

  submit_install_docs_in_solo: 'Submit install docs in Solo (info)',
  /**/ counter_sign_rep_install_doc: 'Counter-sign rep install doc (info)',
  /**/ sign_customer_install_docs: 'Sign customer install docs (info)',

  ecohome_report: `${env.HOLDING_NAME} report`,
  /**/ make_sure_ecohome_and_solo_match:
    'Make Sure Ecohome/Solo #s match (info)',
  /**/ take_picture_of_utility_bill: 'Take picture of utility bill',
  /**/ take_picture_of_id: 'Take picture of ID',
  /**/ take_pictures_of_meter: 'Take pictures of meter (example)',
  /**/ submit_detailed_notes_for_efficiencies:
    'Submit detailed notes for efficiencies (info)',
  /**/ review_notes_with_customers: 'Review notes w/ customers',
  /**/ sign_ecohome_report: `Sign ${env.HOLDING_NAME} report`,
  /**/ get_google_review_and_or_pic_with_customer:
    'Get a 5-star Google Review from the client',
  /**/ take_pic_with_customer: 'Take picture with customer',
  /**/ offer_incentives_for_referrals: 'Offer incentives for referrals',

  update_notes_on_HL: 'Update notes on HL',

  report_sale_to_slack: 'Report sale to Slack!',

  // End Checklist
  // Roofing Claiming

  roof_claiming_date: 'Date',
  roof_claiming_homeowner: 'Homeowner',
  roof_claiming_phone: 'Phone',
  roof_claiming_phone_alt: 'Alt Phone',
  roof_claiming_email: 'Email',
  roof_claiming_address: 'Address',
  roof_claiming_city: 'City',
  roof_claiming_zip: 'Zip',
  roof_claiming_deductible: 'Deductible',
  roof_claiming_shingle_manufacturer: 'Shingle Manufacturer',
  roof_claiming_style: 'Style',
  roof_claiming_color: 'Color',
  roof_claiming_drip_color: 'Drip Color',

  roof_claiming_tear_off_layers_shingles: 'Tear off all layers of shingles',
  roof_claiming_install_felt: 'Install Felt',
  roof_claiming_close_valleys: 'Close Valleys ',
  roof_claiming_ridges_color_coordinated: 'Ridges Color Coordinated',
  roof_claiming_install_new_flashings: 'Install New Flashings',
  roof_claiming_replace_ventilation: 'Replace ventilation as needed',
  roof_claiming_install_nails: 'Install nails per code',
  roof_claiming_clean_job_waste_gutters: 'Clean job waste from gutters',
  roof_claiming_two_year_workmanship_warranty: '2 year workmanship warranty ',
  roof_claiming_magnetic_sweep_property:
    'Magnetic sweep property after install ',

  roof_claiming_age: 'Age',
  roof_claiming_layers: 'Layers',
  roof_claiming_predominate_pitch: 'Predominate Pitch',

  roof_claiming_pipe_jack: 'Piple Jack (QTY)',
  roof_claiming_chimney_flashing: 'Chimney Flashing (QTY)',
  roof_claiming_chimney_cap: 'Chimney Cap (QTY)',
  roof_claiming_digital_satellite: 'Digital Satellite (QTY)',
  roof_claiming_gas_cap: 'Gas Vent/Rain Cap (QTY)',
  roof_claiming_other_one: 'Other',

  roof_claiming_turtle_vent: 'Turtle Vent (QTY)',
  roof_claiming_ridge_vent: 'Ridge Vent (QTY)',
  roof_claiming_turbine_vent: 'Turbine Vent (QTY)',
  roof_claiming_power_attic_vent: 'Power Attic Vent (QTY)',
  roof_claiming_other_two: 'Other',
  roof_claiming_other_three: 'Other',

  roof_claiming_notes: 'Roof Claiming Notes',
  roof_claiming_documents_notes: 'Notes',

  roof_claiming_date_loss: 'Date of Loss',
  roof_claiming_insurance_company: 'Insurance Company',
  roof_claiming_claim: 'Claim #',

  roof_claiming_client_sign: 'Client(s) Signature',
  roof_claiming_company_rep_sign: 'Company Signature',

  roof_claiming_printed_name: 'Printed Name',
  roof_claiming_completion_client_sign: 'Client(s) Signature',
  roof_claiming_company_rep_sign_date: 'Date',
  roof_claiming_completion_client_sign_date: 'Date',

  // End Roofing Claiming

  qualify_glow_birth_date: 'Birth Date',
  qualify_glow_city: 'City',
  qualify_glow_email: 'Email',
  qualify_glow_first_name: 'First Name',
  qualify_glow_last_name: 'Last Name',
  qualify_glow_phone_number: 'Phone Number',
  qualify_glow_ssn: 'Last 4 Digits or Full SSN',
  qualify_glow_state: 'State',
  qualify_glow_street: 'Street',
  qualify_glow_zip: 'Zip',

  incentive_glow_carbon_credits: 'Carbon Credits Produce',
  incentive_glow_cash: 'Glow Cash',
  incentive_glow_tax_deduction: 'Tax Deduction',
  incentive_glow_federal_tax_credit: 'Federal Tax Credit',
}

export type CustomerKeyArr = readonly (keyof Customer)[]

export type InputConfig = keyof Customer | CustomerKeyArr

export const subEfficiencyToInputs: Partial<
  Record<keyof Customer, readonly InputConfig[]>
> = {
  new_windows_replace: ['new_windows_replace_custom'],
  new_windows_sliding_glass: ['new_windows_sliding_glass_sqft'],
  roof_fascia_included: ['roof_fascia_included_square_footage'],
  roof_patio_included: ['roof_patio_how_many'],
  roof_plywood_replaced: ['roof_plywood_replaced_square'],
}

// costs slice will break if only cost fields stop being sub-arrays
export const efficiencyToInputs: Partial<
  Record<keyof Customer, readonly InputConfig[]>
> = {
  attic_insulation: [
    'attic_insulation_type',
    'attic_insulation_how_much',
    ['attic_insulation_cost', 'attic_insulation_customer_cost'],
    'attic_insulation_notes',
    'attic_insulation_extra_notes',
  ],
  roof: [
    'roof_square_footage',
    'roof_layover_or_tear',
    'roof_layers_how_many',
    'roof_fascia_included',
    'roof_patio_included',
    'roof_plywood_replaced',
    ['roof_cost', 'roof_customer_cost'],
    'roof_notes',
    'roof_extra_notes',
  ],

  new_windows: [
    'new_windows_california_city',
    'new_windows_sqft_each_window',
    'new_windows_color',
    'new_windows_grid',
    'new_windows_sliding_glass',
    'new_windows_replace',
    ['new_windows_cost', 'new_windows_customer_cost'],
    'new_windows_notes',
    'new_windows_extra_notes',
  ],

  air_conditioner: [
    'air_conditioner_working',
    'air_conditioner_swamp_cooler_use',
    'air_conditioner_swamp_cooler_use_remove',
    'air_conditioner_explain_situation',
    'air_conditioner_current_tons',
    'air_conditioner_new_tons',
    'air_conditioner_unit_type',
    'air_conditioner_swamp_cooler_have_remove',
    'air_conditioner_replace_ducts',
    ['air_conditioner_cost', 'air_conditioner_customer_cost'],
    'air_conditioner_notes',
    'air_conditioner_extra_notes',
  ],
  mini_split: [
    'mini_split_tons',
    ['mini_split_cost', 'mini_split_customer_cost'],
    'mini_split_notes',
  ],
  main_panel_upgrade: [
    'main_panel_upgrade_needed_or_requested',
    'main_panel_upgrade_installation_company',
    ['main_panel_upgrade_cost', 'main_panel_upgrade_customer_cost'],
    'main_panel_upgrade_notes',
  ],
  derate: [['derate_cost', 'derate_customer_cost'], 'derate_notes'],
  smart_thermostat: [
    'smart_thermostat_how_many',
    ['smart_thermostat_cost', 'smart_thermostat_customer_cost'],
    'smart_thermostat_notes',
    'smart_thermostat_extra_notes',
  ],

  attic_fan: [
    'attic_fan_quantity',
    ['attic_fan_cost', 'attic_fan_customer_cost'],
    'attic_fan_notes',
  ],
  pool_pump: [['pool_pump_cost', 'pool_pump_customer_cost'], 'pool_pump_notes'],

  wall_insulation: [
    'wall_insulation_feet',
    ['wall_insulation_cost', 'wall_insulation_customer_cost'],
    'wall_insulation_notes',
  ],

  duct_repair: [
    'duct_repair_quantity',
    ['duct_repair_cost', 'duct_repair_customer_cost'],
    'duct_repair_notes',
  ],
  duct_seal: [
    ['duct_seal_cost', 'duct_seal_customer_cost'],
    'duct_seal_notes',
    'duct_seal_extra_notes',
  ],
  duct_insulation: [
    ['duct_insulation_cost', 'duct_insulation_customer_cost'],
    'duct_insulation_notes',
  ],
  cash: ['cash_amount', ['cash_cost', 'cash_customer_cost'], 'cash_notes'],
  solar_tax: [['solar_tax_cost', 'solar_tax_customer_cost'], 'solar_tax_notes'],

  small_system: [
    ['small_system_cost', 'small_system_customer_cost'],
    'small_system_notes',
  ],

  flat_roof_panels: [
    'flat_roof_panels_how_many',
    ['flat_roof_panels_cost', 'flat_roof_panels_customer_cost'],
    'flat_roof_panels_notes',
  ],

  panel_removal: [
    'panel_removal_how_many',
    ['panel_removal_cost', 'panel_removal_customer_cost'],
    'panel_removal_notes',
  ],
  sub_panel_upgrade: [
    'sub_panel_upgrade_installation_company',
    ['sub_panel_upgrade_cost', 'sub_panel_upgrade_customer_cost'],
    'sub_panel_upgrade_notes',
  ],

  glow: [
    'glow_price_per_watt',
    'glow_power_output_mw',
    ['glow_cost', 'glow_customer_cost'],
    'glow_notes',
  ],

  battery: [
    'battery_installation_company',
    'battery_type',
    'battery_size',
    ['battery_cost', 'battery_customer_cost'],
    'battery_notes',
  ],

  custom_fields: [
    'custom_fields_name',
    'custom_fields_description',
    ['custom_fields_cost', 'custom_fields_customer_cost'],
    'custom_fields_notes',
  ],
  custom_two_fields: [
    'custom_two_fields_name',
    'custom_two_fields_description',
    ['custom_two_fields_cost', 'custom_two_fields_customer_cost'],
    'custom_two_fields_notes',
  ],
  custom_three_fields: [
    'custom_three_fields_name',
    'custom_three_fields_description',
    ['custom_three_fields_cost', 'custom_three_fields_customer_cost'],
    'custom_three_fields_notes',
  ],
  custom_four_fields: [
    'custom_four_fields_name',
    'custom_four_fields_description',
    ['custom_four_fields_cost', 'custom_four_fields_customer_cost'],
    'custom_four_fields_notes',
  ],
  custom_five_fields: [
    'custom_five_fields_name',
    'custom_five_fields_description',
    ['custom_five_fields_cost', 'custom_five_fields_customer_cost'],
    'custom_five_fields_notes',
  ],
  custom_six_fields: [
    'custom_six_fields_name',
    'custom_six_fields_description',
    ['custom_six_fields_cost', 'custom_six_fields_customer_cost'],
    'custom_six_fields_notes',
  ],
  custom_seven_fields: [
    'custom_seven_fields_name',
    'custom_seven_fields_description',
    ['custom_seven_fields_cost', 'custom_seven_fields_customer_cost'],
    'custom_seven_fields_notes',
  ],
  custom_eight_fields: [
    'custom_eight_fields_name',
    'custom_eight_fields_description',
    ['custom_eight_fields_cost', 'custom_eight_fields_customer_cost'],
    'custom_eight_fields_notes',
  ],
  custom_nine_fields: [
    'custom_nine_fields_name',
    'custom_nine_fields_description',
    ['custom_nine_fields_cost', 'custom_nine_fields_customer_cost'],
    'custom_nine_fields_notes',
  ],
  custom_ten_fields: [
    'custom_ten_fields_name',
    'custom_ten_fields_description',
    ['custom_ten_fields_cost', 'custom_ten_fields_customer_cost'],
    'custom_ten_fields_notes',
  ],
}

// TODO: Do this via database
if (env.IS_WHITE_LABEL) {
  if (env.CODENAME === 'glow') {
    const allFields = utils.keys(efficiencyToInputs)
    const nonGlowFields = allFields.filter(
      (field) => !schema.glowFields.includes(field),
    )
    for (const field of nonGlowFields) {
      delete efficiencyToInputs[field]
    }
  } else {
    for (const field of schema.mainOnlyFields) {
      delete efficiencyToInputs[field]
    }
  }
}

export const getFieldLabel = (field: keyof Customer): string => {
  if (field === 'homeRep' && env.CODENAME === 'glow') {
    return 'Sales Representative'
  }
  const maybeLabel = fieldToLabel[field]

  if (maybeLabel) return maybeLabel
  if (field.toLowerCase().includes('notes')) return 'Notes'
  return field
}

export const getMediaItemSrc = (mediaItem: MediaItem): string => {
  return mediaItem.status === 'uploaded'
    ? 'https://firebasestorage.googleapis.com/v0/b/' +
        env.REACT_APP_FIREBASE_PROJECT_ID +
        '.appspot.com/o/' +
        mediaItem.order_id +
        '%2F' +
        // TODO: thumbnail
        mediaItem.name +
        `?alt=media&token=dummy`
    : 'file://' + mediaItem.path
}

export const fieldToRadioOpts = (
  field: keyof Customer,
  customer?: Customer,
): readonly schema.Opt[] => {
  if (field === 'roof_layers_how_many') {
    return customer?.roof_layover_or_tear === 'Layover'
      ? schema.roofLayersHowManyMinOpts
      : schema.roofLayersHowManyOpts
  }
  return []
}

export const qualifyGlowPrimaryAplicantInputs: CustomerKeyArr[] = [
  ['qualify_glow_first_name', 'qualify_glow_last_name'],
  ['qualify_glow_phone_number', 'qualify_glow_email'],
  ['qualify_glow_ssn', 'qualify_glow_birth_date'],
]

export const qualifyAddressInfoInputs: CustomerKeyArr[] = [
  ['qualify_glow_street'],
  ['qualify_glow_city', 'qualify_glow_state', 'qualify_glow_zip'],
]

export const normalizeThumbnail = (
  customerID: string,
  thumbnail: string,
): string => {
  if (thumbnail === 'default_house.png') {
    return ''
  }
  if (thumbnail.length === 0) {
    return ''
  }
  if (thumbnail.startsWith('http')) {
    return thumbnail
  }
  if (thumbnail.startsWith('data:image/')) {
    return thumbnail
  }
  // Base64 jpg
  if (thumbnail.startsWith('/9j/')) {
    return 'data:image/jpeg;base64,' + thumbnail
  }
  // Base64 png
  if (thumbnail.startsWith('iVBO')) {
    return 'data:image/png;base64,' + thumbnail
  }

  return (
    'https://firebasestorage.googleapis.com/v0/b/' +
    env.REACT_APP_FIREBASE_PROJECT_ID +
    '.appspot.com/o/' +
    customerID +
    '%2F' +
    thumbnail +
    '?alt=media&token=69e431fa-59ad-497a-b700-a3aebcc68fc6'
  )
}

export const mediaToLabel: Partial<Record<MediaKind, string>> = {
  owner_house: 'House View from Street',
  thermal: 'Thermal Camera',
  insulation: 'Attic',
  roof: 'Roof',
  windows: 'Windows',
  problem: 'Other Areas',
  hvac_system: 'HVAC',
  electrical: 'Electrical Panel',
  electricity: 'Electricity Bill',
  id: 'ID Picture',
  adders: 'Adjustment Screenshots',
  other_documents: 'Other Documents',
  solar_proposal: 'Solar Proposal',
}

if (env.IS_WHITE_LABEL) {
  if (env.CODENAME === 'glow') {
    const allKinds = utils.keys(mediaToLabel)
    const nonGlowKinds = allKinds.filter(
      (field) => !schema.glowMedia.includes(field),
    )
    for (const field of nonGlowKinds) {
      delete mediaToLabel[field]
    }
  } else {
    for (const mediaKind of schema.mainOnlyMedia) {
      delete mediaToLabel[mediaKind]
    }
  }
}

export const mediaToLabelSiteSurvey: Partial<Record<MediaKind, string>> = {
  all_exterior_walls_site_survey: 'All exterior walls',
  rafters_site_survey: 'Rafters',
  electrical_panel_site_survey: 'Electrical Panel',
  all_planes_roof_site_survey: 'All planes roof',
  other_site_survey: 'Other',
}

export const mediaToLabelRoofClaims: Partial<Record<MediaKind, string>> = {
  rc_front_house: 'Front of the house',
  rc_address_verification: 'Address Verification',
  rc_3d_view: '3D View',
  rc_garage: 'Garage',
  rc_wires_home: 'Wires in to home',
  rc_siding: 'Siding',
  rc_layers: 'Shingle layers',
  rc_roof_inclination: 'Roof inclination angle',
  rc_roof_overview: 'Roof Overview',
  rc_drip_edge: 'Drip edge',
  rc_gutters: 'Gutters',
  rc_ice_shield: 'Ice Shield',
  rc_flashings: 'Flashings',
  rc_penetrations: 'Penetrations',
  rc_hail: 'Hail',
  rc_other_property_wind_damage: 'Property damages',
  rc_wind_damages: 'Wind damages',
  rc_length_shingle: 'Shingle length',
  rc_shingles_color: 'Shingles color',
}

export const mediaToLabelManufacturedHome: Partial<Record<MediaKind, string>> =
  {
    foundation_pic_mh: 'Foundation Pictures',
    hud_plate_mh: 'HUD Plate',
    install_certificate_mh: 'Install Certificate',
    deal_certificate_mh: 'Deal Certificate',
    property_title_mh: 'Property Title',
  }

const fieldToPlaceholder: Partial<Record<keyof Customer, string>> = {
  air_conditioner_size: '5 tons',
  attic_square_footage: '2100',
  attic_insulation_type: 'R-30',
  customerAddress: '611 Harvard Ave, E',
  customerEmail: 'customer@email.com',
  customerName: 'John Doe',
  customerPhone: '(555) 873-9483',
  customerPhoneAlt: '(555) 873-9483',
  homeRep: 'Cesar Barron',
  new_ducts_description: 'Extending/connecting ducts to 2 bedrooms',
  new_windows_areas: 'Bedroom, Hall, Attic.. etc.',
  new_windows_sliding_glass: 'Yes or No',
  solarCompany: env.COMPANY_LABELS[0],
  solarEmail: 'solar@email.com',
  solarRep: 'Jessica Gutierrez',
  ssn: 'Social Security Number',

  yearHomeBuilt: '2001',
  globalNotes: 'Type here',
}

export const getDescriptionValidationGuideline = (
  field: keyof Customer,
  customer?: Customer,
): string => {
  if (
    field === 'air_conditioner_new_tons' &&
    (isNaN(Number(customer?.attic_square_footage)) ||
      !customer?.attic_square_footage)
  ) {
    return 'Input square meters of house to calculate tonnage'
  } else if (
    field === 'air_conditioner_new_tons' &&
    !isNaN(Number(customer?.attic_square_footage))
  ) {
    return `Recommended tonnage for sq. ft. of house: ${(
      Number(customer?.attic_square_footage) / 400
    ).toFixed(2)} tons`
  } else if (
    field === 'roof_layers_how_many' &&
    !isNaN(Number(customer?.roof_layers_how_many)) &&
    Number(customer?.roof_layers_how_many) === 3 &&
    customer?.roof_layover_or_tear === 'Layover'
  ) {
    return 'For more than 2 layers you need to do a tear off'
  }
  // #endregion
  if (
    field === 'battery_size' &&
    customer?.battery_type &&
    customer?.battery_installation_company
  ) {
    return 'Battery size should be bigger than the system'
  }
  return ''
}

export const getFieldPlaceholder = (field: keyof Customer): string => {
  const f = field.toLowerCase()

  if (f.endsWith('cost')) {
    return '3400'
  }

  if (f.endsWith('footage')) {
    return '1170'
  }

  if (
    f.endsWith('much') ||
    f.endsWith('quantity') ||
    f.endsWith('ft') ||
    f.endsWith('feet') ||
    f.endsWith('many') ||
    f.endsWith('footage')
  ) {
    return '100'
  }

  if (f.endsWith('fields_description')) {
    return 'Custom Description'
  }

  if (f.endsWith('fields_name')) {
    return 'Custom Name'
  }

  if (f.endsWith('saving') || f.endsWith('usage')) {
    return '"20%-30%" or "50%"..etc.'
  }

  if (f.endsWith('_notes')) {
    return 'Type here'
  }

  return fieldToPlaceholder[field] || ''
}

export const disableInputs: utils.DeepReadonly<
  Partial<Record<keyof Customer, boolean>>
> = {
  air_conditioner_cost: true,
  air_conditioner_notes: true,
  attic_fan_cost: true,
  attic_insulation_cost: true,
  attic_insulation_notes: true,
  cash_cost: true,
  date: true,
  flat_roof_panels_cost: true,
  glow_cost: true,
  glow_notes: true,
  mini_split_cost: true,
  new_ducts_notes: true,
  new_windows_cost: true,
  new_windows_notes: true,
  new_windows_quantity: true,
  panel_removal_cost: true,
  panel_removal_notes: true,
  panel_removal: true,
  roof_notes: true,
  small_system_cost: true,
  smart_thermostat_cost: true,
  smart_thermostat_notes: true,
  solar_tax_cost: true,
  sort_key: true,
  sub_panel_upgrade_cost: true,
}

export const shouldDisableInput = (
  field: schema.CustomerField,
  { solarCompany }: schema.Customer,
  efficiencyPrices: utils.DeepReadonly<schema.SaleTag>,
): boolean => {
  const isHIL = solarCompany.endsWith('_HIL')
  const isCustomerCost = field.endsWith('_customer_cost')
  const isCost = field.endsWith('_cost')
  const efficiencyField = subFieldToEfficiencyField(field)

  // TODO:TEMP GOOGLE FIX
  if (isCost) {
    return false
  }

  if (isHIL && isCustomerCost) {
    if (!efficiencyField) {
      throw new ReferenceError(
        `No efficiency field for customer cost ${field}?`,
      )
    }
    return (
      Boolean(efficiencyPrices[efficiencyField]) ||
      // Special case fields where the data does not live in the excel
      field === 'air_conditioner_customer_cost' ||
      field === 'mini_split_customer_cost'
    )
  }
  // if (isHIL && isCost) {
  //   return false
  // }

  return Boolean(disableInputs[field])
}

export const shouldEnableInput = (
  field: schema.CustomerField,
  customer: schema.Customer,
  efficiencyPrices: utils.DeepReadonly<schema.SaleTag>,
): boolean => !shouldDisableInput(field, customer, efficiencyPrices)

export const parseCashbackOptions = (options: string): schema.Opt[] => {
  try {
    const _options = JSON.parse(options) as unknown as schema.Opt[]

    return _options
  } catch (e) {
    return []
  }
}

export const getTotalCashback = (options: schema.Opt[], customer: Customer) => {
  let total = 0

  if (options.length) {
    options.forEach((key) => {
      if (customer[key.value as keyof Customer]) {
        total += parseInt(customer[key.value as keyof Customer] as string)
      }
    })
  }
  return total
}

export const isLayover = (value: string) => {
  if (value && value === 'Layover') {
    return true
  }

  return false
}

export const showIsNo = (state: boolean, field: keyof Customer) => {
  if (field === 'new_windows_replace') {
    return !state
  }

  return state
}

export const checkToShowAttr = (
  checkAttribute: keyof Customer,
): keyof Customer => {
  // TODO: Standardize attribute names so all of this is not needed.
  const firstWord = checkAttribute.split('_')[0]
  if (!firstWord) {
    throw new Error(
      `checkToShowAttr() -> Could not extract first word from: ${checkAttribute}`,
    )
  }
  const resultingAttribute = keys(CustomerSchema).find(
    (attr) => attr.includes(firstWord) && attr.includes('show'),
  )

  const resultIsValid =
    resultingAttribute && resultingAttribute in CustomerSchema

  if (resultIsValid) {
    return resultingAttribute as keyof Customer
  }

  throw new TypeError(
    `costToShow() -> resulting attribute not valid based on check attribute: ${checkAttribute}`,
  )
}

export type AirConditionerOptions =
  | 'air_conditioner_current_tons'
  | 'air_conditioner_how_many_tons'
  | 'air_conditioner_explain_situation'
  | 'air_conditioner_new_tons'
  | 'air_conditioner_replace_ducts'
  | 'air_conditioner_swamp_cooler_have_remove'
  | 'air_conditioner_swamp_cooler_use_remove'
  | 'air_conditioner_swamp_cooler_use'
  | 'air_conditioner_tons_how_many'
  | 'air_conditioner_type_installation'
  | 'air_conditioner_unit_type'

export const isAirOptions = (key: unknown): key is AirConditionerOptions => {
  if (
    key === 'air_conditioner_current_tons' ||
    key === 'air_conditioner_how_many_tons' ||
    key === 'air_conditioner_explain_situation' ||
    key === 'air_conditioner_new_tons' ||
    key === 'air_conditioner_replace_ducts' ||
    key === 'air_conditioner_swamp_cooler_have_remove' ||
    key === 'air_conditioner_swamp_cooler_use_remove' ||
    key === 'air_conditioner_swamp_cooler_use' ||
    key === 'air_conditioner_tons_how_many' ||
    key === 'air_conditioner_type_installation' ||
    key === 'air_conditioner_unit_type'
  ) {
    return true
  }

  return false
}

export const caseAC1 = (customer: Customer) => {
  if (
    customer.air_conditioner_working === 'yes' &&
    customer.air_conditioner_current_tons &&
    customer.air_conditioner_unit_type &&
    customer.air_conditioner_new_tons &&
    customer.air_conditioner_swamp_cooler_have_remove &&
    customer.air_conditioner_replace_ducts
  ) {
    return true
  }
  return false
}

export const caseAC2A = (customer: Customer) => {
  if (
    customer.air_conditioner_working === 'no' &&
    customer.air_conditioner_swamp_cooler_use === 'yes' &&
    customer.air_conditioner_swamp_cooler_use_remove &&
    customer.air_conditioner_new_tons &&
    customer.air_conditioner_unit_type
  ) {
    return true
  }

  return false
}

export const caseAC2B = (customer: Customer) => {
  if (
    customer.air_conditioner_working === 'no' &&
    customer.air_conditioner_swamp_cooler_use === 'no' &&
    customer.air_conditioner_explain_situation &&
    customer.air_conditioner_new_tons &&
    customer.air_conditioner_unit_type
  ) {
    return true
  }

  return false
}

export const verifyFillAirConditioner = (customer: Customer) => {
  if (caseAC1(customer) || caseAC2A(customer) || caseAC2B(customer)) {
    return true
  }

  return false
}

export const newWindowsCalifornia = (customer: Customer) => {
  if (customer.solarCompany !== 'CPS') {
    return true
  }
  if (customer.solarCompany === 'CPS') {
    return true
  }

  return false
}

export const newWindowsSlidingGlassYes = (customer: Customer) => {
  if (
    customer.new_windows_sliding_glass === 'yes' &&
    customer.new_windows_sliding_glass_sqft
  ) {
    return true
  }

  return false
}

export const newWindowsSlidingGlassNo = (customer: Customer) => {
  if (customer.new_windows_sliding_glass === 'no') {
    return true
  }
  return false
}

export const newWindowsReplaceYes = (customer: Customer) => {
  if (customer.new_windows_replace === 'yes') {
    return true
  }

  return false
}

export const newWindowsReplaceNo = (customer: Customer) => {
  if (
    customer.new_windows_replace === 'no' &&
    customer.new_windows_replace_custom
  ) {
    return true
  }
  return false
}

export const verifyFillNewWindows = (customer: Customer) => {
  if (
    newWindowsCalifornia(customer) &&
    (newWindowsSlidingGlassYes(customer) ||
      newWindowsSlidingGlassNo(customer)) &&
    (newWindowsReplaceYes(customer) || newWindowsReplaceNo(customer))
  ) {
    return true
  }
  return false
}

export const isRoofCaliforniaCityFilledIn = (customer: Customer): boolean => {
  if (env.getCompanyState(customer.solarCompany) !== 'california') {
    return true
  }
  if (
    env.getCompanyState(customer.solarCompany) === 'california' &&
    customer.roof_california_city
  ) {
    return true
  }

  return false
}

export const roofFasciaIncludedYes = (customer: Customer) => {
  if (
    customer.roof_fascia_included === 'yes' &&
    customer.roof_fascia_included_square_footage
  ) {
    return true
  }
  return false
}

export const roofFasciaIncludedNo = (customer: Customer) => {
  if (customer.roof_fascia_included === 'no') {
    return true
  }

  return false
}

export const roofPatioIncludedYes = (customer: Customer) => {
  if (customer.roof_patio_included === 'yes' && customer.roof_patio_how_many) {
    return true
  }

  return false
}

export const roofPatioIncludedNo = (customer: Customer) => {
  if (customer.roof_patio_included === 'no') {
    return true
  }

  return false
}

export const roofShingleLayers = (customer: Customer) => {
  if (customer.roof_layers_how_many) {
    return true
  }

  return false
}

export const roofPlywoodReplacedYes = (customer: Customer) => {
  if (customer.roof_plywood_replaced === 'yes') {
    return true
  }

  return false
}

export const roofPlywoodReplacedNo = (customer: Customer) => {
  if (customer.roof_plywood_replaced === 'no') {
    return true
  }

  return false
}

export const roofLayover = (customer: Customer) => {
  if (
    customer.roof_layover_or_tear === 'Layover' &&
    (roofFasciaIncludedNo(customer) || roofFasciaIncludedYes(customer)) &&
    (roofPatioIncludedYes(customer) || roofPatioIncludedNo(customer)) &&
    customer.roof_square_footage
  ) {
    return true
  }

  return false
}

export const roofTearOff = (customer: Customer) => {
  if (
    customer.roof_layover_or_tear === 'Tear off' &&
    (roofFasciaIncludedNo(customer) || roofFasciaIncludedYes(customer)) &&
    (roofPatioIncludedYes(customer) || roofPatioIncludedNo(customer)) &&
    (roofPlywoodReplacedNo(customer) || roofPlywoodReplacedYes(customer)) &&
    roofShingleLayers(customer) &&
    customer.roof_square_footage
  ) {
    return true
  }

  return false
}

export const verifyFillRoof = (customer: Customer) => {
  if (
    isRoofCaliforniaCityFilledIn(customer) &&
    (roofLayover(customer) || roofTearOff(customer)) &&
    customer.roof_cost &&
    customer.roof_customer_cost
  ) {
    return true
  }

  return false
}

export const verifyFillMiniSplit = (customer: Customer) => {
  if (customer.mini_split_tons) {
    return true
  }

  return false
}

export const verifyFillAtticInsulation = (customer: Customer) => {
  if (customer.attic_insulation_type && customer.attic_insulation_how_much) {
    return true
  }

  return false
}

export const IsAutoCalcPrice = (key: keyof Customer) => {
  if (
    key === 'attic_insulation' ||
    key === 'duct_seal' ||
    key === 'main_panel_upgrade' ||
    key === 'new_windows' ||
    key === 'smart_thermostat'
  ) {
    return true
  }

  return false
}

export const subFieldToEfficiencyField = (
  field: schema.CustomerField,
): keyof typeof efficiencyToInputs | undefined => {
  const [efficiencyField] =
    utils
      .entries(efficiencyToInputs)
      .find(([_, subFields]) =>
        [subFields]
          .flat(1)
          .some((v: InputConfig | undefined) => v?.includes(field)),
      ) || []

  return efficiencyField
}
export const efficiencyKeys = utils.keys(efficiencyToInputs)

export const verifyEmptyFields = (customer: Customer) => {
  let emptyInputs: string[] = []
  let emptyKeys: string[] = []
  efficiencyKeys.forEach((key) => {
    if (key === 'attic_insulation') {
      if (
        customer.attic_insulation === 'yes' &&
        !verifyFillAtticInsulation(customer)
      ) {
        emptyInputs.push(key)
      }
    }

    if (key === 'new_windows') {
      if (customer.new_windows === 'yes' && !verifyFillNewWindows(customer)) {
        emptyInputs.push(key)
      }
    }

    if (key === 'air_conditioner') {
      if (
        customer.air_conditioner === 'yes' &&
        !verifyFillAirConditioner(customer)
      ) {
        emptyInputs.push(key)
      }
    }

    if (key === 'roof') {
      if (customer.roof === 'yes' && !verifyFillRoof(customer)) {
        emptyInputs.push(key)
      }
    }

    if (key === 'mini_split') {
      if (customer.mini_split === 'yes' && !verifyFillMiniSplit(customer)) {
        emptyInputs.push(key)
      }
    }

    if (
      key !== 'air_conditioner' &&
      key !== 'attic_insulation' &&
      key !== 'mini_split' &&
      key !== 'new_windows' &&
      key !== 'roof'
    ) {
      efficiencyToInputs[key as keyof Customer]?.forEach((value: any) => {
        if (Array.isArray(value)) {
          value.forEach((_field: keyof Customer) => {
            if (
              customer[key as keyof Customer] === 'yes' &&
              !_field.endsWith('notes') &&
              !customer[_field]
            ) {
              emptyInputs.push(key)
            }
          })
        } else {
          if (
            customer[key as keyof Customer] === 'yes' &&
            !(value as keyof Customer).endsWith('notes') &&
            !customer[value as keyof Customer]
          ) {
            emptyInputs.push(key)
          }
        }
      })

      // alert(JSON.stringify(otherKeys))
    }
  })

  if (emptyInputs.length && emptyInputs[0]) {
    return {
      empty: true,
      targetScroll: emptyInputs[0],
      emptyKeys,
    }
  }

  return {
    empty: false,
    targetScroll: '',
    emptyKeys: [],
  }
}

export const isSubField = (field: keyof Customer) => {
  let isSubfield = false
  efficiencyKeys.forEach((key) => {
    if (field?.startsWith(key) && field !== key && !field.endsWith('notes')) {
      isSubfield = true
    }
  })

  return isSubfield
}

export const fieldCanBeEmpty = (field: schema.CustomerField) => {
  if (isSubField(field)) {
    return true
  }

  // TODO: Later to be handled by the new schema validators
  return ['customerPhoneAlt', 'ssn'].includes(field)
}

export const fieldShouldBeFilled = (field: schema.CustomerField): boolean =>
  !fieldCanBeEmpty(field)

export const isLargeEfficiency = (field: keyof Customer) => {
  if (
    field === 'air_conditioner' ||
    field === 'attic_insulation' ||
    field === 'new_windows' ||
    field === 'roof'
  ) {
    return true
  }

  return false
}

//#region MPUCalculator

const MAX_PANELS = 42

interface Inverter {
  readonly capacity: number
  readonly name: string
  readonly numOfPanels: readonly [lowerBound: number, upperBound: number]
}

const availableInverters: readonly Inverter[] = [
  {
    capacity: 1.21,
    name: 'Enphase IQ8+ Microinverters.',
    numOfPanels: [1, 1],
  },
  {
    capacity: 2.42,
    name: '2 x Enphase IQ8+ Microinverters.',
    numOfPanels: [2, 2],
  },
  {
    capacity: 3.63,
    name: '3 x Enphase IQ8+ Microinverters.',
    numOfPanels: [3, 3],
  },
  {
    capacity: 4.84,
    name: '4 x Enphase IQ8+ Microinverters.',
    numOfPanels: [4, 4],
  },
  {
    capacity: 6.05,
    name: '5 x Enphase IQ8+ Microinverters.',
    numOfPanels: [5, 5],
  },
  {
    capacity: 7.26,
    name: '6 x Enphase IQ8+ Microinverters.',
    numOfPanels: [6, 6],
  },
  {
    capacity: 8.47,
    name: '7 x Enphase IQ8+ Microinverters.',
    numOfPanels: [7, 7],
  },
  {
    capacity: 16,
    name: 'Solaredge Inv-3800',
    numOfPanels: [8, 12],
  },
  {
    capacity: 21,
    name: 'Solaredge Inv-5000',
    numOfPanels: [13, 16],
  },
  {
    capacity: 25,
    name: 'Solaredge Inv-6000',
    numOfPanels: [17, 20],
  },
  {
    capacity: 32,
    name: 'Solaredge Inv-7600',
    numOfPanels: [21, 25],
  },
  {
    capacity: 40,
    name: 'Solaredge Inv-10000',
    numOfPanels: [26, 33],
  },
  {
    capacity: 47.5,
    name: 'Solaredge Inv-11400',
    numOfPanels: [34, 38],
  },
  {
    capacity: 50,
    name: 'Solaredge Inv-6000 + Solaredge Inv-6000',
    numOfPanels: [39, 40],
  },
  {
    capacity: 53,
    name: 'Solaredge Inv-7600 + Solaredge Inv 5000',
    numOfPanels: [41, 42],
  },
]

const reverseInverters = availableInverters.slice().reverse()

interface MPU {
  readonly capacity: number
  readonly name: string
}

const availableMPUs: readonly MPU[] = [
  {
    capacity: 225,
    name: '225amp MPU',
  },
]

const [maxCapInverter] = (availableMPUs as [Inverter])
  .slice()
  .sort((a, b) => a.capacity - b.capacity)

export const { capacity: MAX_MPU_RATING } = maxCapInverter!

export interface MPUCalculatorResult {
  readonly maxPanelsAsIs: number | null
  readonly maxPanelsWithDerate: number | null
  readonly inverterAsIs: Inverter | null
  readonly inverterWithDerate: Inverter | null
}

const VALID_DERATES = [100, 175, 200]

export const mpuCalculator = (
  busRating: number,
  mainBreakerRating: number,
): MPUCalculatorResult => {
  const res: Writable<MPUCalculatorResult> = {
    maxPanelsAsIs: null,
    maxPanelsWithDerate: null,
    inverterAsIs: null,
    inverterWithDerate: null,
  }

  // Information!G11 = numOfPanels

  // Information!F15 = chosenInverter according to numOfPanels
  // Information!K15 = chosenInverter.capacity * 1.25

  for (const inverter of reverseInverters) {
    const {
      capacity,
      numOfPanels: [, upperBound],
    } = inverter
    /**
     * IF (B2*1.2)>B3+(Information!C48*1.25):
     *   CONCATENATE("Maximun number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A48),
     * else:
     *   IF (B2*1.2)>B3+(Information!C46*1.25):
     *      CONCATENATE("Maximun number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A46)
     *   else:
     *     IF (B2*1.2)>B3+(Information!C44*1.25):
     *       CONCATENATE("Maximun number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A44)
     *     else:
     *       IF (B2*1.2)>B3+(Information!C39*1.25):
     *         CONCATENATE("Maximun number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A39)
     *       else:
     *         IF (B2*1.2)>B3+(Information!C31*1.25):
     *           CONCATENATE("Maximun number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A31)
     *         else:
     *           IF (B2*1.2)>B3+(Information!C26*1.25):
     *             CONCATENATE("Maximun number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A26)
     *           else:
     *             IF (B2*1.2)>B3+(Information!C21*1.25):
     *               CONCATENATE("Maximun number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A21)
     *             else:
     *               IF (B2*1.2)>B3+(Information!C18*1.25):
     *                 CONCATENATE("Maximun number of Solar Panels possible to install without the need of Derate or MPU: ",Information!A18),"No possible to install Solar Panels without the need of Derate or MPU"))))))))
     */
    if (busRating * 1.2 > mainBreakerRating + capacity * 1.25) {
      res.maxPanelsAsIs = upperBound
      res.inverterAsIs = inverter
      break
    }
  }

  if (!res.maxPanelsAsIs || !res.inverterAsIs) {
    return res
  }

  for (const numOfPanels of range(
    MAX_PANELS - res.maxPanelsAsIs,
    res.maxPanelsAsIs + 1,
  )) {
    /**
     * IF (B2*1.2)>B3+Information!K15:
     *   "Derate is not need"
     * else IF (B2*1.2)>B3-25+Information!K15:
     *   IF B3-25=200:
     *     CONCATENATE("Derate size should be: ",B3-25)
     *   else IF B3-25=175:
     *     CONCATENATE("Derate size should be: ",B3-25,)
     *   else IF B3-25=100:
     *     CONCATENATE("Derate size should be: ",B3-25,)
     *   else:
     *    "Derate is not possible"
     * else:
     *   "Derate is not possible"
     */
    const inverterViaPanels = availableInverters.find(
      ({ numOfPanels: [lowerBound, upperBound] }) =>
        isBetween({
          lowerBound,
          upperBound,
          value: numOfPanels,
        }),
    )
    if (!inverterViaPanels) {
      return res
    }
    if (
      busRating * 1.2 >
      mainBreakerRating + inverterViaPanels.capacity * 1.25
    ) {
      // Derate not needed
      if (numOfPanels !== res.maxPanelsAsIs) {
        console.error('Assertion failed: numOfPanels === res.maxPanelsAsIs')
      }
      continue
    }

    if (
      busRating * 1.2 >
      mainBreakerRating - 25 + inverterViaPanels.capacity * 1.25
    ) {
      /**
       * IF B3-25=200:
       *     CONCATENATE("Derate size should be: ",B3-25)
       *   else IF B3-25=175:
       *     CONCATENATE("Derate size should be: ",B3-25,)
       *   else IF B3-25=100:
       *     CONCATENATE("Derate size should be: ",B3-25,)
       *   else:
       *    "Derate is not possible"
       */
      if (VALID_DERATES.includes(mainBreakerRating - 25)) {
        res.maxPanelsWithDerate = numOfPanels
        const inverterViaPanels = availableInverters.find(
          ({ numOfPanels: [lowerBound, upperBound] }) =>
            isBetween({
              lowerBound,
              upperBound,
              value: numOfPanels,
            }),
        )
        if (!inverterViaPanels) {
          console.error(
            `Found panels with derate but not Inverter for those panels?`,
          )
          continue
        }
        res.inverterWithDerate = inverterViaPanels
      }
    }
  }

  return res
}

// TODO: Move to react utils
export function useMpuCalculator() {
  const [mpuBusRating, setMpuBusRating] = React.useState('')
  const [mpuMainBreakerRating, setMPUMainBreakerRating] = React.useState('')

  const mpuCalculatorResult = React.useMemo((): MPUCalculatorResult | null => {
    if ([mpuBusRating, mpuBusRating].every(stringIsNumber)) {
      // const parsedNumOfSolarPanels = parseInt(numOfSolarPanels, 10)
      const parsedMPUBusRating = parseInt(mpuBusRating, 10)
      const parsedMPUMainBreakerRating = parseInt(mpuMainBreakerRating, 10)

      return mpuCalculator(parsedMPUBusRating, parsedMPUMainBreakerRating)
    }

    return null
  }, [mpuBusRating, mpuMainBreakerRating])

  return {
    mpuBusRating,
    mpuCalculatorResult,
    mpuMainBreakerRating,
    setMpuBusRating,
    setMPUMainBreakerRating,
  }
}

//#endregion MPUCalculator

export const validateInputMaxLength = (
  field: keyof Customer,
): number | undefined => {
  if (field === 'yearHomeBuilt') {
    return 4
  }
  return
}

export const validateNumberOfPhotosAllowed = (ofWhat: MediaKind): number => {
  if (ofWhat === 'owner_house') {
    return 1
  }
  return 40
}

type GoogleLibrary =
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'

export const googleLibraries: GoogleLibrary[] = ['places']

export const invoiceEfficiencyOpts: utils.DeepReadonly<
  Record<string, string[] | Record<string, string[]>>
> = {
  artificial_turf: [
    'Evergreen Collection Waterproof Solid Grass 1x1 Indoor/Outdoor Artificial Grass Tile, 1 ft. x 1 ft',
  ],
  attic_insulation: ['Attic Insulation'],
  duct_seal: ['Duct Seal'],
  efficient_boiler: [
    'Rinnai M120SN Condensing Gas Boiler, Natural Gas: Materials+Labor',
    'Aspen Firetube 126,000 BTU Combi High Efficiency Wall Hung Condensing Gas Fired Boiler: Materials+Labor',
  ],
  heat_pump: [
    'Goodman 5 Ton 15.5 SEER Heat Pump GSZC180601, Coil CHPF4860D6, 120,000 BTU 97% AFUE Horizontal Gas Furnace GMVM971205DN',
    '56,000 BTU 4.6 Ton 17.5 SEER Ducted Central Split Air Conditioner Heat Pump System',
  ],
  hvac: [
    '2 T HVAC System Installation: Miscellaneous + Labor',
    '2.5 T HVAC System Installation: Miscellaneous + Labor',
    '3 T HVAC System Installation: Miscellaneous + Labor',
    '3.5 T HVAC System Installation: Miscellaneous + Labor',
    '4 T HVAC System Installation: Miscellaneous + Labor',
    '5 T HVAC System Installation: Miscellaneous + Labor',
  ],
  mini_split: [
    '1T HVAC mini split: Miscellaneous+Labor',
    '2T HVAC mini split: Miscellaneous+Labor',
  ],
  radiant_floor: ['WarmlyYours Tempzone Electric Radiant Floor  '],
  roof: {
    roof_layover: [
      'Install 30-year Shingles according to manufactures specifications\nSeal penetrations',
    ],
    shingles_tear_off: [
      'Tear off the existing roof.\nInstall one-layer of synthetic underlayment.\nInstall new 2x2 drep edge metal.\nInstall 30-year Shingles according to manufactures specifications.\nSeal penetrations.\n',
    ],
  },
  thermostat: [
    'Ecobee3 Lite Programmable Smart Thermostat - Energy Star Certified',
    'Nest Learning Thermostat - Smart Wi-Fi Thermostat Stainless Steel',
  ],
  wall_insulation: ['R-15 Faced Insulation 2x4" walls'],
  whole_house_fans: [
    'Whole House Fan/Ventilation',
    'QA-Deluxe 6500 Includes Plug & Play Wireless On/Off Remote Control | Energy Efficient Whole House Fan | R-5 Insulated Damper',
  ],
  windows: ['Sliding Windows', 'Sliding Windows: Low-E Energy Efficient.'],
  windows_film: [
    'KESPEN Window Privacy Film One Way Daytime Privacy Static Cling Sun Blocking Anti UV Reflective Window Tint for Home and Office, Black-Silver',
  ],
}

export const companyToLabel: utils.ReadonlyRecord<string> = (() => {
  const res: Record<string, string> = {}

  for (const [subCompanyLabel, subCompanyValue] of utils.zip(
    env.COMPANY_LABELS,
    env.COMPANY_VALUES,
  ) as readonly [string, string][]) {
    res[subCompanyValue] = subCompanyLabel
  }

  return res
})()

//#region Glow cost cacl
const nper = 10 // Commitment (years)
const pmt = 0 // Payment amount per period

const calcAvg = (rate: number, value: number): number => {
  const pv =
    (value * (1 - Math.pow(1 + rate, -nper))) / rate -
    (pmt * (1 - Math.pow(1 + rate, -nper))) / rate
  return Number(pv.toFixed(2))
}

const cashFlowDiscount = 0.11 // Cashflow discount
const _powerOutputMW = 0.0156

export const calculateProtocolFee = (
  pricePerWatt: number,
  hoursOfSunPerDay: number,
  escalator: number,
  powerOutputMW: number = _powerOutputMW,
): number => {
  const firstYearElectricityOldPrice = Number(
    (pricePerWatt * hoursOfSunPerDay * powerOutputMW * 365.25).toFixed(2),
  )

  const lifetimeOldElectricityValue = calcAvg(
    -escalator,
    firstYearElectricityOldPrice,
  )

  const protocolCashRequirements = calcAvg(
    cashFlowDiscount,
    lifetimeOldElectricityValue / nper,
  )

  return protocolCashRequirements
}

export const calculateInstallationCost = (
  installationCostPerWatt: number,
  powerOutputMW: number = _powerOutputMW,
): number => {
  return installationCostPerWatt * powerOutputMW * 1000000
}

//#endregion Glow cost calc

const validateCaseForAcOptions = (key: keyof Customer, customer: Customer) => {
  if (
    key === 'air_conditioner_current_tons' &&
    customer.air_conditioner_working === 'yes'
  ) {
    return true
  }

  if (key.endsWith('cost') || key.endsWith('notes')) {
    return true
  }

  if (
    key === 'air_conditioner_swamp_cooler_use' &&
    customer.air_conditioner_working === 'no'
  ) {
    return true
  }

  if (
    key === 'air_conditioner_swamp_cooler_use_remove' &&
    customer.air_conditioner_swamp_cooler_use === 'yes' &&
    customer.air_conditioner_working === 'no'
  ) {
    return true
  }

  if (
    key === 'air_conditioner_replace_ducts' &&
    customer.air_conditioner_working === 'yes'
  ) {
    return true
  }
  if (key === 'air_conditioner_working') {
    return customer.air_conditioner === 'yes'
  }
  if (
    key === 'air_conditioner_swamp_cooler_have_remove' &&
    customer.air_conditioner_working === 'yes'
  ) {
    return true
  }

  if (
    (key === 'air_conditioner_new_tons' ||
      key === 'air_conditioner_unit_type') &&
    customer.air_conditioner_working
  ) {
    return true
  }

  if (
    key === 'air_conditioner_explain_situation' &&
    customer.air_conditioner_working === 'no' &&
    customer.air_conditioner_swamp_cooler_use === 'no'
  ) {
    return true
  }

  // if (
  //   (caseAC1(customer) || caseAC2A(customer) || caseAC2B(customer)) &&
  //   customer.air_conditioner_working === 'yes'
  // ) {
  //   return true
  // }

  return false
}

/**
 * Windows global color selectors cut-off.
 */
export const Jun4_2024 = 1717519937085
export const shouldHideInput = (
  field: schema.CustomerField,
  customer: schema.Customer,
): boolean => {
  if (field === 'battery') {
    if (!customer.solarCompany) return true
    return env.getCompanyState(customer.solarCompany) !== 'california'
  }
  if (
    field === 'air_conditioner_any_swamp_cooler' ||
    field === 'air_conditioner_current_tons' ||
    field === 'air_conditioner_explain_situation' ||
    field === 'air_conditioner_extra_notes' ||
    field === 'air_conditioner_new_tons' ||
    field === 'air_conditioner_replace_curve' ||
    field === 'air_conditioner_size' ||
    field === 'air_conditioner_swamp_cooler_have_remove' ||
    field === 'air_conditioner_swamp_cooler_use' ||
    field === 'air_conditioner_replace_ducts' ||
    field === 'air_conditioner_replace_ducts_how_many' ||
    field === 'air_conditioner_swamp_cooler_use_remove' ||
    field === 'air_conditioner_tons_how_many' ||
    field === 'air_conditioner_type_installation' ||
    field === 'air_conditioner_unit_type' ||
    field === 'air_conditioner_working'
  ) {
    return !validateCaseForAcOptions(field, customer)
  }
  if (field === 'new_windows_california_city') {
    // TODO: Hardcoding until states vary from "california" as default because
    // we don't have price data for other states other than Nevada.
    return (
      customer.solarCompany !== 'CPS' ||
      env.getCompanyState(customer.solarCompany) !== 'california'
    )
  }
  // Hide for older customers
  if (field === 'new_windows_color') {
    return utils.normalizeTimestampToMs(customer.createdAt) < Jun4_2024
  }
  if (field === 'new_windows_sliding_glass_sqft') {
    return customer.new_windows_sliding_glass !== 'yes'
  }
  if (field === 'new_windows_replace_custom') {
    return (
      customer.new_windows_replace === 'yes' ||
      customer.new_windows_replace === ''
    )
  }
  if (field === 'roof_layers_how_many') {
    return customer.roof_layover_or_tear === 'no'
  }
  if (field === 'roof_fascia_included_square_footage') {
    return customer.roof_fascia_included === 'no'
  }
  if (field === 'roof_patio_how_many') {
    return customer.roof_patio_included === 'no'
  }
  if (field === 'roof_plywood_replaced_square') {
    return customer.roof_plywood_replaced === 'no'
  }
  if (field === 'roof_california_city') {
    // TODO: Hardcoding until states vary from "california" as default because
    // we don't have price data for other states other than Nevada.
    return (
      !customer.solarCompany.startsWith('CPS') ||
      env.getCompanyState(customer.solarCompany) !== 'california'
    )
  }
  if (field === 'new_windows_sliding_glass_size') {
    return customer.new_windows_sliding_glass === 'no'
  }

  if (field === 'battery_type') {
    return customer.battery_installation_company.toLowerCase() === ''
  }

  if (field === 'battery_size') {
    return customer.battery_type.toLowerCase() === ''
  }

  if (field === 'glow') {
    const shouldDisplay = env.IS_MAIN || env.CODENAME === 'glow'
    const shouldHide = !shouldDisplay
    return shouldHide
  }
  return false
}

export const shouldDisplayInput = (
  field: schema.CustomerField,
  customer: schema.Customer,
): boolean => !shouldHideInput(field, customer)

// TODO: Move to settings
export const roofCompanies: readonly string[] = [
  'APS_RC',
  'CPS_RC',
  'NPS_RC',
  'affordable',
]
