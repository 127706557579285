export const FormatPrice = (price: number, symbol: string) => {
  if (isNaN(price)) return '0'
  if (price < 0) {
    const deleteMinus = parseInt(price.toString().replace('-', ''))

    return `-${symbol}${deleteMinus.toLocaleString('en')}`
  }

  return `${symbol}${price.toLocaleString('en')}`
}
