import React from 'react'

import * as c from '../../../common'
import * as common from '../../../common'
import * as r from '../../../react-utils'

import * as Mui from '@mui/material'
import * as MuiIcons from '@mui/icons-material'
import DeviceDetector from 'device-detector-js'
import { ErrorBoundary } from 'react-error-boundary'
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

import * as cp from '../../comps'

import CheckGroup from '../../comps/CheckGroup'
import CloserCalc from '../../../app/components/CloserCalc'
import ConnectedInput from '../../comps/ConnectedInput'
import DropDownInput from '../../comps/DropdownInput'
import MediaInput from '../../comps/MediaInput'
import NavBar from '../../comps/NavBar'
import NotFound from '../../comps/NotFound'
import Pad from '../../comps/Pad'
import PhoneField from '../../comps/PhoneField'
import PlacesInput from '../../comps/PlacesInput'
import SsnInput from '../../comps/SsnInput'
import StickyNav from '../../comps/StickyNav'
import ToastAlert from '../../comps/ToastAlert'
import gStyles from '../../global.module.css'

import ManufacturedHome from './ManufacturedHome'
import MPUCalc from './MPUCalc'
import SiteSurvey from './SiteSurvey'
import TotalCost from './TotalCost'

import styles from './Customer.module.css'
import RoofingClaims from './RoofingClaims'

export interface CustomerProps {}

const deviceDetector = new DeviceDetector()

const deviceInfo = deviceDetector.parse(navigator.userAgent)

export default React.memo<CustomerProps>(function Customer() {
  const customerID = useParams<'customerID'>().customerID!

  const location = useLocation()
  const [oldCustomerAlert, setOldCustomerAlert] = React.useState(false)
  const [fillAlert, setFillAlert] = React.useState(false)

  const [mpuCalculatorOpen, , toggleMpuCalculator] = r.useBool(false)

  const navigate = useNavigate()

  const stackRef = React.useRef<HTMLDivElement>(document.createElement('div'))

  const handleCloseOldCustomerAlert = React.useCallback(() => {
    setOldCustomerAlert(false)
  }, [])

  const handleFillAlert = React.useCallback(() => {
    setFillAlert((current) => !current)
  }, [setFillAlert])

  React.useEffect((): common.EmptyFn => {
    if (customerID) {
      common.dispatch(
        common.subToSingleCustomer({
          customerID,
        }),
      )

      return (): void => {
        common.dispatch(
          common.unSubFromSingleCustomer({
            customerID,
          }),
        )
      }
    }
    return common.EMPTY_FN
  }, [customerID])

  const selectCustomer = React.useMemo(
    (): ReturnType<typeof common.makeSelectCustomer> =>
      common.makeSelectCustomer(),
    [],
  )

  const selectCustomerArgs = React.useMemo((): common.SelectCustomerParams => {
    if (typeof customerID === 'string') {
      return {
        customerID,
      }
    } else {
      return {
        customerID: '',
      }
    }
  }, [customerID])

  const customer = common.useSelector(
    (_): common.Customer => selectCustomer(_, selectCustomerArgs),
  )

  // const lastTimeout = React.useRef(setTimeout(common.EMPTY_FN, 0))
  // const lastTimeoutColor = React.useRef(setTimeout(common.EMPTY_FN, 0))

  const isRoofClaims = r.useIsRoofClaims({ customerID })

  React.useEffect(() => {
    if (location.hash) {
      return
    }
    if (customer.date && typeof customer.date === 'number') {
      if (customer.date < 1679616000000) {
      }
    }
  }, [customer.date, location.hash])

  const handleAuditorReport = React.useCallback(() => {
    // const res = verifyEmptyFields(customer)
    // console.log('KEY INFO: ', JSON.stringify(res))
    // if (res.empty) {
    //   const targetScroll = document.getElementById(res.targetScroll)
    //   console.log(JSON.stringify(res))

    //   targetScroll?.scrollIntoView({
    //     behavior: 'smooth',
    //     block: isLargeEfficiency(res.targetScroll as keyof Customer)
    //       ? 'start'
    //       : 'center',
    //   })

    //   if (targetScroll) {
    //     targetScroll.style.borderRadius = '10px'
    //     targetScroll.style.backgroundColor = '#b2e2f2'

    //     setTimeout(() => {
    //       targetScroll.style.backgroundColor = 'unset'
    //       targetScroll.style.borderRadius = 'unset'
    //     }, 1800)
    //   }
    //   handleFillAlert()
    // }

    // if (!res.empty) {
    window.open(`/customers/${customerID}/auditor`, '_blank')?.focus()
    // }
  }, [customerID])

  const [closerCalcOpen, , toggleCloserCalc] = r.useBool(false)

  const handleCustomerReport = React.useCallback(() => {
    // const res = common.verifyEmptyFields(customer)
    // if (!res.empty) {
    navigate(`/customers/${customerID}/customer`)
    //   return
    // }

    // const targetScroll = document.getElementById(res.targetScroll)

    // targetScroll?.scrollIntoView({
    //   behavior: 'smooth',
    //   block: common.isLargeEfficiency(res.targetScroll as keyof Customer)
    //     ? 'start'
    //     : 'center',
    // })
    // if (targetScroll) {
    //   targetScroll.style.borderRadius = '10px'
    //   targetScroll.style.backgroundColor = '#b2e2f2'

    //   setTimeout(() => {
    //     targetScroll.style.backgroundColor = 'unset'
    //     targetScroll.style.borderRadius = 'unset'
    //   }, 1800)
    // }
    // handleFillAlert()
  }, [customerID, navigate])

  // React.useEffect(() => {
  //   if (location.hash) {
  //     const efficiency = location.hash.slice(1)

  //     const targetScroll = document.getElementById(efficiency as string)

  //     const res = common.verifyEmptyFields(customer)
  //     if (res.empty) {
  //       console.log(JSON.stringify(res))
  //       clearTimeout(lastTimeout.current)
  //       lastTimeout.current = setTimeout(() => {
  //         targetScroll?.scrollIntoView({
  //           behavior: 'smooth',
  //           block: common.isLargeEfficiency(
  //             res.targetScroll as keyof common.Customer,
  //           )
  //             ? 'start'
  //             : 'center',
  //         })
  //         if (targetScroll) {
  //           targetScroll.style.borderRadius = '10px'
  //           targetScroll.style.backgroundColor = '#b2e2f2'
  //           clearTimeout(lastTimeoutColor.current)
  //           lastTimeoutColor.current = setTimeout(() => {
  //             targetScroll.style.backgroundColor = 'unset'
  //             targetScroll.style.borderRadius = 'unset'
  //             location.hash = ''
  //           }, 1600)
  //         }
  //         setFillAlert(true)
  //       }, 1200)
  //     }
  //   }
  //   const efficiency = location.hash.slice(1)

  //   const targetScroll = document.getElementById(efficiency as string)

  //   const res = common.verifyEmptyFields(customer)
  //   if (!res.empty) {
  //     clearTimeout(lastTimeout.current)
  //     return
  //   }

  //   clearTimeout(lastTimeout.current)
  //   lastTimeout.current = setTimeout(() => {
  //     targetScroll?.scrollIntoView({
  //       behavior: 'smooth',
  //       block: common.isLargeEfficiency(
  //         res.targetScroll as unknown as keyof Customer,
  //       )
  //         ? 'start'
  //         : 'center',
  //     })
  //     if (targetScroll) {
  //       targetScroll.style.borderRadius = '10px'
  //       targetScroll.style.backgroundColor = '#b2e2f2'
  //       clearTimeout(lastTimeoutColor.current)
  //       lastTimeoutColor.current = setTimeout(() => {
  //         targetScroll.style.backgroundColor = 'unset'
  //         targetScroll.style.borderRadius = 'unset'
  //         location.hash = ''
  //       }, 1600)
  //     }
  //     setFillAlert(true)
  //   }, 1200)
  // }, [customer, location])

  const boxPad = React.useMemo(() => {
    return {
      height: `${stackRef.current.style.height}px`,
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    }
  }, [stackRef])

  React.useEffect(() => {
    common.dispatch(common.efficiencyPricesAction())
  }, [])

  if (!customerID) {
    return <NotFound />
  }

  return (
    <>
      <Mui.Box sx={sx['containerHeightStyle']}>
        <Mui.Stack ref={stackRef} sx={sx['stackBarStyle']}>
          <NavBar />

          <Mui.Box sx={sx['breadCumbBoxStyle']}>
            <Mui.Breadcrumbs aria-label="breadcrumb" sx={sx['breadCumbsStyle']}>
              <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
                <MuiIcons.Home sx={{ mr: 0.5 }} fontSize="inherit" />
                Dashboard
              </Link>

              <Mui.Typography
                color="text.primary"
                component="span"
                sx={sx['textBreadCrumbStyle']}
              >
                <MuiIcons.Grain fontSize="inherit" sx={sx['grainIconStyle']} />
                <Mui.Typography sx={sx['grainIconTextStyle']}>
                  {customer.customerName}
                </Mui.Typography>
              </Mui.Typography>
            </Mui.Breadcrumbs>
          </Mui.Box>
        </Mui.Stack>
        <Mui.Box sx={boxPad} />
        <Mui.Box
          sx={
            deviceInfo.device?.model === 'iPad'
              ? sx['inputMediaWrapperStyle']
              : sx['inputMediaWrapperHideStyle']
          }
        />
        <Mui.Box
          sx={
            deviceInfo.device?.model === 'iPad'
              ? sx['wrapperCustomerLgHeighAutoStyle']
              : sx['wrapperCustomerLgHeightStyle']
          }
        >
          <Mui.Box component={Mui.Paper} sx={sx['inputGroupStyle']}>
            <StickyNav customerID={customerID} right={25} bottom={25} />

            <div className={gStyles['card-row-wrap-gapped']}>
              <ConnectedInput customerID={customerID} field="sort_key" />
              <Pad amt={20} />
              <ConnectedInput customerID={customerID} field="date" />
              <Pad amt={20} />
              <DropDownInput customerID={customerID} field="homeRep" />
              <Pad amt={20} />
              <ConnectedInput customerID={customerID} field="customerName" />
              <Pad amt={20} />
              <PlacesInput customerID={customerID} field="customerAddress" />
              <Pad amt={20} />
              <PhoneField customerID={customerID} field="customerPhone" />
              <Pad amt={20} />
              <PhoneField
                customerID={customerID}
                field="customerPhoneAlt"
                noLenCheck
              />
              <Pad amt={20} />
              <ConnectedInput customerID={customerID} field="customerEmail" />
              <Pad amt={20} />
              <DropDownInput customerID={customerID} field="solarCompany" />
              <Pad amt={20} />
              <DropDownInput customerID={customerID} field="solarRep" />
              <Pad amt={20} />
              {common.IS_MAIN && (
                <>
                  <ConnectedInput customerID={customerID} field="solarEmail" />
                  <Pad amt={20} />
                </>
              )}
              <ConnectedInput customerID={customerID} field="yearHomeBuilt" />
              <Pad amt={20} />
              <ConnectedInput
                customerID={customerID}
                field="attic_square_footage"
              />

              <Pad amt={20} />
              <SsnInput customerID={customerID} field="ssn" />
            </div>

            <Pad amt={240} />

            <div className={gStyles['card']}>
              <TotalCost type="Customer" customerID={customerID} />

              <div className={gStyles['row-wrap-gapped']}>
                <cp.ConnectedInput
                  customerID={customerID}
                  field="globalNotes"
                />
                <cp.ConnectedInput
                  customerID={customerID}
                  field="roof_claiming_notes"
                />
                <cp.ConnectedInput
                  customerID={customerID}
                  field="site_survey_notes"
                />
                <cp.ConnectedInput
                  customerID={customerID}
                  field="manufactured_home_notes"
                />
              </div>
            </div>

            {/* CheckGroup group */}

            {c.efficiencyKeys.map((field) => (
              <React.Fragment key={customerID + field}>
                <Pad amt={0} />

                <ErrorBoundary fallback={errorBoundaryFallback[field]}>
                  <CheckGroup
                    customer={customer}
                    customerID={customerID}
                    field={field}
                    fillAlert={fillAlert}
                  />
                </ErrorBoundary>
              </React.Fragment>
            ))}

            {/* end checkgroups */}
            <Pad amt={20} />

            <Pad amt={50} />

            {!isRoofClaims &&
              Object.keys(common.mediaToLabel).map((mediaKind) => (
                <React.Fragment key={customerID + mediaKind}>
                  <MediaInput
                    customerID={customerID}
                    ofWhat={mediaKind as common.MediaKind}
                  />
                  <Pad amt={20} />
                </React.Fragment>
              ))}

            {!isRoofClaims && (
              <>
                <SiteSurvey customerID={customerID} /> <Pad amt={20} />
              </>
            )}

            {!isRoofClaims && c.CODENAME !== 'glow' && (
              <>
                <ManufacturedHome customerID={customerID} />
                <Pad amt={20} />
              </>
            )}

            {isRoofClaims && (
              <>
                <RoofingClaims customerID={customerID} />
                <Pad amt={20} />
              </>
            )}

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Mui.Typography
                onClick={handleAuditorReport}
                sx={sx['reportLink']}
              >
                Auditor Report
              </Mui.Typography>
              <Pad amt={20} />
              {common.IS_MAIN && customer.solarCompany !== 'affordable' && (
                <>
                  <Mui.Typography
                    onClick={toggleCloserCalc}
                    sx={sx['reportLink']}
                  >
                    Commission Calculator
                  </Mui.Typography>
                  <Pad amt={20} />
                </>
              )}
              {common.IS_MAIN && customer.solarCompany !== 'affordable' && (
                <>
                  <Mui.Typography
                    onClick={handleCustomerReport}
                    sx={sx['reportLink']}
                  >
                    Customer Report
                  </Mui.Typography>
                  <Pad amt={20} />
                </>
              )}

              {common.IS_MAIN && customer.solarCompany !== 'affordable' && (
                <>
                  <Link
                    className={styles['report-links']}
                    to={`/customers/${customerID}/services`}
                  >
                    Energy Efficiencies Report{' '}
                  </Link>

                  <Pad amt={20} />
                </>
              )}

              <Mui.Button
                color="secondary"
                onClick={toggleMpuCalculator}
                sx={sx['mpuButtonStyle']}
                variant="contained"
              >
                MPU Calculator
              </Mui.Button>
            </div>
          </Mui.Box>

          <Mui.Modal
            onClose={handleCloseOldCustomerAlert}
            open={oldCustomerAlert}
          >
            <Mui.Box component={Mui.Paper} sx={sx['modalBoxStyle']}>
              <Mui.Alert severity="error" sx={sx['alertTextStyle']}>
                The creation date of this customer is before 03/24/2023 , If you
                need to update the price, click on “update prices” on the
                correspond efficiency
              </Mui.Alert>
              <Pad amt={30} />
              <Mui.Button
                onClick={handleCloseOldCustomerAlert}
                sx={sx['alertButtonStyle']}
                variant="contained"
              >
                OK
              </Mui.Button>
            </Mui.Box>
          </Mui.Modal>
        </Mui.Box>

        <ToastAlert
          duration={6000}
          handleClose={handleFillAlert}
          message="Please fill all fields"
          open={fillAlert}
          severity="info"
          type="alert"
        />
      </Mui.Box>

      <Mui.Dialog onClose={toggleMpuCalculator} open={mpuCalculatorOpen}>
        <Mui.DialogTitle>MPU Calculator</Mui.DialogTitle>
        <Mui.DialogContent>
          <MPUCalc />
        </Mui.DialogContent>
      </Mui.Dialog>
      <Outlet />

      <cp.Dialog onClose={toggleCloserCalc} open={closerCalcOpen}>
        <CloserCalc customerID={customerID} />
      </cp.Dialog>
    </>
  )
})

// const Accordion = styled((props: AccordionProps) => (
//   <MuiAccordion disableGutters elevation={0} square {...props} />
// ))(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
//   '&:not(:last-child)': {
//     borderBottom: 0,
//   },
//   '&:before': {
//     display: 'none',
//   },
// }))

// const AccordionSummary = styled((props: AccordionSummaryProps) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === 'dark'
//       ? 'rgba(255, 255, 255, .05)'
//       : 'rgba(0, 0, 0, .03)',
//   flexDirection: 'row-reverse',
//   '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
//     transform: 'rotate(90deg)',
//   },
//   '& .MuiAccordionSummary-content': {
//     marginLeft: theme.spacing(1),
//   },
// }))

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//   padding: theme.spacing(2),
//   borderTop: '1px solid rgba(0, 0, 0, .125)',
// }))

const sx = {
  alertTextStyle: {
    fontSize: {
      sm: '22px',
      xs: '16px',
    },
    textAlign: 'justify',
  },
  alertButtonStyle: {
    fontSize: {
      sm: '24px',
      xs: '18px',
    },
    marginTop: 'auto',
  },
  breadCumbBoxStyle: {
    backgroundColor: '#154360',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '3px',
    paddingTop: '3px',
    width: '100%',
    zIndex: 100,
  },
  breadCumbsStyle: {
    alignSelf: 'flex-start',
    display: 'flex',
    width: {
      sm: '90%',
      xs: '100%',
    },
  },
  containerHeightStyle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100vh',
    width: '100vw',
  },
  fillFieldsAlertStyle: {
    fontSize: '18px',
    width: '298px',
  },
  grainIconStyle: {
    color: 'white',
    mr: 0.5,
  },
  grainIconTextStyle: {
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: {
      sm: 'auto',
      xs: '100px',
    },
  },
  inputGroupStyle: {
    alignContent: { xs: 'center', sm: 'flex-start' },
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',

    paddingTop: '40px',
    position: 'relative',
    paddingLeft: '24px',
    paddingRight: '24px',
    maxWidth: '100vw',
  },
  inputMediaWrapperStyle: {
    display: 'flex',
    height: '70px',
    width: '100%',
  },
  inputMediaWrapperHideStyle: {
    display: {
      lg: 'none',
      xs: 'flex',
    },
    height: '70px',
    width: '100%',
  },
  modalBoxStyle: {
    alignItems: 'center',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    left: '50%',
    padding: '20px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      sm: '500px',
      xs: '320px',
    },
  },
  mpuButtonStyle: {
    alignSelf: 'flex-start',
    backgroundColor: 'gainsboro',
    border: 'unset',
    borderRadius: '10px',
    cursor: 'pointer',
    padding: '7px',
  },
  stackBarStyle: {
    width: '100%',
  },
  textBreadCrumbStyle: {
    alignItems: 'center',
    display: 'flex',
  },
  reportLink: {
    alignSelf: 'left',
    color: 'blue',
    cursor: 'pointer',
    margin: 0,
    textDecoration: 'none',
    '&:hover': {
      color: 'black',
    },
  },
  wrapperCustomerLgHeighAutoStyle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    overflowY: 'auto',
    position: 'relative',
    width: '100%',
  },
  wrapperCustomerLgHeightStyle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: {
      lg: 'calc( 100% - 70px )',
      xs: 'auto',
    },
    overflowY: 'auto',
    position: 'relative',
    width: '100%',
  },
}

const errorBoundaryFallback = (() => {
  const res: Partial<Record<c.CustomerField, React.ReactElement>> = {}
  for (const field of c.efficiencyKeys) {
    res[field] = (
      <div
        style={{
          padding: 24,
          borderColor: '#CCCCCC',
          borderStyle: 'solid',
          borderWidth: 1,
        }}
      >
        <span>
          Something went wrong with the "{c.getFieldLabel(field)}" efficiency,
          please report to Dev Dpt.
        </span>
      </div>
    )
  }
  return res as Record<c.CustomerField, React.ReactElement>
})()
