import React from 'react'

import * as c from '../../../common'
import { Customer, efficiencyToInputs, getFieldLabel } from '../../../common'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FlareIcon from '@mui/icons-material/Flare'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MuiLink from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { MenuProps } from '@mui/material/Menu'
import { styled, alpha, useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

export interface StickyNavProps {
  bottom?: number
  customerID: string
  // handleFillAlert: () => void
  left?: number
  right?: number
  top?: number
}

export default React.memo<StickyNavProps>(function StickyNav({
  bottom,
  customerID,
  left,
  right,
  top,
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const theme = useTheme()
  const navigate = useNavigate()
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = React.useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const handleAuditorReport = React.useCallback(() => {
    // const res = verifyEmptyFields(customer)
    // console.log('KEY INFO: ', JSON.stringify(res))
    // if (res.empty) {
    //   const targetScroll = document.getElementById(res.targetScroll)

    //   targetScroll?.scrollIntoView({
    //     behavior: 'smooth',
    //     block: isLargeEfficiency(res.targetScroll as keyof Customer)
    //       ? 'start'
    //       : 'center',
    //   })
    //   if (targetScroll) {
    //     targetScroll.style.borderRadius = '10px'
    //     targetScroll.style.backgroundColor = '#b2e2f2'

    //     setTimeout(() => {
    //       targetScroll.style.backgroundColor = 'unset'
    //       targetScroll.style.borderRadius = 'unset'
    //     }, 1800)
    //   }

    //   handleFillAlert()
    // }

    window.open(`/customers/${customerID}/auditor`, '_blank')?.focus()
  }, [customerID])

  const handleCustomerReport = React.useCallback(() => {
    // const res = verifyEmptyFields(customer)
    // console.log('KEY INFO: ', JSON.stringify(res))
    // if (res.empty) {
    //   const targetScroll = document.getElementById(res.targetScroll)

    //   targetScroll?.scrollIntoView({
    //     behavior: 'smooth',
    //     block: isLargeEfficiency(res.targetScroll as keyof Customer)
    //       ? 'start'
    //       : 'center',
    //   })
    //   if (targetScroll) {
    //     targetScroll.style.borderRadius = '10px'
    //     targetScroll.style.backgroundColor = '#b2e2f2'

    //     setTimeout(() => {
    //       targetScroll.style.backgroundColor = 'unset'
    //       targetScroll.style.borderRadius = 'unset'
    //     }, 1800)
    //   }
    //   handleFillAlert()
    // }

    navigate(`/customers/${customerID}/customer`)
  }, [customerID, navigate])

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
    //@ts-ignore
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light'
          ? 'rgb(55, 65, 81)'
          : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }))

  const containerStyle = React.useMemo(() => {
    return {
      backgroundColor: 'transparent',
      bottom: bottom ? bottom : 'unset',
      left: left ? left : 'unset',
      position: 'fixed',
      right: right ? right : 'unset',
      top: top ? top : 'unset',
      zIndex: 100,
    }
  }, [bottom, left, right, top])

  const buttonMenuStyle = React.useMemo(() => {
    return {
      alignItems: 'center',
      backgroundColor: c.themeTuple[theme.palette.mode].paper.backgroundColor!,
      boxShadow:
        '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);',
      borderRadius: '50%',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
      cursor: 'pointer',
      display: 'flex',
      height: { xs: '70px', sm: '90px', md: '100px' },
      justifyContent: 'center',
      width: { xs: '70px', sm: '90px', md: '100px' },
      zIndex: 7,
    }
  }, [theme.palette.mode])

  const links = React.useMemo(
    () =>
      targetNavs.map((value) => (
        <Links efficiency={value} handleClose={handleClose} key={value} />
      )),

    [handleClose],
  )

  return (
    <Box sx={containerStyle}>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        sx={buttonMenuStyle}
      >
        <FormatListBulletedIcon sx={sx['formatListBullet']} />
      </Button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {links}
        <MenuItem disableRipple onClick={handleAuditorReport}>
          <FlareIcon />
          Auditor Report
        </MenuItem>

        {c.IS_MAIN && (
          <MenuItem disableRipple onClick={handleCustomerReport}>
            <FlareIcon />
            Customer Report
          </MenuItem>
        )}
      </StyledMenu>
    </Box>
  )
})

const targetNavs = Object.keys(efficiencyToInputs)

const sx = {
  formatListBullet: { fontSize: { md: '60px', sm: '50px', xs: '40px' } },
  muiLink: { textDecoration: 'none' },
}

interface LinkProps {
  efficiency: string
  handleClose: () => void
}

const Links = React.memo<LinkProps>(({ efficiency, handleClose }) => {
  const theme = useTheme()

  const efficiencyLinkStyle = React.useMemo(() => {
    return {
      color: theme.palette.mode === 'dark' ? '#ffff' : '#000',
    }
  }, [theme.palette.mode])

  const handleScroll = React.useCallback(() => {
    handleClose()
    const target = document.getElementById(`${efficiency}`)
    if (!target) {
      return
    }

    target.style.borderRadius = '10px'
    target.style.backgroundColor = '#b2e2f2'

    setTimeout(() => {
      target.style.backgroundColor = 'unset'
      target.style.borderRadius = 'unset'
    }, 1800)
  }, [efficiency, handleClose])

  return (
    <MuiLink
      href={`#${efficiency}`}
      key={efficiency}
      onClick={handleScroll}
      sx={sx['muiLink']}
    >
      <MenuItem disableRipple>
        <FlareIcon />
        <Typography sx={efficiencyLinkStyle}>
          {getFieldLabel(efficiency as keyof Customer)}
        </Typography>
      </MenuItem>
    </MuiLink>
  )
})
