import * as common from '../../common'

export type TranslateDictionary = common.DeepReadonly<
  Record<common.Lang, common.LangText>
>

export const translateText = (solarCompany: string) => {
  let companyPrefix = ''

  if (solarCompany.endsWith('_HIL')) {
    companyPrefix = '- HIL '
  }

  const californiaReleaseOfLiabilityTranslate: TranslateDictionary = {
    ENG: {
      main1: `This Waiver of Liability is made on this`,
      main2: `day of`,
      main3: `between `,
      main4: `California Power Solutions ${companyPrefix}`,
      main5: `with address of 2600 Ventura St, Fresno, CA 93721 (hereinafter
        “CPS”) and`,
      main6: `with address of`,

      main7: `(hereinafter “Client”), Application ID:`,
      b1: `1. Release:`,
      p1: `Client and anyone claiming on Client’s behalf releases and forever discharges
      CPS and its affiliates, successors, officers, employees, representatives, partners, agents
      and anyone claiming through them (collectively, the “Released Parties”), in their
      individual and/or corporate capacities from claims, liabilities, obligations, previous
      promises or agreements, current or future disputes, demands, damages, causes of
      action (hereinafter “Claims”) which Client may have against CPS or any of the Released
      Parties arising out of or relating to the installation of products and/or performance of
      services previously completed or to be completed.`,

      b2: `2. Consideration:`,
      p2a: `In consideration for the release of Claims, CPS will provide Client a
            payment in the amount of`,
      p2b: `In exchange for such payment, Client agrees
            to accept the payment as full and complete settlement and satisfaction of any and all
            present and prospective Claims.`,

      b3: `3. Cancellation:`,
      p3: `Upon receipt and acceptance of payment noted in Item 2 and outside of
      the legally mandated term for cancellation, Client waives any further rights to cancel,
      reverse, uninstall, or undo or dispute the installation of products and services upon
      which basis the payment is made.`,
      b4: `4. No admission:`,
      p4: `This Release shall not be in any way construed as an admission by the
      CPS that it has acted wrongfully with respect to Releasor or any other person, that it
      admits liability or responsibility at any time for the reasons stated herein, or that Client
      has any other claims whatsoever against the CPS.`,
      b5: `5. Other Provisions::`,
      p5: `This Release shall be binding upon the parties and their respective
      heirs, administrators, personal representatives, executors, successors and assigns. Client
      has the authority to release the Claims and has not assigned or transferred any Claims
      or rights to Claims to any other party. The provisions of this Release are severable. If any
      provision is held to be invalid or unenforceable, it shall not affect the validity or
      enforceability of any other provision. This Waiver and Release constitutes the entire
      agreement between the parties and supersedes any and all prior oral or written
      agreements or understandings between the parties concerning the subject matter of
      this Release. This Waiver and Release does not supersede nor release the Client from
      obligations under previous contracts or agreements with respect to the performance of
      work. This Release may not be altered, amended or modified, except by written mutual
      consent.`,

      b6: `6. Jurisdiction:`,
      p6: `The terms of this Waiver shall be governed by and construed in accordance
      with the laws of the State of California.`,

      noteDate: `(Input date)`,
    },
    ES: {
      main1: `Esta renuncia de responsabilidad se realiza el día`,

      main2: `del mes`,

      main3: `entre , `,
      main4: `California Power Solutions ${companyPrefix}`,
      main5: `con dirección en 2600 Ventura St, Fresno, CA 93721
      (en adelante "CPS") y`,
      main6: `con dirección en`,

      main7: `(en adelante "Cliente"), Número de Identificación de la Aplicación:`,
      b1: `1. Liberación:`,
      p1: `El Cliente y cualquier persona que actúe en nombre del Cliente libera y
      descarga para siempre a CPS y sus afiliados, sucesores, funcionarios, empleados,
      representantes, socios, agentes y cualquier persona que actúe a través de ellos (en
      conjunto, las "Partes Liberadas"), en sus capacidades individuales y/o corporativas,
      de reclamaciones, responsabilidades, obligaciones, promesas anteriores o acuerdos,
      disputas actuales o futuras, demandas, daños, causas de acción (en adelante
      "Reclamaciones") que el Cliente pueda tener contra CPS o cualquiera de las Partes
      Liberadas derivadas de o relacionadas con la instalación de productos y/o la
      realización de servicios previamente completados o por completar.`,
      b2: `2. Contraprestación:`,
      p2a: `Como contraprestación por la liberación de las Reclamaciones, CPS
      proporcionará al Cliente un pago por la cantidad de`,
      p2b: `A cambio de dicho pago,
      el Cliente acepta el pago como liquidación completa y satisfacción de todas y cada
      una de las Reclamaciones presentes y futuras.`,
      b3: `3. Cancelación:`,
      p3: `Tras recibir y aceptar el pago mencionado en el Punto 2 y fuera del plazo
      legalmente establecido para la cancelación, el Cliente renuncia a cualquier otro
      derecho para cancelar, revertir, desinstalar, deshacer o impugnar la instalación de
      productos y servicios en base a los cuales se realiza el pago.`,
      b4: `4. Sin admisión:`,
      p4: `Esta Renuncia no debe interpretarse de ninguna manera como una
      admisión por parte de CPS de que ha actuado de manera incorrecta con respecto al
      Liberador o cualquier otra persona, que admite responsabilidad en cualquier
      momento por los motivos establecidos aquí, o que el Cliente tenga cualquier otro
      reclamo contra CPS.`,
      b5: `5. Otras Disposiciones:`,
      p5: `Esta Renuncia será vinculante para las partes y sus respectivos
      herederos, administradores, representantes personales, ejecutores, sucesores y
      cesionarios. El Cliente tiene la autoridad para liberar las Reclamaciones y no ha
      asignado ni transferido ninguna Reclamación o derecho a Reclamaciones a ninguna otra
      parte. Las disposiciones de esta Renuncia son independientes. Si alguna disposición se
      considera inválida o inaplicable, no afectará la validez o aplicabilidad de ninguna otra
      disposición. Esta Renuncia y Liberación constituye el acuerdo completo entre las partes
      y reemplaza cualquier y todos los acuerdos o entendimientos previos, orales o escritos,
      entre las partes con respecto al tema de esta Renuncia. Esta Renuncia y Liberación no
      reemplaza ni libera al Cliente de las obligaciones bajo contratos anteriores o acuerdos con respecto a la realización de trabajo. Esta Renuncia no puede ser modificada,
      enmendada o modificada, excepto por consentimiento mutuo por escrito.`,
      b6: `6. Jurisdicción:`,
      p6: `Los términos de esta Renuncia estarán regidos por y se interpretarán de
      acuerdo con las leyes del Estado de California.`,
      noteDate: `(Entrada de fecha)`,
    },
  }

  const nevadaReleaseOfLiabilityTranslate: TranslateDictionary = {
    ENG: {
      main1: `This Waiver of Liability is made on this`,

      main2: `day of`,

      main3: `, between`,
      main4: `United Power Solutions ${companyPrefix}`,
      main5: ` with address of 6543 S. Las Vegas Blvd. (2nd Floor), Las Vegas, NV 89119
      (hereinafter “UPS”) and`,
      main6: `with address of`,
      main7: `(hereinafter “Client”), Application ID:`,
      b1: `1.Release:`,
      p1: `Client and anyone claiming on Client’s behalf releases and forever discharges
      UPS and its affiliates, successors, officers, employees, representatives, partners, agents
      and anyone claiming through them (collectively, the “Released Parties”), in their
      individual and/or corporate capacities from claims, liabilities, obligations, previous
      promises or agreements, current or future disputes, demands, damages, causes of
      action (hereinafter “Claims”) which Client may have against UPS or any of the Released
      Parties arising out of or relating to the installation of products and/or performance of
      services previously completed or to be completed`,

      b2: `2.Consideration:`,
      p2a: `In consideration for the release of Claims, UPS will provide Client a
            payment in the amount of`,
      p2b: `In exchange for such payment, Client agrees
            to accept the payment as full and complete settlement and satisfaction of any and all
            present and prospective Claims.`,
      b3: `3. Cancellation:`,

      p3: `Upon receipt and acceptance of payment noted in Item 2 and outside of
           the legally mandated term for cancellation, Client waives any further rights to cancel,
           reverse, uninstall, or undo or dispute the installation of products and services upon
           which basis the payment is made.`,

      b4: `4. No admission:`,

      p4: `This Release shall not be in any way construed as an admission by the
           UPS that it has acted wrongfully with respect to Releasor or any other person, that it
           admits liability or responsibility at any time for the reasons stated herein, or that Client
           has any other claims whatsoever against the UPS.`,

      b5: `5. Other Provisions:`,

      p5: `This Release shall be binding upon the parties and their respective
           heirs, administrators, personal representatives, executors, successors and assigns. Client
           has the authority to release the Claims and has not assigned or transferred any Claims
           or rights to Claims to any other party. The provisions of this Release are severable. If any
           provision is held to be invalid or unenforceable, it shall not affect the validity or
           enforceability of any other provision. This Waiver and Release constitutes the entire
           agreement between the parties and supersedes any and all prior oral or written
           agreements or understandings between the parties concerning the subject matter of
           this Release. This Waiver and Release does not supersede nor release the Client from
           obligations under previous contracts or agreements with respect to the performance of
           work. This Release may not be altered, amended or modified, except by written mutual
           consent.`,
      b6: `6. Jurisdiction:`,
      p6: `The terms of this Waiver shall be governed by and construed in accordance
           with the laws of the State of Nevada.`,

      noteDate: `(Input date)`,
    },
    ES: {
      main1: `Esta renuncia de responsabilidad se realiza el día`,

      main2: `del mes`,

      main3: `entre ,`,
      main4: `United Power Solutions ${companyPrefix}`,
      main5: ` con dirección en 6543 S. Las Vegas Blvd. (2nd Floor),
      Las Vegas, NV 89119 (en adelante "UPS") y`,
      main6: `con dirección en`,

      main7: `(en adelante "Cliente"), Número de Identificación de la Aplicación:`,
      b1: `1. Liberación:`,
      p1: `El Cliente y cualquier persona que actúe en nombre del Cliente libera y
      descarga para siempre a UPS y sus afiliados, sucesores, funcionarios, empleados,
      representantes, socios, agentes y cualquier persona que actúe a través de ellos (en
      conjunto, las "Partes Liberadas"), en sus capacidades individuales y/o corporativas,
      de reclamaciones, responsabilidades, obligaciones, promesas anteriores o acuerdos,
      disputas actuales o futuras, demandas, daños, causas de acción (en adelante
      "Reclamaciones") que el Cliente pueda tener contra UPS o cualquiera de las Partes
      Liberadas derivadas de o relacionadas con la instalación de productos y/o la
      realización de servicios previamente completados o por completar.`,
      b2: `2. Contraprestación::`,
      p2a: `Como contraprestación por la liberación de las Reclamaciones, UPS
      proporcionará al Cliente un pago por la cantidad de`,
      p2b: `A cambio de dicho
      pago, el Cliente acepta el pago como liquidación completa y satisfacción de todas y
      cada una de las Reclamaciones presentes y futuras.`,
      b3: `3. Cancelación:`,
      p3: `Tras recibir y aceptar el pago mencionado en el Punto 2 y fuera del plazo
      legalmente establecido para la cancelación, el Cliente renuncia a cualquier otro
      derecho para cancelar, revertir, desinstalar, deshacer o impugnar la instalación de
      productos y servicios en base a los cuales se realiza el pago.`,
      b4: `4. Sin admisión:`,
      p4: `Esta Renuncia no debe interpretarse de ninguna manera como una
      admisión por parte de UPS de que ha actuado de manera incorrecta con respecto al
      Liberador o cualquier otra persona, que admite responsabilidad en cualquier
      momento por los motivos establecidos aquí, o que el Cliente tenga cualquier otro
      reclamo contra UPS.`,
      b5: `5. Otras Disposiciones`,
      p5: `Esta Renuncia será vinculante para las partes y sus respectivos
      herederos, administradores, representantes personales, ejecutores, sucesores y
      cesionarios. El Cliente tiene la autoridad para liberar las Reclamaciones y no ha
      asignado ni transferido ninguna Reclamación o derecho a Reclamaciones a ninguna otra
      parte. Las disposiciones de esta Renuncia son independientes. Si alguna disposición se
      considera inválida o inaplicable, no afectará la validez o aplicabilidad de ninguna otra
      disposición. Esta Renuncia y Liberación constituye el acuerdo completo entre las partes
      y reemplaza cualquier y todos los acuerdos o entendimientos previos, orales o escritos,
      entre las partes con respecto al tema de esta Renuncia. Esta Renuncia y Liberación no reemplaza ni libera al Cliente de las obligaciones bajo contratos anteriores o acuerdos
      con respecto a la realización de trabajo. Esta Renuncia no puede ser modificada,
      enmendada o modificada, excepto por consentimiento mutuo por escrito.`,
      b6: `6. Jurisdicción:`,
      p6: `Los términos de esta Renuncia estarán regidos por y se interpretarán de
      acuerdo con las leyes del Estado de Nevada.`,
      noteDate: `(Entrada de fecha)`,
    },
  }

  const generalReleaseOfLiabilityTranslate: TranslateDictionary = {
    ENG: {
      main1: 'This General Release (this “Release”) is made on',
      main2: 'between',
      main3: 'and',
      b1: `1.`,
      p1: `Releasor and anyone claiming on behalf of Releasor releases and
      forever discharges Releasee and its affiliates, successors and
      assigns, officers, employees, representatives, partners, agents and
      anyone claiming through them (collectively, the “Released Parties”),
      in their individual and/or corporate capacities from any and all
      claims outside the scope of Solar installation and liabilities,
      obligations, promises, agreements, disputes, demands, damages, causes
      of action of any nature and kind, known or unknown, which Releasor has
      or ever had or may in the future have against Releasee or any of the
      Released Parties arising out of or relating to: Adders to the system
      outside the scope of Solar Installation.(“Claims”).`,
      b2: `2.`,
      p2: `This Release shall not be in any way construed as an admission by
      the Releasee that it has acted wrongfully with respect to Releasor or
      any other person, that it admits liability or responsibility at any
      time for any purpose, or that Releasor has any rights whatsoever
      against the Releasee.`,
      b3: `3.`,
      p3: `This Release shall be binding upon and inure to the benefit of the
      parties and their respective heirs, administrators, personal
      representatives, executors, successors and assigns. Releasor has the
      authority to release the Claims and has not assigned or transferred
      any Claims to any other party. The provisions of this Release are
      severable. If any provision is held to be invalid or unenforceable, it
      shall not affect the validity or enforceability of any other
      provision. This Release constitutes the entire agreement between the
      parties and supersedes any and all prior oral or written agreements or
      understandings between the parties concerning the subject matter of
      this Release. This Release may not be altered, amended or modified,
      except by a written document signed by both parties. The terms of this
      Release shall be governed by and construed in accordance with the laws
      of the State/Commonwealth of`,
      b4: `4.`,
      p4: `Both parties represent they fully understand their right to review
      all aspects of this Release with attorneys of their choice, that they
      have had the opportunity to consult with attorneys of their choice,
      that they have carefully read and fully understand all the provisions
      of this Release and that they are freely, knowingly and voluntarily
      entering into this Release.`,
    },

    ES: {
      main1: 'Esta Autorización General (esta “Autorización”) se realiza el',
      main2: 'entre',
      main3: 'y',
      b1: `1.`,
      p1: `El Liberador y cualquier persona que reclame en nombre del Liberador libera y
      libera para siempre al Liberado y sus afiliados, sucesores y
      cesionarios, funcionarios, empleados, representantes, socios, agentes y
      cualquier persona que reclame a través de ellos (colectivamente, las “Partes Eximidas”),
      en sus capacidades individuales y/o corporativas de todos y cada uno de
      reclamaciones fuera del alcance de la instalación solar y responsabilidades,
      obligaciones, promesas, acuerdos, disputas, demandas, daños, causas
      de acción de cualquier naturaleza y tipo, conocida o desconocida, que el Liberador haya
      o alguna vez tuvo o pueda tener en el futuro contra el Liberado o cualquiera de los
      Partes liberadas que surjan de o estén relacionadas con: Agregadores al sistema
      fuera del alcance de la Instalación Solar. (“Reclamaciones”).`,
      b2: `2.`,
      p2: `Esta Liberación no se interpretará de ninguna manera como una admisión por parte
      del Liberado que ha actuado incorrectamente con respecto al Liberador o
      cualquier otra persona, que admite liabilidad o responsabilidad en cualquier
      tiempo para cualquier propósito, o que el Liberador tiene algún derecho
      contra la Liberación.`,
      b3: `3.`,
      p3: `Esta Liberación será vinculante y redundará en beneficio de la
      partes y sus respectivos herederos, administradores, personas
      representantes, albaceas, sucesores y cesionarios. El liberador tiene la
      autoridad para liberar las Reclamaciones y no ha cedido ni transferido
      cualquier Reclamación a cualquier otra parte. Las disposiciones de este comunicado son
      SEVERABLE_IN_SPANISH. Si alguna disposición se considera inválida o inaplicable,
      no afectará la validez o aplicabilidad de cualquier otro
      disposición. Esta Liberación constituye el acuerdo completo entre las
      partes y reemplaza todos y cada uno de los acuerdos orales o escritos anteriores o
      entendimientos entre las partes respecto del objeto del
      esta versión. Este comunicado no puede ser alterado, enmendado o modificado,
      excepto mediante documento escrito firmado por ambas partes. Los términos de esta
      liberación se regirán e interpretarán de acuerdo con las leyes
      del Estado/Comunidad de`,
      b4: `4.`,
      p4: `Ambas partes declaran que comprenden plenamente su derecho a revisar
      todos los aspectos de esta Liberación con abogados de su elección, que
      han tenido la oportunidad de consultar con abogados de su elección,
      que han leído atentamente y comprenden plenamente todas las disposiciones
      de esta Liberación y que entran libre, consciente y voluntariamente
      a la celebración de esta Liberación.`,
    },
  }

  if (
    solarCompany === 'CPS' ||
    solarCompany === 'CPS_HIL' ||
    solarCompany === 'CPS_RC'
  ) {
    return californiaReleaseOfLiabilityTranslate
  }

  if (
    solarCompany === 'NPS' ||
    solarCompany === 'NPS_HIL' ||
    solarCompany === 'NPS_RC'
  ) {
    return nevadaReleaseOfLiabilityTranslate
  }

  return generalReleaseOfLiabilityTranslate
}
