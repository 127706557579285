import React from 'react'

import * as Mui from '@mui/material'

export interface QualifyButtonProps {
  onClick: () => void
}

export default React.memo<QualifyButtonProps>(function QualifyButton({
  onClick,
}) {
  return (
    <Mui.Box sx={sx['qualifyButtonGrid']}>
      <Mui.Button
        onClick={onClick}
        sx={sx['qualifyButton']}
        variant="contained"
      >
        <Mui.Typography component="span" sx={sx['qualifyButtonLabel']}>
          QUALIFY
        </Mui.Typography>
      </Mui.Button>
    </Mui.Box>
  )
})

const sx = {
  qualifyButton: {
    backgroundColor: '#F3A741',
    borderRadius: '0.8vw',
    letterSpacing: '0.28vw',
    minWidth: 'unset',
    padding: {
      lg: 'calc(1200px*0.01)',
      xs: '1w',
    },
    fontSize: {
      lg: 'calc(1200px*0.01)',
      xs: '1w',
    },
    '&:hover': {
      backgroundColor: '#F3A741',
    },
  },
  qualifyButtonGrid: {
    display: 'grid',
    gap: '1vw',
    gridTemplateRows: '1fr',
    marginLeft: '1.4vw',
  },
  qualifyButtonLabel: {
    alignItems: 'inherit',
    display: 'inherit',
    fontSize: {
      lg: 'calc(1200px*0.02)',
      xs: '2vw',
    },
    justifyContent: 'inherit',
    width: '100%',
  },
}
