import React from 'react'
import {
  cashBackOptions,
  Customer,
  getTotalCashback,
  Opt,
  parseCashbackOptions,
  updateCustomer,
} from '../../../common'

import Alert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import ConnectedInput from '../ConnectedInput'
import Pad from '../Pad'
import TextField from '@mui/material/TextField'
import { FormControl, FormHelperText, SxProps, Theme } from '@mui/material'

export interface CashbacksProps {
  customer?: Customer
  field: keyof Customer
  customerID: string
}

export default React.memo<CashbacksProps>(function Cashbacks({
  customer,
  customerID,
  field,
}) {
  const cashbackOptions = React.useMemo(() => {
    if (!customer?.cash_options) {
      return []
    }
    return parseCashbackOptions(customer.cash_options)
  }, [customer?.cash_options])

  const cashbackTotal = React.useMemo(() => {
    if (!customer) {
      return 0
    }
    return getTotalCashback(cashbackOptions, customer)
  }, [cashbackOptions, customer])

  const cashbackShowError = React.useMemo((): boolean => {
    if (!customer) {
      return false
    }
    for (const cashbackOption of cashbackOptions) {
      const value = customer[cashbackOption.value as keyof Customer] as string
      if (!value) {
        return true
      }
    }
    return false
  }, [cashbackOptions, customer])

  const optionsAutoComplete = React.useMemo(() => {
    return options.filter(
      (value, i) => value.value !== cashbackOptions[i]?.value,
    )
  }, [cashbackOptions])

  const hideCashbackWarning = React.useMemo(() => {
    if (!customer) {
      return false
    }
    return (
      Number(customer.cash_amount) === cashbackTotal ||
      cashbackShowError ||
      !customer.cash_amount ||
      !cashbackOptions.length
    )
  }, [cashbackOptions, cashbackShowError, cashbackTotal, customer])

  const styleCashBackWarning = React.useMemo(
    (): SxProps<Theme> => ({
      display: hideCashbackWarning ? 'none' : 'flex',
      marginTop: '30px',
      width: { xs: '90%', sm: 258, lg: 298 },
    }),
    [hideCashbackWarning],
  )

  const styleContentInputAutoComplete = React.useMemo((): SxProps<Theme> => {
    return {
      border: hideCashbackWarning ? 'unset' : '10px solid #fff4e5',
      display: 'flex',
      flexDirection: 'column',
    }
  }, [hideCashbackWarning])

  const styleContainer = React.useMemo((): SxProps<Theme> => {
    return {
      display: 'flex',
      flexDirection: 'column',
      width: 'max-content',
      boxSizing: 'content-box',
    }
  }, [])

  const onChangeAutoComplete = React.useCallback(
    (_, value) => {
      if (!value) {
        return
      }

      const _cashbacks = value as Opt[]

      updateCustomer(customerID, {
        cash_options: JSON.stringify(_cashbacks),
      })
    },
    [customerID],
  )

  const renderInput = React.useCallback(
    (params) => <TextField {...params} label="Cashback Efficiencies" />,
    [],
  )

  const optionLabel = React.useCallback((option) => option.label, [])

  const cashbackOption = React.useCallback(
    (option): React.ReactElement => {
      return (
        <Box key={option.label} sx={styleContentInput}>
          <ConnectedInput
            customerID={customerID}
            field={option.value as keyof Customer}
          />
          <Pad amt={20} />
        </Box>
      )
    },
    [customerID],
  )

  if (!customer) {
    return null
  }

  return (
    <Box sx={styleContainer}>
      <ConnectedInput customerID={customerID} field={field} />
      <Alert severity="warning" sx={styleCashBackWarning}>
        The amounts entered don't add up to the cashback amount above
      </Alert>
      <Box sx={styleContentInputAutoComplete}>
        {!!customer.cash_amount && (
          <FormControl>
            <Pad amt={30} />

            <Autocomplete
              multiple
              limitTags={2}
              id="multiple-limit-tags"
              options={optionsAutoComplete}
              getOptionLabel={optionLabel}
              value={cashbackOptions}
              onChange={onChangeAutoComplete}
              renderInput={renderInput}
              sx={styleAutoComplete}
            />
            <FormHelperText
              sx={cashbackOptions.length ? sx['hide'] : sx['autoCompleteAlert']}
            >
              Empty selection!
            </FormHelperText>
          </FormControl>
        )}

        <Pad amt={30} />

        {cashbackOptions.map(cashbackOption)}
      </Box>
      <Pad amt={30} />
    </Box>
  )
})

const styleContentInput = { alignSelf: 'center' }

const styleAutoComplete = {
  width: { xs: '90%', sm: 258, lg: 298 },
  alignSelf: 'center',
}

const options = cashBackOptions()

const sx = {
  autoCompleteAlert: {
    color: 'red',
    display: 'unset',
  },
  hide: {
    display: 'none',
  },
}
