import React, { ReactElement } from 'react'

import * as common from '../../../common'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { PDFDownloadLink } from '@react-pdf/renderer'

import { useParams } from 'react-router-dom'

import Modal from '../../comps/Modal/Modal'
import Pad from '../../comps/Pad'
import PageReport from '../../comps/PageReport'
import Signature from '../../comps/Signature'
import ReleaseLiabilityPage from '../../comps/ReleaseLiabilityPage'
import downloadPDF from '../../icons/downloadPDF.png'
import loadingIcon from '../../icons/loading.png'

import CashbackOptions from './CashbackOptions'
import ChecksInputs from './ChecksInputs'
import PDFServices from './PDFServices'
import styles from './Services.module.css'

export interface ServicesProps {
  isTest?: boolean
}

export default React.memo<ServicesProps>(function Services({ isTest }) {
  const [lang, setLang] = React.useState<common.Lang>('ENG')

  const handleLang = React.useCallback(() => {
    setLang((current) => (current === 'ENG' ? 'ES' : 'ENG'))
  }, [setLang])

  const [company, setCompany] = React.useState<string>(
    common.COMPANY_VALUES[0]!,
  )
  const { customerID } = useParams()

  // const [total, setTotal] = React.useState<number>(0)

  const initialState: CheckInput[] = [{ check: true, text: '', price: '' }]

  const [checkInputs, setCheckInputs] =
    React.useState<CheckInput[]>(initialState)
  const [signatureModalOpen, setSignatureModalOpen] = React.useState(false)
  const [companySignatureModalOpen, setCompanySignatureModalOpen] =
    React.useState(false)

  const toggleSignatureModal = React.useCallback(() => {
    setSignatureModalOpen((current) => !current)
  }, [setSignatureModalOpen])

  const toggleCompanySignatureModal = React.useCallback(() => {
    setCompanySignatureModalOpen((current) => !current)
  }, [setCompanySignatureModalOpen])

  const handleCompanyLogo = React.useCallback(() => {
    if (company === 'CPS') {
      setCompany('NPS')
    } else {
      setCompany('CPS')
    }
  }, [setCompany, company])

  React.useEffect((): common.VoidFn => {
    if (!customerID) {
      return common.EMPTY_FN
    }
    common.dispatch(
      common.subToSingleCustomer({
        customerID: customerID,
      }),
    )
    return () => {
      common.dispatch(
        common.unSubFromSingleCustomer({
          customerID,
        }),
      )
    }
  }, [customerID])

  const selectCustomer = React.useMemo(
    (): ReturnType<typeof common.makeSelectCustomer> =>
      common.makeSelectCustomer(),
    [],
  )

  const selectCustomerArgs = React.useMemo((): common.SelectCustomerParams => {
    if (typeof customerID === 'string') {
      return {
        customerID,
      }
    } else {
      return {
        customerID: '',
      }
    }
  }, [customerID])

  const customer = common.useSelector(
    (_): common.Customer => selectCustomer(_, selectCustomerArgs),
  )

  const cashbackOptions = React.useMemo(() => {
    if (customer.cash_options) {
      try {
        const _options = JSON.parse(customer.cash_options) as common.Opt[]
        return _options
      } catch (e) {
        return []
      }
    }
    return []
  }, [customer.cash_options])

  const cashbackTotal = React.useMemo(() => {
    let acc = 0
    if (cashbackOptions.length) {
      cashbackOptions.forEach((option) => {
        if (customer[option.value as keyof common.Customer]) {
          acc += parseInt(
            customer[option.value as keyof common.Customer] as string,
          )
        }
      })
    }

    return acc
  }, [cashbackOptions, customer])

  const efficienciesTotal = React.useMemo(() => {
    let total = 0
    if (!checkInputs.length) return total

    checkInputs.forEach((value) => {
      total += value.price ? parseInt(value.price) : 0
    })

    return total
  }, [checkInputs])

  // React.useEffect(() => {
  //   if (cashbackTotal !== parseInt(customer.cash_amount)) {
  //     return
  //   }

  //   setTotal((current) => current + cashbackTotal)
  // }, [cashbackTotal])

  React.useEffect(() => {
    if (customer.solarCompany === 'CPS') {
      setCompany('CPS')
    } else {
      setCompany('NPS')
    }
  }, [customer])

  const fullAmount = React.useMemo(() => {
    if (cashbackTotal === parseInt(customer.cash_amount)) {
      return cashbackTotal + efficienciesTotal
    }

    return efficienciesTotal
  }, [cashbackTotal, customer.cash_amount, efficienciesTotal])

  const services = React.useMemo(() => {
    if (!customerID) {
      return null
    }

    return (
      <PDFServices
        company={company}
        customerName={customer.customerName}
        customerSignature={customer.c_signature}
        signatureDate={customer.release_liability_date}
        solarCompany={customer.solarCompany}
        total={fullAmount}
        cashAmount={customer.cash_amount}
        cashbackTotal={cashbackTotal}
        options={cashbackOptions}
        customer={customer}
        checksInputs={checkInputs}
      />
    )
  }, [
    customerID,
    company,
    customer,
    fullAmount,
    cashbackTotal,
    cashbackOptions,
    checkInputs,
  ])

  return (
    <div className={styles['container']}>
      <Pad amt={30} />
      <PageReport
        title="Energy Efficiencies"
        company={company}
        companyBorder={true}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button
            endIcon={<AddCircleOutlineIcon />}
            variant="contained"
            onClick={() => {
              setCheckInputs((currenValue) => [
                ...currenValue,
                { check: true, text: '', price: '' },
              ])
            }}
          >
            Add
          </Button>
          <Pad amt={20} />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {checkInputs.map((value, i) => (
              <ChecksInputs
                checkRow={value}
                i={i}
                setCheckInputs={setCheckInputs}
                key={i}
                len={checkInputs.length}
              />
            ))}

            {cashbackTotal === parseInt(customer['cash_amount']) && (
              <React.Fragment>
                {cashbackOptions.map((cashback) => (
                  <CashbackOptions
                    cashbackOption={cashback}
                    customer={customer}
                    key={cashback.value}
                  />
                ))}
              </React.Fragment>
            )}
            <Box sx={{ display: 'flex', width: '70%' }}>
              <Box sx={{ height: '46px', width: '46px' }} />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'flex',
                  height: '56px',
                  width: '230px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                  Total
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'flex',
                  height: '56px',
                  width: '230px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                  $ {fullAmount}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '24px',
                  height: '56px',
                  alignItems: 'center',
                }}
              />
            </Box>
          </Box>
        </Box>
      </PageReport>
      <Pad amt={30} />
      {/* RELEASE OF LIABILITY SECTION */}
      <PageReport
        title="Release of Liability"
        companyBorder={true}
        company={company}
      >
        <ReleaseLiabilityPage
          c_signature_date={customer.c_signature_date}
          c_signature={customer.c_signature}
          company={company}
          customerAddress={customer.customerAddress}
          customerID={customerID as string}
          customerName={customer.customerName}
          handleLang={handleLang}
          lang={lang}
          releaseLiabilityAmount={customer.release_of_liability_amount}
          releaseLiabilityDate={customer.release_liability_date}
          reportAddress={customer.report_address}
          reportAppID={customer.report_app_id}
          reportCompanySignature={customer.report_company_signature}
          reportCompanySignDate={customer.report_company_signature_date}
          reportCustomerName={customer.report_customer_name}
          solarCompany={customer.solarCompany}
          toggleCompanySignatureModal={toggleCompanySignatureModal}
          toggleSignatureModal={toggleSignatureModal}
        />
      </PageReport>

      <Modal open={signatureModalOpen} onRequestClose={toggleSignatureModal}>
        <Signature
          customerID={customerID as string}
          field="c_signature"
          onRequestClose={toggleSignatureModal}
        />
      </Modal>

      <Modal
        open={companySignatureModalOpen}
        onRequestClose={toggleCompanySignatureModal}
      >
        <Signature
          customerID={customerID as string}
          field="report_company_signature"
          onRequestClose={toggleCompanySignatureModal}
        />
      </Modal>

      {/* SWITCH BUTTON */}

      <div className={styles['switch-company']}>
        <input
          type="checkbox"
          id="btnSwitch"
          className={styles['btn-switch']}
        />
        <label
          htmlFor="btnSwitch"
          className={
            company === 'CPS'
              ? styles['lbl-switch-cps']
              : styles['lbl-switch-nps']
          }
          onClick={handleCompanyLogo}
        ></label>
      </div>

      {!isTest && (
        <PDFDownloadLink
          document={services as ReactElement}
          fileName={customer.customerName + '_' + company + '.pdf'}
          //@ts-ignore
          style={{ position: 'fixed', top: '100px', right: '20px' }}
        >
          {({ loading }) =>
            loading ? (
              <img
                className={styles['loading-animate']}
                src={loadingIcon}
                alt="Loading..."
              />
            ) : (
              <img
                className={styles['download-pdf']}
                src={downloadPDF}
                alt="Download PDF"
              />
            )
          }
        </PDFDownloadLink>
      )}
    </div>
  )
})

export interface CheckInput {
  check: boolean
  text: string
  price: string
}
