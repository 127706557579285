import React from 'react'
import * as rn from 'react-native'
import { Text as RNText } from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'

import * as gStyles from '../gStyles'

export type TextProps = c.r<{
  bold?: boolean | null | undefined
  /**
   * If iOS, it will wrap
   */
  centerText?: boolean | null | undefined
  children: any
  grow?: boolean
  numberOfLines?: number
  on?: c.SurfaceType
  padding?: rn.TextStyle['padding']
  paddingHorizontal?: rn.TextStyle['paddingHorizontal']
  right?: number
  size?: 8 | 10 | 12 | 14 | 16 | 18 | 20 | 22 | 24
  style?: rn.StyleProp<rn.TextStyle>
}>

export default React.memo<TextProps>(function Text({
  bold,
  centerText,
  children,
  grow,
  numberOfLines,
  on,
  size = 14,
  style,
}) {
  const s = r.useThemedStyleSheet(themedStyles, on)

  const txtStyle = ((): rn.TextStyle => {
    if (style) return style as rn.TextStyle

    if (size === 8) {
      if (centerText) return bold ? s.size8CenteredBold : s.size8Centered
      return bold ? s.size8Bold : s.size8
    } else if (size === 10) {
      if (centerText) return bold ? s.size10CenteredBold : s.size10Centered
      return bold ? s.size10Bold : s.size10
    } else if (size === 12) {
      if (centerText) return bold ? s.size12CenteredBold : s.size12Centered
      return bold ? s.size12Bold : s.size12
    } else if (size === 14) {
      if (centerText) return bold ? s.size14CenteredBold : s.size14Centered
      return bold ? s.size14Bold : s.size14
    } else if (size === 16) {
      if (centerText) return bold ? s.size16CenteredBold : s.size16Centered
      return bold ? s.size16Bold : s.size16
    } else if (size === 18) {
      if (centerText) return bold ? s.size18CenteredBold : s.size18Centered
      return bold ? s.size18Bold : s.size18
    } else if (size === 20) {
      if (centerText) return bold ? s.size20CenteredBold : s.size20Centered
      return bold ? s.size20Bold : s.size20
    } else if (size === 22) {
      if (centerText) return bold ? s.size22CenteredBold : s.size22Centered
      return bold ? s.size22Bold : s.size22
    } else if (size === 24) {
      if (centerText) return bold ? s.size24CenteredBold : s.size24Centered
      return bold ? s.size24Bold : s.size24
    }

    return s.defaultText
  })()

  const txtNode = (
    <RNText numberOfLines={numberOfLines} style={txtStyle}>
      {children}
    </RNText>
  )

  if ((centerText && rn.Platform.OS === 'ios') || grow) {
    /* textAlignVertical not working in iOS as of May 2024 */
    return (
      <rn.View style={grow ? s.centeredTextWrapperIOSGrow : gStyles.deadCenter}>
        {txtNode}
      </rn.View>
    )
  }

  return txtNode
})

const themedStyles = r.ThemedStyleSheet.create((t, on) => {
  const defaultText = { color: t[on].color, fontFamily: t.fontFamily }
  const defaultBold = { ...defaultText, fontWeight: '700' } as const

  const centeredText = {
    ...defaultText,
    textAlign: 'center',
    textAlignVertical: 'center',
  } as const
  const centeredBold = { ...defaultBold, ...centeredText }

  const size8 = { ...defaultText, fontSize: 8 }
  const size10 = { ...defaultText, fontSize: 10 }
  const size12 = { ...defaultText, fontSize: 12 }
  const size14 = { ...defaultText, fontSize: 14 }
  const size16 = { ...defaultText, fontSize: 16 }
  const size18 = { ...defaultText, fontSize: 18 }
  const size20 = { ...defaultText, fontSize: 20 }
  const size22 = { ...defaultText, fontSize: 22 }
  const size24 = { ...defaultText, fontSize: 24 }
  const size8Bold = { ...defaultBold, ...size8 }
  const size10Bold = { ...defaultBold, ...size10 }
  const size12Bold = { ...defaultBold, ...size12 }
  const size14Bold = { ...defaultBold, ...size14 }
  const size16Bold = { ...defaultBold, ...size16 }
  const size18Bold = { ...defaultBold, ...size18 }
  const size20Bold = { ...defaultBold, ...size20 }
  const size22Bold = { ...defaultBold, ...size22 }
  const size24Bold = { ...defaultBold, ...size24 }
  const size8Centered = { ...centeredText, ...size8 }
  const size10Centered = { ...centeredText, ...size10 }
  const size12Centered = { ...centeredText, ...size12 }
  const size14Centered = { ...centeredText, ...size14 }
  const size16Centered = { ...centeredText, ...size16 }
  const size18Centered = { ...centeredText, ...size18 }
  const size20Centered = { ...centeredText, ...size20 }
  const size22Centered = { ...centeredText, ...size22 }
  const size24Centered = { ...centeredText, ...size24 }
  const size8CenteredBold = { ...centeredBold, ...size8Bold }
  const size10CenteredBold = { ...centeredBold, ...size10Bold }
  const size12CenteredBold = { ...centeredBold, ...size12Bold }
  const size14CenteredBold = { ...centeredBold, ...size14Bold }
  const size16CenteredBold = { ...centeredBold, ...size16Bold }
  const size18CenteredBold = { ...centeredBold, ...size18Bold }
  const size20CenteredBold = { ...centeredBold, ...size20Bold }
  const size22CenteredBold = { ...centeredBold, ...size22Bold }
  const size24CenteredBold = { ...centeredBold, ...size24Bold }

  return {
    centeredBold,
    centeredText,
    defaultText,
    defaultBold,
    centeredTextWrapperIOSGrow: {
      ...gStyles.deadCenter,
      ...gStyles.flexGrow,
    },
    size8,
    size10,
    size12,
    size14,
    size16,
    size18,
    size20,
    size22,
    size24,
    size8Bold,
    size10Bold,
    size12Bold,
    size14Bold,
    size16Bold,
    size18Bold,
    size20Bold,
    size22Bold,
    size24Bold,
    size8Centered,
    size10Centered,
    size12Centered,
    size14Centered,
    size16Centered,
    size18Centered,
    size20Centered,
    size22Centered,
    size24Centered,
    size8CenteredBold,
    size10CenteredBold,
    size12CenteredBold,
    size14CenteredBold,
    size16CenteredBold,
    size18CenteredBold,
    size20CenteredBold,
    size22CenteredBold,
    size24CenteredBold,
    whiteColor: { color: '#FFF' },
  }
})
