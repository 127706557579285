import React from 'react'

import * as common from '../../../common'

import { Chart } from '../../helpers/Vars'
import Button from '@mui/material/Button'
import FinancingChart from '../../routes/auditor/FinancingChart'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import ReplayIcon from '@mui/icons-material/Replay'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Pad from '../Pad'
import PercentIcon from '@mui/icons-material/Percent'
import styles from './FinancingSection.module.css'

export interface FinancingSectionProps {
  cash: string
  chart: Chart
  company: string
  financingYears: number
  handleCash: (e: string) => void
  handleFinancingYears: (years: number) => void
  handleInterestFinancing: (e: any) => void
  interest: number
  interestFinancing: number
  payPerMonth: number
  totalRepayment: number
  resetCash: () => void
}

export default React.memo<FinancingSectionProps>(function FinancingSection({
  cash,
  chart,
  company,
  financingYears,
  handleCash,
  handleFinancingYears,
  handleInterestFinancing,
  interest,
  interestFinancing,
  payPerMonth,
  totalRepayment,
  resetCash,
}) {
  const handleAmountCash = React.useCallback(
    (e) => {
      handleCash(e)
    },
    [handleCash],
  )
  return (
    <div
      className={chart === 'FINANCING' ? styles['container'] : styles['hide']}
      style={{ marginBottom: 100 }}
    >
      {/*TOTAL COST INPUT */}
      <div className={styles['total-cost-input']}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel htmlFor="outlined-adornment-amount">
            Total Cost
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Total Cost"
            value={cash}
            className={styles['total-cost-input']}
            type="number"
            onChange={handleAmountCash}
            sx={{ width: '100%' }}
          />
        </FormControl>

        <ReplayIcon
          sx={{ alignSelf: 'center', fontSize: '29px', cursor: 'pointer' }}
          onClick={resetCash}
        />
      </div>

      <Pad amt={50} />
      <Stack direction="row" spacing={3}>
        <Button
          sx={{
            backgroundColor:
              5 === financingYears ? '#6C757D' : common.companyColors[company],
            borderRadius: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
            color: '#fff',
            '&:hover': {
              backgroundColor:
                5 === financingYears
                  ? '#6C757D'
                  : common.companyColors[company],
              color: '#fff',
            },
          }}
          onClick={() => handleFinancingYears(5)}
        >
          5 Years
        </Button>

        <Button
          sx={{
            backgroundColor:
              10 === financingYears ? '#6C757D' : common.companyColors[company],
            borderRadius: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
            color: '#fff',
            '&:hover': {
              backgroundColor:
                10 === financingYears
                  ? '#6C757D'
                  : common.companyColors[company],
              color: '#fff',
            },
          }}
          onClick={() => handleFinancingYears(10)}
        >
          10 Years
        </Button>

        <Button
          sx={{
            backgroundColor:
              12 === financingYears ? '#6C757D' : common.companyColors[company],
            borderRadius: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
            color: '#fff',
            '&:hover': {
              backgroundColor:
                12 === financingYears
                  ? '#6C757D'
                  : common.companyColors[company],
              color: '#fff',
            },
          }}
          onClick={() => handleFinancingYears(12)}
        >
          12 Years
        </Button>
        <Button
          sx={{
            backgroundColor:
              15 === financingYears ? '#6C757D' : common.companyColors[company],
            borderRadius: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
            color: '#fff',
            '&:hover': {
              backgroundColor:
                15 === financingYears
                  ? '#6C757D'
                  : common.companyColors[company],
              color: '#fff',
            },
          }}
          onClick={() => handleFinancingYears(15)}
        >
          15 Years
        </Button>
      </Stack>

      <Pad amt={100} />
      {/*FINANCING CONTENT */}
      <div className={styles['financing-content']}>
        {/*FINANCING CHART */}

        <div className={styles['financing-chart']}>
          <FinancingChart
            interest={interest}
            payPerMonth={payPerMonth}
            totalRepayment={totalRepayment}
          />
        </div>

        {/*FINANCING INFO */}
        <div className={styles['financing-info']}>
          {/* INTEREST */}
          <div className={styles['interest-text']}>Interest</div>
          <Pad amt={5} />
          <h1 className={styles['interest-price']}>
            {isNaN(interest)
              ? 0
              : `$ ${Math.round(interest).toLocaleString('en')}`}
          </h1>
          <Pad amt={20} />
          {/* ESTIMATED COST */}
          <div className={styles['estimated-cost-text']}>
            <span>Estimated Total Cost</span> <br />
            <span>(including taxes and interest)</span>
            <Pad amt={5} />
            <h1 className={styles['interest-price']}>
              {isNaN(totalRepayment)
                ? 0
                : `$ ${Math.round(totalRepayment).toLocaleString('en')}`}
            </h1>
          </div>
        </div>
      </div>

      <div className={styles['interest-rate']}>
        <TextField
          id="input-with-icon-textfield"
          type="number"
          label="Interest Rate"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PercentIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleInterestFinancing}
          defaultValue={interestFinancing * 100}
          sx={{ width: '100px' }}
          size="small"
        />
        <Pad amt={62} row />
      </div>
    </div>
  )
})
