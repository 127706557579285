import React from 'react'
import { Chart } from '../../helpers/Vars'
import styles from './CashDescription.module.css'
// import TextField from '@mui/material/TextField'
import Pad from '../Pad'
import ReplayIcon from '@mui/icons-material/Replay'

export interface CashDescriptionProps {
  cash: string
  handleCash: (e: any) => void
  resetCash: () => void
  chart: Chart
}

export default React.memo<CashDescriptionProps>(function CashDescription({
  cash,
  chart,
  handleCash,
  resetCash,
}) {
  return (
    <div className={chart === 'CASH' ? styles['container'] : styles['hide']}>
      <div className={styles['total-cash-title']}>
        Total Cash: $
        <input
          type="number"
          onChange={handleCash}
          style={{
            backgroundColor: 'white',
            color: 'black',
            fontSize: '36px',
            width: '230px',
            fontWeight: '700',
            marginLeft: '10px',
          }}
          value={cash}
        />
        <ReplayIcon
          onClick={resetCash}
          sx={{
            marginLeft: '15px',
            width: '32px',
            height: '32px',
            cursor: 'pointer',
          }}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <Pad amt={278} row />
        <span>Click to edit</span>
      </div>
      <Pad amt={600} />
    </div>
  )
})
