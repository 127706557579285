import React from 'react'

import ToastAlert from '../ToastAlert'

export default React.memo(function OfflineAlert() {
  const [open, setOpen] = React.useState(false)

  const handleClose = React.useCallback(() => {
    setOpen(false)
  }, [setOpen])

  React.useEffect(() => {
    window.addEventListener('offline', () => {
      setOpen(true)
    })
    window.addEventListener('online', () => {
      setOpen(false)
    })
  }, [])

  return (
    <ToastAlert
      duration={10000}
      handleClose={handleClose}
      message="You might be offline, check your signal"
      open={open}
      severity="warning"
      type="alert"
    />
  )
})
