import React, { ReactElement } from 'react'

import { useLocation } from 'react-router-dom'
import styles from './Modal.module.css'

export interface ModalProps {
  children: ReactElement
  onRequestClose(e: React.MouseEvent<HTMLElement, MouseEvent> | undefined): void
  open: boolean
}

export default React.memo<ModalProps>(function Modal({
  children,
  onRequestClose,
  open,
}) {
  const location = useLocation()
  React.useEffect(() => {
    const body = document.querySelector('body')

    if (!body) {
      return
    }

    if (open && location.pathname.endsWith('customers')) {
      //window.addEventListener('scroll', disableScroll)
      body.style.overflow = 'hidden'
      if (!location.pathname.endsWith('customers')) {
        body.style.overflow = 'unset'
      }
    } else {
      // window.removeEventListener('scroll', disableScroll)
      body.style.overflow = 'unset'
    }
  }, [open, location])

  return (
    <div className={open ? styles['container'] : styles['hidden']}>
      <div className={styles['box']}>
        <span className={styles['close']} onClick={onRequestClose}>
          X
        </span>
        {children}
      </div>
    </div>
  )
})

// function disableScroll() {
//   var x = window.scrollX
//   var y = window.scrollY
//   window.onscroll = function () {
//     window.scrollTo(x, y)
//   }
// }
