import * as Theming from './theming'

export interface GlobalEnv {
  ALGOLIA_API_KEY: string
  ALGOLIA_APP_ID: string
  BASE_URL: string
  CODENAME: string
  COMPANY_COLORS: string
  COMPANY_INSULATION: string
  COMPANY_LABELS: string
  COMPANY_STATES: string
  COMPANY_VALUES: string
  DISPLAY_NAME: string
  FB_APP_ID_ANDROID: string
  FB_APP_ID_IOS: string
  FB_APP_ID_WEB: string
  FB_DB_URL: string
  FB_FUNCTIONS_URL: string
  FB_PROJECT_ID: string
  FB_PROJECT_NUMBER: string
  FB_SENDER_ID: string
  G_API_KEY_ANDROID: string
  gApiKeyApi: string
  G_API_KEY_CLOUD: string
  G_API_KEY_IOS: string
  G_API_KEY_WEB: string
  G_CLIENT_ID_ANDROID: string
  G_CLIENT_ID_IOS: string
  G_CLIENT_ID_WEB: string
  HOLDING_NAME: string
  IS_MAIN: number
}

export const PLATFORM = (() => {
  try {
    // https://stackoverflow.com/a/39473604

    if (
      // @ts-ignore
      typeof navigator !== 'undefined' &&
      // @ts-ignore
      navigator.product === 'ReactNative'
    ) {
      return 'rn'
    }

    // @ts-ignore
    if (typeof document !== 'undefined') {
      return 'web'
    }

    if (
      // When running inside the cloud itself
      Boolean(process.env['FUNCTION_IDENTITY']) ||
      // Pre-deploy check
      Boolean(process.env['CLOUD_RUNTIME_CONFIG']) ||
      // Post-deploy check
      (process.env['FIREBASE_CONFIG'] &&
        process.env['GCLOUD_PROJECT'] &&
        process.env['EVENTARC_CLOUD_EVENT_SOURCE'] &&
        process.env['GCF_BLOCK_RUNTIME_go112'])
    ) {
      return 'cloud'
    }

    if (typeof module !== 'undefined' && !!module.exports) {
      return 'node'
    }
  } catch (e) {
    console.log('Error detecting platform:', e)
  }

  return 'unknown'
})()

console.log(`Platform: ${PLATFORM}`)

// Ordered in terms of confidence, but only one will be true at a time
export const IS_RN = PLATFORM === 'rn'
export const IS_WEB = PLATFORM === 'web'
// @ts-ignore
export const IS_NODE = PLATFORM === 'node'
export const IS_CLOUD = PLATFORM === 'cloud'

const raiseErr = (errMsg: string): void => {
  if (IS_RN) {
    // @ts-ignore
    if (__DEV__) {
      console.error(errMsg)
    } else {
      throw new Error(errMsg)
    }
  }
  if (IS_WEB || IS_NODE) {
    throw new Error(errMsg)
  }
  // Clouds gets littered with envvar missing errors
  // if (IS_CLOUD) {
  //   console.log(errMsg)
  // }
}

const GLOBAL_ENV_STR = process.env['REACT_APP_GLOBAL_ENV'] || '{}'

if (GLOBAL_ENV_STR === '{}') {
  raiseErr(
    `GLOBAL_ENV envvar missing. PLATFORM: ${PLATFORM}, env: ${JSON.stringify(
      process.env,
      null,
      2,
    )}`,
  )
}

export const globalEnv = JSON.parse(GLOBAL_ENV_STR) as GlobalEnv
const GLOBAL_ENV = globalEnv

//#region IS_MAIN
export const IS_MAIN = Boolean(GLOBAL_ENV.IS_MAIN)
export const IS_WHITE_LABEL = !IS_MAIN
//#endregion IS_MAIN

//#region CODENAME
export const CODENAME = GLOBAL_ENV.CODENAME || ''

if (!CODENAME) {
  const msg = 'CODENAME envvar missing'
  raiseErr(msg)
}
//#endregion CODENAME
//#region holdingName
export const HOLDING_NAME = GLOBAL_ENV.HOLDING_NAME || ''

if (typeof HOLDING_NAME !== 'string') {
  raiseErr('HOLDING_NAME envvar missing')
}

if (HOLDING_NAME.length < 2) {
  raiseErr('Error: Value for HOLDING_NAME is too short')
}
//#endregion holdingName
//#region COMPANY_VALUES
const COMPANY_VALUES_UNPARSED = GLOBAL_ENV.COMPANY_VALUES || ''

if (typeof COMPANY_VALUES_UNPARSED !== 'string') {
  const msg = `COMPANY_VALUES envvar not an string: ${COMPANY_VALUES_UNPARSED}`
  raiseErr(msg)
}

export const COMPANY_VALUES = COMPANY_VALUES_UNPARSED.split(':')

for (const subCompanyValue of COMPANY_VALUES) {
  if (subCompanyValue.includes(':') || subCompanyValue.length < 3) {
    const msg = `COMPANY_VALUES envvar malformed: ${COMPANY_VALUES_UNPARSED}`
    raiseErr(msg)
  }
}

export const companiesLen = COMPANY_VALUES.length
//#endregion COMPANY_VALUES
//#region COMPANY_LABELS
const COMPANY_LABELS_UNPARSED = GLOBAL_ENV.COMPANY_LABELS || ''

if (typeof COMPANY_LABELS_UNPARSED !== 'string') {
  const msg = `COMPANY_LABELS envvar not a string: ${COMPANY_LABELS_UNPARSED}`
  raiseErr(msg)
}

export const COMPANY_LABELS = COMPANY_LABELS_UNPARSED.split(':')

for (const subCompanyLabel of COMPANY_LABELS) {
  if (subCompanyLabel.includes(':') || subCompanyLabel.length < 3) {
    const msg = 'COMPANY_LABELS envvar malformed'

    raiseErr(msg)
  }
}

if (COMPANY_LABELS.length !== companiesLen) {
  const msg = 'COMPANY_LABELS and COMPANY_VALUES envvars not the same length'
  raiseErr(msg)
}
//#endregion COMPANY_LABELS
//#region GOOGLE_AUTH_CLIENT_ID_WEB
export const GOOGLE_AUTH_CLIENT_ID_WEB = GLOBAL_ENV.G_CLIENT_ID_WEB || ''

if (typeof GOOGLE_AUTH_CLIENT_ID_WEB !== 'string') {
  const msg = `GOOGLE_AUTH_CLIENT_ID_WEB not a string: ${GOOGLE_AUTH_CLIENT_ID_WEB}`
  raiseErr(msg)
}

if (GOOGLE_AUTH_CLIENT_ID_WEB.length < 50) {
  const msg = `GOOGLE_AUTH_CLIENT_ID_WEB envvar too short: ${GOOGLE_AUTH_CLIENT_ID_WEB}`
  raiseErr(msg)
}
//#endregion GOOGLE_AUTH_CLIENT_ID_WEB
//#region GOOGLE_AUTH_CLIENT_ID_REVERSED_WEB
export const GOOGLE_AUTH_CLIENT_ID_REVERSED_WEB =
  GOOGLE_AUTH_CLIENT_ID_WEB.split('.').reverse().join('.')

if (typeof GOOGLE_AUTH_CLIENT_ID_REVERSED_WEB !== 'string') {
  const msg = `GOOGLE_AUTH_CLIENT_ID_REVERSED_WEB not a string: ${GOOGLE_AUTH_CLIENT_ID_REVERSED_WEB}`
  raiseErr(msg)
}

if (GOOGLE_AUTH_CLIENT_ID_REVERSED_WEB.length < 50) {
  const msg = `GOOGLE_AUTH_CLIENT_ID_REVERSED_WEB envvar too short: ${GOOGLE_AUTH_CLIENT_ID_REVERSED_WEB}`
  raiseErr(msg)
}
//#endregion GOOGLE_AUTH_CLIENT_ID_REVERSED_WEB
//#region REACT_APP_FIREBASE_API_KEY
export const REACT_APP_FIREBASE_API_KEY = GLOBAL_ENV.G_API_KEY_WEB || ''

if (typeof REACT_APP_FIREBASE_API_KEY !== 'string') {
  throw new Error(
    `GOOGLE_API_KEY_WEB not a string: ${REACT_APP_FIREBASE_API_KEY}`,
  )
}

// if (REACT_APP_FIREBASE_API_KEY.length < 10) {
//   throw new Error(
//     `REACT_APP_FIREBASE_API_KEY envvar too short: ${REACT_APP_FIREBASE_API_KEY}`,
//   )
// }

export const REACT_APP_FIREBASE_PROJECT_ID = GLOBAL_ENV.FB_PROJECT_ID || ''

if (typeof REACT_APP_FIREBASE_PROJECT_ID !== 'string') {
  const msg = `FIREBASE_PROJECT_ID not a string: ${REACT_APP_FIREBASE_PROJECT_ID}`
  raiseErr(msg)
}

export const REACT_APP_FIREBASE_STORAGE_BUCKET = `${REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`

if (typeof REACT_APP_FIREBASE_STORAGE_BUCKET !== 'string') {
  const msg = `FIREBASE_STORAGE_BUCKET not a string: ${REACT_APP_FIREBASE_STORAGE_BUCKET}`
  raiseErr(msg)
}

export const REACT_APP_FIREBASE_AUTH_DOMAIN = `${REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`

if (typeof REACT_APP_FIREBASE_AUTH_DOMAIN !== 'string') {
  const msg = `REACT_APP_FIREBASE_AUTH_DOMAIN not a string: ${REACT_APP_FIREBASE_AUTH_DOMAIN}`
  raiseErr(msg)
}

export const REACT_APP_ALGOLIA_API_KEY = GLOBAL_ENV.ALGOLIA_API_KEY || ''

if (typeof REACT_APP_ALGOLIA_API_KEY !== 'string') {
  const msg = `ALGOLIA_API_KEY not a string: ${REACT_APP_ALGOLIA_API_KEY}`
  raiseErr(msg)
}

if (REACT_APP_ALGOLIA_API_KEY.length < 10) {
  const msg = `ALGOLIA_API_KEY envvar too short: ${REACT_APP_ALGOLIA_API_KEY}`
  raiseErr(msg)
}

export const REACT_APP_ALGOLIA_APP_ID = GLOBAL_ENV.ALGOLIA_APP_ID || ''

if (typeof REACT_APP_ALGOLIA_APP_ID !== 'string') {
  const msg = `ALGOLIA_APP_ID not a string: ${REACT_APP_ALGOLIA_APP_ID}`
  raiseErr(msg)
}

if (REACT_APP_ALGOLIA_APP_ID.length < 10) {
  const msg = `ALGOLIA_APP_ID envvar too short: ${REACT_APP_ALGOLIA_APP_ID}`
  raiseErr(msg)
}

//#region BASE_URL
export const REACT_APP_BASE_URL = GLOBAL_ENV.BASE_URL || ''

if (typeof REACT_APP_BASE_URL !== 'string') {
  const msg = `REACT_APP_BASE_URL not a string: ${REACT_APP_BASE_URL}`
  raiseErr(msg)
}

// 'x.com'.length === 5
if (REACT_APP_BASE_URL.length < 5) {
  const msg = `BASE_URL envvar too short: ${REACT_APP_BASE_URL}`
  raiseErr(msg)
}

if (REACT_APP_BASE_URL.endsWith('/')) {
  const msg = `BASE_URL envvar must not end in slash: ${REACT_APP_BASE_URL}`
  raiseErr(msg)
}

const [beforeDot, afterDot] = REACT_APP_BASE_URL.split('.')

if (!beforeDot || !afterDot) {
  const msg = `BASE_URL malformed: ${REACT_APP_BASE_URL}`
  raiseErr(msg)
}
//#endregion BASE_URL
//#region COMPANY_INSULATION
const COMPANY_INSULATION_UNPARSED = GLOBAL_ENV.COMPANY_INSULATION || ''

if (typeof COMPANY_INSULATION_UNPARSED !== 'string') {
  const msg = `REACT_APP_COMPANY_INSULATION envvar not a string: ${COMPANY_INSULATION_UNPARSED}`
  raiseErr(msg)
}

const companyInsulationKVPairs = COMPANY_INSULATION_UNPARSED.split(':')

if (companyInsulationKVPairs.length !== companiesLen) {
  const msg = `Did not provide the same amount of insulation descriptors (${companyInsulationKVPairs.length}) (${COMPANY_INSULATION_UNPARSED}) as there are companies (${companiesLen}): ${COMPANY_VALUES}`
  raiseErr(msg)
}

for (const companyInsulationKVPair of companyInsulationKVPairs) {
  if (
    companyInsulationKVPair.length < 3 ||
    companyInsulationKVPair.includes(':') ||
    !companyInsulationKVPair.includes('=')
  ) {
    const msg = `Malformed company insulation envvar: ${COMPANY_INSULATION_UNPARSED}`
    raiseErr(msg)
  }
}

export const companyToInsulationTypes: Readonly<
  Record<string, readonly string[]>
> = (() => {
  const res: Record<string, readonly string[]> = {}

  for (const companyInsulationKVPair of companyInsulationKVPairs) {
    const [company, insulationTypesUnparsed] =
      companyInsulationKVPair.split('=')

    if (!company || !insulationTypesUnparsed) {
      const msg = `Malformed company insulation envvar: ${COMPANY_INSULATION_UNPARSED}`
      raiseErr(msg)
      return {}
    }
    if (!COMPANY_VALUES.includes(company)) {
      const msg = `Provided a company for insulation types (${company}) that doesn't exist in the sub companies list: ${COMPANY_VALUES_UNPARSED}`
      raiseErr(msg)
      return {}
    }
    const insulationTypes = insulationTypesUnparsed.split(',')

    for (const it of insulationTypes) {
      if (it.length < 3 || it.includes(',') || it.includes(':')) {
        const msg = `Malformed company insulation envvar: ${COMPANY_INSULATION_UNPARSED}`
        raiseErr(msg)
        return {}
      }
    }

    res[company] = insulationTypes
  }

  return res
})()
//#endregion COMPANY_INSULATION
//#region companyColors
const COMPANY_COLORS_UNPARSED = GLOBAL_ENV.COMPANY_COLORS || ''

if (typeof COMPANY_COLORS_UNPARSED !== 'string') {
  const msg = `REACT_APP_COMPANY_COLORS envvar not a string: ${COMPANY_COLORS_UNPARSED}`
  raiseErr(msg)
}

const companyColorsKVPairs = COMPANY_COLORS_UNPARSED.split(':')

if (companyColorsKVPairs.length !== companiesLen) {
  const msg = `Did not provide the same amount of company colors (${companyColorsKVPairs.length}) (${COMPANY_COLORS_UNPARSED}) as there are companies (${companiesLen}): ${COMPANY_VALUES}`
  raiseErr(msg)
}

for (const companyColorsKVPair of companyColorsKVPairs) {
  if (
    companyColorsKVPair.length < 3 ||
    companyColorsKVPair.includes(':') ||
    !companyColorsKVPair.includes('=')
  ) {
    const msg = `Malformed company colors envvar: ${COMPANY_COLORS_UNPARSED}`
    raiseErr(msg)
  }
}

const allowedHexChars: readonly string[] = '0123456789ABCDEF'.split('')

export const companyColors: Readonly<Record<string, string>> = (() => {
  const res: Record<string, string> = {}

  for (const companyColorsKVPair of companyColorsKVPairs) {
    const [company, _color] = companyColorsKVPair.split('=')

    if (!company || !_color) {
      const msg = `Malformed company colors envvar: ${COMPANY_COLORS_UNPARSED}`
      raiseErr(msg)
      return {}
    }
    const color = _color!.toUpperCase()
    if (!COMPANY_VALUES.includes(company)) {
      const msg = `Provided color for a company (${company}) that doesn't exist in the companies list: ${COMPANY_VALUES_UNPARSED}`
      raiseErr(msg)
      return {}
    }
    if (color.length !== 7 || !color.startsWith('#')) {
      const msg = `Colors must be hexadecimal, got: ${color}`
      raiseErr(msg)
      return {}
    }
    const hexValues = color.split('#')

    if (
      !hexValues.every((v) =>
        v.split('').every((c) => allowedHexChars.includes(c)),
      )
    ) {
      const msg = `Colors must be hexadecimal, got: ${color}`
      raiseErr(msg)
      return {}
    }

    res[company] = color
  }

  return res
})()
//#endregion companyColors
//#region logo
export const validBase64Prefixes = [
  'data:image/jpeg;base64,',
  'data:image/png;base64,',
  'data:image/svg+xml;base64,',
] as const

export const LOGO_BIG = process.env['REACT_APP_LOGO_BIG'] || ''

const logoBigIsValid = validBase64Prefixes.some((p) => LOGO_BIG.startsWith(p))

if (!logoBigIsValid) {
  const msg = 'Invalid format type string of REACT_APP_LOGO_BIG'

  raiseErr(msg)
}

export const LOGO_BIG_DARK = process.env['REACT_APP_LOGO_BIG_DARK'] || ''

const logoBigDarkIsValid = validBase64Prefixes.some((p) =>
  LOGO_BIG_DARK.startsWith(p),
)

if (!logoBigDarkIsValid && LOGO_BIG_DARK) {
  const msg = 'Invalid format type string of REACT_APP_LOGO_BIG_DARK'

  raiseErr(msg)
}
//#endregion logo
//#region displayName
export const DISPLAY_NAME = GLOBAL_ENV.DISPLAY_NAME || ''

if (typeof DISPLAY_NAME !== 'string') {
  const msg = 'Invalid type value for REACT_APP_DISPLAY_NAME'
  raiseErr(msg)
}

if (DISPLAY_NAME.length < 1) {
  const msg = 'TypeError: Value for REACT_APP_DISPLAY_NAME is too short'
  raiseErr(msg)
}
//#endregion displayName
//#region companyStates
const COMPANY_STATES = (GLOBAL_ENV.COMPANY_STATES || '').split(
  ':',
) as readonly string[]

if (!Array.isArray(COMPANY_VALUES)) {
  throw new TypeError(
    `Malformed company_values envvar (${JSON.stringify(COMPANY_VALUES)})`,
  )
}
if (!Array.isArray(COMPANY_STATES)) {
  throw new TypeError(
    `Malformed company_states envvar (${JSON.stringify(COMPANY_STATES)})`,
  )
}

if (!COMPANY_VALUES.every((v) => typeof v === 'string')) {
  throw new TypeError(
    `Malformed company_values envvar (${JSON.stringify(COMPANY_VALUES)})`,
  )
}

if (!COMPANY_STATES.every((v) => typeof v === 'string')) {
  throw new TypeError(
    `Malformed company_states envvar (${JSON.stringify(COMPANY_STATES)})`,
  )
}

if (COMPANY_VALUES.length !== COMPANY_STATES.length) {
  throw new Error(
    `Company values not the same length (${JSON.stringify(
      COMPANY_VALUES,
    )}) as states (${JSON.stringify(COMPANY_STATES)})`,
  )
}

export const getCompanyState = (company: string): string => {
  const idx = COMPANY_VALUES.indexOf(company)
  if (idx === -1) {
    throw new ReferenceError(
      `getCompanyState() -> requested state for company (${company}) not in company_values (${JSON.stringify(
        COMPANY_VALUES,
      )})`,
    )
  }
  return COMPANY_STATES[idx]
}
//#endregion companyStates

export const themeTuple =
  Theming.themeTuples[CODENAME] || Theming.defaultThemeTuple
export const { dark, light } = themeTuple
export { upsLeaf } from './theming'
export type { Layer, SurfaceType, Theme } from './theming'
