import React from 'react'

import { useLocation } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'

import { ColorModeContext } from '../../App'

export default function HandleTheme() {
  const { pathname } = useLocation()
  const theme = useTheme()

  const { toggleColorMode } = React.useContext(ColorModeContext)

  React.useEffect(() => {
    if (
      pathname.endsWith('/auditor') ||
      pathname.endsWith('/customer') ||
      pathname.endsWith('/debts') ||
      pathname.endsWith('/services')
    ) {
      return
    }

    if (!localStorage.getItem('theme-ecohome')) {
      localStorage.setItem(
        'theme-ecohome',
        theme.palette.mode === 'dark' ? 'light' : 'dark',
      )
      toggleColorMode()

      return
    }

    localStorage.setItem('theme-ecohome', theme.palette.mode)
  }, [pathname, theme.palette.mode, toggleColorMode])

  React.useEffect(() => {
    if (
      pathname.endsWith('/auditor') ||
      pathname.endsWith('/customer') ||
      pathname.endsWith('/debts') ||
      pathname.endsWith('/services')
    ) {
      if (theme.palette.mode === 'dark') {
        localStorage.removeItem('theme-ecohome')
        toggleColorMode()
      }
    }
  }, [pathname, theme.palette.mode, toggleColorMode])

  return null
}
