import React from 'react'
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  useColorScheme,
} from 'react-native'

import * as c from '../../common'
import * as common from '../../common'
import { Customer } from '../../common'

interface OptionGroupProps {
  buttons: common.Opts
  disabled?: boolean
  field?: keyof Customer
  onPress: (index: common.Opt['value']) => void
  selectedValue?: common.Opt['value']
}

export default React.memo<OptionGroupProps>(function OptionGroup({
  buttons,
  disabled,
  field,
  onPress,
  selectedValue,
}) {
  const [localValue, setLocalValue] = React.useState<common.Opt['value']>(
    selectedValue || '',
  )

  React.useEffect(() => {
    setLocalValue(selectedValue || '')
  }, [selectedValue])

  const isEmptySelection = React.useMemo(() => {
    return (
      (field === 'solarRep' ||
        field === 'solarCompany' ||
        field === 'homeRep' ||
        c.isDropdownField(field) ||
        !field) &&
      !localValue
    )
  }, [field, localValue])

  const handlePress = React.useCallback(
    (value: string) => {
      onPress(value)
      setLocalValue(value)
    },
    [onPress],
  )

  const currValueNotInOpts = React.useMemo(
    () => !!selectedValue && !buttons.find((o) => o.value === selectedValue),
    [buttons, selectedValue],
  )

  const renderOption = React.useCallback(
    (item: common.Opt, index: number) => {
      return (
        <Option
          disabled={!!disabled || currValueNotInOpts}
          key={item.value}
          index={index}
          item={item}
          onChange={handlePress}
          selectedValue={localValue}
          totalItems={buttons.length - 1}
        />
      )
    },
    [buttons.length, currValueNotInOpts, disabled, handlePress, localValue],
  )
  return (
    <View>
      <View
        style={isEmptySelection ? styles.contentButtonError : styles.content}
      >
        {buttons.map(renderOption)}
      </View>
      {isEmptySelection && (
        <Text style={styles.textError}>! Select an option</Text>
      )}
    </View>
  )
})

interface OptionProps {
  readonly disabled: boolean
  readonly index: number
  readonly item: common.Opt
  readonly onChange: (value: common.Opt['value']) => void
  readonly selectedValue?: common.Opt['value']
  readonly totalItems: number
}

const Option = React.memo<OptionProps>(
  ({ disabled, index, item, onChange, selectedValue, totalItems }) => {
    const colorScheme = useColorScheme()

    const buttonStyles = React.useMemo(
      () => [
        styles.button,
        item.value === selectedValue && styles.selectedButton,
        {
          borderTopStartRadius: index === 0 ? 100 : 0,
          borderBottomLeftRadius: index === 0 ? 100 : 0,
          borderTopEndRadius: index === totalItems ? 100 : 0,
          borderBottomRightRadius: index === totalItems ? 100 : 0,
          borderLeftWidth: index % 4 ? 1 : 0,
        },
        colorScheme === 'dark' &&
          item.value !== selectedValue &&
          styles.buttonDark,
        disabled && styles.disabled,
      ],
      [colorScheme, disabled, index, item.value, selectedValue, totalItems],
    )
    const textStyles = React.useMemo(
      () => [
        styles.buttonText,
        item.value === selectedValue && styles.selectedButtonText,
        colorScheme === 'dark' &&
          item.value !== selectedValue &&
          styles.buttonTextDark,
      ],
      [colorScheme, item.value, selectedValue],
    )

    const onPress = React.useCallback(() => {
      onChange(item.value)
    }, [item.value, onChange])

    if (disabled) {
      return (
        <View style={buttonStyles}>
          <Text style={textStyles}>{item.label}</Text>
        </View>
      )
    }
    return (
      <TouchableOpacity
        activeOpacity={0.8}
        onPress={onPress}
        style={buttonStyles}
      >
        <Text style={textStyles}>{item.label}</Text>
      </TouchableOpacity>
    )
  },
)

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: '#E4E4E4',
    borderColor: 'rgba(0,0,0,0.1)',
    flex: 1,
    height: 40,
    justifyContent: 'center',
  },
  buttonDark: {
    backgroundColor: '#1c1c1c',
  },
  buttonText: {
    color: '#000',
    fontFamily: c.themeTuple.light.fontFamily,
    fontSize: 14,
    paddingHorizontal: 15,
    textAlign: 'center',
  },
  buttonTextDark: {
    color: '#B0B0B0',
  },
  content: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 5,
  },
  contentButtonError: {
    alignItems: 'center',
    borderColor: 'red',
    borderRadius: 10,
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 10,
  },
  disabled: {
    backgroundColor: 'grey',
  },
  selectedButton: {
    backgroundColor: c.themeTuple.light.paper.highlight,
  },
  selectedButtonText: {
    color: '#FFF',
  },
  textDark: {
    color: '#FFF',
  },
  textError: {
    color: 'red',
    marginLeft: 5,
    marginTop: 10,
  },
})
