import React from 'react'
import Highcharts, { Options, PointOptionsObject } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { FormatPrice } from '../../helpers/FormatPrice'

export interface SolarChartProps {
  contentUser: 'ALARM' | 'SOLAR' | 'NORMAL'
  monthlyAlertPayment: number[]
  ppaPayments: number[]
  ppaTwoPayments: number[]
  solarLen: number
  solarPayments: number[]
  yearlyPayments: number[]
}

// TODO: Why no React.memo()?
export default function SolarChart({
  contentUser,
  monthlyAlertPayment,
  ppaPayments,
  ppaTwoPayments,
  solarLen,
  solarPayments,
  yearlyPayments,
}: SolarChartProps) {
  const setMarker = (index: number): boolean => {
    if (
      index / 12 === 0 ||
      index / 12 === 5 ||
      index / 12 === 10 ||
      index / 12 === 15 ||
      index / 12 === 20 ||
      index / 12 === 25 ||
      index / 12 === 30
    ) {
      return true
    } else {
      return false
    }
  }

  const xLabels = React.useMemo(() => {
    const _xLabels = new Array(361).fill(0).map((_, i) => {
      if (setMarker(i)) {
        return (i / 12).toString()
      } else {
        return (i / 12).toString()
      }
    })

    return _xLabels
  }, [])

  const solarMonthlyAmounts: Array<
    number | [number | string, number | null] | null | PointOptionsObject
  > = React.useMemo(() => {
    if (solarPayments.length) {
      let monthlyPayments: object[] = []
      solarPayments.forEach((value, i) => {
        if (i === solarLen) {
          console.log('solarLen', solarLen)
          monthlyPayments.push(
            {
              y: solarPayments[0] ? solarPayments[0] / 12 : 0,
              x: i * 12,
              marker: { enabled: setMarker(i * 12) },
            },
            { y: 0, x: i * 12, marker: { enabled: setMarker(i * 12) } },
          )
        } else {
          monthlyPayments.push({
            y: value / 12,
            x: i * 12,
            marker: { enabled: setMarker(i * 12) },
          })
        }
      })

      return monthlyPayments
    } else {
      return []
    }
  }, [solarPayments, solarLen])

  const ppaMonthlyAmounts: Array<
    number | [number | string, number | null] | null | PointOptionsObject
  > = React.useMemo(() => {
    if (ppaPayments.length) {
      let monthlyPayments: object[] = []
      ppaPayments.forEach((value, i) => {
        if (i === solarLen) {
          monthlyPayments.push(
            {
              y: ppaPayments[i] ? ppaPayments[i]! / 12 : 0,
              x: i * 12,
              marker: { enabled: setMarker(i * 12) },
            },
            { y: 0, x: i * 12, marker: { enabled: setMarker(i * 12) } },
          )
        } else {
          monthlyPayments.push({
            y: value / 12,
            x: i * 12,
            marker: { enabled: setMarker(i * 12) },
          })
        }
      })

      return monthlyPayments
    } else {
      return []
    }
  }, [solarLen, ppaPayments])

  const ppaTwoMonthlyAmounts: Array<
    number | [number | string, number | null] | null | PointOptionsObject
  > = React.useMemo(() => {
    if (ppaTwoPayments.length) {
      let monthlyPayments: object[] = []
      ppaTwoPayments.forEach((value, i) => {
        if (i === solarLen) {
          monthlyPayments.push(
            {
              y: ppaTwoPayments[i] ? ppaTwoPayments[i]! / 12 : 0,
              x: i * 12,
              marker: { enabled: setMarker(i * 12) },
            },
            { y: 0, x: i * 12, marker: { enabled: setMarker(i * 12) } },
          )
        } else {
          monthlyPayments.push({
            y: value / 12,
            x: i * 12,
            marker: { enabled: setMarker(i * 12) },
          })
        }
      })

      return monthlyPayments
    } else {
      return []
    }
  }, [ppaTwoPayments, solarLen])

  const energyYearPayments: Array<
    number | [number | string, number | null] | null | PointOptionsObject
  > = React.useMemo(() => {
    if (yearlyPayments.length) {
      let monthlyPayments: object[] = []
      yearlyPayments.forEach((value, i) => {
        monthlyPayments.push({
          y: value / 12,
          x: i * 12,
          marker: { enabled: setMarker(i * 12) },
        })
      })

      return monthlyPayments
    } else {
      return []
    }
  }, [yearlyPayments])

  const _monthlyAlertPayments: Array<
    number | [number | string, number | null] | null | PointOptionsObject
  > = React.useMemo(() => {
    if (monthlyAlertPayment.length) {
      let monthlyPayments: object[] = []
      monthlyAlertPayment.forEach((value, i) => {
        monthlyPayments.push({
          y: Math.round(value),
          x: i * 12,
          marker: { enabled: setMarker(i * 12) },
        })
      })

      return monthlyPayments
    } else {
      return []
    }
  }, [monthlyAlertPayment])

  Highcharts.setOptions({
    lang: {
      //@ts-expect-error
      numericSymbols: [null, null, null, null, null],
    },
  })
  const options: Options = {
    accessibility: {
      enabled: false,
    },
    title: {
      text: '',
    },
    chart: {
      type: 'spline',
      width: 600,
    },
    xAxis: {
      title: { text: 'Years', textAlign: 'center' },
      tickInterval: 60,
      categories: xLabels,
    },

    yAxis: {
      title: { text: 'USD $' },
      crosshair: true,
      tickPixelInterval: 60,
    },

    credits: {
      enabled: false,
    },

    series: [
      {
        data:
          solarPayments.reduce((acc, item) => {
            return (acc = acc + item)
          }) === 0
            ? []
            : solarMonthlyAmounts,
        color: '#2B8A3E',
        name: 'SOLAR',
        type: 'line',
        showInLegend: contentUser !== 'SOLAR',
      },

      {
        data:
          yearlyPayments.reduce((acc, item) => {
            return (acc = acc + item)
          }) === 0 || !yearlyPayments
            ? []
            : energyYearPayments,
        color: '#D9480F',
        name: 'Energy',
        type: 'line',
      },

      {
        data:
          monthlyAlertPayment.reduce((acc, item) => {
            return (acc = acc + item)
          }) === 0
            ? []
            : _monthlyAlertPayments,
        color: '#000',
        name: 'Alarm',
        type: 'line',
        dashStyle: 'LongDash',
        showInLegend: contentUser === 'ALARM',
        visible: contentUser === 'ALARM',
      },

      {
        data:
          ppaPayments.reduce((acc, item) => {
            return (acc = acc + item)
          }) === 0
            ? []
            : ppaMonthlyAmounts,
        color: '#b186f1',
        name: 'PPA 1',
        type: 'line',
        showInLegend: contentUser === 'SOLAR',
      },

      {
        data:
          ppaTwoPayments.reduce((acc, item) => {
            return (acc = acc + item)
          }) === 0
            ? []
            : ppaTwoMonthlyAmounts,
        color: '#2B8A3E',
        name: 'PPA 2',
        type: 'line',
        showInLegend: contentUser === 'SOLAR',
      },
    ],
    tooltip: {
      formatter: function () {
        //@ts-ignore
        return `Year: <b>${parseInt(this.x)}</b></br>Energy: <b>${
          //@ts-ignore
          yearlyPayments[parseInt(this.x)] / 12 === undefined
            ? 0
            : FormatPrice(
                //@ts-ignore
                Math.round(yearlyPayments[parseInt(this.x)] / 12),
                '$',
              )
          // Math.round(yearlyPayments[this.x] / 12).toLocaleString('en')
        }</b><br/>${
          contentUser !== 'SOLAR'
            ? //@ts-ignore
              `Solar: <b>${
                //@ts-ignore
                !isNaN(solarPayments[parseInt(this.x)])
                  ? //@ts-ignore
                    FormatPrice(solarPayments[parseInt(this.x)] / 12, '$')
                  : 0
              }</b></br>`
            : ''
        }
        ${
          contentUser === 'SOLAR'
            ? `PPA 1: <b>${
                //@ts-expect-error
                ppaTwoPayments[parseInt(this.x)] / 12 === undefined
                  ? 0
                  : '$ ' +
                    Math.round(
                      //@ts-expect-error
                      ppaTwoPayments[parseInt(this.x)] / 12,
                    ).toLocaleString('en')
              }</b><br/>`
            : ''
        }
        ${
          contentUser === 'SOLAR'
            ? `PPA 2: <b>${
                //@ts-expect-error
                ppaPayments[parseInt(this.x)] / 12 === undefined
                  ? 0
                  : '$ ' +
                    Math.round(
                      //@ts-expect-error
                      ppaPayments[parseInt(this.x)] / 12,
                    ).toLocaleString('en')
              }</b><br/>`
            : ''
        }
        
        ${
          //@ts-ignore
          solarPayments[parseInt(this.x)] === undefined
            ? `Savings: <b>${FormatPrice(
                //@ts-expect-error
                Math.round(yearlyPayments[parseInt(this.x)] / 12),
                '$',
              )}</b></br>`
            : //@ts-expect-error
            yearlyPayments[parseInt(this.x)] -
                //@ts-expect-error
                solarPayments[parseInt(this.x)] ===
              undefined
            ? 0
            : 'Savings: <b>' +
              FormatPrice(
                Math.round(
                  //@ts-expect-error
                  (yearlyPayments[parseInt(this.x)] -
                    //@ts-expect-error
                    solarPayments[parseInt(this.x)]) /
                    12,
                ),
                '$',
              ) +
              '</b></br>'
        }
          ${
            contentUser === 'ALARM'
              ? `</br>Alarm: <b>$ ${
                  //@ts-expect-error
                  monthlyAlertPayment[parseInt(this.x)]
                    ? //@ts-expect-error
                      monthlyAlertPayment[parseInt(this.x)]
                    : 0
                }</b>`
              : ''
          }`
      },
    },
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}
