import React from 'react'

import { MAX_MPU_RATING, useMpuCalculator } from '../../../../common'

import { clsx } from 'clsx'

import Pad from '../../../comps/Pad'
import gClasses from '../../../global.module.css'

import classes from './MPUCalc.module.css'

export default React.memo(function MPUCalc() {
  const {
    mpuBusRating,
    mpuCalculatorResult,
    mpuMainBreakerRating,
    setMpuBusRating,
    setMPUMainBreakerRating,
  } = useMpuCalculator()

  return (
    <div className={clsx(classes['col'], classes['flex'])}>
      <div
        className={clsx(classes['flex'], classes['row'], classes['justified'])}
      >
        <div
          className={clsx(
            classes['flex'],
            classes['col'],
            classes['aligned'],
            classes['relative'],
            classes['no-wrap'],
          )}
        >
          <div className={clsx(classes['flex'], classes['row'])}>
            <div className={clsx(classes['flex'], classes['col'])}>
              <Input
                label="MPU Bus Rating"
                onChange={setMpuBusRating}
                value={mpuBusRating}
              />

              <Pad amt={24} />

              <Input
                label="MPU Main Breaker Rating"
                onChange={setMPUMainBreakerRating}
                value={mpuMainBreakerRating}
              />
            </div>
          </div>
        </div>

        <Pad amt={128} row />

        <div
          className={clsx(
            classes['flex'],
            classes['col'],
            classes['aligned'],
            classes['relative'],
            classes['no-wrap'],
          )}
        >
          <span className={clsx(classes['center-text'], classes['subtitle'])}>
            Max # of solar panels without derate procedure or MPU replacement:
          </span>

          <Pad amt={12} />

          <span
            className={clsx(
              classes['bold-text'],
              classes['center-text'],
              classes['small-text'],
            )}
          >
            {mpuCalculatorResult?.maxPanelsAsIs || 'N/A'}
          </span>

          <Pad amt={36} />

          <span className={clsx(classes['center-text'], classes['subtitle'])}>
            {mpuCalculatorResult?.maxPanelsAsIs
              ? `Inverter required for ${mpuCalculatorResult.maxPanelsAsIs} panels:`
              : `Inverter required:`}
          </span>

          <Pad amt={12} />

          <span
            className={clsx(
              classes['bold-text'],
              classes['center-text'],
              classes['small-text'],
            )}
          >
            {mpuCalculatorResult?.inverterAsIs?.name || 'N/A'}
          </span>

          <Pad amt={36} />

          <span className={clsx(classes['center-text'], classes['subtitle'])}>
            Max # of solar panels with a derate procedure:
          </span>

          <Pad amt={12} />

          <span
            className={clsx(
              classes['bold-text'],
              classes['center-text'],
              classes['small-text'],
            )}
          >
            {mpuCalculatorResult?.maxPanelsWithDerate ||
              'Derate procedure not possible'}
          </span>

          <Pad amt={36} />

          <span className={clsx(classes['center-text'], classes['subtitle'])}>
            {mpuCalculatorResult?.inverterWithDerate
              ? `Inverter required for ${mpuCalculatorResult.maxPanelsWithDerate} panels:`
              : `Inverter required:`}
          </span>

          <Pad amt={12} />

          <span
            className={clsx(
              classes['bold-text'],
              classes['center-text'],
              classes['small-text'],
            )}
          >
            {mpuCalculatorResult?.inverterWithDerate?.name || 'N/A'}
          </span>
        </div>
      </div>

      <Pad amt={48} />

      <span
        className={clsx(
          classes['bit-bigger-text'],
          classes['center-text'],
          classes['subtitle'],
        )}
      >
        MPU Upgrade is needed if derate not possible
      </span>

      <span
        className={clsx(
          classes['bit-bigger-text'],
          classes['center-text'],
          classes['subtitle'],
        )}
      >
        Max MPU Rating: {MAX_MPU_RATING}
      </span>

      <span
        className={clsx(
          classes['bit-bigger-text'],
          classes['center-text'],
          classes['subtitle'],
        )}
      >
        For installations of 43 panels and upwards contact Pio
      </span>
    </div>
  )
})

interface InputProps {
  readonly label: string
  readonly onChange: (newText: string) => void
  readonly value: string
}

const Input = React.memo<InputProps>(({ label, onChange, value }) => {
  const inputRef = React.useRef(document.createElement('input'))
  const handleClick = React.useCallback(() => {
    inputRef.current.focus()
  }, [])

  const [focused, setFocused] = React.useState(false)
  const didFocus = React.useCallback(() => {
    setFocused(true)
  }, [])
  const didBlur = React.useCallback(() => {
    setFocused(false)
  }, [])

  return (
    <div
      className={clsx(classes['flex'], classes['col'])}
      onClick={handleClick}
    >
      <span
        className={clsx(
          classes['center-text'],
          classes['subtitle'],
          gClasses['unselectable'],
        )}
      >
        {label}
      </span>

      <Pad amt={4} />

      <div
        className={clsx(
          classes['flex'],
          classes['row'],
          focused ? 'top-border-orange' : 'top-border',
          focused ? 'bottom-border-orange' : 'bottom-border',
          classes['round-top-border'],
          classes['round-bottom-border'],
          focused ? 'side-border-orange' : 'side-border',
          classes['bg-w'],
        )}
      >
        <div
          className={clsx(
            classes['right-border'],
            classes['relative'],
            classes['input-symbol'],
          )}
        >
          <span
            className={clsx(
              classes['absolutely-centered'],
              classes['bit-bigger-text'],
              gClasses['unselectable'],
            )}
          >
            #
          </span>
        </div>

        <input
          className={clsx(
            classes['input'],
            classes['input-long'],
            classes['bit-bigger-text'],
          )}
          inputMode="numeric"
          onBlur={didBlur}
          onChange={React.useCallback(
            (e) => {
              onChange(e.target.value)
            },
            [onChange],
          )}
          onFocus={didFocus}
          placeholder="123.45"
          ref={inputRef}
          type="text"
          value={value}
        />
      </div>
    </div>
  )
})
