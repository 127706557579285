import React, { ReactElement } from 'react'

import * as common from '../../../common'

import Pad from '../Pad'

import styles from './PageReport.module.css'

export interface PageReportProps {
  autoHeight?: boolean
  children: ReactElement
  company: string
  companyBorder: boolean
  flexibleWidth?: boolean
  title?: string
  manualHeight?: number
}

export default React.memo<PageReportProps>(function PageReport({
  autoHeight,
  children,
  company,
  companyBorder,
  flexibleWidth,
  title,
  manualHeight,
}) {
  const padCompanyStyle = React.useMemo((): React.CSSProperties => {
    return {
      backgroundColor: common.companyColors[company],
      height: '20px',
      width: '100%',
    }
  }, [company])

  const manualHeightMixin = React.useMemo((): React.CSSProperties => {
    return {
      height: manualHeight + 'px',
    }
  }, [manualHeight])

  return (
    <div
      className={flexibleWidth ? styles['container-flex'] : styles['container']}
    >
      {companyBorder && (
        <>
          {' '}
          <div style={padCompanyStyle}></div>
          <Pad amt={30} />
        </>
      )}

      {title && <h1 className={styles['title']}>{title}</h1>}

      <div
        className={autoHeight ? styles['page-auto-height'] : styles['page']}
        style={manualHeight ? manualHeightMixin : undefined}
      >
        {children}
      </div>
    </div>
  )
})
