import React from 'react'

import { Link, useLocation } from 'react-router-dom'

import * as c from '../../../common'
import * as common from '../../../common'

import * as Mui from '@mui/material'
import * as MuiIcons from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'

import * as cp from '../../../app/components'
import Cashbacks from '../Cashbacks'
import Checkbox from '../Checkbox'
import ConnRowInput from '../ConnRowInput'
import ConnectedInput from '../ConnectedInput'
import DropdownInput from '../DropdownInput'
import InsulationType from '../InsulationType/InsulationType'
import ModalPrice from '../ModalPrice'
import Pad from '../Pad'
import ToastAlert from '../ToastAlert'
import gStyles from '../../global.module.css'
import { IsAutoCalcPrice } from '../../helpers/IsAutoCalcPrice'

export interface CheckGroupProps {
  customer: common.Customer
  customerID: string
  field: keyof common.Customer
  fillAlert?: boolean
  isSubGroup?: boolean
}

export default React.memo<CheckGroupProps>(function CheckGroup({
  customer,
  customerID,
  field,
  fillAlert,
  isSubGroup,
}) {
  const isDark = useMediaQuery('(prefers-color-scheme: dark)')
  const [open, setOpen] = React.useState(false)
  const [priceModalOpen, setPriceModalOpen] = React.useState(false)

  const handlePriceModalOpen = React.useCallback(() => {
    setPriceModalOpen(true)
  }, [setPriceModalOpen])

  const handlePriceModalClose = React.useCallback(() => {
    setPriceModalOpen(false)
  }, [setPriceModalOpen])

  const [err, setErr] = React.useState('')
  const [clearModal, setClearModal] = React.useState(false)

  const selectCustomerField = React.useMemo(
    (): ReturnType<typeof common.makeSelectCustomerField> =>
      common.makeSelectCustomerField(),
    [],
  )
  const selectCustomerFieldArgs = React.useMemo(
    (): common.SelectCustomerFieldParams => ({
      customerID,
      field,
    }),
    [customerID, field],
  )

  // Remember to subscribe to the customer elsewhere!
  const currentValue = common.useSelector(
    (_): common.ValueOf<common.Customer> =>
      selectCustomerField(_, selectCustomerFieldArgs),
  )

  const [checkValue, setCheckValue] = React.useState(currentValue as string)

  const handleCheckValue = React.useCallback(
    (value: string) => {
      setCheckValue(value)
    },
    [setCheckValue],
  )

  const handleClearModal = React.useCallback(() => {
    if (currentValue === 'no') return
    setClearModal((current) => !current)
  }, [currentValue, setClearModal])

  const isErr = React.useCallback(() => {
    if (err) return true
    return false
  }, [err])

  const handleErr = React.useCallback(() => {
    setErr('')
  }, [setErr])
  const handleClose = React.useCallback(() => {
    setOpen((current) => !current)
  }, [setOpen])

  const isSubEfficiency = React.useMemo(
    (): boolean => common.efficiencyKeys.indexOf(field) === -1,
    [field],
  )

  const inputs = React.useMemo((): readonly common.InputConfig[] => {
    let _inputs = common.efficiencyToInputs[field] || []
    if (isSubEfficiency) {
      _inputs = common.subEfficiencyToInputs[field] || []
    }

    return _inputs.filter((inputOrInputs) => {
      if (Array.isArray(inputOrInputs)) {
        const inputs = inputOrInputs
        return inputs.filter((input) =>
          common.shouldDisplayInput(input, customer),
        )
      }
      const input = inputOrInputs
      // TODO: Why is the cast needed?
      return common.shouldDisplayInput(input as common.CustomerField, customer)
    })
  }, [customer, field, isSubEfficiency])

  const showContents = React.useMemo((): boolean => {
    return isSubGroup
      ? inputs.length > 0
      : checkValue === 'yes' || (typeof checkValue === 'boolean' && checkValue)
  }, [isSubGroup, inputs.length, checkValue])

  const validateCashback = React.useCallback(() => {
    if (!customer) {
      return false
    }
    const cashbackOptions = common.parseCashbackOptions(customer.cash_options)
    const cashbackTotal = common.getTotalCashback(cashbackOptions, customer)

    return (
      Number(customer.cash_amount) === cashbackTotal && !!customer?.cash_amount
    )
  }, [customer])

  const handleCleanEfficiency = React.useCallback(() => {
    let batch: c.PartialCustomer = {}

    const customerSchemaKeys = Object.keys(common.CustomerSchema)
    const findCustomerKey = customerSchemaKeys.filter(
      (t) => t.startsWith(`${field}_`) && !roofClaimingFields.includes(t),
    )

    for (let customerSchema of findCustomerKey) {
      batch = {
        ...batch,
        [customerSchema]:
          common.CustomerSchema[customerSchema as keyof common.Customer] ===
          common.YesNo
            ? 'no'
            : '',
      }
    }

    if (!findCustomerKey.length) {
      return
    }

    if (field === 'new_windows')
      batch.new_windows_sqft_each_window = JSON.stringify([c.newWindowGroup()])

    common.updateCustomer(customerID, {
      [field]: 'no',
      ...batch,
    })

    setClearModal(false)
  }, [customerID, field, setClearModal])

  const showCostInputs = React.useMemo(() => {
    if (field !== 'cash') {
      return true
    }

    return validateCashback()
  }, [field, validateCashback])

  const manualUpdateEfficiency = React.useCallback(() => {
    if (!common.efficiencyKeys.includes(field)) {
      return
    }
    common.updateCustomer(customerID, {
      efficiency_updated: field,
    })
  }, [customerID, field])

  React.useEffect(() => {
    setCheckValue(currentValue as string)
  }, [currentValue])

  const subInputMapper = React.useCallback(
    (subfield: c.CustomerField) => {
      if (common.isYesNoOrYesNoEmpty(subfield))
        return (
          <React.Fragment key={customerID + '-' + subfield}>
            <CheckGroup
              customer={customer}
              customerID={customerID}
              field={subfield as keyof common.Customer}
              isSubGroup
            />
            {showCostInputs && (
              <>
                <ConnectedInput
                  customerID={customerID}
                  field={subfield}
                  fillAlert={fillAlert}
                />
                <Mui.Box sx={sx['pad']} />
              </>
            )}
          </React.Fragment>
        )

      if (common.isDropdownField(subfield))
        return (
          <React.Fragment key={customerID + '-' + subfield}>
            <DropdownInput
              customerID={customerID}
              field={subfield}
              fillAlert={fillAlert}
            />
          </React.Fragment>
        )

      return (
        <React.Fragment key={customerID + '>' + subfield}>
          <ConnectedInput
            customerID={customerID}
            field={subfield}
            fillAlert={fillAlert}
          />

          <Pad amt={24} row />
        </React.Fragment>
      )
    },
    [customer, customerID, fillAlert, showCostInputs],
  )
  const inputMapper = React.useCallback(
    (subFieldOrSubfields: c.InputConfig) => {
      if (c.isArray(subFieldOrSubfields)) {
        const subfields = subFieldOrSubfields

        return (
          <Mui.Box
            key={customerID + '-' + subfields.toString()}
            sx={sx['inputWrapper']}
          >
            <Mui.Box sx={sx['inputs']}>{subfields.map(subInputMapper)}</Mui.Box>
          </Mui.Box>
        )
      }

      const field = subFieldOrSubfields

      if (field === 'cash_amount')
        return (
          <Cashbacks
            customer={customer}
            customerID={customerID}
            field={field}
            key={customerID + '-' + field}
          />
        )

      if (field === 'new_windows_sqft_each_window')
        return (
          <ConnRowInput
            customerID={customerID}
            field={field}
            key={customerID + '-' + field}
          />
        )

      if (field === 'new_windows_sliding_glass_sqft')
        return (
          <ConnRowInput
            customerID={customerID}
            field={field}
            key={customerID + '-' + field}
          />
        )

      if (field === 'attic_insulation_type')
        return (
          <InsulationType
            customerID={customerID}
            field={field}
            key={customerID + '-' + field}
          />
        )

      if (field === 'mini_split_tons')
        return (
          <ConnRowInput
            customerID={customerID}
            field={field}
            key={customerID + '-' + field}
          />
        )

      if (c.isYesNoOrYesNoEmpty(field)) {
        return (
          <CheckGroup
            customer={customer}
            customerID={customerID}
            field={field}
            isSubGroup
            key={customerID + '-' + field}
          />
        )
      }

      if (c.isDropdownField(field))
        return (
          <DropdownInput
            customerID={customerID}
            field={field}
            fillAlert={fillAlert}
            key={customerID + '-' + field}
          />
        )

      return (
        <React.Fragment key={customerID + '-' + field}>
          <ConnectedInput
            customerID={customerID}
            field={field}
            fillAlert={fillAlert}
          />

          <Pad amt={20} />
        </React.Fragment>
      )
    },
    [customer, customerID, fillAlert, subInputMapper],
  )

  if (c.shouldHideInput(field, customer)) {
    return <></>
  }

  return (
    <>
      <div className={gStyles['card']}>
        <Mui.Box sx={sx['checkBoxWrapper']}>
          <Checkbox
            checkValue={checkValue}
            customer={customer}
            customerID={customerID}
            field={field}
            handleCheckValue={handleCheckValue}
          />

          <Pad amt={32} row />

          {c.efficiencyKeys.includes(field) && (
            <>
              <ModalPrice
                customer={customer}
                field={field}
                onRequestClose={handlePriceModalClose}
                onRequestOpen={handlePriceModalOpen}
                open={priceModalOpen}
              />
              <Mui.Tooltip
                sx={sx['deleteTooltipIcon']}
                title={`Reset ${common.getFieldLabel(field)}`}
              >
                <MuiIcons.Delete
                  onClick={handleClearModal}
                  sx={isDark ? sx['deleteIconDark'] : sx['deleteIcon']}
                />
              </Mui.Tooltip>

              {(() => {
                if (field === 'glow') {
                  return (
                    <Mui.Tooltip title="Glow Piechart">
                      <GlowPieChartMemoized />
                    </Mui.Tooltip>
                  )
                }
                return null
              })()}
            </>
          )}
        </Mui.Box>

        <Mui.Box sx={showContents ? sx['wrapperContent'] : sx['hidden']}>
          <Pad amt={24} />

          <Mui.Box sx={sx['show']}>{inputs.map(inputMapper)}</Mui.Box>

          {IsAutoCalcPrice(field) && (
            <cp.Button
              alignSelfStart
              // flipColorScheme={false}
              on="canvas"
              onPress={manualUpdateEfficiency}
            >
              Update Prices
            </cp.Button>
          )}
        </Mui.Box>
      </div>
      <Mui.Modal onClose={handleClearModal} open={clearModal}>
        <Mui.Box component={Mui.Paper} sx={sx['boxModal']}>
          <MuiIcons.HighlightOff
            onClick={handleClearModal}
            sx={sx['closeIcon']}
          />
          <Mui.Alert severity="warning" sx={sx['alertClearEfficiency']}>
            Are you sure to restore efficiency?
          </Mui.Alert>
          <Pad amt={30} />
          <Mui.Box sx={sx['clearEfficiencyBox']}>
            <Mui.Button onClick={handleCleanEfficiency} variant="contained">
              Yes
            </Mui.Button>
            <Pad amt={20} row />
            <Mui.Button onClick={handleClearModal} variant="contained">
              No
            </Mui.Button>
          </Mui.Box>
        </Mui.Box>
      </Mui.Modal>

      <ToastAlert
        duration={6000}
        handleClose={handleClose}
        message="Prices Succesfully updated!"
        open={open}
        severity="success"
        type="alert"
      />

      <ToastAlert
        duration={6000}
        handleClose={handleErr}
        message={`Error: ${common.processErr(err)}`}
        open={isErr()}
        severity="error"
        type="alert"
      />
    </>
  )
})

const sx = {
  alertClearEfficiency: {
    fontSize: '18px',
    marginTop: '20px',
    textAlign: {
      sm: 'left',
      xs: 'center',
    },
  },
  alertPrice: {
    width: '100%',
  },
  boxModal: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '18px',
    height: '200px',
    justifyContent: 'center',
    left: '50%',
    padding: '12px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      sm: '500px',
      xs: '320px',
    },
  },
  buttonCalcPrice: {
    marginBottom: '20px',
    width: 200,
    zIndex: 7,
  },
  checkBoxWrapper: {
    alignItems: 'center',
    display: 'flex',
    width: {
      sm: '100%',
      xs: '320px',
    },
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: '10px',
    top: '5px',
  },
  clearEfficiencyBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '10px',
    position: 'relative',
    width: {
      sm: '100%',
    },
  },
  deleteTooltipIcon: {
    display: {
      lg: 'unset',
      xs: 'none',
    },
  },
  deleteIcon: {
    alignSelf: 'flex-start',
    cursor: 'pointer',
    color: c.light.paper.color,
  },
  deleteIconDark: {
    alignSelf: 'flex-start',
    cursor: 'pointer',
    color: c.dark.paper.color,
  },
  hidden: {
    display: 'none',
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputs: {
    display: 'flex',
    flexDirection: {
      sm: 'row',
      xs: 'column',
    },
  },
  pad: { width: '24px' },
  linkPieChart: {
    alignSelf: 'flex-start',
    textDecoration: 'none',
  },
  pieChartIcon: {
    color: 'black',
    width: '25px',
  },
  show: {
    display: 'unset',
  },
  wrapperContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}

const GlowPieChartForwarded = React.forwardRef<HTMLAnchorElement>(
  function GlowPieChart(props, ref) {
    const location = useLocation()

    const linkState = React.useMemo(
      () => ({
        background: location,
      }),
      [location],
    )

    return (
      <Link
        // Mui.Tooltip
        {...props}
        // Mui.Tooltip
        ref={ref}
        state={linkState}
        style={sx['linkPieChart']}
        to={`${location.pathname}/glow`}
      >
        <MuiIcons.PieChart sx={sx['pieChartIcon']} />
      </Link>
    )
  },
)

const GlowPieChartMemoized = React.memo(GlowPieChartForwarded)

const roofClaimingFields = Object.keys(common.RoofClaimingContractData)
