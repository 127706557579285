import React from 'react'

import ReactApexChart from 'react-apexcharts'

export interface PieChartProps {
  data: ApexAxisChartSeries | ApexNonAxisChartSeries
}

export default React.memo<PieChartProps>(function PieChart({ data }) {
  const options: ApexCharts.ApexOptions = {
    chart: {
      height: 'auto',
      type: 'pie',
    },
    colors: ['#ffd500', '#00e676', '#ff4081', '#6200ea'],
    labels: [
      'Federal Tax Credit',
      'Glow Cash Savings',
      'Tax Deduction',
      'Remaining Cost',
    ],
    legend: {
      show: false,
    },
  }

  return (
    <ReactApexChart options={options} series={data} type="pie" width="80%" />
  )
})
