import React from 'react'
import { createPortal } from 'react-dom'

import * as c from '../../../common'
import * as r from '../../../react-utils'

import * as Mui from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import uniq from 'lodash.uniq'
import uniqBy from 'lodash.uniqby'
import { useLoadScript } from '@react-google-maps/api'

import * as milieu from '../../milieu'
import gStyles from '../../global.module.css'
import Pad from '../Pad'

import styles from './AddressInput.module.css'

export interface IPrediction {
  readonly description: string
  readonly place_id?: null | string | undefined
}

export interface AddressInputProps {
  /**
   * The component is coded accounting for this list to not change too
   * frequently.
   */
  readonly additionalPredictions?: readonly IPrediction[]
  readonly disabled?: boolean
  /**
   * Predictions are absolutely positioned.
   */
  readonly distanceFromTop?: number
  readonly inputClassName?: string
  readonly onChangeText: (text: string) => void
  readonly predictionsDOMNode?: HTMLDivElement
  readonly renderMUITextField?: boolean
  readonly value: string
}

export default React.memo<AddressInputProps>(function AddressInput({
  additionalPredictions,
  distanceFromTop,
  disabled,
  inputClassName,
  onChangeText,
  predictionsDOMNode,
  renderMUITextField,
  value,
}) {
  const isMounted = r.useIsMounted()
  const { isLoaded: isPlacesAPILoaded } = useLoadScript({
    googleMapsApiKey: milieu.REACT_APP_GOOGLE_PLACES_API_KEY,
    libraries: c.googleLibraries,
  })

  const input = React.useRef<HTMLInputElement>(r.ghostInput)
  const muiInput = React.useRef<HTMLDivElement>(r.ghostDiv)

  const [autoCompleteService, setAutoCompleteService] =
    React.useState<google.maps.places.AutocompleteService | null>(null)

  React.useEffect(() => {
    if (isPlacesAPILoaded) {
      setAutoCompleteService(new google.maps.places.AutocompleteService())
    }
  }, [isPlacesAPILoaded])

  const predictionsStyleMixin = React.useMemo(
    (): React.CSSProperties => ({
      top:
        distanceFromTop ||
        input.current.offsetHeight ||
        muiInput.current.offsetHeight,
    }),
    [distanceFromTop],
  )

  const [isInputFocused, , _toggleIsInputFocused] = r.useBool(false)
  const toggleIsInputFocused = React.useCallback(() => {
    // TODO: Do this better
    // Give it a bit of time for isPredictionsFocused to become true, else
    // clicking on those won't work.
    setTimeout(() => {
      _toggleIsInputFocused()
    }, 100)
  }, [_toggleIsInputFocused])
  const isInputBlurred = !isInputFocused

  const [isPredictionsFocused, , toggleIsPredictionsFocused] = r.useBool(false)
  const isPredictionsBlurred = !isPredictionsFocused

  const isBlurred = isInputBlurred && isPredictionsBlurred

  const [, setQueryToPredictions] =
    React.useState<c.ReadonlyRecordPartial<readonly IPrediction[]>>(
      emptyHitsRecord,
    )

  const [queryToOpts, setQueryToOpts] =
    React.useState<c.ReadonlyRecordPartial<readonly string[]>>(emptyHitsRecord)

  const predictionSelected = React.useMemo(
    (): boolean => !!queryToOpts[value]?.includes(value),
    [queryToOpts, value],
  )

  React.useEffect(() => {
    if (additionalPredictions) {
      setQueryToPredictions({
        '': additionalPredictions,
      })
      setQueryToOpts({
        '': additionalPredictions?.map((p) => p.description),
      })
    }
  }, [additionalPredictions])

  React.useEffect(() => {
    if (queryToOpts[value]) {
      return
    }

    autoCompleteService?.getPlacePredictions(
      {
        componentRestrictions: { country: 'us' },
        input: value,
      },
      (
        obtainedPredictions:
          | google.maps.places.QueryAutocompletePrediction[]
          | null,
      ) => {
        if (obtainedPredictions && isMounted()) {
          setQueryToPredictions((qTs) => ({
            ...qTs,
            [value]: uniqBy(
              [
                ...(additionalPredictions ||
                  (c.EMPTY_ARRAY as readonly IPrediction[])),
                ...obtainedPredictions.map((obtainedPrediction) => ({
                  description: obtainedPrediction.description,
                  place_id: obtainedPrediction.place_id,
                })),
              ],
              'description',
            ),
          }))
          setQueryToOpts((qTs) => ({
            ...qTs,
            [value]: uniq([
              ...(
                additionalPredictions ||
                (c.EMPTY_ARRAY as readonly IPrediction[])
              ).map((p) => p.description),
              ...obtainedPredictions.map((op) => op.description),
            ]),
          }))
        }
      },
    )
  }, [
    additionalPredictions,
    autoCompleteService,
    isMounted,
    queryToOpts,
    value,
  ])

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeText(e.target.value)
    },
    [onChangeText],
  )

  const currPredictions = queryToOpts[value] || c.emptyArrStr

  const handlePredictionSelect = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      onChangeText(e.currentTarget.dataset['prediction']!)
    },
    [onChangeText],
  )

  const shouldHidePredictions =
    isBlurred || predictionSelected || currPredictions.length === 0

  const predictionsRendered = (
    <div
      className={
        shouldHidePredictions ? gStyles['display-none'] : styles['predictions']
      }
      style={predictionsStyleMixin || undefined}
    >
      {currPredictions.map((opt) => (
        <div
          className={styles['prediction']}
          data-prediction={opt}
          key={opt}
          onBlur={toggleIsPredictionsFocused}
          onClick={handlePredictionSelect}
          onFocus={toggleIsPredictionsFocused}
        >
          <LocationOnIcon sx={sx['locationIcon']} />

          <Pad amt={16} row />

          <span>{opt}</span>
        </div>
      ))}
    </div>
  )

  return (
    <>
      {(() => {
        if (renderMUITextField) {
          return (
            <Mui.TextField
              autoComplete="off"
              className={inputClassName}
              disabled={disabled}
              label="Customer Address"
              name="customerAddress"
              onBlur={toggleIsInputFocused}
              onChange={handleInputChange}
              onFocus={toggleIsInputFocused}
              placeholder="6621 S Harvard Ave"
              ref={muiInput}
              type="search"
              value={value}
            />
          )
        }
        return null
      })()}

      {(() => {
        if (!renderMUITextField) {
          return (
            <input
              autoComplete="off"
              className={inputClassName}
              disabled={disabled}
              name="customerAddress"
              onBlur={toggleIsInputFocused}
              onChange={handleInputChange}
              onFocus={toggleIsInputFocused}
              placeholder={disabled ? '' : '6621 S Harvard Ave'}
              ref={input}
              type="search"
              value={value}
            />
          )
        }
        return null
      })()}

      {(() => {
        if (predictionsDOMNode) {
          return createPortal(predictionsRendered, predictionsDOMNode)
        }
        return null
      })()}

      {(() => {
        if (!predictionsDOMNode) {
          return predictionsRendered
        }
        return null
      })()}
    </>
  )
})

const sx = {
  grid: {
    display: 'flex',
    width: 44,
  },
  gridDescription: {
    width: 'calc(100% - 44px)',
    wordWrap: 'break-word',
  },
  locationIcon: {
    color: 'text.secondary',
  },
}

const emptyHitsRecord = Object.freeze({
  '': [],
} as const)
