import React from 'react'

import * as c from '../../../common'

import * as Mui from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

import Pad from '../Pad'

import styles from './Checkbox.module.css'

export interface CheckboxProps {
  /**
   * Can be empty string.
   */
  checkValue: string
  customer?: c.Customer
  customerID: string
  field: keyof c.Customer
  handleCheckValue: (value: string) => void
}

export default React.memo<CheckboxProps>(function Checkbox({
  checkValue,
  customer,
  customerID,
  field,
  handleCheckValue,
}) {
  const isDark = useMediaQuery('(prefers-color-scheme: dark)')
  const isChecked = checkValue === 'yes'

  const error = React.useMemo(() => {
    return !checkValue && c.fieldShouldBeFilled(field)
  }, [checkValue, field])

  const alertCheckStyle = React.useMemo((): Mui.SxProps<Mui.Theme> => {
    return {
      color: 'error.main',
      display: error ? 'unset' : 'none',
    }
  }, [error])

  const handleCheckbox = React.useCallback(
    (option: 'yes' | 'no' | undefined) => {
      handleCheckValue(option as string)

      c.updateCustomer(customerID, {
        [field]: option as string,
      })
      if (!customer) {
        return
      }
    },
    [customer, customerID, field, handleCheckValue],
  )

  const handleCheckboxYes = React.useCallback(() => {
    handleCheckbox('yes')
  }, [handleCheckbox])

  const handleCheckboxNo = React.useCallback(() => {
    handleCheckbox('no')
  }, [handleCheckbox])

  // React.useEffect(() => {
  //   setCheckbox(currentValue as string)
  // }, [customerID, field, currentValue])

  const isParentCheckbox = React.useMemo(
    () => efficiencies.includes(field),
    [field],
  )
  const nestedCheckYes = checkValue === 'yes'

  const nestedCheckedNo = checkValue === 'no'

  const checkYes = isParentCheckbox ? isChecked : nestedCheckYes

  const checkNo = isParentCheckbox ? !isChecked : nestedCheckedNo

  return (
    <Mui.Box>
      <span style={isDark ? sx['labelDark'] : sx['label']}>
        {c.getFieldLabel(field)}
      </span>

      <div className={styles['input-group']}>
        <Mui.FormControlLabel
          control={
            <Mui.Checkbox
              checked={checkYes}
              onChange={handleCheckboxYes}
              sx={isDark ? sx['checkDark'] : sx['check']}
            />
          }
          label={
            <span style={isDark ? sx['labelDark'] : sx['label']}>Yes</span>
          }
        />

        <Mui.FormControlLabel
          control={
            <Mui.Checkbox checked={checkNo} onChange={handleCheckboxNo} />
          }
          label={<span style={isDark ? sx['labelDark'] : sx['label']}>No</span>}
        />
      </div>

      <Mui.FormHelperText sx={alertCheckStyle}>Empty Value</Mui.FormHelperText>
      <Pad amt={30} />
    </Mui.Box>
  )
})

const sx = {
  check: { color: c.themeTuple.light.paper.highlight },
  checkDark: { color: c.themeTuple.dark.paper.highlight },
  label: {
    color: c.themeTuple.light.paper.color,
    fontFamily: c.themeTuple.light.fontFamily,
  },
  labelDark: {
    color: c.themeTuple.dark.paper.color,
    fontFamily: c.themeTuple.dark.fontFamily,
  },
}

const efficiencies = Object.keys(c.efficiencyToInputs)
