import React from 'react'

import * as r from '../../../react-utils'
import { Customer, updateCustomer } from '../../../common'

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
// import moment from 'moment'

import Pad from '../Pad/Pad'

export interface InsulationTypeProps {
  customerID: string
  field: keyof Customer
}

export default React.memo<InsulationTypeProps>(function InsulationType({
  customerID,
  field,
}) {
  const insulationOpts = r.useInsulation(customerID)
  const [currentInsulationType] = r.useCustomerField(
    customerID,
    'attic_insulation_type',
  )

  const [atticInsulationType, setAtticInsulationType] = React.useState<string>(
    currentInsulationType,
  )

  const handleInsulationType = React.useCallback(
    (e) => {
      setAtticInsulationType(e.target.value)
      updateCustomer(customerID, {
        [field]: e.target.value,
      })
    },
    [customerID, field],
  )

  React.useEffect(() => {
    setAtticInsulationType(currentInsulationType)
  }, [currentInsulationType])

  return (
    <Box sx={sx['container']}>
      <FormControl>
        <Typography>Attic Insulation Type</Typography>
        <Pad amt={20} />
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="insulationType"
          onChange={handleInsulationType}
          row
          value={atticInsulationType}
        >
          {insulationOpts.map((opt) => (
            <FormControlLabel
              control={fromControlLabelControl}
              key={opt.value}
              label={opt.label}
              value={opt.value}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <Pad amt={30} />
    </Box>
  )
})

const sx = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}

const fromControlLabelControl = <Radio />
