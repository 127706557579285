export interface Question {
  question: string
  answer: string[] | undefined
}

// FAQ QUESTIONS

export const questions: Question[] = [
  {
    question:
      'What does this program cost you out of pocket and do you have a deposit?',
    answer: [
      'Absolutely not you will never pay anything out of pocket nor have deposits or ballon payments. Reason being on top of the federal and state incentives you already pay for renewable. When customers switch to solar, like your neighbors you technically payed for them to switch reason being they over produce more than they consume',
    ],
  },

  {
    question:
      'You obviously care about your home and like to take care of it, so what happens when we install on your roof? Well we have a 25 year roof penetration warranty which means if we make a mistake we will take care of your roof from roof or leak damage created by the equipment.',
    answer: [
      'We install the panels on a railing system rather than into the roof, using the railing system into structure of the roof which makes it 195 mph wind resistant wise and it sits an inch and a half off the roof.',
    ],
  },

  {
    question: 'What if the panels break?',
    answer: [
      'Well if something happens with the equipment its under our 25 year warranty remember to please never hire anyone to fix the panels or clean the panels besides US.',
    ],
  },

  {
    question: 'What if I sell my home?',
    answer: [
      'Well if you sell your home you sell your home the thing is there is a UCC-1 which allows you to switch your solar to the next homeowner 2 things will happen you can either transfer the bill over to the next person which the service transfer team will work with your realtors lawyers and escrow agents',
      'Second option is if the new homeowner wants to buy it out right which is the main case if they roll in the price of the solar system into the home selling it for more and that homeowner doesn’t have an electric bill again.',
    ],
  },
  {
    question: ' How solar works?',
    answer: undefined,
  },

  {
    question: 'What do we get out of it how do we stay in business?',
    answer: [
      'Well it’s simple you pay us rather than your local utility you receive a lower rate on your bill rather then the increasing rate. You utility benefits off of the over production selling it for more and you receive a credit back. No out of pocket cost just a lower electric bill.',
    ],
  },
]

// AUTHORIZED USERS
export const authorizedEmails = [
  'castellanorick@gmail.com',
  'erwinjv98@gmail.com',
  'jaron@unitedpowersolutions.com',
  'magabrieladcm@gmail.com',
  'mariag@unitedpowersolutions.com',
  'marial@unitedpowersolutions.com',
  'ricardo@unitedpowersolutions.com',
]

export const solarClosers = [
  'alanf@unitedpowersolutions.com',
  'rickyxp10@hotmail.com',
]

// ADDERS INPUTS
export const addersInputs = ['system_size']

export const addersCustomerInputs = [
  'payment_given_customer',
  'customer_initials',
]

export type Company = 'NPS' | 'CPS' | 'UTPS' | 'TPS' | 'NMPS' | 'APS'
export const companyColors: Partial<Record<Company, string>> = {
  APS: '#EE4127',
  CPS: '#08be90',
  NMPS: '#D8A939',
  NPS: '#ff6a00',
  TPS: '#1A5589',
  UTPS: '#154635',
}

export type Chart =
  | 'SOLAR'
  | 'FINANCING'
  | 'CASH'
  | 'ALARM'
  | 'REPS'
  | undefined
