import * as redux from '@reduxjs/toolkit'
import * as reduxEffects from 'redux-saga/effects'

import * as c from '../../common'
import * as schema from '../schema'

/* eslint-disable @typescript-eslint/no-explicit-any */
type R<T> = T extends Promise<infer U> ? U : T
export type YieldReturn<T> = R<
  ReturnType<T extends (...args: any) => any ? T : any>
>

export const updatedCustomerActionCreator = redux.createAction<{
  customerID: string
  data: Partial<schema.Customer>
}>('common/updatedCustomer')

export function appSelect<TSelected>(
  selector: (state: c.State) => TSelected,
): reduxEffects.SelectEffect {
  return reduxEffects.select(selector)
}

type Dispatcher = (action: redux.Action<any>) => void

let _dispatcher: Dispatcher

export const setDispatcher = (dispatcher: Dispatcher): void => {
  _dispatcher = dispatcher
}

export const dispatch = (action: redux.Action<any>): void => {
  if (!_dispatcher) {
    throw new ReferenceError(
      'Called redux/common/dispatch before setting dispatcher.',
    )
  }

  _dispatcher(action)
}
