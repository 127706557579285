import React from 'react'

import * as c from '../../../common'
import * as r from '../../../react-utils'

import * as Mui from '@mui/material'
import * as MuiIcons from '@mui/icons-material'
import { useLoadScript } from '@react-google-maps/api'
import { Link } from 'react-router-dom'

interface PlaceType {
  description: string
  place_id: string
}

interface Location {
  lat: string
  lng: string
}

interface PlaceInputProps {
  customerID: string
  field: keyof c.Customer
  width?: number
}

export default React.memo<PlaceInputProps>(function PlacesInput({
  customerID,
  field,
  width,
}) {
  const t = r.useTheme()
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: c.globalEnv.G_API_KEY_WEB,
    libraries: c.googleLibraries,
  })

  const [options, setOptions] = React.useState<readonly PlaceType[]>([])
  const [location, setLocation] = React.useState<Location>({
    lat: '0',
    lng: '0',
  })

  const containerRef = React.useRef<HTMLDivElement>(
    document.createElement('div'),
  )

  const isMounted = r.useIsMounted()

  const selectCustomerField = React.useMemo(
    (): ReturnType<typeof c.makeSelectCustomerField> =>
      c.makeSelectCustomerField(),
    [],
  )
  const selectCustomerFieldArgs = React.useMemo(
    (): c.SelectCustomerFieldParams => ({
      customerID,
      field,
    }),
    [customerID, field],
  )
  const listRef = React.useRef<HTMLDivElement>(document.createElement('div'))
  // Remember to subscribe to the customer elsewhere!
  const currentValue = c.useSelector(
    (_): c.ValueOf<c.Customer> =>
      selectCustomerField(_, selectCustomerFieldArgs),
  )

  const [address, setAddress] = React.useState(currentValue)

  const updateAddress = React.useCallback(
    (e) => {
      setAddress(e.target.value)
      listRef.current.style.display = 'unset'
      c.updateCustomer(customerID, {
        [field]: e.target.value,
      })
    },
    [customerID, field, setAddress],
  )

  const handleAddress = React.useCallback(
    (address: string) => {
      listRef.current.style.display = 'none'
      setAddress(address)
    },
    [setAddress],
  )

  const places = options.map((option) => option.description)

  const listItem = React.useCallback(
    (params) => {
      const inputStyle = {
        backgroundColor: t.input.paper.backgroundColor,
        width: {
          lg: width || 298,
          sm: width || 258,
          xs: width || '90%',
        },
      }

      return (
        <Mui.TextField
          inputProps={{
            ...params.inputProps,
          }}
          label="Customer Address"
          onChange={updateAddress}
          sx={inputStyle}
          type="text"
        />
      )
    },
    [t.input.paper.backgroundColor, updateAddress, width],
  )

  React.useEffect(() => {
    if (!isMounted()) {
      return
    }
    if (!isLoaded) {
      return
    }
    new google.maps.places.AutocompleteService().getPlacePredictions(
      {
        componentRestrictions: { country: 'us' },
        input: address as string,
      },
      (
        predictions: google.maps.places.QueryAutocompletePrediction[] | null,
      ) => {
        let results: PlaceType[] = []
        if (predictions) {
          predictions.forEach((value) => {
            results.push({
              description: value.description,
              place_id: value.place_id as string,
            })
          })
          if (!isMounted()) {
            return
          }
          setOptions(results)
        }
      },
    )
  }, [isMounted, address, isLoaded])

  React.useEffect(() => {
    if (!isLoaded) {
      return
    }
    const geocoder = new google.maps.Geocoder()

    geocoder.geocode({ placeId: options[0]?.place_id }, (results, status) => {
      if (status === 'OK') {
        if (results?.length) {
          setLocation({
            lat: results[0]?.geometry.location.lat().toString() || '0',
            lng: results[0]?.geometry.location.lng().toString() || '0',
          })
        }
      }
    })
  }, [isLoaded, options])

  React.useEffect(() => {
    setAddress(currentValue)
  }, [currentValue])

  React.useEffect(() => {
    if (!isMounted()) {
      return
    }
    const outerClick = (e: MouseEvent) => {
      if (containerRef.current.contains(e.target as Node) === false) {
        listRef.current.style.display = 'none'
      }
    }
    document.addEventListener('click', outerClick)
    document.removeEventListener('click', outerClick)
  }, [isMounted])

  const listOptionsStyle = {
    display: 'none',
    left: 0,
    position: 'absolute',
    top: 56,
    width: {
      lg: width || 298,
      sm: width || 258,
      xs: width || '90%',
    },
    zIndex: 100,
  }

  return (
    <Mui.Box sx={sx['container']} ref={containerRef}>
      <Mui.Box sx={sx['autoCompleteAndLink']}>
        <Mui.Autocomplete
          id="map-input"
          options={places}
          renderInput={listItem}
          value={address as string}
        />
        <Link
          target="_blank"
          to={`/customers/${location.lat}/${location.lng}/address`}
        >
          <MuiIcons.Place sx={sx['placeIcon']} />
        </Link>
      </Mui.Box>

      <Mui.List
        component={Mui.Paper}
        ref={listRef}
        sx={width ? listOptionsStyle : sx['listOptions']}
      >
        {options.map((option) => (
          <MuiList
            customerID={customerID}
            field={field}
            handleAddress={handleAddress}
            key={option.description}
            option={option}
          />
        ))}
      </Mui.List>
    </Mui.Box>
  )
})

const MuiList = React.memo(
  ({
    customerID,
    field,
    handleAddress,
    option,
  }: {
    customerID: string
    field: keyof c.Customer
    handleAddress: (address: string) => void
    option: PlaceType
  }) => {
    const onChange = React.useCallback(() => {
      handleAddress(option.description)
      c.updateCustomer(customerID, {
        [field]: option.description,
      })
    }, [customerID, field, handleAddress, option.description])

    return (
      <Mui.ListItem
        key={option.description}
        onClick={onChange}
        sx={sx['listItem']}
      >
        <Mui.Grid alignItems="center" container>
          <Mui.Grid item sx={sx['locationGrid']}>
            <MuiIcons.LocationOn sx={sx['locationOnIcon']} />
          </Mui.Grid>
          <Mui.Grid item sx={sx['descriptionGrid']}>
            <Mui.Typography color="text.secondary" variant="body2">
              {option.description}
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.ListItem>
    )
  },
)

const sx = {
  autoCompleteAndLink: { alignItems: 'center', display: 'flex' },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    position: 'relative',
  },
  descriptionGrid: { width: 'calc(100% - 44px)', wordWrap: 'break-word' },
  listItem: { '&:hover': { backgroundColor: 'gainsboro' }, cursor: 'pointer' },
  listOptions: {
    display: 'none',
    left: 0,
    position: 'absolute',
    top: 56,
    width: { lg: 298, sm: 258, xs: '90%' },
    zIndex: 100,
  },
  locationGrid: { display: 'flex', width: 44 },
  locationOnIcon: { color: 'text.secondary' },
  placeIcon: {
    color: 'primary.main',
    cursor: 'pointer',
    fontSize: '30px',
    marginLeft: '20px',
  },
}
