import { PartialCustomer } from '../schema'
import { _updateCustomer } from '../api'

import createSagaMiddleware from 'redux-saga'
import { AnyAction, Middleware, Store, configureStore } from '@reduxjs/toolkit'
import { useSelector as _useSelector } from 'react-redux'

import { all, call } from 'redux-saga/effects'

import * as utils from '../utils'
import { GetUserID, Storage, setStorage, setUserIDGetter } from '../api'

import * as costs from './costs'
import { bookmarks, bookmarksSaga } from './bookmarks'
import { setDispatcher, updatedCustomerActionCreator } from './common'
import { customers, customersSaga } from './customers'
import { closers, closersSaga } from './closers'
import { efficiencyPrices, efficiencyPriceSaga } from './efficiencyPrices'
import { invoicesSaga, invoices } from './invoices'
import { media, mediaSaga } from './media'
import { setters, settersSaga } from './setters'
import { settings, settingsSaga } from './settings'
import { thumbnails } from './thumbnails'

const sagaMiddleware = createSagaMiddleware({
  onError(e) {
    utils.log('saga error', e)
  },
})

let actionsTypeLoggerEnabled = false
let actionsPayloadLoggerEnabled = false
const loggerMiddleware: Middleware = (_) => (next) => (action) => {
  if (actionsTypeLoggerEnabled || actionsPayloadLoggerEnabled) {
    utils.log('----Store logger---')
  }

  if (actionsTypeLoggerEnabled) {
    utils.log(action.type)
  }

  if (actionsPayloadLoggerEnabled) {
    utils.log('---Payload---')
    utils.log(action.payload)
  }

  if (actionsTypeLoggerEnabled || actionsPayloadLoggerEnabled) {
    utils.log('////Store logger---')
  }

  next(action)
}

const reducers = {
  bookmarks,
  closers,
  costs: costs.costs,
  customers,
  efficiencyPrices,
  invoices,
  media,
  setters,
  settings,
  thumbnails,
}

export type State = utils.DeepReadonly<{
  [K in keyof typeof reducers]: ReturnType<typeof reducers[K]>
}>

let exposedStore: Store | undefined

export const dispatch = (action: unknown) => {
  if (!exposedStore) {
    throw new Error('Tried to dispatch() before initializing the store.')
  }
  exposedStore.dispatch(action as AnyAction)
}

export interface InitStoreParams {
  fireStorage?: Storage
  getUserID?: GetUserID
}

export const initStore = ({ fireStorage, getUserID }: InitStoreParams = {}) => {
  if (!fireStorage) {
    throw new ReferenceError(`fireStorage must be provided to initStore()`)
  }
  setStorage(fireStorage)
  if (!getUserID) {
    throw new ReferenceError(`getUserID must be provided to initStore()`)
  }
  setUserIDGetter(getUserID)

  const store = configureStore({
    reducer: reducers,
    middleware(getDefaultMiddleware) {
      const defaultMiddleware = getDefaultMiddleware({
        serializableCheck: { ignoredPaths: ['settings'] },
        thunk: false,
      })

      return [loggerMiddleware].concat(defaultMiddleware).concat(sagaMiddleware)
    },
  })

  setDispatcher(store.dispatch.bind(store))

  exposedStore = store

  function* rootSaga() {
    yield all([
      call(bookmarksSaga),
      call(closersSaga),
      call(customersSaga),
      call(efficiencyPriceSaga),
      call(invoicesSaga),
      call(mediaSaga),
      call(settersSaga),
      call(settingsSaga),
    ])
  }

  sagaMiddleware.run(rootSaga)

  return store
}

export const useSelector = <TSelected>(
  selector: (state: State) => TSelected,
): TSelected => _useSelector<State, TSelected>(selector)

export const updateCustomer = (
  key: string,
  data: PartialCustomer,
  opts?: {
    skipModifyDate?: boolean | undefined | null
  },
) => {
  if (data.battery_installation_company || data.battery_type) {
    data.battery_size = ''
  }
  dispatch(
    updatedCustomerActionCreator({
      customerID: key,
      data,
    }),
  )
  _updateCustomer(key, data, opts)
}
