import React, { useCallback } from 'react'

import * as common from '../../../common'
import * as r from '../../../react-utils'

import * as Mui from '@mui/material'
import * as MuiIcons from '@mui/icons-material'
import { useLoadScript } from '@react-google-maps/api'
import { ref, update } from 'firebase/database'
import { useLocation } from 'react-router-dom'

import { db } from '../../fire'
import { REACT_APP_GOOGLE_PLACES_API_KEY } from '../../milieu'

interface IPlace {
  description: string
  place_id: string
}

interface DebtsPlaceProps {
  address: string
  consolidationDebtsData: common.ConsolidationDebtData[]
  customerID: string
  disabled: boolean
  page: number
}

export default React.memo<DebtsPlaceProps>(function DebtsPlace({
  address,
  consolidationDebtsData,
  customerID,
  disabled,
  page,
}) {
  const [options, setOptions] = React.useState<readonly IPlace[]>([])
  const [value, setValue] = React.useState(address || '')
  const location = useLocation()
  const listRef = React.useRef<HTMLDivElement>(document.createElement('div'))
  const isMounted = r.useIsMounted()

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_PLACES_API_KEY,
    libraries: common.googleLibraries,
  })

  React.useEffect(() => {
    if (!isMounted()) {
      return
    }
    if (!isLoaded) {
      return
    }
    new google.maps.places.AutocompleteService()?.getPlacePredictions(
      {
        componentRestrictions: { country: 'us' },
        input: value || '',
      },
      (
        predictions: google.maps.places.QueryAutocompletePrediction[] | null,
      ) => {
        let results: IPlace[] = []
        if (predictions) {
          predictions.forEach((value) => {
            results.push({
              description: value.description,
              place_id: value.place_id as string,
            })
          })
          setOptions(results)
        }
      },
    )
  }, [isMounted, address, value, isLoaded])

  const updateAddress = React.useCallback(
    (address: string) => {
      setValue(address)
      const cb = [
        ...consolidationDebtsData.map((value, i) => {
          if (i === page) {
            return {
              ...value,
              address: address,
            }
          }

          return value
        }),
      ]
      update(ref(db, `/Debts/${customerID}`), {
        consolidationDebts: JSON.stringify(cb),
      })
      listRef.current.style.display = 'none'
    },
    [consolidationDebtsData, customerID, page],
  )

  const updateAddressText = React.useCallback(
    (e) => {
      setValue(e.target.value)

      const cb = [
        ...consolidationDebtsData.map((value, i) => {
          if (i === page) {
            return {
              ...value,
              address: e.target.value,
            }
          }

          return value
        }),
      ]

      update(ref(db, `/Debts/${customerID}`), {
        consolidationDebts: JSON.stringify(cb),
      })
      listRef.current.style.display = 'unset'
    },
    [consolidationDebtsData, customerID, page],
  )

  React.useEffect(() => {
    setValue(address)
  }, [address])

  const customerAddressInputRenderer = useCallback(
    (params: Mui.AutocompleteRenderInputParams) => (
      <Mui.TextField
        inputProps={{
          ...params.inputProps,
          type: location.pathname.endsWith('auditor') ? 'password' : 'text',
        }}
        label="Customer Address"
        onChange={updateAddressText}
      />
    ),
    [location.pathname, updateAddressText],
  )

  return (
    <Mui.Box sx={sx['rootStyle']}>
      <Mui.Autocomplete
        disabled={disabled}
        freeSolo
        id="map-input"
        options={options.map((option) => option.description)}
        renderInput={customerAddressInputRenderer}
        value={value}
      />

      <Mui.List component={Mui.Paper} ref={listRef} sx={sx['debtsListStyle']}>
        {options.map(({ description, place_id }) => (
          <Place
            description={description}
            key={place_id}
            onClick={updateAddress}
          />
        ))}
      </Mui.List>
    </Mui.Box>
  )
})

interface PlaceProps {
  description: string
  onClick: (description: string) => void
}

const Place = React.memo<PlaceProps>(({ description, onClick }) => {
  const handleClick = useCallback(() => {
    onClick(description)
  }, [description, onClick])

  return (
    <Mui.ListItem onClick={handleClick} sx={sx['addressOption']}>
      <Mui.Grid alignItems="center" container>
        <Mui.Grid item sx={sx['addressOptionIconContainer']}>
          <MuiIcons.LocationOn sx={sx['addressOptionIcon']} />
        </Mui.Grid>
        <Mui.Grid item sx={sx['addressOptionLabel']}>
          <Mui.Typography color="text.secondary" variant="body2">
            {description}
          </Mui.Typography>
        </Mui.Grid>
      </Mui.Grid>
    </Mui.ListItem>
  )
})

const sx = {
  addressOption: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'gainsboro',
    },
  },
  addressOptionIcon: {
    color: 'text.secondary',
  },
  addressOptionIconContainer: {
    display: 'flex',
    width: 44,
  },
  addressOptionLabel: {
    width: 'calc(100% - 44px)',
    wordWrap: 'break-word',
  },
  debtsListStyle: {
    display: 'none',
    left: 0,
    position: 'absolute',
    top: 56,
    zIndex: 100,
  },
  rootStyle: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    position: 'relative',
  },
}
